import React, { MouseEvent } from "react";
import { QuizCompletionSvg } from "../../../../../../../../../../icons";
import { Stats } from "../../../../../../components/Stats";
import { BaseCompletionCard } from "../BaseCompletionCard";

export type Props = {
  isActive?: boolean;
  attempts?: number;
  maxAttempts: number;
  correctPromptCount: number;
  continueStr?: string;
  quizCompleteStr?: string;
  attemptStr?: string;
  correctAnswersStr?: string;
  onNextCard: (event: MouseEvent<HTMLButtonElement>) => void;
  onCardActive?: () => void;
};

export function GradedQuizCard({
  isActive,
  attempts,
  maxAttempts,
  correctPromptCount,
  continueStr = "Continue",
  quizCompleteStr = "Complete",
  attemptStr = "Attempt",
  correctAnswersStr = "Correct answers",
  onNextCard,
  onCardActive,
}: Props) {
  return (
    <BaseCompletionCard
      isActive={isActive}
      title={`${quizCompleteStr}!`}
      ctaText={continueStr}
      onClick={onNextCard}
      onCardActive={onCardActive}
      showCtaButton={false}
    >
      <QuizCompletionSvg
        style={{ marginLeft: "40px", marginRight: "40px", width: 200 }}
      />
      <Stats
        stats={[
          {
            text: attemptStr,
            value: `${attempts}/${maxAttempts}`,
          },
          { text: correctAnswersStr, value: correctPromptCount },
        ]}
      />
    </BaseCompletionCard>
  );
}
