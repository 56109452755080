import React, { PropsWithChildren, ReactElement } from "react";
import TooltipComponent, { TooltipProps } from "@mui/material/Tooltip";

export function Tooltip({
  children,
  ...props
}: PropsWithChildren<TooltipProps>) {
  let element = children;
  if (!element) {
    return null;
  }

  if (typeof element === "string") {
    element = <span>{element}</span>;
  }

  if (Array.isArray(element)) {
    if (typeof element[0] === "string") {
      element = <span>{element[0]}</span>;
    } else {
      element = element[0] as ReactElement;
    }
  }

  return (
    <TooltipComponent {...props}>{element as ReactElement}</TooltipComponent>
  );
}
