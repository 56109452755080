import React, { HTMLAttributes, ReactNode } from "react";
import { Container, Label, Value, Icon } from "./styled";

export type Props = Omit<HTMLAttributes<HTMLDivElement>, "children"> & {
  text: ReactNode;
  value: ReactNode;
  icon?: ReactNode;
};

export function Stat({ text, value, icon, ...props }: Props) {
  return (
    <Container {...props}>
      <Label>{text}</Label>
      <Value>
        {!!icon && <Icon>{icon}</Icon>}
        {value}
      </Value>
    </Container>
  );
}
