import { createContext, useContext, useMemo, PropsWithChildren } from "react";
import { CCTypes, CCHelpers } from "@bigspring/core-components";
import { useAuth } from "../Authentication";
import adminTracker, { IAdminTrack } from "./adminTracker";
import { useCollections } from "@shared/Collections";
import { identify } from "./helpers";

type AnalyticsProviderContext = {
  client: CCTypes.SegmentPropFunctions | undefined;
  adminTrack: IAdminTrack | undefined;
};

const Context = createContext<AnalyticsProviderContext>({
  client: undefined,
  adminTrack: undefined,
});

export function AnalyticsProvider({ children }: PropsWithChildren<unknown>) {
  const { user, currentTab } = useAuth();
  const { currentCompany } = useCollections();

  const segment = CCHelpers.useSegment(
    !!process.env.NEXT_PUBLIC_SEGMENT_ENABLED
  );

  const identifyData = identify(user, currentCompany.id);
  if (identifyData) {
    void segment.identify(identifyData);
  }

  const adminTrack = useMemo(() => {
    // See the comment in Layout -> Navigation for more information about this
    // This is a temporary solution
    let tab =
      typeof window !== "undefined"
        ? window.sessionStorage.getItem("current-tab") ?? ""
        : "";
    if (currentTab) {
      tab = currentTab;
    }

    if (!currentCompany.id || !user) {
      return;
    }
    const companyProfile = user.companyProfiles.find(
      (companyProfile) => companyProfile.company.id === currentCompany.id
    );

    return adminTracker(segment as CCTypes.SegmentPropFunctions, {
      tab,
      companyProfileId: companyProfile?.id ?? null,
      companyId: currentCompany.id,
    });
  }, [currentCompany.id, currentTab, segment, user]);

  return (
    <Context.Provider
      value={{
        client: segment,
        adminTrack,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useAnalytics() {
  return useContext(Context);
}
