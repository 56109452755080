import { CC } from "@bigspring/core-components";
import { Company } from "@shared/Collections/models/company";
import { useMemo } from "react";
import { MenuButton, Section } from "../../styles";
import { BaseMenu, MenuOption, Props as BaseMenuProps } from "../BaseMenu";
import { CompanySelector } from "../BaseMenu/styles";
import { CreateCompanyButton } from "./styles";

export type Props<Option extends MenuOption> = BaseMenuProps & {
  options: Option[][];
  selectedOption?: Option;
  companies: Company[];
  selectedCompany?: Company;
  onCreateCompanyClick: () => void;
  onOptionClick: (option: Option) => Promise<void>;
  onCompanyChange: (company: Company) => Promise<void>;
};

export function SuperAdminMenu<Option extends MenuOption>({
  label,
  options,
  selectedOption,
  selectedCompany,
  companies,
  onCreateCompanyClick,
  onOptionClick,
  onCompanyChange,
  onOpenMenuClick,
  onCloseMenuClick,
}: Props<Option>) {
  const optionsWithoutSuperAdminOptions = useMemo(
    () => options.filter((option, index) => index > 0),
    [options]
  );

  return (
    <BaseMenu
      label={label}
      onOpenMenuClick={onOpenMenuClick}
      onCloseMenuClick={onCloseMenuClick}
    >
      <Section item container xs={12} direction="column" $borderBottom>
        <CC.FloatMenu
          color="primary"
          element={
            <CompanySelector
              color="utility"
              text={selectedCompany?.name}
              endIcon={<CC.ChevronDownIcon />}
            />
          }
          showFilter
          optionsHeader={
            <CreateCompanyButton
              type="button"
              color="lightblue"
              startIcon={<CC.AddCircleOutlineIcon $large />}
              text="Create Company"
              onClick={onCreateCompanyClick}
            />
          }
          defaultValue={selectedCompany?.id}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          options={companies}
          onChange={onCompanyChange}
        />
        <CC.Grid item xs={12} container direction="column">
          {options[0].map((option) => (
            <CC.Grid key={option.text} item>
              <MenuButton
                text={option.text}
                color="admin-navigation"
                startIcon={option.startIcon(selectedOption === option)}
                selected={selectedOption === option}
                onClick={() => onOptionClick(option)}
              />
            </CC.Grid>
          ))}
        </CC.Grid>
      </Section>

      {optionsWithoutSuperAdminOptions.map((section, index) => (
        <Section
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          item
          xs={12}
          container
          direction="column"
          $borderBottom={index + 1 < optionsWithoutSuperAdminOptions.length}
        >
          {section.map((option) => (
            <CC.Grid key={option.text} item>
              <MenuButton
                text={option.text}
                color="admin-navigation"
                startIcon={option.startIcon(selectedOption === option)}
                selected={selectedOption === option}
                onClick={() => onOptionClick(option)}
              />
            </CC.Grid>
          ))}
        </Section>
      ))}
    </BaseMenu>
  );
}
