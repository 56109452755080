import { useApolloClient } from "@apollo/client";
import { Company } from "@shared/Collections/models/company";
import { usePermissions } from "@shared/PermissionsProvider";
import { useAnalytics } from "components/shared/Analytics";
import { useAuth } from "components/shared/Authentication";
import { useCollections } from "components/shared/Collections";
import { useCustomRouter } from "components/shared/CustomRouter";
import { getPathnameByRouteKey } from "config/routes";
import { useEffect, useMemo } from "react";
import {
  NavigationMenuOptionLabel,
  NAVIGATION_MENU_OPTIONS,
  SelectedRule,
  SUPER_ADMIN_MENU_OPTIONS,
  AdminMenuOption,
} from "./menuOptions";
import { NavigationMenuView } from "./view";
import { FeatureType, Role } from "@gql/generated/graphql";

const ENV = process.env.NEXT_PUBLIC_ENV || "local";

let envLabel: string | undefined;
if (ENV !== "production") {
  if (ENV === "development") {
    envLabel = "Dev";
  } else {
    envLabel = ENV.charAt(0).toUpperCase() + ENV.toLowerCase().slice(1);
  }
}

type Props = {
  onOpenMenuClick: () => void;
  onCloseMenuClick: () => void;
};

function Navigation({ onOpenMenuClick, onCloseMenuClick }: Props) {
  const { logout, setCurrentTab } = useAuth();
  const { adminTrack } = useAnalytics();
  const { companies, currentCompany, changeCurrentCompany, hasCompanyFeature } =
    useCollections();
  const { push, replace, pathname } = useCustomRouter();
  const apolloClient = useApolloClient();
  const { hasRole, hasPermission } = usePermissions();

  const isSuperAdmin = hasRole(Role.SuperAdmin);

  const menuOptions = useMemo(() => {
    if (isSuperAdmin) {
      return SUPER_ADMIN_MENU_OPTIONS.reduce<AdminMenuOption[][]>(
        (sections, section) => {
          const sectionOptions = section.reduce<AdminMenuOption[]>(
            (options, option) => {
              if (option.text === NavigationMenuOptionLabel.LOGOUT) {
                option.action = logout;
              }

              return [...options, option];
            },
            []
          );

          return [...sections, sectionOptions];
        },
        []
      );
    }

    const hasAccess = (option: AdminMenuOption) => {
      if (option.permissions?.length) {
        return hasPermission(option.permissions, currentCompany.id as string);
      }

      return true;
    };

    return NAVIGATION_MENU_OPTIONS.reduce<AdminMenuOption[][]>(
      (sections, section) => {
        let filteredSection: AdminMenuOption[] = [];
        if (hasCompanyFeature(FeatureType.AnonymousUsers)) {
          filteredSection = section.reduce<AdminMenuOption[]>(
            (options, option) => {
              if (option.text === NavigationMenuOptionLabel.LOGOUT) {
                option.action = logout;

                return [...options, option];
              } else if (option.showInAnonymousUsers) {
                return [...options, option];
              }

              return options;
            },
            []
          );
        } else {
          filteredSection = section.reduce<AdminMenuOption[]>(
            (options, option) => {
              if (hasAccess(option)) {
                if (option.text === NavigationMenuOptionLabel.LOGOUT) {
                  option.action = logout;
                }

                return [...options, option];
              }

              return options;
            },
            []
          );
        }

        if (!filteredSection.length) {
          return sections;
        }

        return [...sections, filteredSection];
      },
      []
    );
  }, [
    isSuperAdmin,
    logout,
    hasPermission,
    currentCompany.id,
    hasCompanyFeature,
  ]);

  /**
   * Returns a tuple where the first element represents the section and the second the option position in it.
   */
  const currentMenuItemIndex = useMemo(() => {
    let result: [number, number] | undefined;
    let sectionIndex = 0;

    for (const section of menuOptions) {
      const optionIndex = section.findIndex((option) => {
        if (!option.uri) {
          return false;
        }

        if (option.selectedRule === SelectedRule.STARTS_WITH) {
          return pathname.startsWith(getPathnameByRouteKey(option.uri));
        }

        if (option.selectedRule === SelectedRule.EQUALS) {
          return pathname === getPathnameByRouteKey(option.uri);
        }

        return false;
      });

      if (optionIndex !== -1) {
        // This is used to set the value of currenTab present in Analytics and DataDog providers during initial render.
        // If we call setCurrentTab directly from here we are gonna get a console error
        // because we are updating a parent component when a child is still beign rendered.
        // After the initial render we use the useEffect below to set the currentTab
        // and we use the currentTab variable in those providers.
        window.sessionStorage.setItem(
          "current-tab",
          menuOptions[sectionIndex][optionIndex].text as string
        );

        result = [sectionIndex, optionIndex];

        break;
      }

      sectionIndex++;
    }

    return result;
  }, [menuOptions, pathname]);

  useEffect(() => {
    if (currentMenuItemIndex) {
      const [section, option] = currentMenuItemIndex;

      setCurrentTab(menuOptions[section][option].text as string);
    }
  }, [currentMenuItemIndex, menuOptions, setCurrentTab]);

  const onCompanyChange = async (company: Company) => {
    if (!(await replace("dashboard", { companyId: company.id }))) {
      return;
    }

    sessionStorage.clear();

    changeCurrentCompany(company.id);

    await apolloClient.resetStore();

    window.dispatchEvent(new CustomEvent("companyChange"));
  };

  const onOptionClick = async (option: AdminMenuOption) => {
    const tabsVisible: NavigationMenuOptionLabel[] = [];
    menuOptions.forEach((section) => {
      section.forEach((option) => {
        tabsVisible.push(option.text);
      });
    });

    adminTrack?.navigationMenuOptionSelected({
      tab: option.text as NavigationMenuOptionLabel,
      tabsVisible,
    });

    if (option.uri) {
      void push(option.uri, { companyId: currentCompany.id });

      return;
    }

    option.action?.();
  };

  const selectedOption = useMemo(() => {
    const sectionIndex = currentMenuItemIndex?.[0];
    const optionIndex = currentMenuItemIndex?.[1];

    if (
      typeof sectionIndex !== "undefined" &&
      typeof optionIndex !== "undefined"
    ) {
      return menuOptions[sectionIndex]?.[optionIndex];
    }
  }, [currentMenuItemIndex, menuOptions]);

  return (
    <NavigationMenuView<AdminMenuOption, "superAdminMenu" | "nonSuperAdminMenu">
      as={isSuperAdmin ? "superAdminMenu" : "nonSuperAdminMenu"}
      additionalProps={{
        label: envLabel,
        companies,
        options: menuOptions,
        selectedOption,
        selectedCompany: currentCompany,
        onCreateCompanyClick: () => push("companyCreate"),
        onCompanyChange,
        onOptionClick,
        onOpenMenuClick,
        onCloseMenuClick,
      }}
    />
  );
}

export default Navigation;
