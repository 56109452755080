import BasePaper, { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export const Paper = styled(BasePaper)`
  padding: 20px;
  box-shadow: none;
  border-radius: 4px;
  background-color: #fafaf7;
`;
export type { PaperProps };
