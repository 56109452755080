import { useState, useRef, useMemo, useEffect, MouseEventHandler } from "react";
import { nanoid } from "nanoid";
import type { VideoJsPlayer } from "video.js";
import type { Props } from "./types";
import { useOnScreen } from "../../../utils/useOnScreen";
import { useOnePlayer } from "../../../utils/useOnePlayer";
import { initPlayer, updatePlayer, disposePlayer } from "./player";
import { appendVideojsStyles, removeVideojsStyles } from "./player/styleTag";

// Keep track of the number of players currently rendered so we don't remove <script> and <style> tags used by other instances when we unmount
let instanceCount = 0;

export const useVideoPlayer = (props: Props) => {
  const { isThumbnail = false, options, onClick, videoInline } = props;

  // Hide the player until external styled have loaded
  const [isVisible, setIsVisible] = useState(isThumbnail);

  const [initialized, setInitialized] = useState(false);

  // References to <video> element and Video.js player
  const containerRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<VideoJsPlayer | null>(null);

  // Attach external player ref
  if (props.playerRef) {
    props.playerRef.current = playerRef.current;
  }

  // Click event handler
  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (isThumbnail) {
      e.preventDefault();
    }
    onClick?.(e);
  };

  // Reference to `options` prop for effect dependency
  const optionsRef = useRef(options);

  if (JSON.stringify(optionsRef.current) !== JSON.stringify(options)) {
    optionsRef.current = options;
  }

  useEffect(() => {
    // Init or update player
    if (!videoInline) {
      if (videoRef.current) {
        if (!playerRef.current) {
          const initAndShow = async () => {
            instanceCount += 1;
            await appendVideojsStyles();
            await initPlayer(props, playerRef, videoRef);
            setIsVisible(true);
          };

          void initAndShow();
        } else {
          void updatePlayer(props, playerRef, videoRef);
        }
      }
    }

    // Dispose player on unmount
    return () => {
      if (!videoInline) {
        void disposePlayer(props, playerRef, videoRef);
        // If no more players are rendered, remove appended <script> and <style> tags
        instanceCount -= 1;
        if (!instanceCount) {
          removeVideojsStyles();
        }
      }
    };

    // TODO: Check why we have these dependencies in this hook which see to be a one time hook
  }, [optionsRef, videoRef]);

  useEffect(() => {
    if (videoInline && videoRef.current) {
      if (initialized) {
        void updatePlayer(props, playerRef, videoRef);
      } else {
        const initAndShow = async () => {
          instanceCount += 1;
          await appendVideojsStyles();
          await initPlayer(props, playerRef, videoRef);
          setIsVisible(true);
        };

        void initAndShow();

        setInitialized(true);
      }
    }
  }, [options?.src, videoRef]);

  // Pause when player moves off-screen
  const isOnScreen = useOnScreen(containerRef);

  useEffect(() => {
    if (props.playWhenOnScreen && isOnScreen) {
      const playPromise = playerRef.current?.play();

      playPromise?.catch(() => {
        void playerRef.current?.play();
      });
    }
  }, [props.playWhenOnScreen, isOnScreen]);

  // Enforce only one media element playing at a time
  useOnePlayer(videoRef, !props.ignoreOtherPlayers);

  const id = useMemo(() => `video-player-${nanoid()}`, []);

  return {
    id,
    isVisible,
    containerRef,
    videoRef,
    playerRef,
    handleClick,
  };
};
