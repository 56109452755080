import { css, styled } from "@mui/material/styles";
import { Body1 } from "../../../../../BSPBody1";
import { InnerContainer } from "../../../InnerContainer";

export const Container = styled(InnerContainer)`
  padding: 0;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const text = css`
  ${Body1}
  letter-spacing: 0.004em;
  font-size: 20px;
  line-height: 32px;
`;

export const Label = styled("span")`
  ${text}
  font-weight: 400;
`;

export const Value = styled("div")`
  ${text}
  font-weight: 700;
  display: flex;
  align-items: center;
`;

export const Icon = styled("div")`
  margin-right: 8px;
  margin-top: 10px;
`;
