import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { typewriterTrack } from "../../../lib/events";
import { secondsToTime } from "../../../utils/time";
import { SettingsIcon2 } from "../../../../icons";
import { CircularProgress } from "../CircularProgress";
import { Tooltip } from "../Tooltip";
import { SelectedDevices, SettingsModal } from "./components/SettingsModal";
import {
  ActionsArea,
  CenterMenuWrapper,
  CountDown,
  LeftMenuWrapper,
  LoadingWrapper,
  MenuOption,
  RecordButton,
  RecordWrapper,
  RedDot,
  RightMenuWrapper,
  Time,
  TimeMobile,
} from "./styles";
import {
  AppSource,
  ButtonType,
  ResponseType,
} from "../../../../typewriter/segment";

export enum Option {
  UPLOAD_FILE = "upload_file",
  SETTINGS = "settings",
}

type ExposedMethods = {
  onOptionClick: (type: Option) => boolean;
};

type Props = {
  localizations?: {
    recordAudio?: string;
    audioInput?: string;
    settings?: string;
    ok?: string;
  };
  trackingProps: {
    appSource: "admin" | "pwa";
  };
  onLoaded?: VoidFunction;
  onError?: (error: Error) => void;
  /** Event triggered when count down starts after user clicks on record button */
  onCountDownStarts?: VoidFunction;
  /* Event triggered during audio recording every 1 second */
  onTimeUpdate?: (seconds: number) => void;
  /** Event triggered when audio recording starts after count down */
  onRecordStarts?: VoidFunction;
  /** Event triggered when autio recording ends */
  onRecordEnds: (file: File) => void;
};

const COUNT_DOWN = 3;
const EXPORTED_AUDIO_FORMAT = "audio/webm";
const FALLBACK_EXPORTED_AUDIO_FORMAT = "audio/mp4";

// NOTE: Create a view if component complexity grows more than this
export const AudioRecorder = forwardRef<ExposedMethods, Props>(
  (
    {
      localizations,
      trackingProps,
      onLoaded,
      onError,
      onCountDownStarts,
      onTimeUpdate,
      onRecordStarts,
      onRecordEnds,
    },
    ref
  ) => {
    const streamRef = useRef<MediaStream | null>(null);

    const mediaRecorderRef = useRef<MediaRecorder | null>(null);

    const countDownIntervalRef = useRef<number | null>(null);
    const timerIntervalRef = useRef<number | null>(null);

    const [isLoading, setIsLoading] = useState(false);
    const [hasRecordStarted, setHasRecordStarted] = useState(false);
    const [countDown, setCountDown] = useState(COUNT_DOWN);
    const [seconds, setSeconds] = useState(0);

    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [availableDevices, setAvailableDevices] = useState<MediaDeviceInfo[]>(
      []
    );
    const [selectedDevices, setSelectedDevices] = useState<SelectedDevices>({
      audioDevice: undefined,
    });

    const startStream = useCallback(async (audioDevice?: MediaDeviceInfo) => {
      streamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: audioDevice
          ? {
              deviceId: audioDevice?.deviceId,
            }
          : true,
        video: false,
      });
    }, []);

    const startRecording = () => {
      if (!streamRef.current) {
        return;
      }

      try {
        if (MediaRecorder.isTypeSupported(EXPORTED_AUDIO_FORMAT)) {
          mediaRecorderRef.current = new MediaRecorder(streamRef.current, {
            mimeType: EXPORTED_AUDIO_FORMAT,
          });
        } else {
          mediaRecorderRef.current = new MediaRecorder(streamRef.current, {
            mimeType: FALLBACK_EXPORTED_AUDIO_FORMAT,
          });
        }
      } catch (error) {
        onError?.(error as Error);
      }

      mediaRecorderRef.current?.addEventListener(
        "dataavailable",
        function ({ data }) {
          if (data.size > 0) {
            generateAudio(data);
          }
        }
      );

      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "recording"
      ) {
        mediaRecorderRef.current.start();

        setHasRecordStarted(true);

        startTimer();

        onRecordStarts?.();
      }
    };

    const generateAudio = (data: Blob) => {
      const blob = new Blob([data], {
        type: MediaRecorder.isTypeSupported(EXPORTED_AUDIO_FORMAT)
          ? EXPORTED_AUDIO_FORMAT
          : FALLBACK_EXPORTED_AUDIO_FORMAT,
      });

      onRecordEnds(
        new File([blob], `user-audio-upload-${new Date().getMilliseconds()}`, {
          type: MediaRecorder.isTypeSupported(EXPORTED_AUDIO_FORMAT)
            ? EXPORTED_AUDIO_FORMAT
            : FALLBACK_EXPORTED_AUDIO_FORMAT,
        })
      );
    };

    const startTimer = () => {
      let time = 0;
      timerIntervalRef.current = window.setInterval(() => {
        setSeconds((prevState) => prevState + 1);
        onTimeUpdate?.(++time);
      }, 1000);
    };

    const stopTimer = () => {
      if (timerIntervalRef.current) {
        window.clearInterval(timerIntervalRef.current);
        timerIntervalRef.current = null;
      }

      setSeconds(0);
    };

    const onRecordStop = () => {
      if (!mediaRecorderRef.current) {
        return;
      }

      if (mediaRecorderRef.current.state !== "inactive") {
        mediaRecorderRef.current.stop();

        setHasRecordStarted(false);

        stopTimer();
      }
    };

    const stopStream = () => {
      streamRef.current?.getTracks().forEach((track) => track.stop());

      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current.pause();
      }
    };

    const startCountDown = () => {
      countDownIntervalRef.current = window.setInterval(() => {
        setCountDown((prevState) => {
          if (prevState === 1 && countDownIntervalRef.current) {
            stopCountDown();
            startRecording();
          }

          return prevState - 1;
        });
      }, 1000);

      onCountDownStarts?.();
    };

    const stopCountDown = () => {
      if (countDownIntervalRef.current) {
        window.clearInterval(countDownIntervalRef.current);
        countDownIntervalRef.current = null;
      }

      setCountDown(COUNT_DOWN);
    };

    const onUploadFileClick = () => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "audio/*";

      input.onchange = function (event) {
        const file = (event.target as HTMLInputElement)?.files?.[0];

        if (!file) {
          return;
        }

        if (
          file.type !== "video/mp4" &&
          file.type !== "video/webm" &&
          !file.type.startsWith("audio/")
        ) {
          return onError?.(new Error("File is not supported"));
        }

        onRecordEnds(file);
      };

      input.click();
    };

    const getDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();

      setAvailableDevices(devices);

      return devices;
    };

    useEffect(() => {
      const onDeviceChange = async () => {
        const devices = await getDevices();

        // We need to filter by groupId too because sometimes we can have more than one "default" device ID
        // in different groups.
        // This filter is done in order to remove a current selected device that is not available anymore.
        // E.g: If we are using the headphones microphone and we turn the device off, etc.
        let selectedAudioDevice = devices.find(
          (device) =>
            device.deviceId === selectedDevices.audioDevice?.deviceId &&
            device.groupId === selectedDevices.audioDevice?.groupId
        );

        // If no audio device try selecting the default one. If no default, select the first available
        if (!selectedAudioDevice) {
          selectedAudioDevice = devices.find(
            (device) => device.deviceId === "default"
          );
          if (!selectedAudioDevice) {
            selectedAudioDevice = devices.filter(
              (device) => device.kind === "audioinput"
            )?.[0];
          }
        }

        setSelectedDevices({ audioDevice: selectedAudioDevice });

        void startStream(selectedAudioDevice);
      };

      navigator.mediaDevices.addEventListener("devicechange", onDeviceChange);

      return () => {
        navigator.mediaDevices.removeEventListener(
          "devicechange",
          onDeviceChange
        );
      };
    }, [selectedDevices, startStream]);

    const onSettingsChange = async (selectedDevices: SelectedDevices) => {
      setSelectedDevices(selectedDevices);

      void startStream(selectedDevices.audioDevice);
    };

    useEffect(() => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        return onError?.(new Error("Your browser does not support recording!"));
      }

      const init = async () => {
        try {
          setIsLoading(true);

          await startStream();
          const devices = await getDevices();

          const defaultDevice = devices.find(
            (device) =>
              device.deviceId === "default" && device.kind === "audioinput"
          ) as InputDeviceInfo | undefined;
          if (defaultDevice) {
            setSelectedDevices({ audioDevice: defaultDevice });
          }

          setIsLoading(false);

          onLoaded?.();
        } catch (error) {
          setIsLoading(false);

          onError?.(error as Error);
        }
      };

      void init();

      return () => {
        stopStream();
        stopTimer();
        stopCountDown();
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onOptionClick = (type: Option) => {
      let result = false;

      switch (type) {
        case Option.UPLOAD_FILE:
          onUploadFileClick();

          typewriterTrack("recorderOptionClicked", {
            appSource: trackingProps.appSource as AppSource,
            responseType: ResponseType.Audio,
            buttonType: ButtonType.Upload,
          });

          return true;
        case Option.SETTINGS:
          setShowSettingsModal((prevState) => {
            result = !prevState;

            return result;
          });

          typewriterTrack("recorderOptionClicked", {
            appSource: trackingProps.appSource as AppSource,
            responseType: ResponseType.Audio,
            buttonType: ButtonType.Settings,
          });

          return result;
        default:
          throw new Error(`Unsupported option type ${type}`);
      }
    };

    useImperativeHandle(ref, () => ({
      onOptionClick(type: Option) {
        return onOptionClick(type);
      },
    }));

    if (isLoading) {
      return (
        <LoadingWrapper>
          <CircularProgress color="theme" />
        </LoadingWrapper>
      );
    }

    return (
      <>
        {showSettingsModal && (
          <SettingsModal
            availableDevices={availableDevices}
            selectedDevices={selectedDevices}
            onInputChange={onSettingsChange}
            onAcceptClick={() => onOptionClick(Option.SETTINGS)}
          />
        )}
        <TimeMobile>
          {hasRecordStarted && <RedDot />}
          {secondsToTime(seconds)}
        </TimeMobile>
        <ActionsArea>
          <LeftMenuWrapper />
          <CenterMenuWrapper>
            <RecordWrapper>
              <Tooltip
                title={localizations?.recordAudio ?? "Record audio"}
                placement="top"
              >
                <RecordButton
                  type="button"
                  $hideBackground={!!countDownIntervalRef.current}
                  $showStop={hasRecordStarted}
                  onClick={hasRecordStarted ? onRecordStop : startCountDown}
                >
                  {countDownIntervalRef.current && (
                    <CountDown>{countDown}</CountDown>
                  )}
                </RecordButton>
              </Tooltip>
              <Time>
                {hasRecordStarted && <RedDot />}
                {secondsToTime(seconds)}
              </Time>
            </RecordWrapper>
          </CenterMenuWrapper>
          <RightMenuWrapper>
            <MenuOption
              type="button"
              color="dark"
              icon={<SettingsIcon2 />}
              onClick={() => onOptionClick(Option.SETTINGS)}
              disabled={!!countDownIntervalRef.current || hasRecordStarted}
            />
          </RightMenuWrapper>
        </ActionsArea>
      </>
    );
  }
);

AudioRecorder.displayName = "AudioRecorder";
