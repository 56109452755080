import {
  getPathnameByRoute,
  getPathnameByRouteKey,
  getRoute,
  PRIVATE_ROUTES,
  PrivateRoute,
} from "@config/routes";
import { FeatureType, Permission, Role } from "@gql/generated/graphql";
import { useAuth, User } from "@shared/Authentication";
import { useCollections } from "@shared/Collections";
import { useCustomRouter } from "@shared/CustomRouter";
import {
  useEffect,
  createContext,
  useContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";

type PermissionsProviderContext = {
  hasRole: (roles?: Role[] | Role, companyId?: string) => boolean;
  hasPermission: (
    permissions?: Permission[] | Permission,
    companyId?: string
  ) => boolean;
};

const Context = createContext<PermissionsProviderContext>({
  hasRole: () => false,
  hasPermission: () => false,
});

function getPrivateRoute(
  pathname: string,
  privateRoutes: PrivateRoute[] = []
): PrivateRoute | null {
  const privateRoute = privateRoutes.find(({ route, rule }) => {
    const pathnameRoute = getPathnameByRoute(route);
    if (rule === "startsWith") {
      return pathname.startsWith(pathnameRoute);
    }

    return pathname === pathnameRoute;
  });

  return privateRoute || null;
}

function hasProfilePermission(
  user: User,
  permission: Permission,
  companyId?: string
) {
  if (!companyId || !user) {
    return false;
  }

  if (user.isSuperAdmin) {
    return true;
  }

  const companyProfile = user.companyProfiles?.find(
    (cp) => cp.company.id === companyId
  );

  return !!companyProfile?.permissions?.some((pg) => pg === permission);
}

function isAllowedByPermissions(
  user?: User,
  permissions?: Permission[] | Permission,
  companyId?: string
): boolean {
  // If there is no user or user does not have roles, the resource requested is not available.
  if (!user?.isSuperAdmin && !user?.companyProfiles?.length) {
    return false;
  }

  // No permissions specified means the route is available to all roles.
  if (!permissions) {
    return true;
  }

  if (Array.isArray(permissions)) {
    return permissions.some((permission) =>
      hasProfilePermission(user, permission, companyId)
    );
  }

  return hasProfilePermission(user, permissions, companyId);
}

function hasProfileRole(user: User, role: Role, companyId?: string) {
  if (user?.isSuperAdmin) return true;

  if (companyId) {
    const companyProfile = user?.companyProfiles?.find(
      (cp) => cp.company.id === companyId
    );

    return !!companyProfile?.roles?.some((pg) => {
      return pg === role;
    });
  }

  return false;
}

function isUserAllowedByRoles(
  user?: User,
  roles?: Role[] | Role,
  companyId?: string
): boolean {
  // If there is no user or user does not have roles, the resource requested is not available.
  if (!user?.isSuperAdmin && !user?.companyProfiles?.length) {
    return false;
  }

  // No role specified means the route is available to all roles.
  if (!roles) {
    return true;
  }

  if (Array.isArray(roles)) {
    return roles.some((role) => hasProfileRole(user, role, companyId));
  }

  return hasProfileRole(user, roles, companyId);
}

function isUserAllowedByPermissions(
  user?: User,
  permissions?: Permission[] | Permission,
  company_id?: string
): boolean {
  // If there is no user or user does not have roles, the resource requested is not available.
  if (!user?.isSuperAdmin && !user?.companyProfiles.length) {
    return false;
  }

  // No role specified means the route is available to all roles.
  if (!permissions) {
    return true;
  }

  if (Array.isArray(permissions)) {
    return permissions.some((role) =>
      hasProfilePermission(user, role, company_id)
    );
  }

  return hasProfilePermission(user, permissions, company_id);
}

function hasAccessToRoute(
  pathname: string,
  user?: User,
  companyId?: string,
  supportsAnonymousUsers?: boolean
): boolean {
  if (!PRIVATE_ROUTES.length) {
    return true;
  }

  const privateRoute = getPrivateRoute(pathname, PRIVATE_ROUTES);

  // Always allow access to public routes
  if (!privateRoute) {
    return true;
  }

  if (privateRoute.showInAnonymousUsers && supportsAnonymousUsers) {
    return true;
  }

  if (privateRoute.roles && privateRoute.permissions) {
    return (
      isAllowedByPermissions(user, privateRoute.permissions, companyId) &&
      isUserAllowedByRoles(user, privateRoute.roles, companyId)
    );
  } else if (privateRoute.roles) {
    return isUserAllowedByRoles(user, privateRoute.roles, companyId);
  } else if (privateRoute.permissions) {
    return isAllowedByPermissions(user, privateRoute.permissions, companyId);
  }

  return false;
}

export function PermissionsProvider({ children }: PropsWithChildren<unknown>) {
  const { push, pathname } = useCustomRouter();
  const { user } = useAuth();
  const { currentCompany, hasCompanyFeature } = useCollections();

  const [redirected, setRedirected] = useState(false);

  const pathnameTransformedMemorized = useMemo(
    // transforms from /companies/[companyId]/profiles to /companies/:companyId
    () => pathname?.replace(/\[(.*?)\]/, ":$1"),
    [pathname]
  );

  const hasPermission = useCallback(
    (permissions?: Permission[] | Permission, companyId?: string) =>
      isUserAllowedByPermissions(
        user,
        permissions,
        companyId ?? currentCompany.id
      ),
    [user, currentCompany.id]
  );

  // TODO: We need to move all this logic to a middleware
  const redirectToTheFirstAvailablePage = useCallback(() => {
    if (hasPermission(Permission.Profiles)) {
      void push("profiles");
    } else if (hasPermission(Permission.ProfileGroups)) {
      void push("profileGroups");
    } else if (hasPermission(Permission.LearningPlans)) {
      void push("learningPlans");
    } else if (hasPermission(Permission.Skills)) {
      void push("skills");
    } else if (hasPermission(Permission.RepPrompts)) {
      void push("reps");
    } else if (hasPermission(Permission.RepResponses)) {
      void push("repResponses");
    } else if (hasPermission(Permission.ProfileTags)) {
      void push("profileTags");
    } else if (hasPermission(Permission.CompanyAnalytics)) {
      void push("analytics");
    } else if (hasPermission(Permission.ApiKeys)) {
      void push("apiKeys");
    } else {
      console.debug("Unable to guess default page");
    }
  }, [hasPermission, push]);

  const hasAccessToCurrentRoute = useMemo(
    () =>
      hasAccessToRoute(
        pathname,
        user,
        currentCompany.id,
        hasCompanyFeature(FeatureType.AnonymousUsers)
      ),
    [currentCompany.id, hasCompanyFeature, pathname, user]
  );

  const hasRole = useCallback(
    (roles?: Role[] | Role, companyId?: string) =>
      isUserAllowedByRoles(user, roles, companyId ?? currentCompany.id),
    [user, currentCompany.id]
  );

  useEffect(() => {
    // Company features take precedence over user permissions
    // TODO: Move this to Collections
    if (
      hasCompanyFeature(FeatureType.AnonymousUsers) &&
      !hasRole(Role.SuperAdmin)
    ) {
      if (
        !redirected &&
        PRIVATE_ROUTES.filter((route) => route.showInAnonymousUsers).every(
          (route) => {
            if (route.rule === "startsWith") {
              return !pathname.startsWith(route.route);
            }

            return pathname !== route.route;
          }
        )
      ) {
        setRedirected(true);
        void push("learningPlans");
      }
    } else if (
      !hasAccessToCurrentRoute ||
      pathname === getPathnameByRouteKey("dashboard")
    ) {
      void redirectToTheFirstAvailablePage();
    }
  }, [
    hasAccessToCurrentRoute,
    hasCompanyFeature,
    hasRole,
    pathname,
    push,
    redirectToTheFirstAvailablePage,
    redirected,
    user,
  ]);

  if (
    !hasAccessToCurrentRoute &&
    pathnameTransformedMemorized === getRoute("dashboard")
  ) {
    void redirectToTheFirstAvailablePage();
  }

  if (
    !hasAccessToCurrentRoute ||
    pathnameTransformedMemorized === getRoute("dashboard")
  ) {
    return null;
  }

  return (
    <Context.Provider
      value={{
        hasRole,
        hasPermission,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function usePermissions() {
  return useContext(Context);
}
