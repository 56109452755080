import React from "react";
import {
  GradedQuizCard,
  Props as GradedQuizProps,
} from "./components/GradedQuizCard";
import {
  UngradedQuizCard,
  Props as UngradedQuizProps,
} from "./components/UngradedQuizCard";
import { SurveyCard, Props as SurveyCardProps } from "./components/SurveyCard";
import { FailedCard, Props as FailedCardProps } from "./components/FailedCard";

export enum CompletionCardType {
  GRADED_QUIZ_SUCCEED,
  UNGRADED_QUIZ_SUCCEED,
  SURVEY_SUCCEED,
  FAILED,
}

interface CompletionCardPropsMap {
  [CompletionCardType.GRADED_QUIZ_SUCCEED]: GradedQuizProps;
  [CompletionCardType.UNGRADED_QUIZ_SUCCEED]: UngradedQuizProps;
  [CompletionCardType.SURVEY_SUCCEED]: SurveyCardProps;
  [CompletionCardType.FAILED]: FailedCardProps;
}

type Props<Type extends CompletionCardType> = {
  as: CompletionCardType;
  componentProps: CompletionCardPropsMap[Type];
};

export function CompletionCard<Type extends CompletionCardType>({
  as,
  componentProps,
}: Props<Type>) {
  switch (as) {
    case CompletionCardType.GRADED_QUIZ_SUCCEED:
      return <GradedQuizCard {...(componentProps as GradedQuizProps)} />;
    case CompletionCardType.UNGRADED_QUIZ_SUCCEED:
      return <UngradedQuizCard {...(componentProps as UngradedQuizProps)} />;
    case CompletionCardType.SURVEY_SUCCEED:
      return <SurveyCard {...(componentProps as SurveyCardProps)} />;
    case CompletionCardType.FAILED:
      return <FailedCard {...(componentProps as FailedCardProps)} />;
    default:
      throw new Error(`Unsupported completion card type: ${as}`);
  }
}
