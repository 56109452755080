import { BSPBody2 } from "../BSPBody2";
import { styled } from "@mui/material/styles";

export const SkillTag = styled("div")`
  background-color: ${({ theme }) =>
    theme.kinetic.v2.palette.rgb.grayUiLighter};
  border-radius: 12px;
  padding: 4px 8px;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextDarker};
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
`;

export const SkillTagText = styled(BSPBody2)`
  font-weight: 500;
`;
