import React, { MouseEvent } from "react";
import { QuizCompletionSvg } from "../../../../../../../../../../icons";
import { CtaButton } from "../../../../../../components/CtaButton";
import { ButtonContainer } from "../../../../styles";
import { BaseCompletionCard } from "../BaseCompletionCard";

export type Props = {
  isActive?: boolean;
  continueStr?: string;
  quizCompleteStr?: string;
  onNextCard: (event: MouseEvent<HTMLButtonElement>) => void;
  onReviewClicked: () => void;
  onCardActive?: () => void;
};

export function UngradedQuizCard({
  isActive,
  continueStr = "Continue",
  quizCompleteStr = "Complete",
  onNextCard,
  onReviewClicked,
  onCardActive,
}: Props) {
  return (
    <BaseCompletionCard
      isActive={isActive}
      title={`${quizCompleteStr}!`}
      ctaText={continueStr}
      onClick={onNextCard}
      onCardActive={onCardActive}
      showCtaButton={false}
    >
      <QuizCompletionSvg
        style={{ marginLeft: "40px", marginRight: "40px", width: 200 }}
      />

      <ButtonContainer>
        <CtaButton text={"Review"} onClick={onReviewClicked} />
      </ButtonContainer>
    </BaseCompletionCard>
  );
}
