import AccordionComponent from "@mui/material/Accordion";
import AccordionSummaryComponent from "@mui/material/AccordionSummary";
import AccordionDetailsComponent from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { BSPBody1 } from "../BSPBody1";

const Wrapper = styled(AccordionComponent)`
  border-radius: 8px !important;
  margin-bottom: 20px !important;
  box-shadow: none;
  border: 1px solid ${({ theme }) => theme.colors.border[1]};

  &:before {
    display: none;
  }

  &.MuiAccordion-root.Mui-disabled {
    background-color: ${({ theme }) =>
      theme.kinetic.v2.palette.rgb.grayUiLight};
  }

  ${({ theme }) => theme.breakpoint.maxWidth.sm`
    border-radius: 0 !important;
    border: none;
    margin-bottom: 0 !important;
  `}
`;

const Summary = styled(AccordionSummaryComponent)`
  &.MuiAccordionSummary-root {
    display: flex !important;
    padding: 0 20px !important;

    &.Mui-expanded {
      min-height: unset;
    }

    &.Mui-focusVisible {
      background-color: inherit;
    }

    .MuiAccordionSummary-content {
      width: 90%;
      margin: 20px 0;
    }
  }
`;

const Details = styled(AccordionDetailsComponent)`
  &.MuiAccordionDetails-root {
    padding: 8px 20px 20px;
    white-space: pre-wrap;
  }
`;

const Title = styled(BSPBody1)`
  font-weight: 500 !important;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ExpandIconWrapper = styled("div")`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export * from "./useAccordion";

export const Accordion = {
  Wrapper,
  Summary,
  Title,
  Details,
  ExpandIconWrapper,
};
