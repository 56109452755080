import { Permission, Role } from "@gql/generated/graphql";

export type PrivateRoute = {
  /**
   * The path we are making private, usually an entry in routes config (i.e. routes.dashboard)
   */
  route: string;

  /**
   * If set, user needs to have any of the roles in this list to enter this route.
   */
  roles?: Role[];
  permissions?: Permission[];

  /**
   * Route matching rule.
   *   - 'equals' means window.location.pathname should match exactly with route provided.
   *   - 'startsWith' means window.location.pathname should begin with route provided (i.e. anything under /admin)
   */
  rule?: "startsWith" | "equals";

  /**
   * If set, route will be available for companies with ANONYMOUS_USERS feature flag enabled.
   */
  showInAnonymousUsers?: boolean;
};

export const TITLE_SUFFIX = "Admin | BigSpring";

const COMPANY_PREFIX = "/companies/:companyId";
/**
 * List of named routes
 */
const ROUTES = {
  dashboard: `${COMPANY_PREFIX}`,
  health: "/health",
  login: "/login",
  logout: "/logout",
  maintenance: "/maintenance",
  companyCreate: "/company/new",
  analytics: `${COMPANY_PREFIX}/analytics`,
  apiKeys: `${COMPANY_PREFIX}/api-keys`,
  apiKeysDocs: `${COMPANY_PREFIX}/api-keys/docs`,
  company: `${COMPANY_PREFIX}/edit`,
  customVocabularies: `${COMPANY_PREFIX}/custom-vocabularies`,
  customVocabulariesView: `${COMPANY_PREFIX}/custom-vocabularies/:id/view`,
  customVocabulariesCreate: `${COMPANY_PREFIX}/custom-vocabularies/new`,
  customVocabulariesEdit: `${COMPANY_PREFIX}/custom-vocabularies/:id`,
  duplicateCompetencies: `${COMPANY_PREFIX}/super-admin-scripts/duplicate-competencies`,
  deleteSkillProgress: `${COMPANY_PREFIX}/super-admin-scripts/delete-skill-progress`,
  cloneProfile: `${COMPANY_PREFIX}/super-admin-scripts/clone-profile`,
  cloneSkill: `${COMPANY_PREFIX}/super-admin-scripts/clone-skill`,
  cloneLearningPlan: `${COMPANY_PREFIX}/super-admin-scripts/clone-learning-plan`,
  profiles: `${COMPANY_PREFIX}/profiles`,
  profilesCreate: `${COMPANY_PREFIX}/profiles/new`,
  profilesEdit: `${COMPANY_PREFIX}/profiles/:id`,
  profilesUpload: `${COMPANY_PREFIX}/profiles/upload`,
  profileGroups: `${COMPANY_PREFIX}/profile-groups`,
  profileGroupsCreate: `${COMPANY_PREFIX}/profile-groups/new`,
  profileGroupsUpload: `${COMPANY_PREFIX}/profile-groups/upload`,
  profileGroupsEdit: `${COMPANY_PREFIX}/profile-groups/:id`,
  profileTags: `${COMPANY_PREFIX}/profile-tags`,
  profileTagsCreate: `${COMPANY_PREFIX}/profile-tags/new`,
  profileTagsUpload: `${COMPANY_PREFIX}/profile-tags/upload`,
  profileTagsEdit: `${COMPANY_PREFIX}/profile-tags/:id`,
  skills: `${COMPANY_PREFIX}/skills`,
  skillsCreate: `${COMPANY_PREFIX}/skills/new`,
  skillsEdit: `${COMPANY_PREFIX}/skills/:id`,
  skillsLocalizations: `${COMPANY_PREFIX}/skills/localizations/:id`,
  learningPlans: `${COMPANY_PREFIX}/learning-plans`,
  learningPlansCreate: `${COMPANY_PREFIX}/learning-plans/new`,
  learningPlansEdit: `${COMPANY_PREFIX}/learning-plans/:id`,
  learningPlansLocalizations: `${COMPANY_PREFIX}/learning-plans/localizations/:id`,
  learningPlansUpload: `${COMPANY_PREFIX}/learning-plans/upload`,
  reps: `${COMPANY_PREFIX}/reps`,
  repsLocalizations: `${COMPANY_PREFIX}/reps/localizations/:id`,
  repsCreate: `${COMPANY_PREFIX}/reps/new`,
  repsEdit: `${COMPANY_PREFIX}/reps/:id`,
  repResponses: `${COMPANY_PREFIX}/rep-responses`,
  repResponse: `${COMPANY_PREFIX}/rep-responses/:id`,
  repVideoIntelligence: `${COMPANY_PREFIX}/rep-responses/:id/video-intel`,
  superAdminScripts: `${COMPANY_PREFIX}/super-admin-scripts`,
  topics: `${COMPANY_PREFIX}/topics`,
  topicsCreate: `${COMPANY_PREFIX}/topics/new`,
  topicsEdit: `${COMPANY_PREFIX}/topics/:id`,
  topicsLocalizations: `${COMPANY_PREFIX}/topics/localizations/:id`,
};

/**
 * Private route configuration.
 *
 * NOTE: When the rule applied is "startsWith", the order within the array matters.
 * More specific routes must precede less specifics. E.g.: /reps/responses must be placed before /reps
 */
export const PRIVATE_ROUTES: PrivateRoute[] = [
  {
    route: ROUTES.dashboard,
    rule: "equals",
  },
  {
    route: ROUTES.company,
    rule: "startsWith",
    permissions: [Permission.Admins],
  },
  {
    route: ROUTES.customVocabularies,
    rule: "startsWith",
    permissions: [Permission.Admins],
  },
  {
    route: ROUTES.profiles,
    rule: "startsWith",
    permissions: [Permission.Profiles],
  },
  {
    route: ROUTES.profileGroups,
    rule: "startsWith",
    permissions: [Permission.ProfileGroups],
  },
  {
    route: ROUTES.profileTags,
    rule: "startsWith",
    permissions: [Permission.ProfileTags],
  },
  {
    route: ROUTES.skills,
    rule: "startsWith",
    permissions: [Permission.Skills],
    showInAnonymousUsers: true,
  },
  {
    route: ROUTES.learningPlans,
    rule: "startsWith",
    permissions: [Permission.LearningPlans],
    showInAnonymousUsers: true,
  },
  {
    route: ROUTES.repVideoIntelligence,
    rule: "startsWith",
    permissions: [Permission.RepResponses],
  },
  {
    route: ROUTES.repResponses,
    rule: "startsWith",
    permissions: [Permission.RepResponses],
  },
  {
    route: ROUTES.reps,
    rule: "startsWith",
    permissions: [Permission.RepPrompts],
  },
  {
    route: ROUTES.superAdminScripts,
    rule: "startsWith",
    roles: [Role.SuperAdmin],
  },
  {
    route: ROUTES.logout,
    rule: "equals",
  },
  {
    route: ROUTES.analytics,
    rule: "equals",
    permissions: [Permission.CompanyAnalytics],
  },
  {
    route: ROUTES.apiKeys,
    rule: "equals",
    permissions: [Permission.ApiKeys],
  },
  {
    route: ROUTES.apiKeysDocs,
    rule: "equals",
    permissions: [Permission.ApiKeys],
  },
];

export type AvailableRoutes = keyof typeof ROUTES;

export function getRoute(
  routeKey: AvailableRoutes,
  params: Record<string, string> = {}
): string {
  let route = ROUTES[routeKey];
  for (const [k, v] of Object.entries(params)) {
    route = route.replace(`:${k}`, v);
  }

  return route;
}

/**
 * From: /companies/:companyId/learning-plan/:id
 * To:   /companies/[companyId]/learning-plan/[id]
 *
 * @param routeKey
 * @returns
 */
export function getPathnameByRouteKey(routeKey: AvailableRoutes): string {
  const route = ROUTES[routeKey];

  return getPathnameByRoute(route);
}

/**
 * From: /companies/:companyId/learning-plan/:id
 * To:   /companies/[companyId]/learning-plan/[id]
 *
 * @param routeKey
 * @returns
 */
export function getPathnameByRoute(route: string): string {
  const re = /(:[a-zA-z\-_]+)(?=\/|$)/g;
  const attrs = route.match(re);
  if (!attrs) return route;

  for (const attr of attrs) {
    route = route.replace(attr, `[${attr.slice(1)}]`);
  }

  return route;
}

/**
 * Get /companies/:companyId/**
 *
 * @returns
 */
export function getCompanyIdInRoute(): string | null {
  if (typeof window === "undefined") return null;

  return window.location.pathname.match(/companies\/([^/]+)/)?.[1] ?? null;
}

export default ROUTES;
