import { styled } from "@mui/material/styles";
import RadioMui from "@mui/material/Radio";
import FormControlLabelMui from "@mui/material/FormControlLabel";
import { Body2 } from "../BSPBody2";

export const FormControlLabel = styled(FormControlLabelMui)`
  ${Body2};
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
`;

export const Radio = styled(RadioMui)`
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
  &.Mui-checked {
    color: ${({ theme }) => theme.kinetic.v2.palette.rgb.blue};
    border: ${({ theme }) => theme.kinetic.palette.rgb.gray400};
  }
  &:disabled,
  &.Mui-disabled,
  &.Radio--Disabled {
    color: ${({ theme }) => theme.kinetic.palette.rgb.gray400};
  }
  &:hover:not(.Mui-disabled),
  &.Radio--hover:not(.Mui-disabled) {
    background-color: ${({ theme }) => theme.kinetic.palette.rgb.gray100};
  }
  &:focus:not(.Mui-disabled),
  &.Radio--focus:not(.Mui-disabled),
  &.Mui-focusVisible:not(.Mui-disabled) {
    background-color: ${({ theme }) => theme.kinetic.palette.rgb.blue100};
  }
`;
