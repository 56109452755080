import { styled } from "@mui/material/styles";
import { BSPBody1 } from "../../../BSPBody1";
import { withTransientProps } from "../../../../../lib/emotion";

export const Description = styled(BSPBody1)`
  width: 100%;
  font-weight: 400;
  letter-spacing: 0.004em;
  margin: 0;
  user-select: none;
  white-space: pre-wrap;

  font-size: 20px;
  line-height: 30px;
`;

Description.defaultProps = {
  as: "p",
};

export const InnerContainer = styled("div", withTransientProps)<{
  $isVerticalCard: boolean;
}>`
  display: flex;
  align-items: center;
  padding: ${({ $isVerticalCard }) => ($isVerticalCard ? "0 40px" : "0")};
  width: fill-available;
`;
