import React, { ChangeEventHandler, ChangeEvent, useId, useMemo } from "react";
import { Dialog } from "../../../../../dialog";
import { RadioGroup } from "../../../../../RadioGroup";
import { CircularProgress } from "../../../../../CircularProgress";
import {
  Container,
  DialogHeader,
  DialogContent,
  DialogActions,
  InnerDialogActions,
  SubmitButton,
  ResultRow,
  ResultText,
  PromptContent,
  ChoiceRadio,
  ChoiceCheckbox,
  ChoiceImage,
  TextArea,
  CharacterCount,
} from "./styles";
import { CtaButton } from "../../../../components/CtaButton";
import { ButtonColor } from "../../../../../Button/styles";
import { Grid } from "../../../../../Grid";

export type Choice = {
  id: string;
  type: "TEXT" | "IMAGE" | "INPUT";
  content?: string;
  mediaUrl?: string;
  isCorrect?: boolean;
  isFailed?: boolean;
};

export type Prompt = {
  id: string;
  content: string;
  choices: Choice[];
  isCompleted: boolean | null;
  isMultiple?: boolean;
};

type FormState = {
  resultText?: string;
  color: ButtonColor;
  backgroundColor?: "negative" | "positive";
  text: string;
};

type Props = {
  isReview: boolean;
  isLoading: boolean;
  isGradedQuiz: boolean;
  isSurvey: boolean;
  isOpen: boolean;
  hasAttemptsRemaining: boolean;
  correctToPass?: number;
  promptCount: number;
  prompt: Prompt;
  promptIndex: number;
  promptIsCorrect?: boolean;
  areChoicesDisabled: boolean;
  hasInputChoice: boolean;
  presetChoices: Choice[];
  selectedChoiceIds: Array<string>;
  submittedChoiceIds?: Array<string>;
  input: string;
  trimmedInput: string;
  isCtaEnabled: boolean;
  continueStr: string;
  correctStr: string;
  incorrectStr: string;
  tryAgainStr: string;
  checkAnswerStr: string;
  onChangeChoice: (
    e: ChangeEvent<HTMLInputElement>,
    value: string,
    isMultiple: boolean
  ) => void;
  onChangeInput: ChangeEventHandler<HTMLTextAreaElement>;
  onCloseForm: VoidFunction;
  onSubmit: VoidFunction;
};

export function FormModal({
  isReview,
  isLoading,
  isGradedQuiz,
  isSurvey,
  isOpen,
  promptCount,
  prompt,
  promptIndex,
  promptIsCorrect,
  areChoicesDisabled,
  hasInputChoice,
  presetChoices,
  selectedChoiceIds,
  submittedChoiceIds,
  input,
  trimmedInput,
  isCtaEnabled,
  continueStr,
  correctStr,
  incorrectStr,
  tryAgainStr,
  checkAnswerStr,
  onChangeChoice,
  onChangeInput,
  onCloseForm,
  onSubmit,
}: Props) {
  const modalId = useId();

  let inputState: "valid" | "inactive" | "limit" = "valid";

  if (!trimmedInput.length) {
    inputState = "inactive";
  } else if (trimmedInput.length >= 1000) {
    inputState = "limit";
  }

  const state = useMemo<FormState>(() => {
    if (!submittedChoiceIds?.length) {
      if (isSurvey) {
        return {
          color: "primary",
          text: continueStr,
        };
      }

      return {
        color: "primary",
        text: checkAnswerStr,
      };
    }

    if (!isSurvey) {
      if (promptIsCorrect) {
        return {
          resultText: correctStr,
          color: "positive",
          backgroundColor: "positive",
          text: continueStr,
        };
      }

      if (!isGradedQuiz) {
        return {
          resultText: incorrectStr,
          color: "negative",
          backgroundColor: "negative",
          text: tryAgainStr,
        };
      }

      return {
        resultText: incorrectStr,
        color: "negative",
        backgroundColor: "negative",
        text: continueStr,
      };
    }

    return {
      color: "positive",
      backgroundColor: "positive",
      text: continueStr,
    };
  }, [
    checkAnswerStr,
    continueStr,
    correctStr,
    incorrectStr,
    isGradedQuiz,
    isSurvey,
    promptIsCorrect,
    submittedChoiceIds,
    tryAgainStr,
  ]);

  return (
    <Dialog
      open={isOpen}
      fullScreen
      aria-labelledby={modalId}
      onClose={onCloseForm}
    >
      <DialogHeader isCenter id={modalId} onClose={onCloseForm}>
        {promptIndex + 1}/{promptCount}
      </DialogHeader>
      <DialogContent dividers={false}>
        <Container>
          <PromptContent>{prompt.content}</PromptContent>
          {prompt?.isMultiple ? (
            <Grid>
              {presetChoices.map((choice) => {
                const isImage = choice.type === "IMAGE";

                return (
                  <Grid container key={choice?.id}>
                    <Grid pb={"11px"}>
                      <ChoiceCheckbox
                        key={choice.id}
                        value={selectedChoiceIds.includes(choice.id)}
                        label={
                          isImage ? (
                            <ChoiceImage
                              src={choice.mediaUrl}
                              alt={choice.content}
                            />
                          ) : (
                            choice.content
                          )
                        }
                        onChange={(e) =>
                          onChangeChoice(e, choice.id, !!prompt?.isMultiple)
                        }
                        disabled={isReview || areChoicesDisabled}
                        $isImage={isImage}
                        $isReview={isReview && choice.isCorrect}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <RadioGroup
              value={
                (selectedChoiceIds?.[0] || submittedChoiceIds?.[0]) ?? null
              }
              onChange={(e, value) =>
                onChangeChoice(e, value, !!prompt?.isMultiple)
              }
            >
              {presetChoices.map((choice) => {
                const isImage = choice.type === "IMAGE";

                return (
                  <ChoiceRadio
                    key={choice.id}
                    value={choice.id}
                    label={
                      isImage ? (
                        <ChoiceImage
                          src={choice.mediaUrl}
                          alt={choice.content}
                        />
                      ) : (
                        choice.content
                      )
                    }
                    disabled={isReview || areChoicesDisabled}
                    $isImage={isImage}
                    $isReview={isReview && choice.isCorrect}
                  />
                );
              })}
            </RadioGroup>
          )}

          {hasInputChoice && (
            <>
              <TextArea
                autoFocus
                $state={inputState}
                value={input}
                disabled={areChoicesDisabled}
                onChange={onChangeInput}
              />
              <CharacterCount $state={inputState}>
                {trimmedInput.length}/1000
              </CharacterCount>
            </>
          )}
        </Container>
      </DialogContent>
      <DialogActions
        color={isReview ? undefined : state.backgroundColor}
        $isSubmitted={!!submittedChoiceIds?.length}
      >
        {!!state.resultText && !isReview && (
          <ResultRow>
            <ResultText>{state.resultText}</ResultText>
          </ResultRow>
        )}
        <InnerDialogActions>
          {isReview ? (
            <CtaButton text="Next" onClick={onSubmit} />
          ) : (
            <SubmitButton
              color={state.color}
              align="center"
              type="button"
              disabled={!isCtaEnabled}
              icon={
                isLoading ? (
                  <CircularProgress
                    size="sm"
                    color={isCtaEnabled ? "white" : "black"}
                  />
                ) : undefined
              }
              text={!isLoading ? state.text : undefined}
              onClick={onSubmit}
            />
          )}
        </InnerDialogActions>
      </DialogActions>
    </Dialog>
  );
}
