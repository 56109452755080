import { useEffect, useState, useRef, RefObject } from "react";

/**
 * React hook to detect when an element is on screen, for scroll-dependent behavior.
 * Reference: https://stackoverflow.com/a/67826055

 * @param ref
 * @returns true if element is on screen, false otherwise
 */
export const useOnScreen = (ref: RefObject<HTMLElement>) => {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef<IntersectionObserver | null>(null);

  // Set up the observer
  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting)
    );
  }, []);

  // Start observing the target element on mount
  useEffect(() => {
    if (ref.current) {
      observerRef.current?.observe(ref.current);
    }

    // Stop observing the target element on unmount
    return () => {
      observerRef.current?.disconnect();
    };
  }, [ref]);

  return isOnScreen;
};
