import { SC, CC } from "@bigspring/core-components";
import { motion } from "framer-motion";
import { Button } from "@shared/Button";

export const Wrapper = SC.styled.nav`
  position: relative;
  z-index: 1;
`;

export const Menu = SC.styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.radix.colors.white};
  filter: drop-shadow(
      0px 2px 2px
        ${({ theme }) => theme.kinetic.v2.palette.rgba.grayTextDark(0.16)}
    )
    drop-shadow(
      0px 2px 8px
        ${({ theme }) => theme.kinetic.v2.palette.rgba.grayTextDark(0.24)}
    );

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const MenuIcon = SC.styled(motion.div)`
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 10;
  cursor: pointer;
  padding-left: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) =>
    theme.kinetic.v2.palette.rgb.grayUiLight};
`;

export const Header = SC.styled(CC.Grid)`
  border-bottom: 1px solid
    ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiLight};
  padding: 20px;
`;

export const MenuOpenIcon = SC.styled(CC.MenuOpenIcon)`
  cursor: pointer;
`;

export const BigSpringLogo = SC.styled(CC.BigSpringMarkIcon)`
  width: 125px;
  height: 24px;
`;

export const CompanySelector = SC.styled(Button)`
  border: none;
  border-radius: 0;
  box-shadow: none !important;
  padding: 12px 26px;
  margin-bottom: 5px;
  &:hover, &:focus-visible {
    border: none;
    background-color: transparent;
  }
  span {
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.radix.colors.gray.gray1};
    svg {
      fill: ${({ theme }) => theme.radix.colors.gray.gray1};
    }
  }
`;
