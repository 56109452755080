import {
  CollectionsQuery,
  CountryCode,
  Language,
  Timezone,
} from "@gql/generated/graphql";

type RawCompany = CollectionsQuery["companies"]["nodes"][0];
type RawCompanyFeature = NonNullable<RawCompany["companyFeatures"]>[0];
type RawCompanyLanguage = NonNullable<RawCompany["companyLanguages"]>[0];

export class Company {
  id: string;

  name: string;

  externalId: string;

  countryCode: CountryCode;

  timezone: Timezone;

  defaultLanguage: Language;

  // Replace these by models if needed
  companyFeatures: RawCompanyFeature[];

  companyLanguages: RawCompanyLanguage[];

  constructor(company?: RawCompany) {
    this.id = company?.id ?? "";
    this.name = company?.name ?? "";
    this.externalId = company?.externalId ?? "";
    this.countryCode = company?.countryCode ?? CountryCode.UnitedStates;
    this.timezone = company?.timezone ?? Timezone.UsEastern;
    this.defaultLanguage = company?.defaultLanguage ?? Language.En;
    this.companyFeatures = company?.companyFeatures ?? [];
    this.companyLanguages = company?.companyLanguages ?? [];
  }
}
