import React, {
  useRef,
  useState,
  useEffect,
  ReactEventHandler,
  KeyboardEventHandler,
  useCallback,
} from "react";
import { AudioPlayer } from "./player";
import { useOnScreen } from "../../../utils/useOnScreen";
import { useOnePlayer } from "../../../utils/useOnePlayer";

type AudioHandler = ReactEventHandler<HTMLAudioElement>;

type Props = {
  src?: string;
  preload?: "none" | "metadata" | "auto" | "";
  autoPlay?: boolean;
  loop?: boolean;
  onPlay?: AudioHandler;
  onPause?: AudioHandler;
  onEnded?: AudioHandler;
  onSeeking?: (el: HTMLAudioElement) => void;
  onSeeked?: AudioHandler;
  onTimeUpdate?: AudioHandler;
  onLoadedMetadata?: AudioHandler;
  onError?: AudioHandler;
};

const clamp = (n: number, max = 100, min = 0) =>
  Math.max(min, Math.min(n, max));

const boundInfinite = (n?: number) => {
  if (!n || !Number.isFinite(n)) {
    return 0;
  }

  return Math.max(0, n);
};

/**
 * BigSpring Audio Player
 * @param props
 */
export function AudioPlayerController({
  src,
  preload,
  autoPlay,
  loop,
  onPlay,
  onPause,
  onEnded,
  onSeeking,
  onSeeked,
  onTimeUpdate,
  onLoadedMetadata,
  onError,
}: Props) {
  // Refs
  const containerRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);
  const playheadRef = useRef<HTMLButtonElement>(null);

  // States
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [playheadMarginLeft, setPlayheadMarginLeft] = useState(0);
  const [playedTime, setPlayedTime] = useState(0);
  const [duration, setDuration] = useState(0);

  // Methods
  const getTimes = () => {
    const currentTime = boundInfinite(audioRef.current?.currentTime);
    const duration = boundInfinite(audioRef.current?.duration);
    const percentage = duration ? currentTime / duration : 0;

    return { currentTime, duration, percentage };
  };

  const getTimelineWidth = () =>
    trackRef.current && playheadRef.current
      ? trackRef.current.offsetWidth - playheadRef.current.offsetWidth
      : 0;

  /** Action (play/pause) button handler */
  const handleActionButton: ReactEventHandler = (e) => {
    e.preventDefault();
    const player = audioRef.current;
    if (isLoading || !player) {
      return;
    }
    if (isPlaying) {
      player.pause();
    } else {
      if (audioRef.current.ended) {
        audioRef.current.currentTime = 0;
      }

      void player.play();
    }
  };

  // Audio element event handlers
  const handlePlay: AudioHandler = (e) => {
    if (!isSeeking) {
      setIsPlaying(true);
      onPlay?.(e);
    }
  };

  const handlePause: AudioHandler = (e) => {
    if (!isSeeking) {
      setIsPlaying(false);
      onPause?.(e);
    }
  };

  const handleEnded: AudioHandler = (e) => {
    if (!isSeeking) {
      setIsPlaying(false);
      onEnded?.(e);
    }
  };

  const handleTimeUpdate: AudioHandler = (e) => {
    const { currentTime, percentage } = getTimes();
    setPlayedTime(currentTime);
    setPlayheadMarginLeft(percentage * getTimelineWidth());
    onTimeUpdate?.(e);
  };

  const handleDurationChange: AudioHandler = () => {
    if (!audioRef.current) {
      return;
    }
    const duration = audioRef.current.duration || 0;
    if (Number.isFinite(duration)) {
      setDuration(duration);
      setIsLoading(false);
    } else {
      // Chrome hack — equivalent of hitting the audio player with a hammer
      // https://stackoverflow.com/a/16978083
      // https://bit.ly/3FiYJg9
      // eslint-disable-next-line no-self-assign
      audioRef.current.src = audioRef.current.src;
      setDuration(0);
      setIsLoading(true);
    }
  };

  const handleLoadedMetadata: AudioHandler = (e) => {
    setIsLoading(false);
    handleDurationChange(e);
    onLoadedMetadata?.(e);
  };

  // Playhead seek event handlers
  /** Seek audio using keyboard when user focuses on audio slider */
  const handleSeekKeyDown: KeyboardEventHandler<HTMLButtonElement> = (e) => {
    if (isLoading || !audioRef.current) {
      return;
    }
    const keyCode = e.code;
    if (keyCode === "Space") {
      // Play/pause
      return handleActionButton(e);
    }
    const [, digit = null] = keyCode.match(/^Digit(\d)/) || [];
    const [, arrow = null] = keyCode.match(/^Arrow(.+)/) || [];
    if (digit === null && arrow === null) {
      return;
    }
    const { duration, percentage } = getTimes();
    let percentageResult = percentage;
    if (digit !== null) {
      percentageResult = parseInt(digit) / 10;
    } else if (arrow !== null) {
      switch (arrow) {
        case "Left":
          percentageResult -= 0.01;
          break;
        case "Right":
          percentageResult += 0.01;
          break;
        case "Up":
          percentageResult += 0.1;
          break;
        case "Down":
          percentageResult -= 0.1;
          break;
        default:
          break;
      }
    }
    const seekTime = clamp(percentageResult * duration, duration);
    audioRef.current.currentTime = Math.round(seekTime);
    onSeeking?.(audioRef.current);
  };

  const handleStartSeek = () => {
    if (!isLoading) {
      setIsSeeking(true);
      audioRef.current?.pause();
    }
  };

  const handleEndSeek = useCallback(() => {
    if (!isLoading && isSeeking) {
      setIsSeeking(false);
      const ended = audioRef.current?.ended;
      if (isPlaying && !ended) {
        void audioRef.current?.play();
      } else if (ended) {
        setIsPlaying(false);
      }
    }
  }, [isLoading, isPlaying, isSeeking]);

  const handleSeeking = useCallback(
    <T extends MouseEvent | React.MouseEvent>(e: T) => {
      if (isLoading || !audioRef.current) {
        return;
      }
      const timelineWidth = getTimelineWidth();
      const trackLeft = boundInfinite(
        trackRef.current?.getBoundingClientRect().left
      );
      const seekMarginLeft = clamp(e.clientX - trackLeft, timelineWidth);
      const percentage = seekMarginLeft / timelineWidth;
      const duration = boundInfinite(audioRef.current.duration);
      const seekTime = clamp(percentage * duration, duration);
      audioRef.current.currentTime = seekTime;
      onSeeking?.(audioRef.current);
    },
    [isLoading, onSeeking]
  );

  const handleWindowSeeking = useCallback(
    (e: MouseEvent) => {
      if (isSeeking) {
        handleSeeking(e);
      }
    },
    [handleSeeking, isSeeking]
  );

  /**
   * Listen for mouse move/up/leave events on window/document for easier seeking
   * Allows for seeking when dragging mouse outside audio player container
   */
  useEffect(() => {
    window.addEventListener("mousemove", handleWindowSeeking);
    window.addEventListener("mouseup", handleEndSeek);
    document.addEventListener("mouseleave", handleEndSeek);

    return () => {
      window.removeEventListener("mousemove", handleWindowSeeking);
      window.removeEventListener("mouseup", handleEndSeek);
      document.removeEventListener("mouseleave", handleEndSeek);
    };
  }, [handleWindowSeeking, handleEndSeek]);

  /** Pause audio when player moves off-screen */
  const isOnScreen = useOnScreen(containerRef);
  useEffect(() => {
    if (!isOnScreen) {
      audioRef.current?.pause();
    }
  }, [isOnScreen]);

  /** Enforce only one media element playing at a time */
  useOnePlayer(audioRef);

  return (
    <AudioPlayer
      {...{
        src,
        preload,
        autoPlay,
        loop,
        duration,
        playedTime,
        playheadMarginLeft,
        isLoading,
        isPlaying,
        containerRef,
        audioRef,
        trackRef,
        playheadRef,
        handleActionButton,
        handleSeekKeyDown,
        handleStartSeek,
        handleSeeking,
        handleSeeked: onSeeked,
        handlePlay,
        handlePause,
        handleEnded,
        handleTimeUpdate,
        handleDurationChange,
        handleLoadedMetadata,
        handleError: onError,
      }}
    />
  );
}
