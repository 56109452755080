import React from "react";
import { BaseCard, CardProps } from "../BaseCard";
import { SkillCompletionIcon, Wrapper } from "./styles";
import { Title } from "../../components/Title";
import { AlignedContent } from "../../styles";
import { CtaButton } from "../../components/CtaButton";
import { ButtonContainer } from "../FormCard/styles";
import { BSPBody1 } from "../../../BSPBody1";

export type ResetCardProps = CardProps & {
  titleStr: string;
  descriptionStr: string;
  nextStr?: string;
  onRedoClick?: () => void;
};

export function ResetCard({
  isActive,
  titleStr,
  descriptionStr,
  nextStr = "Next",
  onRedoClick,
}: ResetCardProps) {
  return (
    <Wrapper>
      <BaseCard isActive={isActive}>
        <AlignedContent $align="center">
          <Title text={titleStr} />
          <BSPBody1>{descriptionStr}</BSPBody1>
          <SkillCompletionIcon />
          <ButtonContainer>
            <CtaButton
              text={nextStr}
              onClick={() => {
                document.dispatchEvent(
                  new CustomEvent("bigspring::skill::menu::refetchData")
                );
                onRedoClick?.();
              }}
            />
          </ButtonContainer>
        </AlignedContent>
      </BaseCard>
    </Wrapper>
  );
}
