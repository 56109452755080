import { CheckIcon, ClearIcon } from "../../../../../../../../icons";
import { css, styled } from "@mui/material/styles";

export const ActionsWrapper = styled("div")`
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 200px;
  z-index: 1;
  ${({ theme }) => theme.breakpoint.maxWidth.small`
    width: 140px;
  `}
`;

export const Button = styled("button")`
  cursor: pointer;
  background: transparent;
  border: none;
  box-shadow: 0 0 20px 12px rgb(50, 50, 50);
  border-radius: 50%;
  padding: 0;
  width: 50px;
  height: 50px;
  opacity: 0.8;
  transition: opacity 200ms ease;
  &:hover {
    opacity: 1;
  }
  ${({ theme }) => theme.breakpoint.maxWidth.small`
    width: 30px;
    height: 30px;
  `}
`;

const BaseIcon = css`
  padding: 10px;
  width: 100%;
  height: 100%;
  /* We need border radius here as well for Chrome */
  border-radius: 50%;
`;

export const CancelIcon = styled(ClearIcon)`
  ${BaseIcon};
  fill: black;
  background-color: white;
`;

export const AcceptIcon = styled(CheckIcon)`
  ${BaseIcon};
  background-color: ${({ theme }) => theme.kinetic.v2.palette.rgb.positive};
  fill: white;
`;
