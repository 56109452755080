import { CC } from "@bigspring/core-components";
import React, { forwardRef, MouseEventHandler } from "react";
import { ButtonProps } from "./Button";
import { useAnalytics } from "@shared/Analytics";

export type ButtonLinkProps = Omit<ButtonProps, "onClick"> & {
  href?: string;
  target?: string;
  rel?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      onClick: _onClick,
      href,
      target,
      rel,
      text,
      title,
      "aria-label": ariaLabel,
      ...props
    },
    ref
  ) => {
    const { adminTrack } = useAnalytics();

    const onClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
      adminTrack?.buttonClicked(
        (typeof text === "string" && text) || title || ariaLabel
      );

      _onClick?.(event);
    };

    return (
      <CC.Link
        underline="none"
        ref={ref}
        href={href}
        target={target}
        rel={rel}
        onClick={onClick}
      >
        <CC.Button
          {...props}
          text={text}
          title={title}
          aria-label={ariaLabel}
        />
      </CC.Link>
    );
  }
);

ButtonLink.displayName = "ButtonLink";
