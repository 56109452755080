import { SC, CC } from "@bigspring/core-components";
import { Button } from "@shared/Button";

const { styled, css } = SC;

export const Section = styled(CC.Grid, SC.withTransientProps)<{
  $borderBottom?: boolean;
}>`
  display: flex;
  padding: 2px 0;
  ${({ theme, $borderBottom }) =>
    $borderBottom &&
    css`
      border-bottom: 1px solid ${theme.kinetic.v2.palette.rgb.grayUiLight};
    `};
`;
export const MenuButton = styled(Button)`
  span {
    font-size: 16px;
    line-height: 22px;
  }
`;
