import React, { useState, useRef, useEffect } from "react";
import { Image, Props } from "../Image";
import { Skeleton as StyledSkeleton } from "./styles";

export function ImageWthSkeleton({ imageStyles, ...props }: Props) {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const aspectRatio = imageStyles?.aspectRatio;
  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (imgRef?.current) {
      imgRef.current?.addEventListener("load", () => setLoaded(true));
    }

    return imgRef?.current?.removeEventListener("load", onLoad);
  }, [imgRef]);

  return (
    <React.Fragment>
      <Image ref={imgRef} imageStyles={imageStyles} {...props} />
      {!loaded && (
        <StyledSkeleton variant="rectangular" $aspectRatio={aspectRatio} />
      )}
    </React.Fragment>
  );
}
