import { PointsTag as PointsTagContainer } from "./styles";
import { BSPBody2 } from "../BSPBody2";
import React from "react";
import { CoinIcon } from "../../../../icons";

export function PointTag({ pointCount }: { pointCount: number }) {
  return (
    <PointsTagContainer>
      <div
        style={{
          height: "14px",
          width: "14px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {" "}
        <CoinIcon />
      </div>
      <div style={{ marginLeft: "6px" }}>
        <BSPBody2>{pointCount}</BSPBody2>
      </div>
    </PointsTagContainer>
  );
}
