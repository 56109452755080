import { CC } from "@bigspring/core-components";
import { MenuOption } from "./components/BaseMenu";
import { Permission } from "@gql/generated/graphql";
import { AvailableRoutes } from "@config/routes";

export type AdminMenuOption = MenuOption & {
  text: NavigationMenuOptionLabel;
  uri?: AvailableRoutes;
  action?: () => void;
  selectedRule?: SelectedRule;
  permissions?: Permission[];
  /** TODO: In the future we'll have permissions at company level */
  showInAnonymousUsers?: boolean;
};

export enum NavigationMenuOptionLabel {
  // super admin section
  CREATE_COMPANY = "Create Company",
  SUPER_ADMIN_SCRIPTS = "Scripts",
  // normal admin users
  DASHBOARD = "Dashboard",
  ANALYTICS = "Analytics",
  API_KEYS = "API Keys",
  COMPANY = "Company",
  PEOPLE = "Users",
  GROUPS = "Groups",
  TAGS = "Tags",
  CUSTOM_VOCABULARIES = "Custom Vocabularies",
  SKILLS = "Content",
  TOPICS = "Topics",
  REP_PROMPTS = "Practice",
  REP_RESPONSES = "Gallery",
  LEARNING_PLANS = "Campaigns",
  LOGOUT = "Sign-out",
}

export enum SelectedRule {
  STARTS_WITH,
  EQUALS,
}

/**
 * Every array inside the main one represents a section in the menu.
 */
export const NAVIGATION_MENU_OPTIONS: AdminMenuOption[][] = [
  [
    {
      text: NavigationMenuOptionLabel.PEOPLE,
      startIcon: (isSelected) =>
        isSelected ? <CC.PersonIcon /> : <CC.PersonOutlinedIcon />,
      uri: "profiles",
      selectedRule: SelectedRule.STARTS_WITH,
      permissions: [Permission.Profiles],
    },
    {
      text: NavigationMenuOptionLabel.GROUPS,
      startIcon: (isSelected) =>
        isSelected ? <CC.AccountGroupIcon /> : <CC.AccountGroupOutlinedIcon />,
      uri: "profileGroups",
      selectedRule: SelectedRule.STARTS_WITH,
      permissions: [Permission.Profiles],
    },
  ],
  [
    {
      text: NavigationMenuOptionLabel.ANALYTICS,
      startIcon: (isSelected) =>
        isSelected ? <CC.ViewDashboardIcon /> : <CC.DashboardOutlinedIcon />,
      uri: "analytics",
      selectedRule: SelectedRule.STARTS_WITH,
      permissions: [Permission.CompanyAnalytics],
    },
    {
      text: NavigationMenuOptionLabel.REP_RESPONSES,
      startIcon: (isSelected) =>
        isSelected ? <CC.StoreIcon /> : <CC.StoreOutlinedIcon />,
      uri: "repResponses",
      selectedRule: SelectedRule.STARTS_WITH,
      permissions: [Permission.RepResponses],
    },
  ],
  [
    {
      text: NavigationMenuOptionLabel.TOPICS,
      startIcon: (isSelected) =>
        isSelected ? <CC.FileTreeIcon /> : <CC.FileTreeOutlinedIcon />,
      uri: "topics",
      selectedRule: SelectedRule.STARTS_WITH,
      permissions: [Permission.Topics],
      showInAnonymousUsers: true,
    },
    {
      text: NavigationMenuOptionLabel.LEARNING_PLANS,
      startIcon: (isSelected) =>
        isSelected ? (
          <CC.SubscriptionsIcon />
        ) : (
          <CC.SubscriptionsOutlinedIcon />
        ),
      uri: "learningPlans",
      selectedRule: SelectedRule.STARTS_WITH,
      permissions: [Permission.LearningPlans],
      showInAnonymousUsers: true,
    },
    {
      text: NavigationMenuOptionLabel.SKILLS,
      startIcon: (isSelected) =>
        isSelected ? <CC.TopicIcon /> : <CC.TopicOutlinedIcon />,
      uri: "skills",
      selectedRule: SelectedRule.STARTS_WITH,
      permissions: [Permission.Skills],
      showInAnonymousUsers: true,
    },
    {
      text: NavigationMenuOptionLabel.REP_PROMPTS,
      startIcon: (isSelected) =>
        isSelected ? <CC.RepIcon /> : <CC.RepOutlineIcon />,
      uri: "reps",
      selectedRule: SelectedRule.STARTS_WITH,
      permissions: [Permission.RepPrompts],
    },
  ],
  [
    {
      text: NavigationMenuOptionLabel.API_KEYS,
      startIcon: (isSelected) =>
        isSelected ? <CC.KeyIcon /> : <CC.KeyOutlinedIcon />,
      uri: "apiKeys",
      selectedRule: SelectedRule.STARTS_WITH,
      permissions: [Permission.ApiKeys],
    },
  ],
  [
    {
      text: NavigationMenuOptionLabel.LOGOUT,
      startIcon: () => <CC.LogoutIcon />,
    },
  ],
];

export const SUPER_ADMIN_MENU_OPTIONS: AdminMenuOption[][] = [
  [
    {
      text: NavigationMenuOptionLabel.COMPANY,
      startIcon: (isSelected) =>
        isSelected ? <CC.StoreIcon /> : <CC.StoreOutlinedIcon />,
      uri: "company",
      selectedRule: SelectedRule.STARTS_WITH,
      permissions: [Permission.Admins],
    },
    {
      text: NavigationMenuOptionLabel.SUPER_ADMIN_SCRIPTS,
      startIcon: () => <CC.TerminalIcon />,
      uri: "superAdminScripts",
      selectedRule: SelectedRule.STARTS_WITH,
      permissions: [Permission.Admins],
    },
    {
      text: NavigationMenuOptionLabel.TAGS,
      startIcon: (isSelected) =>
        isSelected ? <CC.TagIcon /> : <CC.TagOutlinedIcon />,
      uri: "profileTags",
      selectedRule: SelectedRule.STARTS_WITH,
      permissions: [Permission.ProfileTags],
    },
    {
      text: NavigationMenuOptionLabel.CUSTOM_VOCABULARIES,
      startIcon: () => <CC.CustomVocabulariesIcon />,
      uri: "customVocabularies",
      selectedRule: SelectedRule.STARTS_WITH,
      permissions: [Permission.Admins],
    },
  ],
  ...NAVIGATION_MENU_OPTIONS,
];
