import { TITLE_SUFFIX } from "config/routes";
import Head from "next/head";
import NProgress from "nprogress";
import { PropsWithChildren, useEffect } from "react";
import Navigation from "./components/Navigation";
import { GlobalStyles, Main, Wrapper, Version } from "./styles";
import { useCustomRouter } from "../CustomRouter";
import { AnimatePresence, useAnimation } from "framer-motion";
import { useAuth } from "@shared/Authentication";

const MAIN_VARIANTS = {
  initial: { opacity: 0 },
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

export default function Layout({ children }: PropsWithChildren<unknown>) {
  const { events, asPath } = useCustomRouter();
  const { user } = useAuth();
  const controls = useAnimation();

  useEffect(() => {
    const onRouteChangeStart = () => {
      NProgress.start();
    };

    const onRouteChangeComplete = () => {
      NProgress.done();
    };

    const onRouteChangeError = () => {
      NProgress.done();
    };

    events.on("routeChangeStart", onRouteChangeStart);
    events.on("routeChangeComplete", onRouteChangeComplete);
    events.on("routeChangeError", onRouteChangeError);

    return () => {
      events.off("routeChangeStart", onRouteChangeStart);
      events.off("routeChangeComplete", onRouteChangeComplete);
      events.off("routeChangeError", onRouteChangeError);
    };
  }, [events]);

  useEffect(() => {
    void controls.start({ opacity: 1 });
  }, [controls]);

  return (
    <>
      <GlobalStyles />
      <Head>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta charSet="utf-8" />

        <title>{TITLE_SUFFIX}</title>
        <meta name="description" content="BigSpring Admin" />
      </Head>
      <Wrapper
        $singleColumn={!user}
        initial={{ opacity: 0 }}
        animate={controls}
        transition={{ duration: 0.5 }}
      >
        {user && (
          <Navigation
            onOpenMenuClick={() =>
              controls.start({ gridTemplateColumns: "280px 1fr" })
            }
            onCloseMenuClick={() =>
              controls.start({ gridTemplateColumns: "40px 1fr" })
            }
          />
        )}
        <AnimatePresence exitBeforeEnter>
          <Main
            key={asPath}
            initial="initial"
            animate="enter"
            exit="exit"
            transition={{ duration: 0.15 }}
            variants={MAIN_VARIANTS}
          >
            {children}
            <Version>
              bigspring-admin@
              {process.env.NEXT_PUBLIC_VERSION || "unknown-version"}
            </Version>
          </Main>
        </AnimatePresence>
      </Wrapper>
    </>
  );
}
