import React, { PropsWithChildren } from "react";
import { GridProps } from "../../../Grid";
import { Section as StyledSection, InnerSection } from "./styles";

type Props = PropsWithChildren<{
  stretchLeft?: boolean;
  stretchRight?: boolean;
  innerProps?: GridProps;
}>;

export function Section({
  children,
  stretchLeft,
  stretchRight,
  innerProps,
  ...props
}: Props) {
  return (
    <StyledSection item {...props}>
      <InnerSection
        $stretchLeft={stretchLeft}
        $stretchRight={stretchRight}
        container
        {...innerProps}
      >
        {children}
      </InnerSection>
    </StyledSection>
  );
}
