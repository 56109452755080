import { BSPBody1 } from "../BSPBody1";
import {
  CollectionsIcon as CollectionsIconMui,
  PhotoCameraIcon,
  PausePresentationIcon,
  PresentToAllIcon,
  FlipCameraIcon as FlipCameraIconMui,
} from "../../../../icons";
import { css, styled } from "@mui/material/styles";

export const Wrapper = styled("div")<{
  $maxWidth?: number;
  $maxHeight?: number;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin: auto;
  max-width: ${({ $maxWidth }) => $maxWidth}px;
  max-height: ${({ $maxHeight }) => $maxHeight}px;
  ${({ theme }) => theme.breakpoint.minWidth.large`
    max-height: 969px;
  `}
`;

export const LoadingWrapper = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const CountDown = styled(BSPBody1)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 6rem;
  font-weight: 500;
  color: white;
  opacity: 0.9;
  ${({ theme }) => theme.breakpoint.maxWidth.small`
    font-size: 4rem;
  `}
`;

export const PromptWrapper = styled("div")<{
  $isRecording?: boolean;
}>`
  position: absolute;
  top: 40px;
  left: 40px;
  max-width: 300px;
  z-index: 1;
  ${({ theme, $isRecording }) => theme.breakpoint.maxWidth.small`
    top: 20px;
    display: ${$isRecording ? "none" : "block"};
    left: 50%;
    transform: translateX(-50%);
  `}
`;

export const PromptPreview = styled(BSPBody1)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  backdrop-filter: blur(20px);
`;

export const Prompt = styled("div")<{
  $isOpen?: boolean;
}>`
  margin-top: 20px;
  visibility: ${({ $isOpen }) => ($isOpen ? "visible" : "hidden")};
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  color: white;
  border-radius: 6px;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  transition: opacity 250ms ease;
  max-height: 400px;
  overflow-y: auto;
  white-space: pre-line;
  backdrop-filter: blur(20px);
  ${({ theme }) => theme.breakpoint.maxWidth.small`
    margin-top: 10px;
    max-height: 300px;
  `}
`;

export const ActionsArea = styled("div")`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 140px;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  column-gap: 10px;
  align-items: center;
  justify-items: center;
  background: rgb(30, 30, 37);
  background: linear-gradient(
    360deg,
    rgba(30, 30, 37, 1) 3%,
    rgba(35, 35, 41, 0.3505996148459384) 70%,
    rgba(2, 0, 36, 0) 100%
  );
  ${({ theme }) => theme.breakpoint.maxWidth.small`
    grid-template-columns: 2fr 140px 2fr;
  `}
`;

export const LeftMenuWrapper = styled("div")`
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
  justify-self: end;
  align-items: center;
  ${({ theme }) => theme.breakpoint.maxWidth.small`
    justify-self: center;
  `}
`;

export const CenterMenuWrapper = styled("div")<{
  $centerRecord?: boolean;
}>`
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
  align-items: center;
  ${({ theme, $centerRecord }) => theme.breakpoint.maxWidth.small`
    justify-self: ${$centerRecord ? "end" : "center"};
  `}
`;

export const RightMenuWrapper = styled("div")`
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
  justify-self: start;
  align-items: center;
  ${({ theme }) => theme.breakpoint.maxWidth.small`
    justify-self: center;
  `}
`;

export const RecordButton = styled("button")<{
  $showStop?: boolean;
}>`
  position: relative;
  border-radius: ${({ $showStop }) => ($showStop ? "6px" : "50%")};
  background-color: red;
  padding: ${({ $showStop }) => ($showStop ? 26 : 28)}px;
  border: none;
  opacity: 0.6;
  transition: opacity 200ms ease, border-radius 500ms ease, padding 200ms ease;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
    cursor: initial;
  }

  &::after {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border: 5px solid white;
    border-radius: ${({ $showStop }) => ($showStop ? "10px" : "50%")};
    transition: border-radius 400ms ease;
  }

  ${({ theme, $showStop }) => theme.breakpoint.maxWidth.small`
    padding: ${$showStop ? 20 : 22}px;
  `}

  ${({ theme, $showStop }) => theme.breakpoint.maxWidth.xsmall`
    padding: ${$showStop ? 18 : 20}px;
  `}
`;

export const Icon = css`
  color: white;
  cursor: pointer;
  /* width: 100%; */
  /* height: 100%; */
  transition: opacity 200ms ease;
  /* For Safari to not cause flickering */
  -webkit-transform: translate3d(0, 0, 0);
  &:hover {
    opacity: 1;
  }
`;

export const CameraIcon = styled(PhotoCameraIcon)`
  opacity: 0.7;
  ${Icon};
`;

export const CameraButton = styled("button")`
  background: none;
  border: none;
  padding: 0;
  width: 60px;
  height: 60px;
  > svg {
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.breakpoint.maxWidth.small`
    width: 35px;
    height: 35px;
  `}

  ${CameraIcon} {
    user-select: none;
  }
`;

export const CollectionsIcon = styled(CollectionsIconMui)`
  opacity: 0.7;
  fill: white;
  ${Icon};
`;

export const FlipCameraIcon = styled(FlipCameraIconMui)`
  opacity: 0.7;
  ${Icon};
`;

export const ShareScreenIcon = styled(PresentToAllIcon)`
  opacity: 0.7;
  ${Icon};
`;

export const StopScreenShareIcon = styled(PausePresentationIcon)`
  opacity: 0.7;
  ${Icon};
  fill: rgba(252, 89, 89, 1);
`;

export const MenuOption = styled("div")`
  width: 35px;
  height: 35px;
  cursor: pointer;
  > svg {
    width: 100%;
    height: 100%;
  }
  ${({ theme }) => theme.breakpoint.maxWidth.small`
    width: 25px;
    height: 25px;
  `}
`;

export const TextArea = styled("textarea")<{
  $maxHeight: number;
}>`
  position: absolute;
  left: 50%;
  top: 40px;
  width: 90%;
  transform: translateX(-50%);
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  resize: none;
  outline: 0;
  color: white;
  font-size: 2rem;
  max-height: ${({ $maxHeight }) => $maxHeight - 150}px;
  text-align: center;
  line-height: 2;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  ${({ theme }) => theme.breakpoint.maxWidth.small`
    width: 100%;
    font-size: 1.5rem;
  `}
`;

TextArea.defaultProps = {
  rows: 20,
};
