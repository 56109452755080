import { styled } from "@mui/material/styles";
import { AspectRatio } from "../../types";

type Props = { $isFill?: boolean; $aspectRatio?: AspectRatio };

export const Container = styled("div")<Props>`
  position: relative;
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || "initial"};
  width: 100%;
  height: 100%;
`;

export const VideoCardContainer = styled("div")`
  height: 100%;
  width: 600px;
  max-width: 600px;

  ${({ theme }) => theme.breakpoint.maxWidth.xs`
    width: 100%;
  `};
`;
