import React, { HTMLAttributes } from "react";
import { ButtonArea, Circle, Icon } from "./styles";

type Props = HTMLAttributes<HTMLButtonElement>;

export function PlayButton(props: Props) {
  return (
    <ButtonArea {...props}>
      <Circle>
        <Icon />
      </Circle>
    </ButtonArea>
  );
}
