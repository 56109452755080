import React, { RefObject, useState } from "react";
import Measure, { ContentRect } from "react-measure";
import {
  RecordButton,
  CountDown,
  Wrapper,
  TextArea,
  CameraIcon,
  CameraButton,
  FlipCameraIcon,
  LoadingWrapper,
  ActionsArea,
  CollectionsIcon,
  CenterMenuWrapper,
  LeftMenuWrapper,
  RightMenuWrapper,
  MenuOption,
  ShareScreenIcon,
  StopScreenShareIcon,
  Prompt,
  PromptPreview,
  PromptWrapper,
} from "./styles";
import { CircularProgress } from "../CircularProgress";
import { OptionsMenu, OptionType } from "./components/OptionsMenu";
import { Tooltip } from "../Tooltip";
import { SelectedDevices } from "./components/OptionsMenu/components/SettingsModal";
import {
  Effect,
  EffectType,
} from "./components/OptionsMenu/components/VisualEffectsModal";
import { ChevronDownIcon, CloseIcon } from "../../../../icons";
import { BSPBody1 } from "../BSPBody1";
import { Interjection, InterjectionView } from "./components/InterjectionView";

export enum MenuOptionType {
  PRESENTATION = "presentation",
}

export enum ShootType {
  PHOTO,
  VIDEO,
}

export type Prompt = {
  title: string;
  content: string;
};

type Props = {
  wrapperRef: RefObject<HTMLDivElement>;
  canvasRef: RefObject<HTMLCanvasElement>;
  wrapperDim: { width: number; height: number };
  videoDim: { width: number; height: number };
  isPhotoEnabled: boolean;
  isVideoEnabled: boolean;
  isLoading: boolean;
  hasShootStarted?: ShootType;
  showFlipCameraOption: boolean;
  showScreenShareOption: boolean;
  showCountDown: boolean;
  showTimerCountDown: boolean;
  availableDevices: MediaDeviceInfo[];
  selectedDevices: SelectedDevices;
  prompt?: Prompt;
  countDown: number;
  timerCountDown: number;
  interjections: Interjection[];
  options: Record<OptionType | MenuOptionType, boolean>;
  onResize: (contentRect: ContentRect) => void;
  onShootClick: (type: ShootType) => void;
  onToggleCameraClick: () => void;
  onOptionClick: (type: OptionType | MenuOptionType) => Promise<void>;
  onUploadFileClick: () => void;
  onSettingsChange: (selectedDevices: SelectedDevices) => void;
  onEffectChange: (effect: Effect<EffectType>) => void;
};

export function WebcamView({
  wrapperRef,
  canvasRef,
  wrapperDim,
  videoDim,
  isPhotoEnabled,
  isVideoEnabled,
  isLoading,
  hasShootStarted,
  showFlipCameraOption,
  showScreenShareOption,
  showCountDown,
  showTimerCountDown,
  availableDevices,
  selectedDevices,
  prompt,
  countDown,
  timerCountDown,
  interjections,
  options,
  onResize,
  onShootClick,
  onToggleCameraClick,
  onOptionClick,
  onUploadFileClick,
  onSettingsChange,
  onEffectChange,
}: Props) {
  const [isPromptOpen, setIsPromptOpen] = useState(true);

  return (
    <Measure innerRef={wrapperRef} bounds onResize={onResize}>
      {({ measureRef }) => (
        <Wrapper
          ref={measureRef}
          $maxHeight={videoDim.height}
          $maxWidth={videoDim.width}
          style={{ height: `${wrapperDim.height}px` }}
        >
          {isLoading && (
            <LoadingWrapper>
              <CircularProgress />
            </LoadingWrapper>
          )}
          <canvas
            ref={canvasRef}
            width={videoDim.width}
            height={videoDim.height}
          />
          {showCountDown && <CountDown>{countDown}</CountDown>}
          {showTimerCountDown && (
            <InterjectionView
              timerCountDown={timerCountDown}
              interjections={interjections}
            />
          )}
          {!!prompt && (
            <PromptWrapper $isRecording={!!hasShootStarted}>
              <PromptPreview
                onClick={() => setIsPromptOpen((prevState) => !prevState)}
              >
                {prompt.title.length > 25
                  ? `${prompt.title.substring(0, 25)}...`
                  : prompt.title}
                <ChevronDownIcon />
              </PromptPreview>
              <Prompt $isOpen={isPromptOpen}>
                <div
                  style={{ textAlign: "right", cursor: "pointer" }}
                  onClick={() => setIsPromptOpen(false)}
                >
                  <CloseIcon />
                </div>
                <BSPBody1>{prompt.content}</BSPBody1>
              </Prompt>
            </PromptWrapper>
          )}
          {options.prompter && (
            <TextArea $maxHeight={wrapperDim.height} autoFocus />
          )}
          <ActionsArea>
            <LeftMenuWrapper>
              <MenuOption onClick={onUploadFileClick}>
                <Tooltip title="Upload a file" placement="top">
                  <CollectionsIcon />
                </Tooltip>
              </MenuOption>
              {showFlipCameraOption && (
                <MenuOption onClick={onToggleCameraClick}>
                  <Tooltip title="Flip camera" placement="top">
                    <FlipCameraIcon />
                  </Tooltip>
                </MenuOption>
              )}
            </LeftMenuWrapper>
            <CenterMenuWrapper $centerRecord={isVideoEnabled && isPhotoEnabled}>
              {isVideoEnabled && (
                <Tooltip title="Record video" placement="top">
                  <RecordButton
                    disabled={hasShootStarted === ShootType.PHOTO}
                    $showStop={hasShootStarted === ShootType.VIDEO}
                    onClick={() => {
                      setIsPromptOpen(false);
                      onShootClick(ShootType.VIDEO);
                    }}
                  />
                </Tooltip>
              )}
              {isPhotoEnabled && (
                <Tooltip title="Take photo" placement="top">
                  <CameraButton
                    disabled={
                      hasShootStarted === ShootType.VIDEO ||
                      hasShootStarted === ShootType.PHOTO
                    }
                    onClick={() => onShootClick(ShootType.PHOTO)}
                  >
                    <CameraIcon />
                  </CameraButton>
                </Tooltip>
              )}
            </CenterMenuWrapper>
            <RightMenuWrapper>
              {showScreenShareOption && (
                <MenuOption
                  onClick={() => onOptionClick(MenuOptionType.PRESENTATION)}
                >
                  {options.presentation ? (
                    <Tooltip title="Stop sharing" placement="top">
                      <StopScreenShareIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Share screen" placement="top">
                      <ShareScreenIcon />
                    </Tooltip>
                  )}
                </MenuOption>
              )}
              <OptionsMenu
                hasShootStarted={!!hasShootStarted}
                isCameraEnabled={options.camera}
                isMicEnabled={options.mic}
                isPrompterEnabled={options.prompter}
                availableDevices={availableDevices}
                selectedDevices={selectedDevices}
                onSettingsChange={onSettingsChange}
                onOptionClick={onOptionClick}
                onEffectChange={onEffectChange}
              />
            </RightMenuWrapper>
          </ActionsArea>
        </Wrapper>
      )}
    </Measure>
  );
}
