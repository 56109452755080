import { CC, SC } from "@bigspring/core-components";

const { styled } = SC;

export const ErrorContainer = styled(CC.Container)`
  margin-top: 64px;
  max-width: 600px;
`;

export const ErrorHeader = styled(CC.Grid)`
  text-align: center;
`;

ErrorHeader.defaultProps = {
  container: true,
  direction: "column",
};

export const ErrorIconContainer = styled(CC.Grid)`
  svg {
    width: 100px;
    height: 100px;
    fill: ${({ theme }) => theme.kinetic.v2.palette.rgb.negative};
  }
`;

ErrorIconContainer.defaultProps = {
  item: true,
};

export const ErrorMessage = styled.pre`
  margin: 0;
  white-space: pre-wrap;
`;
