import React, { useMemo } from "react";
import { Button } from "../../../../../Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
} from "../../../../../dialog";
import { Grid } from "../../../../../Grid";
import { Select } from "../../../../../Select";
import deviceDetect from "ismobilejs";

export type SelectedDevices = {
  audioDevice?: MediaDeviceInfo;
  videoDevice?: MediaDeviceInfo;
};

type Props = {
  hasShootStarted: boolean;
  availableDevices: MediaDeviceInfo[];
  selectedDevices: SelectedDevices;
  onInputChange: (selectedDevices: SelectedDevices) => void;
  onAcceptClick: () => void;
};

export function SettingsModal({
  hasShootStarted,
  availableDevices,
  selectedDevices,
  onInputChange,
  onAcceptClick,
}: Props) {
  const isMobile = deviceDetect(window.navigator).phone;

  const audioInputDevices = useMemo(
    () => availableDevices.filter((device) => device.kind === "audioinput"),
    [availableDevices]
  );

  const videoDevices = useMemo(
    () => availableDevices.filter((device) => device.kind === "videoinput"),
    [availableDevices]
  );

  const onAudioInputChange = (value: string) => {
    const device = availableDevices.find((device) => device.deviceId === value);

    onInputChange({
      videoDevice: selectedDevices.videoDevice,
      audioDevice: device,
    });
  };

  const onVideoInputChange = (value: string) => {
    const device = availableDevices.find((device) => device.deviceId === value);

    onInputChange({
      audioDevice: selectedDevices.audioDevice,
      videoDevice: device,
    });
  };

  return (
    <Dialog open fullWidth onClose={onAcceptClick}>
      <DialogHeader onClose={onAcceptClick}>Settings</DialogHeader>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Select
              label="Audio Input"
              disabled={hasShootStarted}
              options={audioInputDevices}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.deviceId}
              value={selectedDevices.audioDevice?.deviceId}
              onChange={(value) => onAudioInputChange(value as string)}
            />
          </Grid>
          {!isMobile && (
            <Grid item xs={12}>
              <Select
                label="Video Input"
                disabled={hasShootStarted}
                options={videoDevices}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.deviceId}
                value={selectedDevices.videoDevice?.deviceId}
                onChange={(value) => onVideoInputChange(value as string)}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button text="Ok" align="center" onClick={onAcceptClick} />
      </DialogActions>
    </Dialog>
  );
}
