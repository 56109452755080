import React, { useMemo, HTMLAttributes } from "react";
import { nanoid } from "nanoid";
import { Stat, Props as StatProps } from "./components/Stat";
import { Container } from "./styled";

export type Props = Omit<HTMLAttributes<HTMLDivElement>, "children"> & {
  stats: StatProps[];
};

export function Stats({ stats = [], ...props }: Props) {
  const statsWithIds = useMemo(
    () => stats.map((stat) => ({ ...stat, id: stat.id || nanoid() })),
    [stats]
  );

  return (
    <Container {...props}>
      {statsWithIds.map((stat) => (
        <Stat key={stat.id} {...stat} />
      ))}
    </Container>
  );
}
