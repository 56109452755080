import type videojs from "video.js";
import { toggleDefaultCaptions } from "../textTracks";
import { seekToPercent, seekToDiff } from "../seek";

const toggleFullScreen = (player: videojs.Player) => {
  player.isFullscreen() ? player.exitFullscreen() : player.requestFullscreen();
};

/**
 * Dispatch an action given a keydown event — used as a Video.js option
 *
 * @param this Video.js player
 * @param event keyboard event
 * @returns void
 */
export function handleHotkeys(
  this: videojs.Player,
  event: videojs.KeyboardEvent
) {
  if (event.code.startsWith("Digit")) {
    const percent = parseInt(event.code.slice(5)) / 10;

    return seekToPercent(this, percent);
  }

  switch (event.code) {
    case "Space":
    case "KeyK":
      this.paused() ? void this.play() : this.pause();

      return;
    case "ArrowLeft":
    case "KeyJ":
      return seekToDiff(this, -10);
    case "ArrowRight":
    case "KeyL":
      return seekToDiff(this, 10);
    case "KeyC":
      return toggleDefaultCaptions(this);
    case "KeyF":
      return toggleFullScreen(this);
    default:
      break;
  }
}
