// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

export interface AddObject {
  actionSource?: string
  companyId?: string
  numObjects?: number
  objectType?: string
  sessionId?: string
  tab?: string
  [property: string]: any
}

export interface ButtonClicked {
  buttonText?: string
  companyId?: string
  location?: string
  sessionId?: string
  tab?: string
  [property: string]: any
}

export interface CSVProcessFailed {
  companyId?: string
  sessionId?: string
  tab?: string
  [property: string]: any
}

export interface CSVSubmitted {
  companyId?: string
  sessionId?: string
  tab?: string
  [property: string]: any
}

export interface CSVUploaded {
  companyId?: string
  sessionId?: string
  tab?: string
  [property: string]: any
}

/**
 * Skill or card preview opened
 */
export interface CardPreviewed {
  learningCardPosition: number
  learningCardType: LearningCardType
  skillExternalId: string
  skillName: string
  skillState: SkillState
  target: Target
  [property: string]: any
}

export enum LearningCardType {
  File = 'file',
  FlashCard = 'flash-card',
  Form = 'form',
  Linkout = 'linkout',
  Video = 'video',
}

export enum SkillState {
  Draft = 'draft',
  New = 'new',
  Published = 'published',
}

export enum Target {
  Card = 'card',
  Skill = 'skill',
}

export interface DeleteObject {
  actionSource?: string
  companyId?: string
  numObjects?: number
  objectType?: string
  sessionId?: string
  tab?: string
  [property: string]: any
}

export interface ErrorDisplayed {
  companyId?: string
  errorMessage?: string[]
  sessionId?: string
  tab?: string
  [property: string]: any
}

export interface FailedSendVerificationCode {
  channel?: string
  errorCode?: string
  errorMessage?: string
  sessionId?: string
  source?: string
  to?: string
  [property: string]: any
}

export interface FailedVerifyVerificationCode {
  channel?: string
  errorCode?: string
  errorMessage?: string
  sessionId?: string
  source?: string
  to?: string
  [property: string]: any
}

export interface InputFocused {
  inputName?: string
  sessionId?: string
  [property: string]: any
}

export interface LearningPlanCreationStarted {
  companyId?: string
  sessionId?: string
  workflowId?: string
  [property: string]: any
}

export interface LearningPlanEditStarted {
  companyId?: string
  sessionId?: string
  workflowId?: string
  [property: string]: any
}

export interface LearningPlanSaved {
  companyId?: string
  duration?: number
  sessionId?: string
  workflowId?: string
  [property: string]: any
}

export interface ListObjects {
  companyId?: string
  numObjects?: number
  objectType?: string
  sessionId?: string
  tab?: string
  [property: string]: any
}

/**
 * Skill flash card or video card media selected
 */
export interface MediaSelected {
  /**
   * Company ID
   */
  companyId: string
  /**
   * Selected file size
   */
  fileSize: number
  /**
   * Selected file type
   */
  fileType: FileType
  flow: Flow
  /**
   * ObjectType
   */
  objectType: string
  /**
   * Page where the file was selected
   */
  tab?: string
  uploadType: UploadType
  [property: string]: any
}

/**
 * Selected file type
 */
export enum FileType {
  Photo = 'photo',
  Video = 'video',
}

export enum Flow {
  Create = 'create',
  Edit = 'edit',
}

export enum UploadType {
  Camera = 'camera',
  DragAndDrop = 'drag-and-drop',
  FileSelector = 'file-selector',
}

export interface NavigationMenuOptionSelected {
  companyId?: string
  sessionId?: string
  tab?: string
  tabsVisible?: string[]
  [property: string]: any
}

export interface NewCodeRequested {
  channel?: string
  sessionId?: string
  source?: string
  to?: string
  [property: string]: any
}

export interface PhoneCountryChanged {
  previousCountry?: string
  selectedCountry?: string
  sessionId?: string
  [property: string]: any
}

export interface ProfileAddFlowStarted {
  companyId?: string
  [property: string]: any
}

export interface ProfileCreationStarted {
  companyId?: string
  sessionId?: string
  workflowId?: string
  [property: string]: any
}

export interface ProfileEditStarted {
  companyId?: string
  sessionId?: string
  workflowId?: string
  [property: string]: any
}

export interface ProfileSaved {
  companyId?: string
  duration?: number
  sessionId?: string
  workflowId?: string
  [property: string]: any
}

export interface SessionEnded {
  internalEventId?: string
  sessionId?: string
  [property: string]: any
}

export interface SessionStarted {
  internalEventId?: string
  sessionId?: string
  [property: string]: any
}

export interface SigninOptionSelected {
  name?: string
  sessionId?: string
  [property: string]: any
}

export interface SigninVerified {
  channel?: string
  sessionId?: string
  source?: string
  to?: string
  [property: string]: any
}

export interface SigninViewed {
  sessionId?: string
  [property: string]: any
}

export interface SkillCreationStarted {
  companyId?: string
  sessionId?: string
  workflowId?: string
  [property: string]: any
}

export interface SkillEditStarted {
  companyId?: string
  sessionId?: string
  workflowId?: string
  [property: string]: any
}

export interface SkillSaved {
  companyId?: string
  duration?: number
  sessionId?: string
  workFlowId?: string
  [property: string]: any
}

export interface SortList {
  companyId?: string
  objectType?: string
  sessionId?: string
  tab?: string
  [property: string]: any
}

export interface SuccessSendVerificationCode {
  channel?: string
  sessionId?: string
  source?: string
  to?: string
  [property: string]: any
}

export interface UpdateObject {
  actionSource?: string
  companyId?: string
  numObjects?: number
  objectType?: string
  sessionId?: string
  tab?: string
  [property: string]: any
}

export interface UserLoggedIn {
  companyExternalId?: any
  companyId?: any
  companyName?: string
  profileRoles?: string[]
  sessionId?: string
  [property: string]: any
}

export interface EmbeddedAnalyticsClicked {
  companyId?: string
  profileId?: string
  response?: { [key: string]: any }
  sessionId?: string
  [property: string]: any
}

export interface TabSelected {
  companyId?: string
  location?: string
  sessionId?: string
  tabName?: string
  [property: string]: any
}

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv, { ErrorObject } from 'ajv'

/**
 * The analytics.js snippet should be available via window.analytics.
 * You can install it by following instructions at: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 * Make sure to also include the TypeScript declarations with: `npm install @segment/analytics-next` (install with --save-dev for type definitions only).
 *
 * If you don't want to use the snippet, you can also install the `@segment/analytics-next` library as a *production* dependency and use it like this:
 * ```ts
 * import { AnalyticsBrowser } from '@segment/analytics-next'
 * import { setTypewriterOptions } from './analytics'
 *
 * const analytics = AnalyticsBrowser.load({ writeKey: 'SEGMENT_WRITE_KEY' })
 *
 * setTypewriterOptions({ analytics: analytics })
 */
import type {
  AnalyticsSnippet,
  Analytics,
  AnalyticsBrowser,
  Options,
} from '@segment/analytics-next'

declare global {
  interface Window {
    analytics: AnalyticsSnippet
  }
}

type AnyAnalytics = AnalyticsSnippet | Analytics | AnalyticsBrowser

/** The callback exposed by analytics.js. */
export type Callback = () => void

export type ViolationHandler = (
  message: Record<string, any>,
  violations: ErrorObject[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
  message,
  violations
) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2
  )

  console.warn(msg)
}

let onViolation = defaultValidationErrorHandler

let analytics: () => AnyAnalytics | undefined = () => {
  return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: AnyAnalytics
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics
    ? () => options.analytics || window.analytics
    : analytics
  onViolation = options.onViolation || onViolation
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
  const ajv = new Ajv({ allErrors: true, verbose: true })

  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors)
  }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Options = {}): Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'typescript',
        version: '9.1.0',
      },
    },
  }
}

/**
 * Fires a 'AddObject' track call.
 *
 * @param AddObject props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function addObject(
  props: AddObject,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Add_Object',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      actionSource: { type: ['string'] },
      companyId: { type: ['string'] },
      numObjects: { type: ['integer'] },
      objectType: { type: ['string'] },
      sessionId: { type: ['string'] },
      tab: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Add Object',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'ButtonClicked' track call.
 *
 * @param ButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function buttonClicked(
  props: ButtonClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      buttonText: { $id: '/properties/buttonText', type: ['string'] },
      companyId: { $id: '/properties/companyId', type: ['string'] },
      location: {
        $id: '/properties/location',
        description: '',
        type: 'string',
      },
      sessionId: { $id: '/properties/sessionId', type: ['string'] },
      tab: { $id: '/properties/tab', type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'CSVProcessFailed' track call.
 *
 * @param CSVProcessFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cSVProcessFailed(
  props: CSVProcessFailed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'CSV_Process_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: { type: ['string'] },
      sessionId: { type: ['string'] },
      tab: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'CSV Process Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'CSVSubmitted' track call.
 *
 * @param CSVSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cSVSubmitted(
  props: CSVSubmitted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'CSV_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: { type: ['string'] },
      sessionId: { type: ['string'] },
      tab: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'CSV Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'CSVUploaded' track call.
 *
 * @param CSVUploaded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cSVUploaded(
  props: CSVUploaded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'CSV_Uploaded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: { type: ['string'] },
      sessionId: { type: ['string'] },
      tab: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'CSV Uploaded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'CardPreviewed' track call.
 *
 * @param CardPreviewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cardPreviewed(
  props: CardPreviewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Card_Previewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Skill or card preview opened',
    properties: {
      learningCardPosition: {
        $id: '/properties/learningCardPosition',
        description: '',
        type: 'integer',
      },
      learningCardType: {
        $id: '/properties/learningCardType',
        description: '',
        enum: ['file', 'flash-card', 'form', 'linkout', 'video'],
        type: 'string',
      },
      skillExternalId: {
        $id: '/properties/skillExternalId',
        description: '',
        type: 'string',
      },
      skillName: {
        $id: '/properties/skillName',
        description: '',
        type: 'string',
      },
      skillState: {
        $id: '/properties/skillState',
        description: '',
        enum: ['draft', 'new', 'published'],
        type: 'string',
      },
      target: {
        $id: '/properties/target',
        description: '',
        enum: ['card', 'skill'],
        type: 'string',
      },
    },
    required: [
      'learningCardType',
      'learningCardPosition',
      'skillName',
      'skillExternalId',
      'skillState',
      'target',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Card Previewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'DeleteObject' track call.
 *
 * @param DeleteObject props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function deleteObject(
  props: DeleteObject,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Delete_Object',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      actionSource: { type: ['string'] },
      companyId: { type: ['string'] },
      numObjects: { type: ['integer'] },
      objectType: { type: ['string'] },
      sessionId: { type: ['string'] },
      tab: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Delete Object',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'ErrorDisplayed' track call.
 *
 * @param ErrorDisplayed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function errorDisplayed(
  props: ErrorDisplayed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Error_Displayed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: { type: ['string'] },
      errorMessage: { items: { type: 'string' }, type: 'array' },
      sessionId: { type: ['string'] },
      tab: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Error Displayed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'FailedSendVerificationCode' track call.
 *
 * @param FailedSendVerificationCode props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function failedSendVerificationCode(
  props: FailedSendVerificationCode,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Failed_Send_Verification_Code',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      channel: { type: ['string'] },
      errorCode: { type: ['string'] },
      errorMessage: { type: ['string'] },
      sessionId: { type: ['string'] },
      source: { type: ['string'] },
      to: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Failed Send Verification Code',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'FailedVerifyVerificationCode' track call.
 *
 * @param FailedVerifyVerificationCode props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function failedVerifyVerificationCode(
  props: FailedVerifyVerificationCode,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Failed_Verify_Verification_Code',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      channel: { type: ['string'] },
      errorCode: { type: ['string'] },
      errorMessage: { type: ['string'] },
      sessionId: { type: ['string'] },
      source: { type: ['string'] },
      to: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Failed Verify Verification Code',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'InputFocused' track call.
 *
 * @param InputFocused props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function inputFocused(
  props: InputFocused,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Input_Focused',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      inputName: { type: ['string'] },
      sessionId: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Input Focused',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'LearningPlanCreationStarted' track call.
 *
 * @param LearningPlanCreationStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function learningPlanCreationStarted(
  props: LearningPlanCreationStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Learning_Plan_Creation_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: {
        $id: '/properties/companyId',
        description: '',
        type: 'string',
      },
      sessionId: {
        $id: '/properties/sessionId',
        description: '',
        type: 'string',
      },
      workflowId: {
        $id: '/properties/workflowId',
        description: '',
        type: 'string',
      },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Learning Plan Creation Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'LearningPlanEditStarted' track call.
 *
 * @param LearningPlanEditStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function learningPlanEditStarted(
  props: LearningPlanEditStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Learning_Plan_Edit_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: {
        $id: '/properties/companyId',
        description: '',
        type: 'string',
      },
      sessionId: {
        $id: '/properties/sessionId',
        description: '',
        type: 'string',
      },
      workflowId: {
        $id: '/properties/workflowId',
        description: '',
        type: 'string',
      },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Learning Plan Edit Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'LearningPlanSaved' track call.
 *
 * @param LearningPlanSaved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function learningPlanSaved(
  props: LearningPlanSaved,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Learning_Plan_Saved',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: {
        $id: '/properties/companyId',
        description: '',
        type: 'string',
      },
      duration: {
        $id: '/properties/duration',
        description: '',
        type: 'number',
      },
      sessionId: {
        $id: '/properties/sessionId',
        description: '',
        type: 'string',
      },
      workflowId: {
        $id: '/properties/workflowId',
        description: '',
        type: 'string',
      },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Learning Plan Saved',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'ListObjects' track call.
 *
 * @param ListObjects props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function listObjects(
  props: ListObjects,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'List_Objects',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: { type: ['string'] },
      numObjects: { type: ['integer'] },
      objectType: { type: ['string'] },
      sessionId: { type: ['string'] },
      tab: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'List Objects',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'MediaSelected' track call.
 *
 * @param MediaSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function mediaSelected(
  props: MediaSelected,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Media_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Skill flash card or video card media selected',
    properties: {
      companyId: {
        $id: '/properties/companyId',
        description: 'Company ID',
        type: 'string',
      },
      fileSize: {
        $id: '/properties/fileSize',
        description: 'Selected file size',
        type: 'number',
      },
      fileType: {
        $id: '/properties/fileType',
        description: 'Selected file type',
        enum: ['photo', 'video'],
        type: 'string',
      },
      flow: {
        $id: '/properties/flow',
        description: '',
        enum: ['create', 'edit'],
        type: 'string',
      },
      objectType: {
        $id: '/properties/objectType',
        description: 'ObjectType',
        type: 'string',
      },
      tab: {
        $id: '/properties/tab',
        description: 'Page where the file was selected',
        type: 'string',
      },
      uploadType: {
        $id: '/properties/uploadType',
        description: '',
        enum: ['camera', 'drag-and-drop', 'file-selector'],
        type: 'string',
      },
    },
    required: [
      'companyId',
      'fileSize',
      'fileType',
      'flow',
      'objectType',
      'uploadType',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Media Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'NavigationMenuOptionSelected' track call.
 *
 * @param NavigationMenuOptionSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function navigationMenuOptionSelected(
  props: NavigationMenuOptionSelected,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Navigation_Menu_Option_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: { type: ['string'] },
      sessionId: { type: ['string'] },
      tab: { type: ['string'] },
      tabsVisible: { items: { type: 'string' }, type: 'array' },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Navigation Menu Option Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'NewCodeRequested' track call.
 *
 * @param NewCodeRequested props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function newCodeRequested(
  props: NewCodeRequested,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'New_Code_Requested',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      channel: { type: ['string'] },
      sessionId: { type: ['string'] },
      source: { type: ['string'] },
      to: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'New Code Requested',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'PhoneCountryChanged' track call.
 *
 * @param PhoneCountryChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function phoneCountryChanged(
  props: PhoneCountryChanged,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Phone_Country_Changed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      previousCountry: { type: ['string'] },
      selectedCountry: { type: ['string'] },
      sessionId: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Phone Country Changed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'ProfileAddFlowStarted' track call.
 *
 * @param ProfileAddFlowStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function profileAddFlowStarted(
  props: ProfileAddFlowStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Profile_Add_Flow_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: {
        $id: '/properties/companyId',
        description: '',
        type: 'string',
      },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Profile Add Flow Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'ProfileCreationStarted' track call.
 *
 * @param ProfileCreationStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function profileCreationStarted(
  props: ProfileCreationStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Profile_Creation_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: {
        $id: '/properties/companyId',
        description: '',
        type: 'string',
      },
      sessionId: {
        $id: '/properties/sessionId',
        description: '',
        type: 'string',
      },
      workflowId: {
        $id: '/properties/workflowId',
        description: '',
        type: 'string',
      },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Profile Creation Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'ProfileEditStarted' track call.
 *
 * @param ProfileEditStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function profileEditStarted(
  props: ProfileEditStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Profile_Edit_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: {
        $id: '/properties/companyId',
        description: '',
        type: 'string',
      },
      sessionId: {
        $id: '/properties/sessionId',
        description: '',
        type: 'string',
      },
      workflowId: {
        $id: '/properties/workflowId',
        description: '',
        type: 'string',
      },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Profile Edit Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'ProfileSaved' track call.
 *
 * @param ProfileSaved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function profileSaved(
  props: ProfileSaved,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Profile_Saved',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: {
        $id: '/properties/companyId',
        description: '',
        type: 'string',
      },
      duration: {
        $id: '/properties/duration',
        description: '',
        type: 'number',
      },
      sessionId: {
        $id: '/properties/sessionId',
        description: '',
        type: 'string',
      },
      workflowId: {
        $id: '/properties/workflowId',
        description: '',
        type: 'string',
      },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Profile Saved',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'SessionEnded' track call.
 *
 * @param SessionEnded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sessionEnded(
  props: SessionEnded,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Session_Ended',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      internalEventId: { type: ['string'] },
      sessionId: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Session Ended',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'SessionStarted' track call.
 *
 * @param SessionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sessionStarted(
  props: SessionStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Session_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      internalEventId: { type: ['string'] },
      sessionId: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Session Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'SigninOptionSelected' track call.
 *
 * @param SigninOptionSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signinOptionSelected(
  props: SigninOptionSelected,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Signin_Option_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: { name: { type: ['string'] }, sessionId: { type: ['string'] } },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Signin Option Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'SigninVerified' track call.
 *
 * @param SigninVerified props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signinVerified(
  props: SigninVerified,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Signin_Verified',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      channel: { type: ['string'] },
      sessionId: { type: ['string'] },
      source: { type: ['string'] },
      to: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Signin Verified',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'SigninViewed' track call.
 *
 * @param SigninViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signinViewed(
  props: SigninViewed,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Signin_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: { sessionId: { type: ['string'] } },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Signin Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'SkillCreationStarted' track call.
 *
 * @param SkillCreationStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function skillCreationStarted(
  props: SkillCreationStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Skill_Creation_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: {
        $id: '/properties/companyId',
        description: '',
        type: 'string',
      },
      sessionId: {
        $id: '/properties/sessionId',
        description: '',
        type: 'string',
      },
      workflowId: {
        $id: '/properties/workflowId',
        description: '',
        type: 'string',
      },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Skill Creation Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'SkillEditStarted' track call.
 *
 * @param SkillEditStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function skillEditStarted(
  props: SkillEditStarted,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Skill_Edit_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: {
        $id: '/properties/companyId',
        description: '',
        type: 'string',
      },
      sessionId: {
        $id: '/properties/sessionId',
        description: '',
        type: 'string',
      },
      workflowId: {
        $id: '/properties/workflowId',
        description: '',
        type: 'string',
      },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Skill Edit Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'SkillSaved' track call.
 *
 * @param SkillSaved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function skillSaved(
  props: SkillSaved,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Skill_Saved',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: {
        $id: '/properties/companyId',
        description: '',
        type: 'string',
      },
      duration: {
        $id: '/properties/duration',
        description: '',
        type: 'number',
      },
      sessionId: {
        $id: '/properties/sessionId',
        description: '',
        type: 'string',
      },
      workFlowId: {
        $id: '/properties/workFlowId',
        description: '',
        type: 'string',
      },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Skill Saved',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'SortList' track call.
 *
 * @param SortList props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sortList(
  props: SortList,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Sort_List',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: { type: ['string'] },
      objectType: { type: ['string'] },
      sessionId: { type: ['string'] },
      tab: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Sort List',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'SuccessSendVerificationCode' track call.
 *
 * @param SuccessSendVerificationCode props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function successSendVerificationCode(
  props: SuccessSendVerificationCode,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Success_Send_Verification_Code',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      channel: { type: ['string'] },
      sessionId: { type: ['string'] },
      source: { type: ['string'] },
      to: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Success Send Verification Code',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'UpdateObject' track call.
 *
 * @param UpdateObject props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function updateObject(
  props: UpdateObject,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'Update_Object',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      actionSource: { type: ['string'] },
      companyId: { type: ['string'] },
      numObjects: { type: ['integer'] },
      objectType: { type: ['string'] },
      sessionId: { type: ['string'] },
      tab: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Update Object',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'UserLoggedIn' track call.
 *
 * @param UserLoggedIn props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function userLoggedIn(
  props: UserLoggedIn,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'User_Logged_In',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyExternalId: {},
      companyId: {},
      companyName: { type: ['string'] },
      profileRoles: { items: { type: 'string' }, type: 'array' },
      sessionId: { type: ['string'] },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'User Logged In',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'EmbeddedAnalyticsClicked' track call.
 *
 * @param EmbeddedAnalyticsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function embeddedAnalyticsClicked(
  props: EmbeddedAnalyticsClicked,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'embeddedAnalyticsClicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: {
        $id: '/properties/companyId',
        description: '',
        type: 'string',
      },
      profileId: {
        $id: '/properties/profileId',
        description: '',
        type: 'string',
      },
      response: {
        $id: '/properties/response',
        description: '',
        type: 'object',
      },
      sessionId: {
        $id: '/properties/sessionId',
        description: '',
        type: 'string',
      },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'embeddedAnalyticsClicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}
/**
 * Fires a 'TabSelected' track call.
 *
 * @param TabSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tabSelected(
  props: TabSelected,
  options?: Options,
  callback?: Callback
): void {
  const schema = {
    $id: 'tabSelected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      companyId: {
        $id: '/properties/companyId',
        description: '',
        type: 'string',
      },
      location: {
        $id: '/properties/location',
        description: '',
        type: 'string',
      },
      sessionId: {
        $id: '/properties/sessionId',
        description: '',
        type: 'string',
      },
      tabName: { $id: '/properties/tabName', description: '', type: 'string' },
    },
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'tabSelected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback
    )
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,

  /**
   * Fires a 'AddObject' track call.
   *
   * @param AddObject props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  addObject,
  /**
   * Fires a 'ButtonClicked' track call.
   *
   * @param ButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  buttonClicked,
  /**
   * Fires a 'CSVProcessFailed' track call.
   *
   * @param CSVProcessFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  cSVProcessFailed,
  /**
   * Fires a 'CSVSubmitted' track call.
   *
   * @param CSVSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  cSVSubmitted,
  /**
   * Fires a 'CSVUploaded' track call.
   *
   * @param CSVUploaded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  cSVUploaded,
  /**
   * Fires a 'CardPreviewed' track call.
   *
   * @param CardPreviewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  cardPreviewed,
  /**
   * Fires a 'DeleteObject' track call.
   *
   * @param DeleteObject props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  deleteObject,
  /**
   * Fires a 'ErrorDisplayed' track call.
   *
   * @param ErrorDisplayed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  errorDisplayed,
  /**
   * Fires a 'FailedSendVerificationCode' track call.
   *
   * @param FailedSendVerificationCode props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  failedSendVerificationCode,
  /**
   * Fires a 'FailedVerifyVerificationCode' track call.
   *
   * @param FailedVerifyVerificationCode props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  failedVerifyVerificationCode,
  /**
   * Fires a 'InputFocused' track call.
   *
   * @param InputFocused props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  inputFocused,
  /**
   * Fires a 'LearningPlanCreationStarted' track call.
   *
   * @param LearningPlanCreationStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  learningPlanCreationStarted,
  /**
   * Fires a 'LearningPlanEditStarted' track call.
   *
   * @param LearningPlanEditStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  learningPlanEditStarted,
  /**
   * Fires a 'LearningPlanSaved' track call.
   *
   * @param LearningPlanSaved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  learningPlanSaved,
  /**
   * Fires a 'ListObjects' track call.
   *
   * @param ListObjects props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  listObjects,
  /**
   * Fires a 'MediaSelected' track call.
   *
   * @param MediaSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mediaSelected,
  /**
   * Fires a 'NavigationMenuOptionSelected' track call.
   *
   * @param NavigationMenuOptionSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  navigationMenuOptionSelected,
  /**
   * Fires a 'NewCodeRequested' track call.
   *
   * @param NewCodeRequested props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  newCodeRequested,
  /**
   * Fires a 'PhoneCountryChanged' track call.
   *
   * @param PhoneCountryChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  phoneCountryChanged,
  /**
   * Fires a 'ProfileAddFlowStarted' track call.
   *
   * @param ProfileAddFlowStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  profileAddFlowStarted,
  /**
   * Fires a 'ProfileCreationStarted' track call.
   *
   * @param ProfileCreationStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  profileCreationStarted,
  /**
   * Fires a 'ProfileEditStarted' track call.
   *
   * @param ProfileEditStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  profileEditStarted,
  /**
   * Fires a 'ProfileSaved' track call.
   *
   * @param ProfileSaved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  profileSaved,
  /**
   * Fires a 'SessionEnded' track call.
   *
   * @param SessionEnded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sessionEnded,
  /**
   * Fires a 'SessionStarted' track call.
   *
   * @param SessionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sessionStarted,
  /**
   * Fires a 'SigninOptionSelected' track call.
   *
   * @param SigninOptionSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  signinOptionSelected,
  /**
   * Fires a 'SigninVerified' track call.
   *
   * @param SigninVerified props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  signinVerified,
  /**
   * Fires a 'SigninViewed' track call.
   *
   * @param SigninViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  signinViewed,
  /**
   * Fires a 'SkillCreationStarted' track call.
   *
   * @param SkillCreationStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  skillCreationStarted,
  /**
   * Fires a 'SkillEditStarted' track call.
   *
   * @param SkillEditStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  skillEditStarted,
  /**
   * Fires a 'SkillSaved' track call.
   *
   * @param SkillSaved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  skillSaved,
  /**
   * Fires a 'SortList' track call.
   *
   * @param SortList props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sortList,
  /**
   * Fires a 'SuccessSendVerificationCode' track call.
   *
   * @param SuccessSendVerificationCode props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  successSendVerificationCode,
  /**
   * Fires a 'UpdateObject' track call.
   *
   * @param UpdateObject props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  updateObject,
  /**
   * Fires a 'UserLoggedIn' track call.
   *
   * @param UserLoggedIn props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  userLoggedIn,
  /**
   * Fires a 'EmbeddedAnalyticsClicked' track call.
   *
   * @param EmbeddedAnalyticsClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  embeddedAnalyticsClicked,
  /**
   * Fires a 'TabSelected' track call.
   *
   * @param TabSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tabSelected,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI]
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(
        method
      )}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`)
      const a = analytics()
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withTypewriterContext()
        )
      }
    }
  },
})
