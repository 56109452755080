import {
  Button as ButtonComponent,
  ButtonProps as ButtonPropsBase,
} from "@bigspring/core-components";
import { MouseEventHandler } from "react";
import { useAnalytics } from "@shared/Analytics";

export type ButtonProps = ButtonPropsBase;

export function Button({
  onClick: _onClick,
  text,
  title,
  "aria-label": ariaLabel,
  ...props
}: ButtonProps) {
  const { adminTrack } = useAnalytics();

  const onClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    adminTrack?.buttonClicked(
      (typeof text === "string" && text) || title || ariaLabel
    );

    _onClick?.(event);
  };

  return (
    <ButtonComponent
      {...props}
      text={text}
      title={title}
      aria-label={ariaLabel}
      onClick={onClick}
    />
  );
}
