import React from "react";
import { GridProps } from "../../../Grid";
import { Panel as StyledPanel } from "./styles";

type Props = GridProps;

export function Panel({ children, ...props }: Props) {
  return (
    <StyledPanel container direction="column" wrap="nowrap" {...props}>
      {children}
    </StyledPanel>
  );
}
