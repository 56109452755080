import React, { PropsWithChildren } from "react";
import { Wrapper } from "../../../../styles";
import { AcceptIcon, ActionsWrapper, Button, CancelIcon } from "./styles";

export type Props = {
  wrapperDim: {
    width: number;
    height: number;
  };
  videoDim: {
    width: number;
    height: number;
  };
  onCancelClick: () => void;
  onAcceptClick: () => void;
};

export function BasePreview({
  children,
  wrapperDim,
  videoDim,
  onCancelClick,
  onAcceptClick,
}: PropsWithChildren<Props>) {
  return (
    <Wrapper
      $maxWidth={videoDim.width}
      $maxHeight={videoDim.height}
      style={{ height: `${wrapperDim.height}px` }}
    >
      {children}
      <ActionsWrapper>
        <Button onClick={onCancelClick}>
          <CancelIcon />
        </Button>
        <Button onClick={onAcceptClick}>
          <AcceptIcon />
        </Button>
      </ActionsWrapper>
    </Wrapper>
  );
}
