import { CC } from "@bigspring/core-components";
import { Company } from "@shared/Collections/models/company";
import { MenuButton, Section } from "../../styles";
import { BaseMenu, MenuOption, Props as BaseMenuProps } from "../BaseMenu";
import { CompanySelector } from "../BaseMenu/styles";
import { CompanyName } from "./styles";

export type Props<Option extends MenuOption> = BaseMenuProps & {
  selectedCompany?: Company;
  options: Option[][];
  selectedOption?: Option;
  companies: Company[];
  onOptionClick: (option: Option) => Promise<void>;
  onCompanyChange: (company: Company) => Promise<void>;
};

export function NonSuperAdminMenu<Option extends MenuOption>({
  label,
  options,
  selectedOption,
  selectedCompany,
  companies,
  onOptionClick,
  onOpenMenuClick,
  onCloseMenuClick,
  onCompanyChange,
}: Props<Option>) {
  return (
    <BaseMenu
      label={label}
      onOpenMenuClick={onOpenMenuClick}
      onCloseMenuClick={onCloseMenuClick}
    >
      <CC.Grid item container xs={12} direction="column">
        <Section item container xs={12} direction="column" $borderBottom>
          {companies.length > 1 ? (
            <CC.FloatMenu
              element={
                <CompanySelector
                  color="utility"
                  text={selectedCompany?.name}
                  endIcon={<CC.ChevronDownIcon />}
                />
              }
              defaultValue={selectedCompany?.id}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              options={companies}
              onChange={onCompanyChange}
            />
          ) : (
            <CompanyName>{selectedCompany?.name}</CompanyName>
          )}
        </Section>
        {options.map((section, index) => (
          <Section
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            item
            xs={12}
            container
            direction="column"
            $borderBottom={index + 1 < options.length}
          >
            {section.map((option) => (
              <CC.Grid key={option.text} item>
                <MenuButton
                  text={option.text}
                  color="admin-navigation"
                  startIcon={option.startIcon(selectedOption === option)}
                  selected={selectedOption === option}
                  onClick={() => onOptionClick(option)}
                />
              </CC.Grid>
            ))}
          </Section>
        ))}
      </CC.Grid>
    </BaseMenu>
  );
}
