import { styled } from "@mui/material/styles";
import { SkillCompletionSvg } from "../../../../../../icons";

type SkillCompletionIconProps = { $isComplete?: boolean };

export const SkillCompletionIcon = styled(
  SkillCompletionSvg
)<SkillCompletionIconProps>`
  width: 200px;
  height: 200px;
  margin-left: 40px;
  margin-right: 40px;
  transition: filter 200ms cubic-bezier(0.4, 0, 0.2, 1);
  filter: saturate(0);
`;

export const Wrapper = styled("div")`
  height: 100%;
  width: 600px;
  max-width: 600px;

  ${({ theme }) => theme.breakpoint.maxWidth.xs`
    width: 100%;
  `};

  @media (max-width: 576px) {
    width: auto;
    height: calc(100vh - 155px);
  }
`;
