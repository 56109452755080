import React, { ReactNode } from "react";
import { BaseCard, CardProps } from "../BaseCard";
import { Description } from "../../components/Description";
import { Title } from "../../components/Title";
import { LinkoutContainer, ButtonContainer } from "./styles";
import { AlignedContent } from "../../styles";

export type LinkoutCardProps = CardProps & {
  title: string;
  description?: string;
  ctaTitle?: ReactNode;
  onClickCta?: () => void;
};

export function LinkoutCard({
  isActive,
  title,
  description,
  ctaTitle = "View link",
  onClickCta,
  onCardActive,
}: LinkoutCardProps) {
  return (
    <LinkoutContainer>
      <BaseCard isActive={isActive} onCardActive={onCardActive}>
        <AlignedContent $verticalAlign="center">
          <Title text={title} />
          {description && <Description text={description} />}
          <ButtonContainer text={ctaTitle} onClick={onClickCta} />
        </AlignedContent>
      </BaseCard>
    </LinkoutContainer>
  );
}
