import React, { MouseEvent } from "react";
import { QuizMissSvg } from "../../../../../../../../../../icons";
import { Stats } from "../../../../../../components/Stats";
import { BaseCompletionCard } from "../BaseCompletionCard";

export type Props = {
  isActive?: boolean;
  attempts: number;
  maxAttempts: number;
  correctPromptCount: number;
  hasAttemptsRemaining: boolean;
  continueStr?: string;
  failedStr?: string;
  tryAgainStr?: string;
  attemptStr?: string;
  correctAnswersStr?: string;
  onRetryForm: (event: MouseEvent<HTMLButtonElement>) => void;
  onNextCard: (event: MouseEvent<HTMLButtonElement>) => void;
  onCardActive?: () => void;
};

export function FailedCard({
  isActive,
  attempts,
  maxAttempts,
  correctPromptCount,
  hasAttemptsRemaining,
  continueStr = "Continue",
  failedStr = "So close.",
  tryAgainStr = "Try again",
  attemptStr = "Attempt",
  correctAnswersStr = "Correct answers",
  onRetryForm,
  onNextCard,
  onCardActive,
}: Props) {
  return (
    <BaseCompletionCard
      isActive={isActive}
      title={failedStr}
      ctaText={hasAttemptsRemaining ? tryAgainStr : continueStr}
      onClick={hasAttemptsRemaining ? onRetryForm : onNextCard}
      onCardActive={onCardActive}
      showCtaButton={hasAttemptsRemaining}
    >
      <QuizMissSvg
        style={{ marginLeft: "40px", marginRight: "40px", width: 200 }}
      />
      <Stats
        stats={[
          {
            text: attemptStr,
            value: `${attempts}/${maxAttempts}`,
          },
          { text: correctAnswersStr, value: correctPromptCount },
        ]}
      />
    </BaseCompletionCard>
  );
}
