import React, {
  useMemo,
  Ref,
  ReactEventHandler,
  KeyboardEventHandler,
} from "react";
import { nanoid } from "nanoid";
import {
  Container,
  ActionButton,
  StyledActionIcon as ActionIcon,
  Track,
  TrackFill,
  Playhead,
  Duration,
} from "./styles";

type AudioHandler = ReactEventHandler<HTMLAudioElement>;

type Props = {
  src?: string;
  preload?: "none" | "metadata" | "auto" | "";
  autoPlay?: boolean;
  loop?: boolean;
  duration: number;
  playedTime: number;
  playheadMarginLeft: number;
  isLoading: boolean;
  isPlaying: boolean;
  containerRef: Ref<HTMLDivElement>;
  audioRef: Ref<HTMLAudioElement>;
  trackRef: Ref<HTMLDivElement>;
  playheadRef: Ref<HTMLButtonElement>;
  handleActionButton: ReactEventHandler<HTMLButtonElement>;
  handleSeekKeyDown: KeyboardEventHandler<HTMLButtonElement>;
  handleStartSeek: () => void;
  handleSeeking: <T extends MouseEvent | React.MouseEvent>(e: T) => void;
  handleSeeked?: AudioHandler;
  handlePlay: AudioHandler;
  handlePause: AudioHandler;
  handleEnded: AudioHandler;
  handleTimeUpdate: AudioHandler;
  handleDurationChange: AudioHandler;
  handleLoadedMetadata: AudioHandler;
  handleError?: AudioHandler;
};

const getTimecode = (seconds = 0, duration = 0) => {
  const syntheticDate = new Date(seconds * 1000).toISOString();
  let startIndex: number;
  // Use minimum padding for duration (supports up to 24 hours)
  if (duration < 600) {
    startIndex = 15; // M:SS
  } else if (duration < 3600) {
    startIndex = 14; // MM:SS
  } else if (duration < 36000) {
    startIndex = 12; // H:MM:SS
  } else {
    startIndex = 11; // HH:MM:SS
  }
  const timecode = syntheticDate.substring(startIndex, 19);

  return timecode;
};

export function AudioPlayer({
  src,
  preload,
  autoPlay,
  loop,
  duration,
  playedTime,
  playheadMarginLeft,
  isLoading,
  isPlaying,
  containerRef,
  audioRef,
  trackRef,
  playheadRef,
  handleActionButton,
  handleSeekKeyDown,
  handleStartSeek,
  handleSeeking,
  handleSeeked,
  handlePlay,
  handlePause,
  handleEnded,
  handleTimeUpdate,
  handleDurationChange,
  handleLoadedMetadata,
  handleError,
}: Props) {
  // ID for the audio element (accessibility)
  const id = useMemo(nanoid, []);

  const actionButtonTitle = isPlaying ? "Pause" : "Play";

  // Timecode string (duration if audio hasn't been played, otherwise current time)
  const seconds = isPlaying ? playedTime : Math.floor(playedTime) || duration;
  const timecode = getTimecode(seconds, duration);

  return (
    <Container ref={containerRef} aria-label="Audio Player" role="region">
      <audio
        ref={audioRef}
        id={id}
        src={src}
        preload={preload ?? "auto"}
        autoPlay={autoPlay ?? false}
        loop={loop ?? false}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        onSeeked={handleSeeked}
        onTimeUpdate={handleTimeUpdate}
        onDurationChange={handleDurationChange}
        onLoadedMetadata={handleLoadedMetadata}
        onError={handleError}
      >
        Your browser does not support the <code>audio</code> element.
      </audio>
      <ActionButton
        type="button"
        icon={<ActionIcon isPlaying={isPlaying} />}
        title={actionButtonTitle}
        aria-label={actionButtonTitle}
        aria-controls={id}
        loading={isLoading}
        onClick={handleActionButton}
        onKeyDown={handleSeekKeyDown}
      />
      <Track
        ref={trackRef}
        onClick={handleSeeking}
        onMouseDown={handleStartSeek}
      >
        <TrackFill
          $disabled={isLoading}
          style={{ width: playheadMarginLeft + 6 }}
        />
        <Playhead
          ref={playheadRef}
          type="button"
          disabled={isLoading}
          style={{ marginLeft: playheadMarginLeft }}
          tabIndex={0}
          role="slider"
          aria-label="Seek"
          aria-controls={id}
          aria-valuemax={100}
          aria-valuemin={0}
          aria-valuenow={Math.round(100 * (playedTime / duration))}
          onKeyDown={handleSeekKeyDown}
        />
      </Track>
      <Duration aria-label="Time">{timecode}</Duration>
    </Container>
  );
}
