import { styled } from "@mui/material/styles";

export const PointsTag = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 20px;
`;
