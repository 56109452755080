import React, { MouseEvent } from "react";
import { SurveyCompletionSvg } from "../../../../../../../../../../icons";
import { BaseCompletionCard } from "../BaseCompletionCard";

export type Props = {
  isActive?: boolean;
  continueStr?: string;
  surveyCompleteStr?: string;
  onNextCard: (event: MouseEvent<HTMLButtonElement>) => void;
  onCardActive?: () => void;
};

export function SurveyCard({
  isActive,
  continueStr = "Continue",
  surveyCompleteStr = "Survey complete!",
  onNextCard,
  onCardActive,
}: Props) {
  return (
    <BaseCompletionCard
      isActive={isActive}
      title={surveyCompleteStr}
      ctaText={continueStr}
      onClick={onNextCard}
      onCardActive={onCardActive}
      showCtaButton={false}
    >
      <SurveyCompletionSvg
        style={{ marginLeft: "40px", marginRight: "40px", width: 200 }}
      />
    </BaseCompletionCard>
  );
}
