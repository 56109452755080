import { css, styled } from "@mui/material/styles";
import { BSPH1 } from "../../../BSPH1";
import { BSPH2 } from "../../../BSPH2";
import { BSPBody2 } from "../../../BSPBody2";

const heading = css`
  font-weight: 500;
`;

export const Heading1 = styled(BSPH1)`
  font-size: 24px;
  line-height: 36px;
  user-select: none;
  ${heading}
`;

export const Heading2 = styled(BSPH2)`
  font-size: 16px;
  line-height: 24px;
  ${heading}
`;

export const Text = styled(BSPBody2)`
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
`;

export const Label = styled(BSPBody2)`
  display: block;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiDarker};
`;
