import React from "react";
import { css, styled } from "@mui/material/styles";
import ChipBase, { ChipProps } from "@mui/material/Chip";

interface Props extends Omit<ChipProps, "color"> {
  color?: ChipProps["color"] | "light-blue";
  $size?: "xs" | "sm" | "md" | "lg";
}

export const StyledChip = styled((props: Props) => {
  return (
    <ChipBase
      {...props}
      color={props.color === "light-blue" ? undefined : props.color}
    />
  );
})<Props>`
  max-width: 100%;
  margin-left: 5px;
  margin-right: 5px;

  ${({ $size }) => {
    if ($size === "xs") {
      return css`
        height: 20px;
        .MuiChip-label {
          font-size: 10px;
          line-height: 16px;
          padding-left: 4px;
          padding-right: 4px;
        }
      `;
    }
    if ($size === "sm") {
      return css`
        height: 24px;
        .MuiChip-label {
          font-size: 12px;
          line-height: 18px;
          padding-left: 8px;
          padding-right: 8px;
        }
      `;
    }
    if ($size === "md") {
      return css`
        height: 32px;
        .MuiChip-label {
          font-size: 14px;
          line-height: 20px;
          padding-left: 12px;
          padding-right: 12px;
        }
      `;
    }
    if ($size === "lg") {
      return css`
        height: 40px;
        .MuiChip-label {
          font-size: 16px;
          line-height: 24px;
          padding-left: 16px;
          padding-right: 16px;
        }
      `;
    }
  }}

  ${({ color }) => {
    if (color === "light-blue") {
      return css`
        background-color: #e8f9fe;
        padding: 3px 8px;
        border-radius: 4px;
        font-weight: 600;
        height: auto;
        .MuiChip-label {
          padding-left: 0;
          padding-right: 0;
          font-size: 12px;
          line-height: 18px;
          color: #252524;
          letter-spacing: 0.02em;
        }
      `;
    }

    if (color === "success") {
      return css`
        background-color: #e9f7e6;
        border-color: #20b107;
        .MuiChip-label {
          color: #252524;
        }
        .MuiChip-deleteIcon {
          color: #252524;
        }
      `;
    }

    if (color === "error") {
      return css`
        background-color: #fdece9;
        border-color: #f47766;
        .MuiChip-label {
          color: #252524;
        }
        .MuiChip-deleteIcon {
          color: #252524;
        }
      `;
    }

    if (color === "warning") {
      return css`
        background-color: #fff3e0;
        border-color: #ffb74d;
        .MuiChip-label {
          color: #252524;
        }
        .MuiChip-deleteIcon {
          color: #252524;
        }
      `;
    }
  }}
`;
