import TabBase, { TabProps } from "@mui/material/Tab";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import TabScrollButton, {
  TabScrollButtonProps,
} from "@mui/material/TabScrollButton";

import TabContext, { TabContextProps } from "@mui/lab/TabContext";

import TabList, { TabListProps } from "@mui/lab/TabList";

import TabPanel, { TabPanelProps } from "@mui/lab/TabPanel";

import { StyledTabPanel, StyledTabsList } from "./styles";

export const Tab = {
  StyledTabPanel,
  StyledTabsList,
  TabBase,
  TabContext,
  TabList,
  TabPanel,
  TabScrollButton,
  Tabs,
};

export type {
  TabProps,
  TabContextProps,
  TabListProps,
  TabPanelProps,
  TabScrollButtonProps,
  TabsProps,
};
