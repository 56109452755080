import { PropsWithChildren, ReactElement } from "react";
import { CC } from "@bigspring/core-components";
import {
  BigSpringLogo,
  Header,
  MenuOpenIcon,
  Wrapper,
  Menu,
  MenuIcon,
} from "./styles";
import { useAnimation } from "framer-motion";

export type MenuOption = {
  text: string;
  startIcon: (isSelected: boolean) => ReactElement;
};

export type Props = {
  label?: string;
  onOpenMenuClick: () => void;
  onCloseMenuClick: () => void;
};

export function BaseMenu({
  children,
  label,
  onOpenMenuClick: _onOpenMenuClick,
  onCloseMenuClick: _onCloseMenuClick,
}: PropsWithChildren<Props>) {
  const controls = useAnimation();
  const openMenuControls = useAnimation();

  const onOpenMenuClick = async () => {
    void openMenuControls.start({ opacity: 0 });
    void controls.start({ x: 0, opacity: 1 });

    _onOpenMenuClick();
  };

  const onCloseMenuClick = async () => {
    void openMenuControls.start({ opacity: 1 });
    void controls.start({ x: -500, opacity: 0 });

    _onCloseMenuClick();
  };

  return (
    <Wrapper>
      <Menu animate={controls} transition={{ duration: 0.5 }}>
        <CC.Grid container direction="column">
          <Header item container xs={12} alignItems="center">
            <CC.Grid item xs={4}>
              <MenuOpenIcon onClick={onCloseMenuClick} />
            </CC.Grid>
            <CC.Grid item container justifyContent="center" xs={4}>
              <BigSpringLogo />
            </CC.Grid>
            <CC.Grid item container justifyContent="flex-end" xs={4}>
              {!!label && <CC.Badge>{label}</CC.Badge>}
            </CC.Grid>
          </Header>
          {children}
        </CC.Grid>
      </Menu>
      <MenuIcon animate={openMenuControls} transition={{ duration: 0.6 }}>
        <CC.MenuIcon onClick={onOpenMenuClick} />
      </MenuIcon>
    </Wrapper>
  );
}
