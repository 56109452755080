import {
  SuperAdminMenu,
  Props as SuperAdminMenuProps,
} from "./components/SuperAdminMenu";
import {
  NonSuperAdminMenu,
  Props as NonSuperAdminMenuProps,
} from "./components/NonSuperAdminMenu";
import { MenuOption } from "./components/BaseMenu";

type MenuType = "superAdminMenu" | "nonSuperAdminMenu";

interface MenuPropsMap<Option extends MenuOption> {
  superAdminMenu: SuperAdminMenuProps<Option>;
  nonSuperAdminMenu: NonSuperAdminMenuProps<Option>;
}

type Props<Type extends MenuType, Option extends MenuOption> = {
  as: Type;
  additionalProps: MenuPropsMap<Option>[Type];
};

export function NavigationMenuView<
  Option extends MenuOption,
  Type extends MenuType
>({ as, additionalProps }: Props<Type, Option>) {
  switch (as) {
    case "superAdminMenu":
      return <SuperAdminMenu {...(additionalProps as any)} />;
    case "nonSuperAdminMenu":
    default:
      return <NonSuperAdminMenu {...additionalProps} />;
  }
}
