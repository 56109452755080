import { useApolloClient } from "@apollo/client";
import { CCHelpers } from "@bigspring/core-components";
import { GlobalStorage } from "@bigspring/core-components/src/utils/global-storage";
import { getRoute } from "@config/routes";
import {
  AuthenticationSource,
  MeQuery,
  Role,
  useAuthLogoutMutation,
  useMeQuery,
} from "@gql/generated/graphql";
import { googleLogout } from "@react-oauth/google";
import { LoadingContainer } from "@shared/Loading";
import { LoadingWrapper } from "@shared/Loading/styles";
import {
  PropsWithChildren,
  useCallback,
  createContext,
  useContext,
  useState,
  useMemo,
} from "react";
import {
  destroyCompanyIds,
  getCompanyId,
  storeCompanyId,
  storeCompanyIds,
} from "utils/auth";
import { useCustomRouter } from "../CustomRouter";

/**
 * Possible values for User
 *
 * - Me_me: logged user info
 * - null: we know there is no user/session (i.e. token invalid or no token at all)
 * - undefined: we don't know yet (i.e. still loading) -- see useEffect under AuthenticationProvider component
 */
export type User = MeQuery["me"] | null;

interface IAuthentication {
  user?: User;
  currentTab: string | null;
  setCurrentTab: (tab: string) => void;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

const Context = createContext<IAuthentication>({
  user: undefined,
  currentTab: null,
  setCurrentTab: () => null,
  login: async () => undefined,
  logout: async () => undefined,
});

/**
 * Only event reported without AdminTracker because its creation is before AdminTracker
 * @param me
 * @param apolloClient
 */
function callAnalyticsToReportSignIn(me: NonNullable<MeQuery["me"]>): void {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const analyticsClient = CCHelpers.useSegment(
    !!process.env.NEXT_PUBLIC_SEGMENT_ENABLED
  );

  let event;
  if (me.isSuperAdmin) {
    event = {
      companyId: null,
      companyExternalId: null,
      profileRoles: Role.SuperAdmin,
    };
  } else {
    event = {
      companyId: me.companyProfiles[0].company.id,
      companyName: me.companyProfiles[0].company.name,
      profileRoles: me.companyProfiles[0].roles,
    };
  }
  void analyticsClient.track("User Logged In", { ...event });
}

export function AuthenticationProvider({
  children,
}: PropsWithChildren<unknown>) {
  const { replace, pathname } = useCustomRouter();
  const apolloClient = useApolloClient();

  const [currentTab, setCurrentTab] = useState<string | null>(null);

  const {
    loading,
    data: user,
    refetch,
  } = useMeQuery({
    variables: CCHelpers.getTraits("admin"),
    fetchPolicy: "cache-and-network",
  });

  const login = useCallback(async () => {
    await apolloClient.clearStore();

    const { data } = await refetch();

    if (data?.me?.companyProfiles[0].company.id) {
      callAnalyticsToReportSignIn(data.me);

      if (!getCompanyId()) {
        const rolesWithAdminAccess = [
          Role.Coach,
          Role.CompanyAdministrator,
          Role.CompanyAnalytics,
          Role.ContentCreator,
          Role.SuperAdmin,
        ];

        const firstProfileWithAccess = data.me.companyProfiles?.find(
          (companyProfile) =>
            companyProfile?.roles?.some((role) =>
              rolesWithAdminAccess.includes(role)
            )
        );

        const companyId =
          firstProfileWithAccess?.company?.id ||
          data.me.companyProfiles[0].company.id;

        storeCompanyId(companyId);
      }

      if (data.me.isSuperAdmin) {
        storeCompanyIds("ALL");
      } else {
        storeCompanyIds(
          data.me.companyProfiles.map(
            (companyProfile) => companyProfile.company.id
          )
        );
      }
    }

    await replace("dashboard");
  }, [apolloClient, refetch, replace]);

  const [doLogout] = useAuthLogoutMutation({
    variables: {
      logoutInput: {
        source: AuthenticationSource.Admin,
      },
    },
  });

  const logout = useCallback(async () => {
    try {
      await doLogout();
    } catch (e) {
      // we don't care if logout fails as we will also remove our credentials locally
    } finally {
      const store = new GlobalStorage();
      const isGoogleAuth = store.getItem("isGauth-admin") === "true";
      if (isGoogleAuth) googleLogout();

      store.clear();
      destroyCompanyIds();

      await apolloClient.clearStore();
      await apolloClient.cache.reset({ discardWatches: true });

      await replace("login");
    }
  }, [apolloClient, doLogout, replace]);

  const value = useMemo(
    () => ({
      user: user?.me,
      currentTab,
      setCurrentTab,
      login,
      logout,
    }),
    [currentTab, login, logout, user]
  );

  if (loading || (!user && pathname !== getRoute("login"))) {
    return (
      <LoadingWrapper>
        <LoadingContainer />
      </LoadingWrapper>
    );
  }

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useAuth() {
  return useContext(Context);
}
