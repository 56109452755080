import React, { HTMLAttributes, ReactNode } from "react";
import { InnerContainer } from "../Description/styles";
import { Title as StyledTitle } from "./styles";

export type Props = Omit<HTMLAttributes<HTMLHeadingElement>, "children"> & {
  text: ReactNode;
  isVerticalCard?: string;
};

export function Title({ text, isVerticalCard, ...props }: Props) {
  return (
    <InnerContainer $isVerticalCard={!!isVerticalCard}>
      <StyledTitle {...props}>{text}</StyledTitle>
    </InnerContainer>
  );
}
