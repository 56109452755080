import { styled } from "@mui/material/styles";

export const FormCardContainer = styled("div")`
  height: 100%;
  width: 600px;
  max-width: 600px;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled("div")<{ width?: string | number }>`
  width: 320px;
`;
