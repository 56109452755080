import type { VideoJsPlayer } from "video.js";
import { GlobalStorage } from "../../../../../utils/global-storage";
import { debounce } from "../../../../../utils/debounce";

const globalStorage = new GlobalStorage();

const VIDEO_PROGRESS_KEY = "videoProgress";

const getSavedProgress = () => {
  const videoProgressStr = globalStorage.getItem(VIDEO_PROGRESS_KEY);
  const videoProgress = JSON.parse(videoProgressStr || "{}");

  return videoProgress as Record<string, number>;
};

const setSavedProgress = (progress: Record<string, number>) =>
  globalStorage.setItem(VIDEO_PROGRESS_KEY, JSON.stringify(progress));

/**
 * Save the video's current time to an object in local storage.
 *
 * @param player Video.js player
 * @returns void
 */
export const saveCurrentTime = debounce((player: VideoJsPlayer) => {
  const videoProgress = getSavedProgress();
  videoProgress[player.currentSrc()] = player.currentTime();
  setSavedProgress(videoProgress);
}, 100);

/**
 * Set the video's current time from an object in local storage.
 *
 * @param player Video.js player
 * @returns boolean for whether time was restored
 */
export const restoreCurrentTime = (player: VideoJsPlayer) => {
  const videoProgress = getSavedProgress();
  const currentTime = videoProgress[player.currentSrc()] || 0;
  if (
    typeof currentTime === "number" &&
    currentTime &&
    currentTime < player.duration() - 1
  ) {
    player.currentTime(currentTime);

    return true;
  }

  return false;
};

/**
 * Remove the video's current time from an object in local storage.
 *
 * @param player Video.js player
 * @returns void
 */
export const removeCurrentTime = (player: VideoJsPlayer) => {
  const videoProgress = getSavedProgress();
  delete videoProgress[player.currentSrc()];
  setSavedProgress(videoProgress);
};
