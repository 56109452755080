import { Component, ErrorInfo, ReactNode } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { DATADOG_ENABLED } from "@shared/DatadogProvider";
import { AdminError } from "@scenes/Error";

export class ErrorBoundary extends Component<
  Record<string, ReactNode>,
  { hasError: boolean }
> {
  constructor(props: Record<string, ReactNode>) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // We catch here unexpected/unhandled errors
    if (DATADOG_ENABLED) {
      datadogRum.addError(error, errorInfo);
    }
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <AdminError
          onTryAgainClick={() => this.setState({ hasError: false })}
        />
      );
    }

    return children;
  }
}
