import {
  BodySegmenter,
  toBinaryMask,
} from "@tensorflow-models/body-segmentation";
import { Segmentation } from "@tensorflow-models/body-segmentation/dist/shared/calculators/interfaces/common_interfaces";

type DrawOptions = {
  flipHorizontal: boolean;
  /**
   * Can be used to place one image on top of the other. E.g: sharing screen while having
   * the user camera
   */
  asPopup?: {
    previousCtx: CanvasRenderingContext2D | null;
    x: number;
    y: number;
    width: number;
    height: number;
    borderRadius: number;
  };
};

type DrawBlurOptions = DrawOptions & {
  backgroundBlurAmount: number;
  edgeBlurAmount: number;
};

type DrawBackgroundOptions = DrawOptions & {
  backgroundImage: HTMLImageElement;
};

type Canvas = HTMLCanvasElement | OffscreenCanvas;

const offScreenCanvases: { [name: string]: Canvas } = {};

function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function waitForEvent<Element extends HTMLElement>(
  item: Element,
  event: keyof HTMLElementEventMap
) {
  return new Promise((resolve) => {
    const listener = () => {
      item.removeEventListener(event, listener);
      resolve(true);
    };

    item.addEventListener(event, listener);
  });
}

function drawRoundedImage(
  video: HTMLVideoElement | HTMLImageElement | Canvas,
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number
) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();

  ctx?.clip();
  ctx?.drawImage(video, x, y, width, height);
}

export function drawBlackBackground(canvas: HTMLCanvasElement) {
  const canvasCtx = canvas.getContext("2d");

  if (canvasCtx) {
    canvasCtx.fillStyle = "black";
    canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
  }
}

/**
 * Draw the current device stream in a canvas without any effect (plain image).
 *
 * @param video
 * @param canvas
 */
export function drawPlainDeviceMedia(
  video: HTMLVideoElement,
  canvas: HTMLCanvasElement
): CanvasRenderingContext2D | null {
  const canvasCtx = canvas.getContext("2d");

  canvasCtx?.drawImage(video, 0, 0, canvas.width, canvas.height);

  return canvasCtx;
}

/**
 * Draw the current user stream in a canvas without any effect (plain image).
 *
 * @param video
 * @param canvas
 * @param options
 */
export function drawPlain(
  video: HTMLVideoElement,
  canvas: HTMLCanvasElement,
  options: DrawOptions
): CanvasRenderingContext2D | null {
  const canvasCtx = options.asPopup?.previousCtx ?? canvas.getContext("2d");

  canvasCtx?.save();

  if (options.flipHorizontal) {
    flipCanvasHorizontal(canvas);
  }

  if (options.asPopup && canvasCtx) {
    drawRoundedImage(
      video,
      canvasCtx,
      options.asPopup.x,
      options.asPopup.y,
      options.asPopup.width,
      options.asPopup.height,
      options.asPopup.borderRadius
    );
  } else {
    canvasCtx?.drawImage(video, 0, 0, video.width, video.height);
  }

  canvasCtx?.restore();

  return canvasCtx;
}

/**
 * Draw the current user stream in a canvas with blur effect.
 *
 * @param model
 * @param video
 * @param canvas
 * @param options
 */
export async function drawWithBlur(
  model: BodySegmenter,
  video: HTMLVideoElement,
  canvas: HTMLCanvasElement,
  options: DrawBlurOptions
): Promise<CanvasRenderingContext2D | null> {
  const segmentation = await model.segmentPeople(video);

  const blurredImage = drawAndBlurImageOnOffScreenCanvas(
    video,
    options.backgroundBlurAmount,
    "blurred"
  );

  const canvasCtx = options.asPopup?.previousCtx ?? canvas.getContext("2d");

  const personMask = await createPersonMask(
    segmentation,
    0.5,
    options.edgeBlurAmount,
    "blurPersonMask"
  );

  canvasCtx?.save();

  if (options.flipHorizontal) {
    flipCanvasHorizontal(canvas);
  }

  // draw the original image on the final canvas
  if (options.asPopup && canvasCtx) {
    drawRoundedImage(
      video,
      canvasCtx,
      options.asPopup.x,
      options.asPopup.y,
      options.asPopup.width,
      options.asPopup.height,
      options.asPopup.borderRadius
    );
  } else {
    canvasCtx?.drawImage(video, 0, 0, video.width, video.height);
  }

  // "destination-in" - "The existing canvas content is kept where both the
  // new shape and existing canvas content overlap. Everything else is made
  // transparent."
  // crop what's not the person using the mask from the original image
  if (canvasCtx) {
    canvasCtx.globalCompositeOperation = "destination-in";
  }

  if (options.asPopup && canvasCtx) {
    drawRoundedImage(
      personMask,
      canvasCtx,
      options.asPopup.x,
      options.asPopup.y,
      options.asPopup.width,
      options.asPopup.height,
      options.asPopup.borderRadius
    );
  } else {
    canvasCtx?.drawImage(personMask, 0, 0, video.width, video.height);
  }

  // "destination-over" - "The existing canvas content is kept where both the
  // new shape and existing canvas content overlap. Everything else is made
  // transparent."
  // draw the blurred background on top of the original image where it doesn't
  // overlap.
  if (canvasCtx) {
    canvasCtx.globalCompositeOperation = "destination-over";
  }

  if (options.asPopup && canvasCtx) {
    drawRoundedImage(
      blurredImage,
      canvasCtx,
      options.asPopup.x,
      options.asPopup.y,
      options.asPopup.width,
      options.asPopup.height,
      options.asPopup.borderRadius
    );
  } else {
    canvasCtx?.drawImage(blurredImage, 0, 0, video.width, video.height);
  }

  canvasCtx?.restore();

  return canvasCtx;
}

function drawAndBlurImageOnOffScreenCanvas(
  video: HTMLVideoElement | Canvas,
  blurAmount: number,
  offscreenCanvasName: string
) {
  const canvas = getOffscreenCanvas(offscreenCanvasName);

  drawAndBlurImageOnCanvas(video, blurAmount, canvas);

  return canvas;
}

export function getOffscreenCanvas(id: string): Canvas {
  if (!offScreenCanvases[id]) {
    offScreenCanvases[id] = createOffScreenCanvas();
  }

  return offScreenCanvases[id];
}

function createOffScreenCanvas(): Canvas {
  if (typeof document !== "undefined") {
    return document.createElement("canvas");
  } else if (typeof OffscreenCanvas !== "undefined") {
    return new OffscreenCanvas(0, 0);
  } else {
    throw new Error("Cannot create a canvas in this context");
  }
}

function drawAndBlurImageOnCanvas(
  video: HTMLVideoElement | Canvas,
  blurAmount: number,
  canvas: Canvas
) {
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

  canvas.width = video.width;
  canvas.height = video.height;

  ctx?.clearRect(0, 0, video.width, video.height);
  ctx?.save();

  if (isSafari()) {
    cpuBlur(canvas, video, blurAmount);
  } else {
    // tslint:disable:no-any
    (ctx as any).filter = `blur(${blurAmount}px)`;

    ctx?.drawImage(video, 0, 0, video.width, video.height);
  }

  ctx?.restore();
}

function cpuBlur(
  canvas: Canvas,
  image: HTMLVideoElement | Canvas,
  blur: number
) {
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

  let sum = 0;
  const delta = 5;
  const alphaLeft = 1 / (2 * Math.PI * delta * delta);
  const step = blur < 3 ? 1 : 2;

  for (let y = -blur; y <= blur; y += step) {
    for (let x = -blur; x <= blur; x += step) {
      const weight =
        alphaLeft * Math.exp(-(x * x + y * y) / (2 * delta * delta));
      sum += weight;
    }
  }

  for (let y = -blur; y <= blur; y += step) {
    for (let x = -blur; x <= blur; x += step) {
      if (ctx) {
        ctx.globalAlpha =
          ((alphaLeft * Math.exp(-(x * x + y * y) / (2 * delta * delta))) /
            sum) *
          blur;

        ctx.drawImage(image, x, y);
      }
    }
  }

  if (ctx) {
    ctx.globalAlpha = 1;
  }
}

async function createPersonMask(
  segmentation: Segmentation | Segmentation[],
  foregroundThreshold: number,
  edgeBlurAmount: number,
  offscreenCanvasName: string
): Promise<Canvas> {
  const backgroundMaskImage = await toBinaryMask(
    segmentation,
    { r: 0, g: 0, b: 0, a: 255 },
    { r: 0, g: 0, b: 0, a: 0 },
    false,
    foregroundThreshold
  );

  const canvas = getOffscreenCanvas(offscreenCanvasName);

  canvas.width = backgroundMaskImage.width;
  canvas.height = backgroundMaskImage.height;
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

  ctx?.putImageData(backgroundMaskImage, 0, 0);

  return drawAndBlurImageOnOffScreenCanvas(
    canvas,
    edgeBlurAmount,
    "blurredMask"
  );
}

function flipCanvasHorizontal(canvas: Canvas) {
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
  ctx?.scale(-1, 1);
  ctx?.translate(-canvas.width, 0);
}

export async function drawWithBackground(
  model: BodySegmenter,
  video: HTMLVideoElement,
  canvas: HTMLCanvasElement,
  options: DrawBackgroundOptions
): Promise<CanvasRenderingContext2D | null> {
  const segmentation = await model.segmentPeople(video);

  const canvasCtx = options.asPopup?.previousCtx ?? canvas.getContext("2d");

  const personMask = await createPersonMask(
    segmentation,
    0.5,
    6,
    "backgroundPersonMask"
  );

  canvasCtx?.save();

  if (options.flipHorizontal) {
    flipCanvasHorizontal(canvas);
  }

  // draw the original image on the final canvas
  if (options.asPopup && canvasCtx) {
    drawRoundedImage(
      video,
      canvasCtx,
      options.asPopup.x,
      options.asPopup.y,
      options.asPopup.width,
      options.asPopup.height,
      options.asPopup.borderRadius
    );
  } else {
    canvasCtx?.drawImage(video, 0, 0, video.width, video.height);
  }

  // "destination-in" - "The existing canvas content is kept where both the
  // new shape and existing canvas content overlap. Everything else is made
  // transparent."
  // crop what's not the person using the mask from the original image
  if (canvasCtx) {
    canvasCtx.globalCompositeOperation = "destination-in";
  }

  if (options.asPopup && canvasCtx) {
    drawRoundedImage(
      personMask,
      canvasCtx,
      options.asPopup.x,
      options.asPopup.y,
      options.asPopup.width,
      options.asPopup.height,
      options.asPopup.borderRadius
    );
  } else {
    canvasCtx?.drawImage(personMask, 0, 0, video.width, video.height);
  }

  // "destination-over" - "The existing canvas content is kept where both the
  // new shape and existing canvas content overlap. Everything else is made
  // transparent."
  // draw the background image on top of the original image where it doesn't
  // overlap.
  if (canvasCtx) {
    canvasCtx.globalCompositeOperation = "destination-over";
  }

  if (options.asPopup && canvasCtx) {
    drawRoundedImage(
      options.backgroundImage,
      canvasCtx,
      options.asPopup.x,
      options.asPopup.y,
      options.asPopup.width,
      options.asPopup.height,
      options.asPopup.borderRadius
    );
  } else {
    canvasCtx?.drawImage(
      options.backgroundImage,
      0,
      0,
      video.width,
      video.height
    );
  }

  canvasCtx?.restore();

  return canvasCtx;
}
