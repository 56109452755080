import { SC } from "@bigspring/core-components";

type Props = {
  $noMargin?: boolean;
};

export const Separator = SC.styled("figure", SC.withTransientProps)<Props>`
  width: 100%;
  height: 1px;
  margin: ${({ $noMargin }) => ($noMargin ? 0 : "20px 0")};
  background-color: ${({ theme }) => theme.kinetic.palette.rgb.gray200};
`;
