import {
  createContext,
  useContext,
  PropsWithChildren,
  useMemo,
  useCallback,
} from "react";
import { CCHelpers } from "@bigspring/core-components";
import { useAuth } from "components/shared/Authentication";
import { getCompanyId, storeCompanyId } from "utils/auth";
import { orderByLanguageName } from "utils/order";
import { FeatureType, FlagsmithFeatureType } from "config/flags";
import { BaseModel } from "components/scenes/baseModel";
import { Company } from "./models/company";
import { LoadingContainer } from "@shared/Loading";
import { LoadingWrapper } from "@shared/Loading/styles";
import {
  FeatureType as DbFeatureType,
  Language,
  MeQuery,
  useCollectionsQuery,
} from "@gql/generated/graphql";
import { LanguageData } from "./types";

interface CollectionsContext {
  userId: string;
  loading: boolean;
  companies: Company[];
  currentCompany: Company;
  flags: NonNullable<MeQuery["me"]>["flags"];
  getFlag: <T extends string | number | object | boolean = false>(
    name: FlagsmithFeatureType
  ) => T | boolean;
  /** @deprecated Use `flags` instead */
  features: string[];
  /** @deprecated Use `getFlag` instead */
  hasCompanyFeature: (name: FeatureType | FeatureType[]) => boolean;
  changeCurrentCompany: (id: string) => void;
  languages: Language[];
  localizations: LanguageData[];
  defaultLanguage: Language;
}

const DEFUALT_COMPANY = new Company();

const Context = createContext<CollectionsContext>({
  userId: "",
  loading: false,
  companies: [],
  currentCompany: DEFUALT_COMPANY,
  flags: [],
  getFlag: () => false,
  features: [],
  hasCompanyFeature: () => false,
  changeCurrentCompany: () => undefined,
  defaultLanguage: Language.En,
  languages: [],
  localizations: [],
});

export function CollectionsProvider({ children }: PropsWithChildren<unknown>) {
  const storedCompanyId = getCompanyId();
  const { user } = useAuth();

  const {
    data: rawData,
    loading,
    error,
  } = useCollectionsQuery({
    skip: !user,
  });

  const companies = useMemo(
    () => rawData?.companies.nodes.map((company) => new Company(company)) ?? [],
    [rawData?.companies.nodes]
  );

  const currentCompany = useMemo(
    () =>
      companies.find((company) => company.id === storedCompanyId) ??
      new Company(),
    [companies, storedCompanyId]
  );

  const defaultLanguage = currentCompany.defaultLanguage;
  const defaultCountryCode = currentCompany.countryCode;
  const defaultTimezone = currentCompany.timezone;

  /**
   * @deprecated Use `flags` instead
   */
  const companyFeatures = currentCompany.companyFeatures.map(
    (cf) => cf.feature.name
  );
  const userId = user?.companyProfiles[0].id ?? "";

  const flags = useMemo(() => user?.flags ?? [], [user?.flags]);

  /**
   * Get a Flagsmith flag enabled state or value for a profile.
   * Returns a boolean for the enabled state if no value is found.
   * Otherwise returns the parsed flag value.
   *
   * @param name Flagsmith feature name
   * @returns Flagsmith feature enabled boolean, or value if set (string | number | object)
   */
  const getFlag = useCallback(
    <T extends string | number | object | boolean = false>(
      name: FlagsmithFeatureType
    ): T | boolean => {
      if (!user) {
        return false;
      }

      const formattedName = name.toLowerCase();

      const flag = flags.find((f) => f.name.toLowerCase() === formattedName);

      // The backend omits disabled flags and enabled flags with "false" values
      if (!flag || flag.value.toLowerCase() === "control") {
        return false;
      }

      // Return flag value
      try {
        return JSON.parse(flag.value as string);
      } catch {
        return flag.value as T;
      }
    },
    [flags, user]
  );

  const _hasFeature = useCallback(
    (name: FeatureType) => {
      return (
        getFlag(name as FlagsmithFeatureType) !== false ||
        companyFeatures.includes(name as unknown as DbFeatureType)
      );
    },
    [companyFeatures, getFlag]
  );

  /**
   * @deprecated Use `getFlag` instead
   */
  const hasCompanyFeature = useCallback(
    (name: FeatureType | FeatureType[]) => {
      if (Array.isArray(name)) {
        return name.every(_hasFeature);
      }

      return _hasFeature(name);
    },
    [_hasFeature]
  );

  const languagePayload = useMemo(() => {
    const languageCodes =
      currentCompany?.companyLanguages
        ?.map((cl) => cl.language)
        .sort((a, b) => orderByLanguageName(a, b, defaultLanguage)) ?? [];

    const languageData = languageCodes.map<LanguageData>((language) => ({
      key: language,
      label: CCHelpers.getLanguageFullName(language, false),
      isDefault: defaultLanguage === language,
    }));

    return { languageCodes, languageData };
  }, [currentCompany, defaultLanguage]);

  const changeCurrentCompany = (id: string) => {
    storeCompanyId(id);
  };

  // TODO: Rename properties across admin. languages -> languageCodes, localizations -> languages

  BaseModel.setCompanyDefaultLanguage(defaultLanguage);
  BaseModel.setCompanyDefaultCountryCode(defaultCountryCode);
  BaseModel.setCompanyDefaultTimezone(defaultTimezone);
  BaseModel.setCompanyLocalizations(languagePayload.languageData);
  BaseModel.setUserFlags(flags);

  const value = useMemo(
    () => ({
      userId,
      loading,
      companies,
      currentCompany,
      flags,
      getFlag,
      features: companyFeatures,
      hasCompanyFeature,
      changeCurrentCompany,
      defaultLanguage,
      languages: languagePayload.languageCodes,
      localizations: languagePayload.languageData,
    }),
    [
      companies,
      companyFeatures,
      currentCompany,
      defaultLanguage,
      flags,
      getFlag,
      hasCompanyFeature,
      languagePayload.languageCodes,
      languagePayload.languageData,
      loading,
      userId,
    ]
  );

  if (error) {
    throw new Error("An error has occurred trying to load the company");
  }

  if (loading) {
    return (
      <LoadingWrapper>
        <LoadingContainer />
      </LoadingWrapper>
    );
  }

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useCollections() {
  return useContext(Context);
}
