import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import * as tf from "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-converter";
import * as tfjsWasm from "@tensorflow/tfjs-backend-wasm";
import * as selfieSegmentation from "@mediapipe/selfie_segmentation";
import {
  BodySegmenter,
  createSegmenter,
  SupportedModels,
} from "@tensorflow-models/body-segmentation";

import { Localizations, MenuOptionType, ShootType, WebcamView } from "./view";
import {
  drawBlackBackground,
  drawPlain,
  drawPlainDeviceMedia,
  drawWithBackground,
  drawWithBlur,
  waitForEvent,
} from "./helpers";
import deviceDetect from "ismobilejs";
import { typewriterTrack } from "../../../lib/events";
import { Effect, EffectType } from "./components/VisualEffectsModal";
import { SelectedDevices } from "./components/SettingsModal";
import { OptionType } from "./components/OptionsMenu";
import {
  AppSource,
  BackgroundType,
  ButtonType,
  ResponseType,
} from "../../../../typewriter/segment";

tfjsWasm.setWasmPaths(
  `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/`
);

type FacingMode = "user" | "environment";

type Options = Record<
  | Exclude<
      OptionType,
      OptionType.BACKGROUNDS | OptionType.SETTINGS | OptionType.UPLOAD_FILE
    >
  | MenuOptionType,
  boolean
>;

type VideoSource = "frontCamera" | "rearCamera";

type Props = {
  // TODO: Replace these two props by mode = 'video' | 'photo'
  /** Whether the camera should allow photos or not. Do not enabled this option if isVideoEnabled is true */
  isPhotoEnabled: boolean;
  /** Whether the camera should allow videos or not. Do not enabled this option if isPhotoEnabled is true */
  isVideoEnabled: boolean;
  // TODO: We have to decide whether we want to handle events and errors
  // in core components or raise events to the parent apps. I think
  // core components should be agnostic of any logging system.
  // Leaving as is since we already have a Segment protocol created for CC
  trackingProps: {
    companyExternalId: string;
    profileExternalId: string;
    appSource: "admin" | "pwa";
  };
  localizations?: Localizations;
  onLoaded?: VoidFunction;
  onError?: (error: Error) => void;
  /* Event triggered during video recording every 1 second. Only available in video mode */
  onTimeUpdate?: (seconds: number) => void;
  /** Event triggered when count down starts after user clicks on record/shoot button */
  onCountDownStarts?: VoidFunction;
  /**
   * Event triggered when screen sharing ends.
   * It gets the current state of the options as first argument
   * in case it is needed to perform any action in callers
   */
  onScreenShareEnds?: (options: Options) => void;
  /** Event triggered when video recording starts after count down */
  onRecordStarts?: VoidFunction;
  /** Event triggered when video recording ends or image is taken */
  onRecordEnds: (file: File, source: VideoSource) => void;
  /** Event triggered when a file is selected from the file system */
  onFileUploaded: (file: File) => void;
};

type ExposedMethods = {
  onOptionClick: (type: OptionType | MenuOptionType) => Promise<boolean>;
};

const FPS = 30;
const COUNT_DOWN = 3;
const EDGE_BLUR_AMOUNT = 10;
const FLIP_USER_CAMERA_HORIZONTAL = true;
const USER_CAMERA_AS_POPUP = {
  x: 40,
  y: 40,
  width: 240,
  height: 135,
  borderRadius: 10,
};

const EXPORTED_IMAGE_FORMAT = "image/jpeg";
const EXPORTED_VIDEO_FORMAT = "video/webm";
const FALLBACK_EXPORTED_VIDEO_FORMAT = "video/mp4";

export const Webcam = forwardRef<ExposedMethods, Props>(
  (
    {
      isPhotoEnabled,
      isVideoEnabled,
      trackingProps,
      localizations,
      onLoaded,
      onError,
      onTimeUpdate,
      onCountDownStarts,
      onScreenShareEnds,
      onRecordStarts,
      onRecordEnds,
      onFileUploaded,
    },
    ref
  ) => {
    const isMobile = deviceDetect(window.navigator).phone;
    const audioContext = useMemo(() => new AudioContext(), []);
    const mediaStreamAudioDestinationNode = useMemo(
      () => new MediaStreamAudioDestinationNode(audioContext),
      [audioContext]
    );

    const mountedRef = useRef(true);
    const drawCanvasTimeoutRef = useRef<number | null>();
    const countDownIntervalRef = useRef<number | null>(null);
    const timerIntervalRef = useRef<number | null>(null);

    /**
     * Canvas containing the user and device media (screen share, camera and microfone)
     */
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    /**
     * The media stream of the user (camera and microfone)
     */
    const userMediaStreamRef = useRef<MediaStream | null>(null);

    /**
     * The media stream of the device (screen sharing)
     */
    const deviceMediaStreamRef = useRef<MediaStream | null>(null);

    /**
     * Media recorder is used to take the image from the canvas
     * and transform it in a video file. You can change the video format
     * by updating the constant EXPORTED_VIDEO_FORMAT
     */
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);

    const currentUserAudioSourceRef = useRef<MediaStreamAudioSourceNode | null>(
      null
    );
    const currentDeviceAudioSourceRef =
      useRef<MediaStreamAudioSourceNode | null>(null);

    const modelRef = useRef<BodySegmenter | null>(null);

    /**
     * We need this ref in order to handle the toggle behavior inside drawCanvas()
     * function since it stars an inifite loop and it loose the React context
     * and loose the states. To toggle the icons in the screen we use the "effects" state
     */
    const effectsRef = useRef<{
      camera: boolean;
      background: HTMLImageElement | false;
      blur: number;
      flipHorizontal: boolean;
    }>({
      camera: true,
      background: false,
      blur: 0,
      flipHorizontal: false,
    });

    const [videoDim, setVideoDim] = useState({ width: 0, height: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const [hideInfinityMirrorWarning, setHideInfinityMirrorWarning] =
      useState(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const [isVisualEffectsModalOpen, setIsVisualEffectsModalOpen] =
      useState(false);
    const [availableDevices, setAvailableDevices] = useState<MediaDeviceInfo[]>(
      []
    );
    const [selectedDevices, setSelectedDevices] = useState<SelectedDevices>({
      audioDevice: undefined,
      videoDevice: undefined,
    });

    const [countDown, setCountDown] = useState(COUNT_DOWN);
    const [hasShootStarted, setHasShootStarted] = useState<
      ShootType | undefined
    >();

    const [seconds, setSeconds] = useState(0);

    /**
     * These are the available menu options. This state is used to update
     * the icons in the view, just for visiblity purposes.
     */
    const [options, setOptions] = useState<Options>({
      prompter: false,
      mic: true,
      camera: true,
      presentation: false,
    });

    /**
     * This is the video element used to capture the user media stream provided
     * by MediaDevices
     */
    const userVideo = useRef(document.createElement("video"));
    userVideo.current.muted = true;
    userVideo.current.playsInline = true;

    /**
     * This is the video element used to capture the device media stream provided
     * by MediaDevices
     */
    const deviceVideo = useRef(document.createElement("video"));
    const isSharingScreenOrBrowserWindow = useRef(false);
    deviceVideo.current.muted = true;
    deviceVideo.current.playsInline = true;

    const drawCanvas = useCallback(async () => {
      if (drawCanvasTimeoutRef.current) {
        window.clearTimeout(drawCanvasTimeoutRef.current);
      }

      if (canvasRef.current) {
        let context: CanvasRenderingContext2D | null = null;

        if (deviceMediaStreamRef.current) {
          context = drawPlainDeviceMedia(
            deviceVideo.current,
            canvasRef.current
          );
        }

        if (
          effectsRef.current.camera &&
          !effectsRef.current.background &&
          !effectsRef.current.blur
        ) {
          drawPlain(userVideo.current, canvasRef.current, {
            flipHorizontal: effectsRef.current.flipHorizontal,
            asPopup: deviceMediaStreamRef.current
              ? {
                  previousCtx: context,
                  x: USER_CAMERA_AS_POPUP.x,
                  y: USER_CAMERA_AS_POPUP.y,
                  width: USER_CAMERA_AS_POPUP.width,
                  height: USER_CAMERA_AS_POPUP.height,
                  borderRadius: USER_CAMERA_AS_POPUP.borderRadius,
                }
              : undefined,
          });
        }

        if (
          effectsRef.current.camera &&
          effectsRef.current.blur &&
          modelRef.current
        ) {
          await drawWithBlur(
            modelRef.current,
            userVideo.current,
            canvasRef.current,
            {
              backgroundBlurAmount: effectsRef.current.blur,
              edgeBlurAmount: EDGE_BLUR_AMOUNT,
              flipHorizontal: effectsRef.current.flipHorizontal,
              asPopup:
                deviceMediaStreamRef.current && effectsRef.current.camera
                  ? {
                      previousCtx: context,
                      x: USER_CAMERA_AS_POPUP.x,
                      y: USER_CAMERA_AS_POPUP.y,
                      width: USER_CAMERA_AS_POPUP.width,
                      height: USER_CAMERA_AS_POPUP.height,
                      borderRadius: USER_CAMERA_AS_POPUP.borderRadius,
                    }
                  : undefined,
            }
          );
        }

        if (
          effectsRef.current.camera &&
          effectsRef.current.background &&
          modelRef.current
        ) {
          await drawWithBackground(
            modelRef.current,
            userVideo.current,
            canvasRef.current,
            {
              flipHorizontal: effectsRef.current.flipHorizontal,
              backgroundImage: effectsRef.current.background,
              asPopup: deviceMediaStreamRef.current
                ? {
                    previousCtx: context,
                    x: USER_CAMERA_AS_POPUP.x,
                    y: USER_CAMERA_AS_POPUP.y,
                    width: USER_CAMERA_AS_POPUP.width,
                    height: USER_CAMERA_AS_POPUP.height,
                    borderRadius: USER_CAMERA_AS_POPUP.borderRadius,
                  }
                : undefined,
            }
          );
        }

        if (!deviceMediaStreamRef.current && !effectsRef.current.camera) {
          drawBlackBackground(canvasRef.current);
        }
      }

      drawCanvasTimeoutRef.current = window.setTimeout(drawCanvas, 1000 / FPS);
    }, []);

    const clearCountDownAndReset = () => {
      if (countDownIntervalRef.current) {
        window.clearInterval(countDownIntervalRef.current);
      }

      countDownIntervalRef.current = null;

      setCountDown(COUNT_DOWN);
    };

    const stopStream = useCallback(() => {
      if (drawCanvasTimeoutRef.current) {
        window.clearTimeout(drawCanvasTimeoutRef.current);
      }

      userMediaStreamRef.current?.getTracks().forEach((track) => track.stop());
      userMediaStreamRef.current = null;
      userVideo.current.srcObject = null;
      if (
        mediaRecorderRef.current?.state &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current?.pause();
      }
      mediaRecorderRef.current = null;

      currentUserAudioSourceRef.current?.disconnect();
      currentUserAudioSourceRef.current = null;

      if (options.presentation) {
        stopScreenShare();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const stopScreenShare = useCallback(() => {
      currentDeviceAudioSourceRef.current?.disconnect();
      currentDeviceAudioSourceRef.current = null;
      deviceMediaStreamRef.current
        ?.getTracks()
        .forEach((track) => track.stop());
      deviceVideo.current.srcObject = null;
      deviceMediaStreamRef.current = null;

      setOptions((prevState) => {
        const result = { ...prevState, presentation: false };

        onScreenShareEnds?.(result);

        return result;
      });

      setHideInfinityMirrorWarning(false);
    }, [onScreenShareEnds]);

    const startStream = useCallback(
      async (facingMode?: FacingMode, selectedDevices?: SelectedDevices) => {
        if (drawCanvasTimeoutRef.current) {
          window.clearTimeout(drawCanvasTimeoutRef.current);
        }

        userMediaStreamRef.current
          ?.getVideoTracks()
          .forEach((track) => track.stop());
        userVideo.current.pause();
        userVideo.current.srcObject = null;

        const mobileHeight = { min: 320, max: 480 };
        const mobileWidth = { min: 640, max: 720 };
        const desktopHeight = { ideal: 1080 };
        const desktopWidth = { ideal: 1920 };

        // Testing mobile width / height values
        // A real mobile device automatically invert aspect ratio and uses the width propety as height and viceversa
        // but in order to test in a desktop machine with mobile view we can use these values.
        // const mobileHeight = { min: 640, max: 720 }
        // const mobileWidth = { min: 320, max: 480 }

        const stream = await navigator.mediaDevices.getUserMedia({
          audio: isVideoEnabled
            ? {
                echoCancellation: true,
                noiseSuppression: true,
                deviceId: selectedDevices?.audioDevice?.deviceId,
              }
            : false,
          video: {
            frameRate: FPS,
            facingMode,
            deviceId: !isMobile
              ? selectedDevices?.videoDevice?.deviceId
              : undefined,
            aspectRatio: 16 / 9,
            noiseSuppression: true,
            width: isMobile ? mobileWidth : desktopWidth,
            height: isMobile ? mobileHeight : desktopHeight,
          },
        });

        if (facingMode === "environment") {
          effectsRef.current.flipHorizontal = false;
        } else if (facingMode === "user") {
          effectsRef.current.flipHorizontal = FLIP_USER_CAMERA_HORIZONTAL;
        }

        userMediaStreamRef.current = stream;
        userVideo.current.srcObject = stream;
        userVideo.current.load();

        await waitForEvent(userVideo.current, "loadedmetadata");

        userVideo.current.width = userVideo.current.videoWidth;
        userVideo.current.height = userVideo.current.videoHeight;

        if (!mountedRef.current) {
          return;
        }

        setVideoDim({
          width: userVideo.current.videoWidth,
          height: userVideo.current.videoHeight,
        });

        void userVideo.current.play();

        await drawCanvas();
      },
      [drawCanvas, isMobile, isVideoEnabled]
    );

    const startScreenShare = useCallback(async () => {
      try {
        if (!navigator.mediaDevices.getDisplayMedia) {
          alert("Your device does not support screen sharing");

          return false;
        }

        const stream = await navigator.mediaDevices.getDisplayMedia({
          audio: {
            noiseSuppression: true,
            echoCancellation: true,
          },
          video: {
            frameRate: FPS,
            // Screen share on mobile is not allowed so we can confidently hardcode
            // these values here
            aspectRatio: 16 / 9,
            width: { ideal: 1920 },
            height: { ideal: 1080 },
          },
        });

        deviceVideo.current.srcObject = stream;
        deviceVideo.current.load();

        await waitForEvent(deviceVideo.current, "loadedmetadata");

        deviceVideo.current.width = deviceVideo.current.videoWidth;
        deviceVideo.current.height = deviceVideo.current.videoHeight;

        void deviceVideo.current.play();

        deviceMediaStreamRef.current = stream;

        // If display surface shared is different than a browser tab then we are sharing an entire monitor or window
        // TODO: Casting this to any to avoid Admin build issue that can't find displaySurface property
        // This is not a problem for now since we are not using this camera version there but we probably
        // need to upgrade NextJs versions to fix this
        isSharingScreenOrBrowserWindow.current =
          (stream.getVideoTracks()[0]?.getSettings() as unknown as any)
            .displaySurface !== "browser";

        // Not all the browsers and operaing systems support device audio capture
        // so we need to validate properly before doing the merge.
        // https://caniuse.com/mdn-api_mediadevices_getdisplaymedia_audio_capture_support
        if (stream.getAudioTracks().length) {
          const deviceSource = audioContext.createMediaStreamSource(
            deviceMediaStreamRef.current
          );
          const deviceGain = audioContext.createGain();
          deviceGain.gain.value = 0.8;
          deviceSource
            .connect(deviceGain)
            .connect(mediaStreamAudioDestinationNode);

          currentDeviceAudioSourceRef.current?.disconnect();
          currentDeviceAudioSourceRef.current = deviceSource;
        }

        stream.getVideoTracks()[0].onended = function () {
          stopScreenShare();

          typewriterTrack("recorderOptionClicked", {
            appSource: trackingProps.appSource as AppSource,
            responseType: isVideoEnabled
              ? ResponseType.Video
              : ResponseType.Image,
            buttonType: ButtonType.RecordScreenStopped,
          });
        };

        return true;
      } catch (error) {
        const err = error as Error;
        if (err.name === "NotAllowedError") {
          console.error(
            "You need to give permissions to the screen sharing to continue!"
          );
        } else {
          // NotAllowedError is not necessarily an unexpected error. It happens everytime
          // a user clicks on the Cancel button or don't allow screen share in Safari for example
          // We are tracking just unexpected errors
          typewriterTrack("webcamScreenShareFailed", {
            companyExternalId: trackingProps.companyExternalId,
            profileExternalId: trackingProps.profileExternalId,
            errorMessage: err.message,
            errorName: err.name,
          });

          onError?.(err);

          alert(
            "An unexpected error has occurred. Please contact customer support!"
          );
        }

        console.error(error);

        return false;
      }
    }, [
      audioContext,
      isVideoEnabled,
      mediaStreamAudioDestinationNode,
      onError,
      stopScreenShare,
      trackingProps.appSource,
      trackingProps.companyExternalId,
      trackingProps.profileExternalId,
    ]);

    const getDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();

      setAvailableDevices(devices);

      return devices;
    };

    useEffect(() => {
      const onDeviceChange = async () => {
        const devices = await getDevices();

        // We need to filter by groupId too because sometimes we can have more than one "default" device ID
        // in different groups.
        // This filter is done in order to remove a current selected device that is not available anymore.
        // E.g: If we are using the headphones microphone and we turn the device off, etc.
        const storageSelectedDevices = localStorage.getItem("selectedDevices");
        let storageSelectedDevicesJson: SelectedDevices | undefined;

        if (storageSelectedDevices) {
          try {
            storageSelectedDevicesJson = JSON.parse(storageSelectedDevices);
          } catch {
            console.warn("no settings saved");
          }
        }

        let selectedAudioDevice = devices.find(
          (device) =>
            device.deviceId ===
              storageSelectedDevicesJson?.audioDevice?.deviceId &&
            device.groupId === storageSelectedDevicesJson?.audioDevice?.groupId
        );

        if (!selectedAudioDevice) {
          selectedAudioDevice = devices.find(
            (device) =>
              device.deviceId === selectedDevices.audioDevice?.deviceId &&
              device.groupId === selectedDevices.audioDevice?.groupId
          );
        }

        let selectedVideoDevice = devices.find(
          (device) =>
            device.deviceId ===
              storageSelectedDevicesJson?.videoDevice?.deviceId &&
            device.groupId === storageSelectedDevicesJson?.videoDevice?.groupId
        );

        if (!selectedVideoDevice) {
          selectedVideoDevice = devices.find(
            (device) =>
              device.deviceId ===
                storageSelectedDevicesJson?.videoDevice?.deviceId &&
              device.groupId ===
                storageSelectedDevicesJson?.videoDevice?.groupId
          );
        }

        // If no audio device try selecting the default one. If no default, select the first available
        if (!selectedAudioDevice) {
          selectedAudioDevice = devices.find(
            (device) => device.deviceId === "default"
          );
          if (!selectedAudioDevice) {
            selectedAudioDevice = devices.filter(
              (device) => device.kind === "audioinput"
            )?.[0];
          }
        }

        // If no video device try selecting the default one. If no default, select the first available
        if (!selectedVideoDevice) {
          selectedVideoDevice = devices.find(
            (device) => device.deviceId === "default"
          );
          if (!selectedVideoDevice) {
            selectedVideoDevice = devices.filter(
              (device) => device.kind === "videoinput"
            )?.[0];
          }
        }

        if (!mountedRef.current) {
          return;
        }

        setSelectedDevices({
          audioDevice: selectedAudioDevice,
          videoDevice: selectedVideoDevice,
        });

        const currentFacingMode = userMediaStreamRef.current
          ?.getVideoTracks()[0]
          .getConstraints().facingMode as FacingMode;

        void startStream(currentFacingMode, {
          audioDevice: selectedAudioDevice,
          videoDevice: selectedVideoDevice,
        });
      };

      if (!navigator.mediaDevices || !navigator.mediaDevices.addEventListener) {
        return onError?.(new Error("Your browser does not support recording!"));
      }

      navigator.mediaDevices.addEventListener("devicechange", onDeviceChange);

      return () => {
        navigator.mediaDevices.removeEventListener(
          "devicechange",
          onDeviceChange
        );
      };
    }, [onError, selectedDevices, startStream]);

    const OrientationFunction = useCallback(async () => {
      setSelectedDevices({
        audioDevice: selectedDevices.audioDevice,
        videoDevice: undefined,
      });

      const currentFacingMode = userMediaStreamRef.current
        ?.getVideoTracks()[0]
        .getConstraints().facingMode as FacingMode;

      void startStream(currentFacingMode, selectedDevices);
      stopStream();
    }, [selectedDevices, startStream, stopStream]);

    useEffect(() => {
      window.addEventListener("orientationchange", OrientationFunction);

      return () =>
        window.removeEventListener("orientationchange", OrientationFunction);
    }, [OrientationFunction]);

    useEffect(() => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        return onError?.(new Error("Your browser does not support recording!"));
      }

      const init = async () => {
        try {
          setIsLoading(true);

          // Lock screen in portrait mode for mobile devices
          // Still not fully supported
          // See https://developer.mozilla.org/en-US/docs/Web/API/ScreenOrientation/lock for more details
          try {
            if (isMobile) {
              await (screen.orientation as any)?.lock("portrait");
            }
          } catch (error) {
            console.error(error);
          }

          // Default to back camera only on mobile for photo camera
          if (isMobile) {
            await startStream(isPhotoEnabled ? "environment" : "user");
          }

          if (!mountedRef.current) {
            // We need to stop stream here as well because sometimes the camera can be opened and closed
            // quickly whilte the promises inside startStream are still resolving so the stopStream present
            // in the return of this hook is executed before the stream starts and thus the camera continues
            // active forever
            stopStream();

            return;
          }

          const devices = await getDevices();

          const storageSelectedDevices =
            localStorage.getItem("selectedDevices");
          let storageSelectedDevicesJson: SelectedDevices | undefined;

          if (storageSelectedDevices) {
            try {
              storageSelectedDevicesJson = JSON.parse(storageSelectedDevices);
            } catch {
              console.warn("no settings saved");
            }
          }

          // Set initial state of config modal inputs
          let audioDevice: MediaDeviceInfo | undefined;
          if (isVideoEnabled) {
            const storageSelectedDevicesAudioId =
              storageSelectedDevicesJson?.audioDevice?.deviceId;

            audioDevice = devices.find(
              (device) => device.deviceId === storageSelectedDevicesAudioId
            );

            if (!audioDevice) {
              audioDevice = devices.find(
                (device) =>
                  device.deviceId === "default" && device.kind === "audioinput"
              );

              if (!audioDevice) {
                audioDevice = devices.find(
                  (device) => device.kind === "audioinput"
                );
              }
            }
          }

          const storageSelectedDevicesAudioId =
            storageSelectedDevicesJson?.videoDevice?.deviceId;
          let videoDevice = devices.find(
            (device) => device.deviceId === storageSelectedDevicesAudioId
          );

          if (!videoDevice) {
            videoDevice = devices.find(
              (device) =>
                device.deviceId === "default" && device.kind === "videoinput"
            );
            if (!videoDevice) {
              videoDevice = devices.find(
                (device) => device.kind === "videoinput"
              );
            }
          }
          if (!isMobile) {
            await startStream(undefined, { audioDevice, videoDevice });
          }

          setSelectedDevices({ audioDevice, videoDevice });

          setIsLoading(false);

          onLoaded?.();
        } catch (error) {
          setIsLoading(false);

          const err = error as Error;

          typewriterTrack("webcamInitiationFailed", {
            companyExternalId: trackingProps.companyExternalId,
            profileExternalId: trackingProps.profileExternalId,
            errorMessage: err.message,
            errorName: err.name,
          });

          onError?.(err);

          console.error(error);
        }
      };

      void init();

      return () => {
        // Still not fully supported
        // See https://developer.mozilla.org/en-US/docs/Web/API/ScreenOrientation/lock for more details
        if (screen.orientation?.unlock) {
          screen.orientation?.unlock();
        }

        clearCountDownAndReset();

        clearTimer();

        stopStream();

        mountedRef.current = false;
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const webcamBackground = localStorage.getItem("webcamBackground");

      if (webcamBackground) {
        try {
          const webcamBackgroundJson = JSON.parse(webcamBackground);

          void onEffectChange(webcamBackgroundJson);
        } catch {
          console.warn("no backround saved");
        }
      }
    }, []);

    const toggleCamera = async () => {
      const currentFacingMode = userMediaStreamRef.current
        ?.getVideoTracks()[0]
        .getConstraints().facingMode;

      await startStream(currentFacingMode === "user" ? "environment" : "user");
    };

    const stopRecording = () => {
      if (mediaRecorderRef.current?.state !== "inactive") {
        mediaRecorderRef.current?.stop();
      }

      if (deviceMediaStreamRef.current?.active) {
        stopScreenShare();
      }

      clearTimer();

      setHasShootStarted(undefined);
    };

    const generateVideo = useCallback(
      (data: Blob) => {
        clearCountDownAndReset();

        const blob = new Blob([data], {
          type: MediaRecorder.isTypeSupported(EXPORTED_VIDEO_FORMAT)
            ? EXPORTED_VIDEO_FORMAT
            : FALLBACK_EXPORTED_VIDEO_FORMAT,
        });

        const currentFacingMode = userMediaStreamRef.current
          ?.getVideoTracks()[0]
          .getConstraints().facingMode;

        onRecordEnds(
          new File(
            [blob],
            `user-video-upload-${new Date().getMilliseconds()}`,
            {
              type: MediaRecorder.isTypeSupported(EXPORTED_VIDEO_FORMAT)
                ? EXPORTED_VIDEO_FORMAT
                : FALLBACK_EXPORTED_VIDEO_FORMAT,
            }
          ),
          currentFacingMode === "user" ? "frontCamera" : "rearCamera"
        );
      },
      [onRecordEnds]
    );

    const startRecording = useCallback(() => {
      if (!canvasRef.current || !userMediaStreamRef.current) {
        return;
      }

      const stream = canvasRef.current.captureStream();

      if (userMediaStreamRef.current.getAudioTracks().length) {
        const userSource = audioContext.createMediaStreamSource(
          userMediaStreamRef.current
        );

        const userGain = audioContext.createGain();
        userGain.gain.value = 1;
        userSource.connect(userGain).connect(mediaStreamAudioDestinationNode);

        currentUserAudioSourceRef.current = userSource;
      }

      const audioTracks =
        mediaStreamAudioDestinationNode.stream.getAudioTracks();
      audioTracks.forEach((track) => stream.addTrack(track));

      try {
        if (MediaRecorder.isTypeSupported(EXPORTED_VIDEO_FORMAT)) {
          mediaRecorderRef.current = new MediaRecorder(stream, {
            mimeType: EXPORTED_VIDEO_FORMAT,
          });
        } else {
          mediaRecorderRef.current = new MediaRecorder(stream, {
            mimeType: FALLBACK_EXPORTED_VIDEO_FORMAT,
          });
        }
      } catch (error) {
        console.error("Error trying to instantiate MediaRecorder: ", error);

        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: FALLBACK_EXPORTED_VIDEO_FORMAT,
        });
      }

      mediaRecorderRef.current.addEventListener("dataavailable", ({ data }) => {
        if (data.size > 0) {
          generateVideo(data);
        } else {
          console.error("No data available");
        }
      });

      if (mediaRecorderRef.current.state !== "recording") {
        mediaRecorderRef.current.start();

        onRecordStarts?.();
      }
    }, [
      audioContext,
      generateVideo,
      mediaStreamAudioDestinationNode,
      onRecordStarts,
    ]);

    const startTimer = () => {
      let time = 0;
      timerIntervalRef.current = window.setInterval(() => {
        setSeconds((prevState) => prevState + 1);
        onTimeUpdate?.(++time);
      }, 1000);
    };

    const clearTimer = () => {
      if (timerIntervalRef.current) {
        window.clearInterval(timerIntervalRef.current);
      }

      setSeconds(0);
      onTimeUpdate?.(0);

      timerIntervalRef.current = null;
    };

    const takePhoto = () => {
      if (deviceMediaStreamRef.current?.active) {
        stopScreenShare();
      }

      canvasRef.current?.toBlob(
        (blob) => {
          if (blob) {
            const currentFacingMode = userMediaStreamRef.current
              ?.getVideoTracks()[0]
              .getConstraints().facingMode;

            onRecordEnds(
              new File(
                [blob],
                `user-photo-upload-${new Date().getMilliseconds()}`,
                { type: EXPORTED_IMAGE_FORMAT }
              ),
              currentFacingMode === "user" ? "frontCamera" : "rearCamera"
            );
          }

          setHasShootStarted(undefined);
        },
        EXPORTED_IMAGE_FORMAT,
        1
      );
    };

    const startCountDown = (type: ShootType) => {
      setHasShootStarted(type);

      countDownIntervalRef.current = window.setInterval(() => {
        setCountDown((prevState) => {
          if (prevState === 1 && countDownIntervalRef.current) {
            clearCountDownAndReset();

            if (type === ShootType.VIDEO) {
              startRecording();

              startTimer();
            } else {
              takePhoto();
            }
          }

          return prevState - 1;
        });
      }, 1000);

      onCountDownStarts?.();
    };

    const stopCountDown = () => {
      clearCountDownAndReset();
      setHasShootStarted(undefined);
    };

    const onShootClick = (type: ShootType) => {
      if (!hasShootStarted) {
        return startCountDown(type);
      }

      if (countDownIntervalRef.current) {
        return stopCountDown();
      }

      if (type === ShootType.VIDEO) {
        stopRecording();
      }
    };

    const onUploadFileClick = useCallback(() => {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.type = "file";
      input.accept = "";
      if (isPhotoEnabled) {
        input.accept += ",image/*";
      }
      if (isVideoEnabled) {
        input.accept += ",video/*";
      }
      input.onchange = function (event) {
        const file = (event.target as HTMLInputElement)?.files?.[0];

        if (!file) {
          input.remove();

          return;
        }

        if (
          (isVideoEnabled && !file.type.startsWith("video/")) ||
          (isPhotoEnabled && !file.type.startsWith("image/"))
        ) {
          input.remove();

          return onError?.(new Error("File is not supported"));
        }

        onFileUploaded(file);
        input.remove();
      };

      input.click();
    }, [isPhotoEnabled, isVideoEnabled, onError, onFileUploaded]);

    const onOptionClick = useCallback(
      async (type: OptionType | MenuOptionType) => {
        let result = false;

        switch (type) {
          case MenuOptionType.PRESENTATION:
            if (!options.presentation) {
              result = await startScreenShare();

              typewriterTrack("recorderOptionClicked", {
                appSource: trackingProps.appSource as AppSource,
                responseType: isVideoEnabled
                  ? ResponseType.Video
                  : ResponseType.Image,
                buttonType: ButtonType.RecordScreenStarted,
              });
            } else {
              stopScreenShare();
              result = false;

              typewriterTrack("recorderOptionClicked", {
                appSource: trackingProps.appSource as AppSource,
                responseType: isVideoEnabled
                  ? ResponseType.Video
                  : ResponseType.Image,
                buttonType: ButtonType.RecordScreenStopped,
              });
            }

            setOptions((prevState) => ({ ...prevState, presentation: result }));

            return result;
          case OptionType.BACKGROUNDS:
            setIsVisualEffectsModalOpen((prevState) => {
              result = !prevState;

              return result;
            });

            typewriterTrack("recorderOptionClicked", {
              appSource: trackingProps.appSource as AppSource,
              responseType: isVideoEnabled
                ? ResponseType.Video
                : ResponseType.Image,
              buttonType: ButtonType.Backgrounds,
            });

            return result;
          case OptionType.SETTINGS:
            setIsSettingsModalOpen((prevState) => {
              result = !prevState;

              return result;
            });

            typewriterTrack("recorderOptionClicked", {
              appSource: trackingProps.appSource as AppSource,
              responseType: isVideoEnabled
                ? ResponseType.Video
                : ResponseType.Image,
              buttonType: ButtonType.Settings,
            });

            return result;
          case OptionType.UPLOAD_FILE:
            onUploadFileClick();

            typewriterTrack("recorderOptionClicked", {
              appSource: trackingProps.appSource as AppSource,
              responseType: isVideoEnabled
                ? ResponseType.Video
                : ResponseType.Image,
              buttonType: ButtonType.Upload,
            });

            return true;
          case OptionType.PROMPTER:
            setOptions((prevState) => {
              result = !prevState.prompter;

              return { ...prevState, prompter: result };
            });

            typewriterTrack("recorderOptionClicked", {
              appSource: trackingProps.appSource as AppSource,
              responseType: isVideoEnabled
                ? ResponseType.Video
                : ResponseType.Image,
              buttonType: result
                ? ButtonType.TeleprompterOn
                : ButtonType.TeleprompterOff,
            });

            return result;
          case OptionType.MIC:
            setOptions((prevState) => {
              result = !prevState.mic;
              if (userMediaStreamRef.current) {
                userMediaStreamRef.current.getAudioTracks()[0].enabled = result;
              }

              return { ...prevState, mic: result };
            });

            typewriterTrack("recorderOptionClicked", {
              appSource: trackingProps.appSource as AppSource,
              responseType: isVideoEnabled
                ? ResponseType.Video
                : ResponseType.Image,
              buttonType: result ? ButtonType.MicOn : ButtonType.MicOff,
            });

            return result;
          case OptionType.CAMERA:
            result = !options.camera;

            effectsRef.current.camera = result;
            if (result) {
              setIsLoading(true);

              const currentFacingMode = userMediaStreamRef.current
                ?.getVideoTracks()[0]
                .getConstraints().facingMode as FacingMode;

              await startStream(currentFacingMode, selectedDevices);

              // Update audio context with new one
              if (
                currentUserAudioSourceRef.current &&
                userMediaStreamRef.current
              ) {
                const newUserAudioSource = audioContext.createMediaStreamSource(
                  userMediaStreamRef.current
                );

                newUserAudioSource.connect(mediaStreamAudioDestinationNode);
                currentUserAudioSourceRef.current.disconnect();

                currentUserAudioSourceRef.current = newUserAudioSource;
              }

              setIsLoading(false);
            } else if (userMediaStreamRef.current) {
              userMediaStreamRef.current
                .getVideoTracks()
                .forEach((track) => track.stop());
            }

            setOptions((prevState) => ({ ...prevState, camera: result }));

            typewriterTrack("recorderOptionClicked", {
              appSource: trackingProps.appSource as AppSource,
              responseType: isVideoEnabled
                ? ResponseType.Video
                : ResponseType.Image,
              buttonType: result ? ButtonType.CameraOn : ButtonType.CameraOff,
            });

            return result;
          default:
            throw new Error(`Unknown option type: ${type}`);
        }
      },
      [
        audioContext,
        isVideoEnabled,
        mediaStreamAudioDestinationNode,
        onUploadFileClick,
        options.camera,
        options.presentation,
        selectedDevices,
        startScreenShare,
        startStream,
        stopScreenShare,
        trackingProps.appSource,
      ]
    );

    useImperativeHandle(ref, () => ({
      onOptionClick(type: OptionType | MenuOptionType) {
        return onOptionClick(type);
      },
    }));

    const onSettingsChange = async (selectedDevices: SelectedDevices) => {
      localStorage.setItem("selectedDevices", JSON.stringify(selectedDevices));
      setSelectedDevices(selectedDevices);

      const currentFacingMode = userMediaStreamRef.current
        ?.getVideoTracks()[0]
        .getConstraints().facingMode as FacingMode;

      void startStream(currentFacingMode, selectedDevices);
    };

    const onEffectChange = async (effect: Effect<EffectType>) => {
      localStorage.setItem("webcamBackground", JSON.stringify(effect));

      if (!modelRef.current) {
        await tf.setBackend("wasm");
        await tf.ready();
        modelRef.current = await createSegmenter(
          SupportedModels.MediaPipeSelfieSegmentation,
          {
            runtime: "mediapipe",
            modelType: "general",
            solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@${selfieSegmentation.VERSION}`,
          }
        );
      }

      switch (effect.type) {
        case EffectType.IMAGE: {
          effectsRef.current.blur = 0;

          const img = new Image();
          img.src = effect.value as string;
          img.crossOrigin = "anonymous";
          img.onload = function () {
            effectsRef.current.background = img;
          };
          break;
        }
        case EffectType.BLUR:
          effectsRef.current.background = false;
          effectsRef.current.blur = effect.value as number;
          break;
        case EffectType.NONE:
          effectsRef.current.background = false;
          effectsRef.current.blur = 0;
          break;
        default:
          throw new Error(`Unknown effect type: ${effect.type}`);
      }

      typewriterTrack("cameraBackgroundToggled", {
        appSource: trackingProps.appSource as AppSource,
        backgroundType: effect.type as unknown as BackgroundType,
      });
    };

    return (
      <WebcamView
        canvasRef={canvasRef}
        videoDim={videoDim}
        isSettingsModalOpen={isSettingsModalOpen}
        isVisualEffectsModalOpen={isVisualEffectsModalOpen}
        isPhotoEnabled={isPhotoEnabled}
        isVideoEnabled={isVideoEnabled}
        isLoading={isLoading}
        hasShootStarted={hasShootStarted}
        showInfinityMirrorWarning={
          options.presentation &&
          isSharingScreenOrBrowserWindow.current &&
          !hideInfinityMirrorWarning
        }
        showCountDown={!!countDownIntervalRef.current && countDown > 0}
        availableDevices={availableDevices}
        selectedDevices={selectedDevices}
        countDown={countDown}
        options={options}
        seconds={seconds}
        localizations={localizations}
        onHideInfinityMirrorWarning={() => setHideInfinityMirrorWarning(true)}
        onShootClick={onShootClick}
        onToggleCameraClick={toggleCamera}
        onOptionClick={onOptionClick}
        onSettingsChange={onSettingsChange}
        onEffectChange={onEffectChange}
      />
    );
  }
);

Webcam.displayName = "Webcam";
