import React, {
  useState,
  isValidElement,
  cloneElement,
  Children,
  MouseEventHandler,
  MouseEvent,
} from "react";
import { MenuProps as MenuMuiProps } from "@mui/material/Menu";
import { PopoverProps } from "@mui/material/Popover";
import { Menu as StyledMenu, MenuItem as StyledMenuItem } from "./styles";

export type MenuItemProps = {
  name: string;
  value?: string;
  color?: "utility" | "primary" | "negative" | "positive";
};

export type MenuProps<T extends MenuItemProps = { name: string }> = Omit<
  MenuMuiProps,
  "onChange" | "open" | "value"
> & {
  options: T[];
  defaultIndex?: number;
  keepSelection?: boolean;
  onChange: (item: T) => void;
};

export function Menu<T extends MenuItemProps = { name: string }>({
  children,
  options,
  defaultIndex,
  keepSelection,
  onClick,
  onChange,
  onClose,
  ...props
}: MenuProps<T>) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(
    defaultIndex ?? null
  );

  const onClickMenu: MouseEventHandler<HTMLDivElement> = (event) => {
    onClick?.(event);
    setAnchorEl(event.currentTarget);
  };

  const onClickMenuItem = (event: MouseEvent<HTMLElement>, index: number) => {
    onChange?.(options[index]);

    setAnchorEl(null);

    if (keepSelection) {
      setSelectedIndex(index);
    }
  };

  const handleClose: PopoverProps["onClose"] = (event, reason) => {
    onClose?.(event, reason);

    setAnchorEl(null);
  };

  const child = Children.only(children);

  if (!isValidElement(child)) {
    return null;
  }

  return (
    <>
      {cloneElement<{ onClick: MouseEventHandler<HTMLDivElement> }>(
        child as any,
        { onClick: onClickMenu }
      )}
      <StyledMenu
        {...props}
        MenuListProps={{ disablePadding: true }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <StyledMenuItem
            key={option.name}
            $color={option.color}
            selected={index === selectedIndex}
            onClick={(event) => onClickMenuItem(event, index)}
          >
            {option.name}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
}
