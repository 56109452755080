import React, { ReactNode } from "react";
import { SyncIcon } from "../../../../../../icons";
import { BaseCard, CardProps } from "../BaseCard";
import { AlignedContent } from "../../styles";
import { Title } from "../../components/Title";
import { CtaButton } from "../../components/CtaButton";
import { Description } from "../../components/Description";

export type ErrorCardProps = CardProps & {
  title?: string;
  description?: string;
  ctaTitle?: ReactNode;
  onClickCta?: () => void;
};

export function ErrorCard({
  isActive,
  title = "Sorry",
  description = "Something didn't load correctly.",
  ctaTitle = "Reload",
  onClickCta,
  onCardActive,
}: ErrorCardProps) {
  return (
    <BaseCard
      themeColor="negative"
      isActive={isActive}
      onCardActive={onCardActive}
    >
      <AlignedContent $align="center">
        <Title text={title} color="red" style={{ marginBottom: "-20px" }} />
        <Description text={description} />
        <CtaButton
          text={ctaTitle}
          color="utility"
          endIcon={<SyncIcon />}
          style={{ width: "auto", margin: "auto" }}
          onClick={onClickCta}
        />
      </AlignedContent>
    </BaseCard>
  );
}
