import { css, styled } from "@mui/material/styles";
import MenuItemMui from "@mui/material/MenuItem";
import SelectMui from "@mui/material/Select";
import { ButtonLabel } from "../typographies";
import { Caption } from "../BSPCaption";
import { Input as InputGlobal } from "../InputAdornment/styles";

export const Select = styled(SelectMui)`
  width: 100%;
  &.MuiInputBase-root {
    ${InputGlobal}
    ${ButtonLabel}
    margin-top: 0;

    .MuiSelect-select {
      padding: 0 10px;
    }
    .MuiSelect-select:focus {
      border-radius: ${({ theme }) => theme.kinetic.borderRadius.none};
      background-color: transparent;
    }
  }
  .MuiFormHelperText-root {
    ${Caption}
    text-transform: uppercase;
    &.Mui-error {
      color: ${(props) =>
        props.theme.kinetic.palette.rgb.negative800} !important;
    }
  }
  &.placeholder--selected {
    .MuiSelect-root {
      color: ${({ theme }) => theme.kinetic.palette.rgb.gray400};
    }
  }
`;

export const MenuItem = styled(MenuItemMui)<{
  disabled?: boolean;
}>`
  display: flex !important;
  font-size: 1rem;
  line-height: 1.5;
  padding: 6px 16px;
  justify-content: flex-start;
  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.kinetic.v2.palette.rgb.grayTextLighter};
    `}
`;
