export function secondsToTime(seconds: number) {
  const h = Math.floor(seconds / 3600),
    m = Math.floor((seconds % 3600) / 60),
    s = Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0");

  if (h > 0) {
    return `${h}:${m}:${s}`;
  }

  return `${m}:${s}`;
}
