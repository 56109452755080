import React from "react";
import { createRoot, Root } from "react-dom/client";
import videojs, { VideoJsPlayer } from "video.js";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../../styles/theme";

export type ComponentProps = { vjscomponent: videojs.Component };

/**
 * Register a React component in the Video.js UI
 * @param Component React component to register
 * @param vjsName Name of video.js default component. See default components here: https://docs.videojs.com/tutorial-components.html
 * @param registeredName Name of new component to register in Video.js, defaults to `vjsName`
 * @returns {React.ComponentType}
 */
export const registerComponent = (
  Component: React.ComponentType<ComponentProps>,
  vjsName: string,
  registeredName?: string
) => {
  const vjscomponent = videojs.getComponent(vjsName);

  class RegisteredComponent extends vjscomponent {
    private root: Root;

    constructor(player: VideoJsPlayer, options?: any) {
      super(player, options);

      this.root = createRoot(this.el());

      // Mount the React component when the player is ready
      this.mount = this.mount.bind(this);
      player.ready(this.mount);

      // Unmount the React component when the player is disposed of
      this.on("dispose", () => this.root.unmount());
    }

    mount() {
      this.root.render(
        <ThemeProvider theme={theme}>
          <Component vjscomponent={this} />
        </ThemeProvider>
      );
    }
  }

  vjscomponent.registerComponent(
    registeredName || vjsName,
    RegisteredComponent
  );

  return RegisteredComponent;
};
