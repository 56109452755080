import React, { ReactElement } from "react";
import { DataGridProps, GridCellParams, MuiEvent } from "@mui/x-data-grid";
import { DataGrid as StyledDataGrid } from "./styled";
import { Header, PageSize } from "../headers/header";

export type Props = DataGridProps & {
  pageSize?: number;
  pageSizeOptions?: PageSize[];
  headerLeftComponent?: ReactElement;
  headerRightComponent?: ReactElement;
};

export const DEFAULT_PAGE_SIZE_OPTIONS = [
  { label: "5 rows", value: 5 },
  { label: "10 rows", value: 10 },
  { label: "20 rows", value: 20 },
  { label: "50 rows", value: 50 },
  { label: "100 rows", value: 100 },
];

export function DataGrid({
  disableColumnFilter = true,
  showColumnVerticalBorder = true,
  showCellVerticalBorder = true,
  autoHeight = false,
  hideFooterSelectedRowCount = true,
  hideFooterPagination = true,
  rows,
  columns,
  pageSize = 10,
  pageSizeOptions,
  headerLeftComponent,
  headerRightComponent,
  ...props
}: Props) {
  return (
    <StyledDataGrid
      paginationModel={{
        pageSize,
        page: 0,
      }}
      components={{
        Toolbar: () => (
          <Header
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            headerLeftComponent={headerLeftComponent}
            headerRightComponent={headerRightComponent}
          />
        ),
      }}
      disableColumnFilter={disableColumnFilter}
      showColumnVerticalBorder={showColumnVerticalBorder}
      showCellVerticalBorder={showCellVerticalBorder}
      autoHeight={autoHeight}
      hideFooterPagination={hideFooterPagination}
      hideFooterSelectedRowCount={hideFooterSelectedRowCount}
      disableRowSelectionOnClick
      onCellClick={(
        _params: GridCellParams,
        event: MuiEvent<React.MouseEvent>
      ) => {
        const range = document.createRange();
        range.selectNode(event.target as Node);
        window.getSelection()?.removeAllRanges();
        window.getSelection()?.addRange(range);
      }}
      rows={rows}
      columns={columns}
      {...props}
    />
  );
}
