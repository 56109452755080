import React, { HTMLAttributes, ReactNode } from "react";
import { Description as StyledDescription, InnerContainer } from "./styles";
import Linkify from "linkify-react";

export type Props = Omit<HTMLAttributes<HTMLParagraphElement>, "children"> & {
  text: ReactNode;
  isVerticalCard?: string;
};

export function Description({ text, isVerticalCard, ...props }: Props) {
  return (
    <InnerContainer $isVerticalCard={!!isVerticalCard}>
      <StyledDescription {...props}>
        <Linkify options={{ target: "_blank", rel: "noreferrer" }}>
          {text}
        </Linkify>
      </StyledDescription>
    </InnerContainer>
  );
}
