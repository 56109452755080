import { styled } from "@mui/material/styles";
import InputMui from "@mui/material/Input";
import FormHelperTextMui from "@mui/material/FormHelperText";
import { Caption } from "../BSPCaption";
import { Input as InputGlobal } from "../InputAdornment/styles";
import { withTransientProps } from "../../../lib/emotion";

export const Wrapper = styled("div", withTransientProps)<{
  $minHeight?: number;
}>`
  min-height: ${({ $minHeight }) => ($minHeight ? `${$minHeight}px` : "auto")};
`;

export const Input = styled(InputMui)`
  ${InputGlobal};
`;

export const FormHelperText = styled(FormHelperTextMui, withTransientProps)<{
  $valid?: boolean;
}>`
  ${Caption};
  text-transform: uppercase;
  color: ${({ theme, $valid }) =>
    !$valid ? theme.kinetic.v2.palette.rgb.negative : undefined};
`;
