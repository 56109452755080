import React, { ReactElement, ReactNode } from "react";
import { CC, SC, CCTypes } from "@bigspring/core-components";
import { useApollo } from "@config/apollo";
import { ApolloProvider } from "@apollo/client";
import { AuthenticationProvider } from "@shared/Authentication";
import { AnalyticsProvider } from "@shared/Analytics";
import { CollectionsProvider } from "@shared/Collections";
import { DatadogProvider } from "@shared/DatadogProvider";
import { CustomRouterProvider } from "@shared/CustomRouter";
import Layout from "@shared/Layout";
import { AppProps } from "next/app";
import { IntlProvider } from "react-intl";
import { PermissionsProvider } from "@shared/PermissionsProvider";
import { NextPage } from "next";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ErrorBoundary } from "@shared/ErrorBoundary";

export type NextPageWithLayout = NextPage<{
  initialApolloState: Record<string, any>;
}> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export type AppPropsWithLayout = AppProps & {
  emotionCache?: CCTypes.EmotionCache;
  pageProps: AppProps["pageProps"] & {
    initialApolloState: Record<string, any>;
  };
  Component: NextPageWithLayout;
};

const clientSideEmotionCache = SC.createEmotionCache();

const DEFAULT_LOCALE = "en";

export default function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
  router,
}: AppPropsWithLayout) {
  const apolloClient = useApollo(pageProps.initialApolloState);

  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);

  return (
    <ApolloProvider client={apolloClient}>
      <GoogleOAuthProvider
        clientId={process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID as string}
      >
        <SC.CacheProvider value={emotionCache}>
          <SC.ThemeProvider theme={SC.theme}>
            <SC.MaterialThemeProvider
              theme={{ [SC.MATERIAL_THEME_ID]: SC.materialTheme }}
            >
              <CC.Notification
                autoHideDuration={10000}
                position={{ horizontal: "center" }}
              >
                <CustomRouterProvider>
                  <AuthenticationProvider>
                    <CollectionsProvider>
                      <AnalyticsProvider>
                        <PermissionsProvider>
                          <DatadogProvider>
                            <IntlProvider
                              locale={router.locale || DEFAULT_LOCALE}
                              defaultLocale={DEFAULT_LOCALE}
                            >
                              <ErrorBoundary>
                                {getLayout(<Component {...pageProps} />)}
                              </ErrorBoundary>
                            </IntlProvider>
                          </DatadogProvider>
                        </PermissionsProvider>
                      </AnalyticsProvider>
                    </CollectionsProvider>
                  </AuthenticationProvider>
                </CustomRouterProvider>
              </CC.Notification>
            </SC.MaterialThemeProvider>
          </SC.ThemeProvider>
        </SC.CacheProvider>
      </GoogleOAuthProvider>
    </ApolloProvider>
  );
}
