import React from "react";
import { BasePreview, Props as BasePreviewProps } from "../BasePreview";
import { Flash, Img } from "./styles";

export type Props = Omit<BasePreviewProps, "onAcceptClick"> & {
  src: string;
  onAcceptClick: (file: File) => void;
};

export function PhotoPreview({
  wrapperDim,
  videoDim,
  src,
  onCancelClick,
  onAcceptClick: _onAcceptClick,
}: Props) {
  const onAcceptClick = async () => {
    const response = await fetch(src);
    const buffer = await response.arrayBuffer();

    const file = new File(
      [buffer],
      `user-photo-upload-${new Date().getMilliseconds()}`,
      {
        type: "image/jpeg",
      }
    );

    _onAcceptClick(file);
  };

  return (
    <BasePreview
      wrapperDim={wrapperDim}
      videoDim={videoDim}
      onAcceptClick={onAcceptClick}
      onCancelClick={onCancelClick}
    >
      <Flash />
      <Img src={src} />
    </BasePreview>
  );
}
