import { css, styled } from "@mui/material/styles";
import { Grid } from "../../../Grid";
import { withTransientProps } from "../../../../../lib/emotion";

export const Section = styled(Grid)`
  position: relative;
  overflow: hidden;

  &:not(:last-of-type) {
    border-bottom: solid 1px
      ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiLight};
  }
`;

type InnerSectionProps = {
  $stretchLeft?: boolean;
  $stretchRight?: boolean;
};

export const InnerSection = styled(Grid, withTransientProps)<InnerSectionProps>`
  position: relative;
  padding: 20px;

  ${({ $stretchLeft, $stretchRight }) =>
    ($stretchLeft || $stretchRight) &&
    css`
      width: calc(100% + 6px);
      left: ${$stretchLeft ? "-6px" : "0"};
    `}

  > * {
    max-width: 100%;
  }
`;
