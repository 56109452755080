import React from "react";
import { BasePreview, Props as BasePreviewProps } from "../BasePreview";
import { Video } from "./styles";

export type Props = Omit<BasePreviewProps, "onAcceptClick"> & {
  src: string;
  onAcceptClick: (file: File) => void;
};

export const EXPORTED_VIDEO_FORMAT = "video/webm";
export const FALLBACK_EXPORTED_VIDEO_FORMAT = "video/mp4";

function blobToFile(blob: Blob) {
  try {
    if (MediaRecorder.isTypeSupported(EXPORTED_VIDEO_FORMAT)) {
      return new File(
        [blob],
        `user-video-upload-${new Date().getMilliseconds()}`,
        {
          type: EXPORTED_VIDEO_FORMAT,
        }
      );
    }

    return new File(
      [blob],
      `user-video-upload-${new Date().getMilliseconds()}`,
      {
        type: FALLBACK_EXPORTED_VIDEO_FORMAT,
      }
    );
  } catch (error) {
    console.error("Error trying to create File: ", error);

    return new File(
      [blob],
      `user-video-upload-${new Date().getMilliseconds()}`,
      {
        type: FALLBACK_EXPORTED_VIDEO_FORMAT,
      }
    );
  }
}

function clearFileFromMemory(src: string) {
  if (src.startsWith("blob:")) {
    URL.revokeObjectURL(src);
  }
}

export function VideoPreview({
  wrapperDim,
  videoDim,
  src,
  onCancelClick: _onCancelClick,
  onAcceptClick: _onAcceptClick,
}: Props) {
  const onCancelClick = () => {
    clearFileFromMemory(src);

    _onCancelClick();
  };

  const onAcceptClick = async () => {
    const response = await fetch(src);
    const blob = await response.blob();

    const file = blobToFile(blob);

    _onAcceptClick(file);

    clearFileFromMemory(src);
  };

  return (
    <BasePreview
      wrapperDim={wrapperDim}
      videoDim={videoDim}
      onAcceptClick={onAcceptClick}
      onCancelClick={onCancelClick}
    >
      <Video src={src} controls playsInline autoPlay />
    </BasePreview>
  );
}
