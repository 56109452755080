import { styled } from "@mui/material/styles";
import { Grid } from "../../../Grid";
import { Section as BaseSection } from "../Section";

export const Section = styled(BaseSection)`
  min-height: 81px;
`;

export const LeftItem = styled(Grid)`
  margin-right: 20px;
`;

export const RightItem = styled(Grid)`
  margin-left: 20px;
`;
