import React from "react";
import { Button, ButtonProps } from "../../../Button";
import { LinkIcon } from "../../../../../../icons";
import { InnerContainer } from "../InnerContainer";

export type Props = Omit<ButtonProps, "children">;

export function CtaButton({ text, color, icon, ...props }: Props) {
  return (
    <InnerContainer>
      <Button
        {...props}
        {...(text ? { text } : { icon: icon || <LinkIcon /> })}
        color={color || "primary"}
        align="center"
      />
    </InnerContainer>
  );
}
