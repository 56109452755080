import { css, styled } from "@mui/material/styles";
import { BlurOnIcon } from "../../../../../../icons";

export const ImageWrapper = styled("div")<{
  $isMobile?: boolean;
  $isSelected: boolean;
  $backgroundImage?: string;
}>`
  cursor: pointer;
  border: 1px solid lightgray;
  border-radius: 10px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ $isSelected, theme }) =>
    $isSelected
      ? `0 0 3px 1px ${theme.kinetic.v2.palette.rgb.blue}`
      : undefined};
  background-image: ${({ $backgroundImage }) => `url("${$backgroundImage}")`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: auto;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 100px;
      height: 200px;
    `};
`;

export const BlurIcon = styled(BlurOnIcon)`
  fill: lightgrey;
`;

export const MoreBlurIcon = styled(BlurOnIcon)`
  width: 30px;
  height: 30px;
  fill: black;
`;
