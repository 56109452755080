import { Panel } from "./components/Panel";
import { Header } from "./components/Header";
import { Section } from "./components/Section";
import { Heading1, Heading2, Text, Label } from "./components/Typography";

export const ConfigPanel = {
  Panel,
  Header,
  Section,
  Heading1,
  Heading2,
  Text,
  Label,
};
