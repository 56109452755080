import React, { MouseEventHandler } from "react";
import { BaseCard, CardProps } from "../../../BaseCard";
import { CtaButton } from "../../../../components/CtaButton";
import { Description } from "../../../../components/Description";
import { Title } from "../../../../components/Title";
import { AlignedContent } from "../../../../styles";
import { ButtonContainer } from "../../styles";
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogActions,
} from "../../../../../dialog";
import { Grid } from "../../../../../Grid";
import { Button } from "../../../../../Button";

type Props = CardProps & {
  showConfirmBeforeStart: boolean;
  isGradedQuiz: boolean;
  isSurvey: boolean;
  isStarted: boolean;
  goBackStr?: string;
  continueStr?: string;
  surveyStr?: string;
  practiceStr?: string;
  gradedQuizReqsStr?: string;
  startStr?: string;
  resumeStr?: string;
  confirmationStr?: string;
  title?: string | null;
  onStartForm: MouseEventHandler<HTMLButtonElement>;
  onConfirmAccept: VoidFunction;
  onConfirmClose: VoidFunction;
};

// TODO: Migrate this to composite pattern
export function IntroCard({
  showConfirmBeforeStart,
  isActive,
  isGradedQuiz,
  isSurvey,
  isStarted,
  goBackStr,
  continueStr,
  surveyStr = "Survey",
  practiceStr = "Practice",
  gradedQuizReqsStr,
  startStr = "Start",
  title,
  resumeStr = "Resume",
  confirmationStr,
  onStartForm,
  onCardActive,
  onConfirmAccept,
  onConfirmClose,
}: Props) {
  return (
    <BaseCard isActive={isActive} onCardActive={onCardActive}>
      <Dialog open={showConfirmBeforeStart}>
        <DialogHeader onClose={onConfirmClose} />
        <DialogContent dividers={false}>
          <Grid container item md={12}>
            <Grid item md={12}>
              {confirmationStr}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Button
                type="button"
                text={goBackStr}
                align="center"
                color="utility"
                onClick={onConfirmClose}
              />
            </Grid>
            <Grid item md={6}>
              <Button
                type="button"
                text={continueStr}
                align="center"
                onClick={onConfirmAccept}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <AlignedContent $align="center">
        <Title text={title || (isSurvey ? surveyStr : practiceStr)} />
        {!isSurvey && isGradedQuiz && <Description text={gradedQuizReqsStr} />}
        <ButtonContainer>
          <CtaButton
            text={isStarted ? resumeStr : startStr}
            onClick={onStartForm}
          />
        </ButtonContainer>
      </AlignedContent>
    </BaseCard>
  );
}
