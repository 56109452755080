import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as tf from "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-converter";
import * as tfjsWasm from "@tensorflow/tfjs-backend-wasm";
import * as selfieSegmentation from "@mediapipe/selfie_segmentation";
import {
  BodySegmenter,
  createSegmenter,
  SupportedModels,
} from "@tensorflow-models/body-segmentation";

import { PreviewView, PreviewType } from "./components/PreviewView";
import { MenuOptionType, Prompt, ShootType, WebcamView } from "./view";
import { ContentRect } from "react-measure";
import {
  drawBlackBackground,
  drawPlain,
  drawPlainDeviceMedia,
  drawWithBackground,
  drawWithBlur,
  waitForEvent,
} from "./helpers";
import {
  EXPORTED_VIDEO_FORMAT,
  FALLBACK_EXPORTED_VIDEO_FORMAT,
} from "./components/PreviewView/components/VideoPreview";
import { ErrorView } from "./components/ErrorView";
import deviceDetect from "ismobilejs";
import { OptionType } from "./components/OptionsMenu";
import { SelectedDevices } from "./components/OptionsMenu/components/SettingsModal";
import {
  Effect,
  EffectType,
} from "./components/OptionsMenu/components/VisualEffectsModal";
import { Interjection } from "./components/InterjectionView";
import { typewriterTrack } from "../../../lib/events";

tfjsWasm.setWasmPaths(
  `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/`
);

export type { Interjection };

type FacingMode = "user" | "environment";

type Props = {
  timer?: number;
  prompt?: Prompt;
  isPhotoEnabled?: boolean;
  isVideoEnabled?: boolean;
  interjections?: Interjection[];
  // TODO: We have to decide whether we want to handle events and errors
  // in core components or raise events to the parent apps. I think
  // core components should be agnostic of any logging system.
  // Leaving as is since we already have a Segment protocol created for CC
  trackingProps: {
    companyExternalId: string;
    profileExternalId: string;
  };
  onAccept: (file: File) => void;
  onError?: (error: Error) => void;
};

const COUNT_DOWN = 3;
const EDGE_BLUR_AMOUNT = 10;
const FLIP_USER_CAMERA_HORIZONTAL = true;
const USER_CAMERA_AS_POPUP = {
  x: 40,
  y: 40,
  width: 240,
  height: 135,
  borderRadius: 10,
};

export function WebcamOld({
  timer,
  prompt,
  interjections,
  isPhotoEnabled = true,
  isVideoEnabled = true,
  trackingProps,
  onAccept,
  onError,
}: Props) {
  const isMobile = deviceDetect(window.navigator).phone;

  const countDownIntervalRef = useRef<number | null>(null);
  const timerIntervalRef = useRef<number | null>(null);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const wrapperDim = useRef({ width: 0, height: 0 });

  /**
   * Canvas containing the user and device media (screen share, camera and microfone)
   */
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  /**
   * The media stream of the user (camera and microfone)
   */
  const userMediaStreamRef = useRef<MediaStream | null>(null);

  /**
   * The media stream of the device (screen sharing)
   */
  const deviceMediaStreamRef = useRef<MediaStream | null>(null);

  /**
   * Media recorder is used to take the image from the canvas
   * and transform it in a video file. You can change the video format
   * by updating the constant EXPORTED_VIDEO_FORMAT in the VideoPreview component
   */
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const requestAnimationFrameRef = useRef<number | null>(null);
  const modelRef = useRef<BodySegmenter | null>(null);

  /**
   * We need this ref in order to handle the toggle behavior inside drawCanvas()
   * function since it stars an inifite loop and it loose the React context
   * and loose the states. To toggle the icons in the screen we use the "effects" state
   */
  const effectsRef = useRef<{
    camera: boolean;
    background: HTMLImageElement | false;
    blur: number;
    flipHorizontal: boolean;
  }>({
    camera: true,
    background: false,
    blur: 0,
    flipHorizontal: false,
  });

  const [videoDim, setVideoDim] = useState({
    width: 0,
    height: 0,
    aspectRatio: 16 / 9,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [availableDevices, setAvailableDevices] = useState<MediaDeviceInfo[]>(
    []
  );
  const [selectedDevices, setSelectedDevices] = useState<SelectedDevices>({
    audioDevice: undefined,
    videoDevice: undefined,
  });
  const [error, setError] = useState<string | undefined>();
  const [countDown, setCountDown] = useState(COUNT_DOWN);
  const [timerCountDown, setTimerCountDown] = useState(timer ?? 0);
  const [hasShootStarted, setHasShootStarted] = useState<
    ShootType | undefined
  >();

  /**
   * Preview of the video or photo taken
   */
  const [filePreview, setFilePreview] = useState<
    { type: PreviewType; src: string } | undefined
  >();

  /**
   * These are the available menu options. This state is used to update
   * the icons in the view, just for visiblity purposes.
   */
  const [options, setOptions] = useState<
    Record<OptionType | MenuOptionType, boolean>
  >({
    prompter: false,
    mic: true,
    camera: true,
    presentation: false,
  });

  const videoDevices = useMemo(
    () => availableDevices.filter((device) => device.kind === "videoinput"),
    [availableDevices]
  );

  /**
   * This is the video element used to capture the user media stream provided
   * by MediaDevices
   */
  const userVideo = useRef(document.createElement("video"));
  userVideo.current.muted = true;
  userVideo.current.playsInline = true;

  /**
   * This is the video element used to capture the device media stream provided
   * by MediaDevices
   */
  const deviceVideo = useRef(document.createElement("video"));
  deviceVideo.current.muted = true;
  deviceVideo.current.playsInline = true;

  const drawCanvas = useCallback(async () => {
    if (canvasRef.current && modelRef.current) {
      let context: CanvasRenderingContext2D | null = null;

      if (deviceMediaStreamRef.current) {
        context = drawPlainDeviceMedia(deviceVideo.current, canvasRef.current);
      }

      if (
        effectsRef.current.camera &&
        !effectsRef.current.background &&
        !effectsRef.current.blur
      ) {
        drawPlain(userVideo.current, canvasRef.current, {
          flipHorizontal: effectsRef.current.flipHorizontal,
          asPopup: deviceMediaStreamRef.current
            ? {
                previousCtx: context,
                x: USER_CAMERA_AS_POPUP.x,
                y: USER_CAMERA_AS_POPUP.y,
                width: USER_CAMERA_AS_POPUP.width,
                height: USER_CAMERA_AS_POPUP.height,
                borderRadius: USER_CAMERA_AS_POPUP.borderRadius,
              }
            : undefined,
        });
      }

      if (effectsRef.current.camera && effectsRef.current.blur) {
        await drawWithBlur(
          modelRef.current,
          userVideo.current,
          canvasRef.current,
          {
            backgroundBlurAmount: effectsRef.current.blur,
            edgeBlurAmount: EDGE_BLUR_AMOUNT,
            flipHorizontal: effectsRef.current.flipHorizontal,
            asPopup:
              deviceMediaStreamRef.current && effectsRef.current.camera
                ? {
                    previousCtx: context,
                    x: USER_CAMERA_AS_POPUP.x,
                    y: USER_CAMERA_AS_POPUP.y,
                    width: USER_CAMERA_AS_POPUP.width,
                    height: USER_CAMERA_AS_POPUP.height,
                    borderRadius: USER_CAMERA_AS_POPUP.borderRadius,
                  }
                : undefined,
          }
        );
      }

      if (effectsRef.current.camera && effectsRef.current.background) {
        await drawWithBackground(
          modelRef.current,
          userVideo.current,
          canvasRef.current,
          {
            flipHorizontal: effectsRef.current.flipHorizontal,
            backgroundImage: effectsRef.current.background,
            asPopup: deviceMediaStreamRef.current
              ? {
                  previousCtx: context,
                  x: USER_CAMERA_AS_POPUP.x,
                  y: USER_CAMERA_AS_POPUP.y,
                  width: USER_CAMERA_AS_POPUP.width,
                  height: USER_CAMERA_AS_POPUP.height,
                  borderRadius: USER_CAMERA_AS_POPUP.borderRadius,
                }
              : undefined,
          }
        );
      }

      if (!deviceMediaStreamRef.current && !effectsRef.current.camera) {
        drawBlackBackground(canvasRef.current);
      }
    }

    requestAnimationFrameRef.current = window.requestAnimationFrame(drawCanvas);
  }, []);

  const clearCountDownAndReset = () => {
    if (countDownIntervalRef.current) {
      window.clearInterval(countDownIntervalRef.current);
    }

    countDownIntervalRef.current = null;

    setCountDown(COUNT_DOWN);
  };

  const stopStream = () => {
    if (requestAnimationFrameRef.current) {
      window.cancelAnimationFrame(requestAnimationFrameRef.current);
    }

    userMediaStreamRef.current?.getTracks().forEach((track) => track.stop());
    userVideo.current.srcObject = null;

    stopScreenShare();
  };

  const stopScreenShare = () => {
    deviceMediaStreamRef.current?.getTracks().forEach((track) => track.stop());
    deviceVideo.current.srcObject = null;
    deviceMediaStreamRef.current = null;
  };

  const startStream = useCallback(
    async (facingMode: FacingMode, selectedDevices?: SelectedDevices) => {
      if (requestAnimationFrameRef.current) {
        window.cancelAnimationFrame(requestAnimationFrameRef.current);
      }

      userMediaStreamRef.current
        ?.getVideoTracks()
        .forEach((track) => track.stop());
      userVideo.current.pause();
      userVideo.current.srcObject = null;

      const aspectRatio = isMobile ? 9 / 16 : 16 / 9;
      const mobileHeight = { min: 480, max: 480 };
      const mobileWidth = { min: 640, max: 720 };
      const desktopHeight = { ideal: 1080 };
      const desktopWidth = { ideal: 1920 };

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          deviceId: selectedDevices?.audioDevice?.deviceId,
        },
        video: {
          facingMode,
          deviceId: !isMobile
            ? selectedDevices?.videoDevice?.deviceId
            : undefined,
          width: isMobile ? mobileWidth : desktopWidth,
          height: isMobile ? mobileHeight : desktopHeight,
          // Do not change this value based on desktop / mobile
          // browser handles it automatically.
          aspectRatio: 16 / 9,
        },
      });

      if (facingMode === "environment") {
        effectsRef.current.flipHorizontal = false;
      } else if (facingMode === "user") {
        effectsRef.current.flipHorizontal = FLIP_USER_CAMERA_HORIZONTAL;
      }

      userMediaStreamRef.current = stream;
      userVideo.current.srcObject = stream;
      userVideo.current.load();

      await waitForEvent(userVideo.current, "loadedmetadata");

      userVideo.current.width = userVideo.current.videoWidth;
      userVideo.current.height = userVideo.current.videoHeight;

      const parentWidth = wrapperRef.current?.parentElement?.clientWidth ?? 0;
      wrapperDim.current.width =
        parentWidth < userVideo.current.videoWidth
          ? parentWidth
          : userVideo.current.videoWidth;
      const height = Math.round(wrapperDim.current.width / aspectRatio);
      wrapperDim.current.height = height || userVideo.current.videoHeight;

      setVideoDim({
        width: userVideo.current.videoWidth,
        height: userVideo.current.videoHeight,
        aspectRatio,
      });

      void userVideo.current.play();

      await drawCanvas();
    },
    [drawCanvas, isMobile]
  );

  const startScreenShare = useCallback(async () => {
    try {
      if (!navigator.mediaDevices.getDisplayMedia) {
        alert("Your device does not support screen sharing");

        return false;
      }

      const stream = await navigator.mediaDevices.getDisplayMedia({
        audio: {
          noiseSuppression: true,
          echoCancellation: true,
        },
        video: {
          width: { ideal: 1920 },
          height: { ideal: 1080 },
          // cursor: 'always',
          // resizeMode: 'crop-and-scale',
        },
      });

      deviceVideo.current.srcObject = stream;
      deviceVideo.current.load();

      await waitForEvent(deviceVideo.current, "loadedmetadata");

      deviceVideo.current.width = deviceVideo.current.videoWidth;
      deviceVideo.current.height = deviceVideo.current.videoHeight;

      void deviceVideo.current.play();

      deviceMediaStreamRef.current = stream;

      stream.getVideoTracks()[0].onended = function () {
        stream.getVideoTracks()[0].onended = null;

        deviceVideo.current.srcObject = null;
        deviceMediaStreamRef.current = null;

        setOptions((prevState) => ({ ...prevState, presentation: false }));
      };

      return true;
    } catch (error) {
      const err = error as Error;
      if (err.name === "NotAllowedError") {
        console.error(
          "You need to give permissions to the screen sharing to continue!"
        );
      } else {
        // NotAllowedError is not necessarily an unexpected error. It happens everytime
        // a user clicks on the Cancel button or don't allow screen share in Safari for example
        // We are tracking just unexpected errors
        typewriterTrack("webcamScreenShareFailed", {
          companyExternalId: trackingProps.companyExternalId,
          profileExternalId: trackingProps.profileExternalId,
          errorMessage: err.message,
          errorName: err.name,
        });

        onError?.(err);

        alert(
          "An unexpected error has occurred. Please contact customer support!"
        );
      }

      console.error(error);

      return false;
    }
  }, [
    onError,
    trackingProps.companyExternalId,
    trackingProps.profileExternalId,
  ]);

  const getDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();

    setAvailableDevices(devices);

    return devices;
  };

  useEffect(() => {
    const onDeviceChange = async () => {
      const devices = await getDevices();

      // We need to filter by groupId too because sometimes we can have more than one "default" device ID
      // in different groups.
      // This filter is done in order to remove a current selected device that is not available anymore.
      // E.g: If we are using the headphones microphone and we turn the device off, etc.
      let selectedAudioDevice = devices.find(
        (device) =>
          device.deviceId === selectedDevices.audioDevice?.deviceId &&
          device.groupId === selectedDevices.audioDevice?.groupId
      );
      let selectedVideoDevice = devices.find(
        (device) =>
          device.deviceId === selectedDevices.videoDevice?.deviceId &&
          device.groupId === selectedDevices.videoDevice?.groupId
      );

      // If no audio device try selecting the default one. If no default, select the first available
      if (!selectedAudioDevice) {
        selectedAudioDevice = devices.find(
          (device) => device.deviceId === "default"
        );
        if (!selectedAudioDevice) {
          selectedAudioDevice = devices.filter(
            (device) => device.kind === "audioinput"
          )?.[0];
        }
      }

      // If no video device try selecting the default one. If no default, select the first available
      if (!selectedVideoDevice) {
        selectedVideoDevice = devices.find(
          (device) => device.deviceId === "default"
        );
        if (!selectedVideoDevice) {
          selectedVideoDevice = devices.filter(
            (device) => device.kind === "videoinput"
          )?.[0];
        }
      }

      setSelectedDevices({
        audioDevice: selectedAudioDevice,
        videoDevice: selectedVideoDevice,
      });

      const currentFacingMode = userMediaStreamRef.current
        ?.getVideoTracks()[0]
        .getConstraints().facingMode as FacingMode;

      void startStream(currentFacingMode, {
        audioDevice: selectedAudioDevice,
        videoDevice: selectedVideoDevice,
      });
    };

    navigator.mediaDevices.addEventListener("devicechange", onDeviceChange);

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        onDeviceChange
      );
    };
  }, [selectedDevices, startStream]);

  useEffect(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setError("Your browser does not support recording!");

      return;
    }

    const init = async () => {
      try {
        setIsLoading(true);

        await tf.setBackend("wasm");
        await tf.ready();
        modelRef.current = await createSegmenter(
          SupportedModels.MediaPipeSelfieSegmentation,
          {
            runtime: "mediapipe",
            modelType: "general",
            solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@${selfieSegmentation.VERSION}`,
          }
        );

        await startStream("user");

        const devices = await getDevices();

        // Set initial state of config modal inputs
        const audioInputId = userMediaStreamRef.current
          ?.getAudioTracks()[0]
          .getSettings().deviceId;
        const videoInputId = userMediaStreamRef.current
          ?.getVideoTracks()[0]
          .getSettings().deviceId;

        const audioDevice = devices.find(
          (device) => device.deviceId === audioInputId
        );
        const videoDevice = devices.find(
          (device) => device.deviceId === videoInputId
        );
        setSelectedDevices({ audioDevice, videoDevice });

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        const err = error as Error;
        if (err.name === "NotAllowedError") {
          setError(
            "Looks like we don't have permission to access your camera and mic. Please enable it in your browser to record."
          );
          // Safari browser returns a weird OverconstrainedError when the camera is not available
        } else if (
          err.name === "NotFoundError" ||
          err.name === "OverconstrainedError"
        ) {
          setError(
            "No camera was found. Please check it is properly connected and try again."
          );
        } else {
          setError(
            "An unexpected error has occurred. Please contact customer support!"
          );
        }

        typewriterTrack("webcamInitiationFailed", {
          companyExternalId: trackingProps.companyExternalId,
          profileExternalId: trackingProps.profileExternalId,
          errorMessage: err.message,
          errorName: err.name,
        });

        onError?.(err);

        console.error(error);
      }
    };

    void init();

    return () => {
      clearCountDownAndReset();

      clearTimer();

      stopStream();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCamera = async () => {
    const currentFacingMode = userMediaStreamRef.current
      ?.getVideoTracks()[0]
      .getConstraints().facingMode;

    await startStream(currentFacingMode === "user" ? "environment" : "user");
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current?.state !== "inactive") {
      mediaRecorderRef.current?.stop();
    }

    clearTimer();

    setHasShootStarted(undefined);
  };

  const generateVideo = useCallback((data: Blob) => {
    clearCountDownAndReset();

    const blob = new Blob([data], {
      type: MediaRecorder.isTypeSupported(EXPORTED_VIDEO_FORMAT)
        ? EXPORTED_VIDEO_FORMAT
        : FALLBACK_EXPORTED_VIDEO_FORMAT,
    });

    const url = URL.createObjectURL(blob);

    setFilePreview({ type: PreviewType.VIDEO, src: url });
  }, []);

  const startRecording = useCallback(() => {
    if (!canvasRef.current || !userMediaStreamRef.current) {
      return;
    }

    const stream = canvasRef.current.captureStream(60);

    const context = new AudioContext();
    const destination = context.createMediaStreamDestination();
    if (userMediaStreamRef.current.getAudioTracks().length) {
      const source1 = context.createMediaStreamSource(
        userMediaStreamRef.current
      );
      const userGain = context.createGain();
      userGain.gain.value = 1;
      source1.connect(userGain).connect(destination);
    }

    // Not all the browsers and operaing systems support device audio capture
    // so we need to validate properly before doing the merge.
    // https://caniuse.com/mdn-api_mediadevices_getdisplaymedia_audio_capture_support
    if (deviceMediaStreamRef.current?.getAudioTracks().length) {
      const source2 = context.createMediaStreamSource(
        deviceMediaStreamRef.current
      );
      const deviceGain = context.createGain();
      deviceGain.gain.value = 1;
      source2.connect(deviceGain).connect(destination);
    }

    const audioTracks = destination.stream.getAudioTracks();
    audioTracks.forEach((track) => stream.addTrack(track));

    try {
      if (MediaRecorder.isTypeSupported(EXPORTED_VIDEO_FORMAT)) {
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: EXPORTED_VIDEO_FORMAT,
        });
      } else {
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: FALLBACK_EXPORTED_VIDEO_FORMAT,
        });
      }
    } catch (error) {
      console.error("Error trying to instantiate MediaRecorder: ", error);

      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: FALLBACK_EXPORTED_VIDEO_FORMAT,
      });
    }

    mediaRecorderRef.current.addEventListener("dataavailable", ({ data }) => {
      if (data.size > 0) {
        generateVideo(data);
      }
    });

    if (mediaRecorderRef.current.state !== "recording") {
      mediaRecorderRef.current.start();
    }
  }, [generateVideo]);

  const startTimer = () => {
    timerIntervalRef.current = window.setInterval(() => {
      setTimerCountDown((prevState) => {
        if (prevState === 0) {
          stopRecording();

          return 0;
        }

        return prevState - 1;
      });
    }, 1000);
  };

  const clearTimer = () => {
    if (timerIntervalRef.current) {
      window.clearInterval(timerIntervalRef.current);
    }

    setTimerCountDown(timer ?? 0);

    timerIntervalRef.current = null;
  };

  const takePhoto = () => {
    const image = canvasRef.current?.toDataURL("image/jpeg") ?? "";

    setHasShootStarted(undefined);

    setFilePreview({ type: PreviewType.PHOTO, src: image });
  };

  const startCountDown = (type: ShootType) => {
    setHasShootStarted(type);

    countDownIntervalRef.current = window.setInterval(() => {
      setCountDown((prevState) => {
        if (prevState === 1 && countDownIntervalRef.current) {
          clearCountDownAndReset();

          if (type === ShootType.VIDEO) {
            startRecording();

            if (typeof timer === "number" && timer > 0) {
              startTimer();
            }
          } else {
            takePhoto();
          }
        }

        return prevState - 1;
      });
    }, 1000);
  };

  const stopCountDown = () => {
    clearCountDownAndReset();
    setHasShootStarted(undefined);
  };

  const onShootClick = (type: ShootType) => {
    if (!hasShootStarted) {
      return startCountDown(type);
    }

    if (countDownIntervalRef.current) {
      return stopCountDown();
    }

    if (type === ShootType.VIDEO) {
      stopRecording();
    }
  };

  const onOptionClick = useCallback(
    async (type: OptionType | MenuOptionType) => {
      if (type === MenuOptionType.PRESENTATION) {
        if (!options.presentation) {
          const result = await startScreenShare();

          setOptions((prevState) => ({
            ...prevState,
            presentation: result,
          }));
        } else {
          stopScreenShare();

          setOptions((prevState) => ({
            ...prevState,
            presentation: false,
          }));
        }

        return;
      }

      setOptions((prevState) => {
        const updatedOption = !prevState[type];

        switch (type) {
          case OptionType.PROMPTER:
            break;
          case OptionType.MIC:
            if (userMediaStreamRef.current) {
              userMediaStreamRef.current.getAudioTracks()[0].enabled =
                updatedOption;
            }
            break;
          case OptionType.CAMERA:
            {
              effectsRef.current.camera = updatedOption;
              if (updatedOption) {
                const currentFacingMode = userMediaStreamRef.current
                  ?.getVideoTracks()[0]
                  .getConstraints().facingMode as FacingMode;

                void startStream(currentFacingMode, selectedDevices);
              } else if (userMediaStreamRef.current) {
                userMediaStreamRef.current
                  .getVideoTracks()
                  .forEach((track) => track.stop());
              }
            }
            break;
          default:
            throw new Error(`Unknown option type: ${type}`);
        }

        return {
          ...prevState,
          [type]: updatedOption,
        };
      });
    },
    [options.presentation, selectedDevices, startScreenShare, startStream]
  );

  const onSettingsChange = async (selectedDevices: SelectedDevices) => {
    setSelectedDevices(selectedDevices);

    const currentFacingMode = userMediaStreamRef.current
      ?.getVideoTracks()[0]
      .getConstraints().facingMode as FacingMode;

    void startStream(currentFacingMode, selectedDevices);
  };

  const onEffectChange = (effect: Effect<EffectType>) => {
    switch (effect.type) {
      case EffectType.BACKGROUND: {
        effectsRef.current.blur = 0;

        const img = new Image();
        img.src = effect.value as string;
        img.crossOrigin = "anonymous";
        img.onload = function () {
          effectsRef.current.background = img;
        };
        break;
      }
      case EffectType.BLUR:
        effectsRef.current.background = false;
        effectsRef.current.blur = effect.value as number;
        break;
      case EffectType.NONE:
        effectsRef.current.background = false;
        effectsRef.current.blur = 0;
        break;
      default:
        throw new Error(`Unknown effect type: ${effect.type}`);
    }
  };

  const updateWrapperAndOffsetDimensions = (contentRect: ContentRect) => {
    const height = Math.round(
      (contentRect.bounds?.width ?? 0) / videoDim.aspectRatio
    );
    const width = contentRect.bounds?.width ?? 0;

    wrapperDim.current.width = width;
    wrapperDim.current.height = height;
  };

  const onUploadFileClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "";
    if (isPhotoEnabled) {
      input.accept += ",image/*";
    }
    if (isVideoEnabled) {
      input.accept += ",video/*";
    }
    input.onchange = function (event) {
      const file = (event.target as HTMLInputElement)?.files?.[0];

      if (!file) {
        return;
      }
      //TODO: Add JS validations of selected files (based on extension)

      setFilePreview({
        type: file.type.includes("video/")
          ? PreviewType.VIDEO
          : PreviewType.PHOTO,
        src: URL.createObjectURL(file),
      });
    };

    input.click();
  };

  const onErrorViewFileUpload = (file: File) => {
    const aspectRatio = isMobile ? 9 / 16 : 16 / 9;
    const width = wrapperRef.current?.parentElement?.clientWidth ?? 0;
    const height = Math.round(width / aspectRatio);

    wrapperDim.current.width = width;
    wrapperDim.current.height = height;

    setVideoDim({
      width,
      height,
      aspectRatio,
    });

    setFilePreview({
      type: file.type.includes("video/")
        ? PreviewType.VIDEO
        : PreviewType.PHOTO,
      src: URL.createObjectURL(file),
    });
  };

  if (filePreview) {
    return (
      <PreviewView
        as={filePreview.type}
        componentProps={{
          wrapperDim: wrapperDim.current,
          videoDim,
          src: filePreview.src,
          onCancelClick: () => {
            setFilePreview(undefined);
          },
          onAcceptClick: (file) => {
            onAccept(file);
            setFilePreview(undefined);
          },
        }}
      />
    );
  }

  if (error) {
    return (
      <ErrorView
        wrapperRef={wrapperRef}
        isVideoEnabled={isVideoEnabled}
        isPhotoEnabled={isPhotoEnabled}
        message={error}
        onFileUpload={onErrorViewFileUpload}
      />
    );
  }

  return (
    <WebcamView
      wrapperRef={wrapperRef}
      canvasRef={canvasRef}
      wrapperDim={wrapperDim.current}
      videoDim={videoDim}
      isPhotoEnabled={isPhotoEnabled}
      isVideoEnabled={isVideoEnabled}
      isLoading={isLoading}
      hasShootStarted={hasShootStarted}
      showFlipCameraOption={isMobile && videoDevices.length > 1}
      showScreenShareOption={!isMobile}
      showCountDown={!!countDownIntervalRef.current && countDown > 0}
      showTimerCountDown={!!timerIntervalRef.current}
      availableDevices={availableDevices}
      selectedDevices={selectedDevices}
      prompt={prompt}
      countDown={countDown}
      timerCountDown={timerCountDown}
      interjections={interjections ?? []}
      options={options}
      onResize={updateWrapperAndOffsetDimensions}
      onShootClick={onShootClick}
      onToggleCameraClick={toggleCamera}
      onOptionClick={onOptionClick}
      onUploadFileClick={onUploadFileClick}
      onSettingsChange={onSettingsChange}
      onEffectChange={onEffectChange}
    />
  );
}
