import { styled } from "@mui/material/styles";
import ToggleButtonBase from "@mui/material/ToggleButton";

export const ToggleButton = styled(ToggleButtonBase)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-transform: none;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border[1]};
  &.MuiToggleButton-root {
    color: ${({ theme }) => theme.colors.typography[4]};
    &.Mui-selected {
      background-color: white;
      color: #555553;
    }
  }
  &.MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.colors.border[1]};
  }
`;
