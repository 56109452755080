import { VideoJsPlayer } from "video.js";
import { GlobalStorage } from "../../../../../utils/global-storage";
import { toggleDefaultCaptions } from "../textTracks";

const globalStorage = new GlobalStorage();

const CLOSED_CAPTIONS_ENABLED_KEY = "videoClosedCaptionsEnabled";

/**
 * Save the video's closed caption visibility to a boolean in local storage.
 *
 * @param player Video.js player
 * @returns void
 */
export const saveCaptionsSetting = (player: VideoJsPlayer) => {
  let areClosedCaptionsEnabled = false;

  const textTracks = player.textTracks();

  for (let i = 0; i < textTracks.length; i += 1) {
    if (
      ["captions", "subtitles"].includes(textTracks[i].kind) &&
      textTracks[i].mode === "showing"
    ) {
      areClosedCaptionsEnabled = true;
      break;
    }
  }

  globalStorage.setItem(
    CLOSED_CAPTIONS_ENABLED_KEY,
    JSON.stringify(areClosedCaptionsEnabled)
  );
};

/**
 * Set the video's closed caption visibility from a boolean in local storage.
 *
 * @param player Video.js player
 * @returns void
 */
export const restoreCaptionsSetting = (player: VideoJsPlayer) => {
  const areClosedCaptionsEnabled = JSON.parse(
    globalStorage.getItem(CLOSED_CAPTIONS_ENABLED_KEY) || "false"
  ) as boolean;
  toggleDefaultCaptions(player, areClosedCaptionsEnabled);
};
