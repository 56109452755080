import { CCTypes } from "@bigspring/core-components";
import { MeQuery, Role } from "@gql/generated/graphql";

export const identify = (
  user?: MeQuery["me"] | null,
  companyId?: string
): CCTypes.AdminIdentificationType | null => {
  if (!user) {
    return null;
  }
  try {
    let companyProfile = user.companyProfiles[0];
    if (companyId) {
      companyProfile =
        user.companyProfiles.find(
          (companyProfile) => companyProfile.company.id === companyId
        ) ?? companyProfile;
    }

    const isCoach =
      user.isSuperAdmin ||
      (user.companyProfiles.length !== 0 &&
        user.companyProfiles.some((companyProfile) =>
          companyProfile.roles.includes(Role.Coach)
        ));

    const response: CCTypes.AdminIdentificationType = {
      userId: user.id,
      companyProfileId: companyProfile.id as string,
      externalId: companyProfile.externalId as string,
      email: companyProfile.emailAddress ?? null,
      name: user.displayName ?? null,
      phone: companyProfile.phoneNumber ?? null,
      isSuperAdmin: user?.isSuperAdmin,
      isCoach: isCoach,
      createdAt: user.createdAt,
      companyId: companyProfile?.company.id,
      company: companyProfile?.company.name,
      companyExternalId: companyProfile?.company.externalId,
      roles: companyProfile.roles,
    };

    return response;
  } catch (error) {
    console.error("Error Admin identification", error);
  }

  return null;
};
