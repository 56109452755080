import { styled, keyframes } from "@mui/material/styles";

const flashAnimation = keyframes`
  from {
    opacity: 0.8;
  }

  to {
    opacity: 0;
  }
`;

export const Flash = styled("div")`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: #ffffff;
  animation: ${flashAnimation} 750ms ease-out;
`;

export const Img = styled("img")`
  width: 100%;
  object-fit: contain;
`;
