import React, { ReactNode } from "react";
import { CoinIcon } from "../../../../../../icons";
import { BaseCard, CardProps } from "../BaseCard";
import { SkillCompletionIcon, StatsBorder, Wrapper } from "./styles";
import { Title } from "../../components/Title";
import { Stats } from "../../components/Stats";
import { AlignedContent } from "../../styles";
import { CtaButton } from "../../components/CtaButton";
import { ButtonContainer } from "../FormCard/styles";

export type CompletionCardProps = CardProps & {
  isComplete: boolean;
  pointsEarned: number;
  completeStr?: ReactNode; // TODO: Check these types, why ReactNode?. Also verify required vs optional
  incompleteStr?: ReactNode;
  pointsEarnedStr?: ReactNode;
  nextStr?: ReactNode;
  onNextClick?: () => void;
};

export function CompletionCard({
  isActive,
  isComplete,
  pointsEarned,
  completeStr = "Skill complete!",
  incompleteStr = "Almost done!",
  pointsEarnedStr = "Points earned",
  nextStr = "Next",
  onNextClick,
  onCardActive,
}: CompletionCardProps) {
  return (
    <Wrapper>
      <BaseCard isActive={isActive} onCardActive={onCardActive}>
        <AlignedContent $align="center">
          <Title text={isComplete ? completeStr : incompleteStr} />
          <SkillCompletionIcon $isComplete={isComplete} />
          {pointsEarned > 0 && (
            <StatsBorder>
              <Stats
                stats={[
                  {
                    text: pointsEarnedStr,
                    value: pointsEarned,
                    icon: <CoinIcon width={24} height={24} />,
                  },
                ]}
              />
            </StatsBorder>
          )}
          <ButtonContainer>
            <CtaButton text={nextStr} onClick={onNextClick} />
          </ButtonContainer>
        </AlignedContent>
      </BaseCard>
    </Wrapper>
  );
}
