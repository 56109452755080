import { styled } from "@mui/material/styles";
import { default as MuiSwitch, SwitchProps } from "@mui/material/Switch";

export const Switch = styled(MuiSwitch)<SwitchProps>`
  .MuiSwitch-switchBase {
    position: absolute !important;
    padding: 9px;
  }

  .MuiSwitch-colorPrimary.Mui-checked {
    color: ${({ theme }) => theme.kinetic.v2.palette.rgb.blue};
    & + .MuiSwitch-track {
      background-color: ${({ theme }) =>
        theme.kinetic.palette.rgba.primaryBlue(0.9)} !important;
    }
  }
  .Mui-disabled {
    opacity: 0.5;
  }
`;

export type { SwitchProps };
