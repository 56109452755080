import { LanguageData } from "@shared/Collections/types";
import {
  CountryCode,
  Language,
  MeQuery,
  Timezone,
} from "@gql/generated/graphql";

type Flag = NonNullable<MeQuery["me"]>["flags"][0];
export abstract class BaseModel {
  protected static companyDefaultLanguage: Language;

  protected static companyDefaultCountryCode?: CountryCode | null;

  protected static companyDefaultTimezone?: Timezone | null;

  protected static companyLocalizations: LanguageData[];

  protected static userFlags?: Flag[];

  static setCompanyDefaultLanguage(defaultLanguage: Language) {
    BaseModel.companyDefaultLanguage = defaultLanguage;
  }

  static setCompanyDefaultCountryCode(defaultCountryCode?: CountryCode | null) {
    BaseModel.companyDefaultCountryCode = defaultCountryCode;
  }

  static setCompanyDefaultTimezone(defaultTimezone?: Timezone | null) {
    BaseModel.companyDefaultTimezone = defaultTimezone;
  }

  static setCompanyLocalizations(localizations: LanguageData[]) {
    BaseModel.companyLocalizations = localizations;
  }

  static setUserFlags(flags: Flag[]) {
    BaseModel.userFlags = flags;
  }

  // React hook form field array seems to have issues working with classes
  // here we are converting the classes to plain objects
  // TODO: Check if this can be avoided in V7, if not, we'd need to do the same for
  // all fields arrays when working with classes
  toPlainObject() {
    return JSON.parse(JSON.stringify(this));
  }
}
