import React from "react";
import StepperBase, { StepperOwnProps } from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.root}`]: {
    marginLeft: 15,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.kinetic.v3.palette.chroma.primarybaseline.css(),
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.kinetic.v3.palette.chroma.primarybaseline.css(),
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    minHeight: 30,
    width: 2,
    border: 0,
    backgroundColor: theme.kinetic.v3.palette.chroma.neutralshade[900].css(),
    borderRadius: 1,
  },
}));

function Wrapper({ children, ...props }: StepperOwnProps) {
  return (
    <StepperBase connector={<Connector />} {...props}>
      {children}
    </StepperBase>
  );
}

export const Stepper = {
  Wrapper,
  Step,
  StepLabel,
  StepContent,
};
