import React, {
  useState,
  useMemo,
  MouseEventHandler,
  MouseEvent,
  ChangeEventHandler,
  ChangeEvent,
} from "react";
import { CardProps } from "../BaseCard";
import { IntroCard } from "./components/IntroCard";
import {
  CompletionCard,
  CompletionCardType,
} from "./components/CompletionCard";
import { FormModal, Prompt, Choice } from "./components/FormModal";
import { FormCardContainer } from "./styles";

export type { Prompt as CardFormPrompt };

export type FormCardProps = CardProps & {
  /** Is the form modal open? */
  isOpen?: boolean;
  /** Is the form a graded quiz? */
  isGradedQuiz?: boolean;
  /** Is the form a survey? */
  isSurvey?: boolean;
  /** Is the form complete on initial render? */
  isComplete: boolean;
  /** Has the form been failed? */
  isFailed: boolean;
  /** Form prompt index */
  promptIndex?: number;
  /** When a string is passed a confirmation popup will be shown before starting the form with that string as a message */
  showConfirmBeforeStart?: string;
  /** Selected choice ID */
  selectedChoiceIds?: Array<string>;
  /** Submitted choice ID */
  submittedChoiceIds?: Array<string>;
  /** Number of form attempts submitted, starting at 0 */
  attempts?: number;
  /** Total number of form attempts permitted */
  maxAttempts?: number;
  /** Number of correct form prompts to pass the form, only defined for graded quizzes */
  correctToPass?: number;
  /** Form prompt data */
  prompts: Prompt[];
  /** Server resoponse is answer correct */
  promptIsCorrect?: boolean;
  /** Title of the form */
  title?: string | null;
  // Localized strings
  goBackStr?: string;
  surveyStr?: string;
  practiceStr?: string;
  startStr?: string;
  resumeStr?: string;
  gradedQuizReqsStr?: string;
  continueStr: string;
  correctStr?: string;
  incorrectStr?: string;
  tryAgainStr: string;
  checkAnswerStr: string;
  quizCompleteStr?: string;
  surveyCompleteStr?: string;
  attemptStr?: string;
  correctAnswersStr?: string;
  failedStr?: string;
  isReview?: boolean;
  /** Callback when starting a form, opening the form modal */
  onStartForm?: VoidFunction;
  /** Callback when retrying a form, opening the form modal */
  onRetryForm?: MouseEventHandler<HTMLButtonElement>;
  /** Callback when closing the form modal */
  onCloseForm?: VoidFunction;
  /** Callback when moving to the next card in the stack */
  onNextCard?: MouseEventHandler<HTMLButtonElement>;
  /** Callback when selecting a form preset choice */
  onChangeChoice?: ChangeEventHandler<HTMLInputElement>;
  /** Callback when changing a form input choice */
  onChangeInput?: ChangeEventHandler<HTMLTextAreaElement>;
  /** Callback when submitting a choice */
  onSubmitChoice?: (
    promptId: string,
    choiceId: Array<string>,
    choiceInput?: string
  ) => Promise<void>;
  /** Callback when moving to the next form prompt */
  onNextPrompt?: VoidFunction;
  /** Callback when re-attempting the current form prompt */
  onRetryPrompt?: VoidFunction;
};

const pluralize = (count: number) => (count !== 1 ? "s" : "");

export function FormCard({
  isActive,
  isLoading = false,
  isOpen: _isOpen = false,
  isGradedQuiz = false,
  isSurvey = false,
  isComplete,
  isFailed,
  promptIndex: _promptIndex = 0,
  showConfirmBeforeStart: _showConfirmBeforeStart,
  selectedChoiceIds: _selectedChoiceIds = [],
  submittedChoiceIds: _submittedChoiceIds = [],
  attempts,
  maxAttempts,
  correctToPass,
  prompts = [],
  promptIsCorrect,
  title,
  goBackStr,
  surveyStr,
  practiceStr,
  startStr,
  resumeStr,
  gradedQuizReqsStr: _gradedQuizReqsStr,
  continueStr,
  correctStr = "Correct",
  incorrectStr = "Incorrect",
  tryAgainStr,
  checkAnswerStr = "Check answer",
  quizCompleteStr,
  surveyCompleteStr,
  attemptStr,
  correctAnswersStr,
  failedStr,
  isReview: _isReview = false,
  onStartForm: _onStartForm,
  onRetryForm: _onRetryForm,
  onCloseForm: _onCloseForm,
  onNextCard: _onNextCard,
  onChangeChoice: _onChangeChoice,
  onChangeInput: _onChangeInput,
  onSubmitChoice: _onSubmitChoice,
  onNextPrompt: _onNextPrompt,
  onRetryPrompt: _onRetryPrompt,
  onCardActive,
}: FormCardProps) {
  const [isModalOpen, setIsModalOpen] = useState(_isOpen);
  const [promptIndex, setCurrentPromptIndex] = useState(_promptIndex);
  const [selectedChoiceIds, setSelectedChoiceIds] =
    useState<Array<string>>(_selectedChoiceIds);
  const [submittedChoiceIds, setSubmittedChoiceIds] =
    useState<Array<string>>(_submittedChoiceIds);

  const [showConfirmBeforeStart, setShowConfirmBeforeStart] = useState(false);
  const [input, setInput] = useState("");
  const [isReview, setIsReview] = useState(_isReview || false);

  const trimmedInput = input.trim();

  const prompt = prompts[promptIndex] as Prompt | undefined;

  const promptCount = prompts.length;

  const correctPromptCount = isSurvey
    ? undefined
    : prompts.filter(
        ({ choices }) =>
          choices.some(({ isCorrect }) => isCorrect === true) &&
          !choices.some(({ isFailed }) => isFailed !== false)
      ).length;

  const hasAttemptsRemaining =
    isGradedQuiz &&
    typeof attempts === "number" &&
    typeof maxAttempts === "number"
      ? attempts < maxAttempts
      : true;

  const presetChoices = useMemo(() => {
    return prompts[promptIndex]?.choices
      ?.map((choice) => (choice.type !== "INPUT" ? choice : null))
      .filter(Boolean) as Choice[];
  }, [prompts, promptIndex]);

  const inputChoices = useMemo(() => {
    return prompts[promptIndex]?.choices
      ?.map((choice) => (choice.type === "INPUT" ? choice.id : null))
      .filter(Boolean) as string[];
  }, [prompts, promptIndex]);

  const hasInputChoice = !!inputChoices.length;

  const isModalCtaEnabled =
    (!isLoading && isReview) || // NOTE: boolean logic has became unnecessarily complex should we simplify this?
    (hasInputChoice
      ? !!trimmedInput && trimmedInput.length <= 1000 // is input valid
      : !!(selectedChoiceIds.length || submittedChoiceIds.length)); // is choice valid

  const gradedQuizReqsStr =
    _gradedQuizReqsStr ||
    `${
      maxAttempts
        ? `You will have ${maxAttempts} attempt${pluralize(
            maxAttempts
          )} to answer`
        : "Answer"
    } ${correctToPass}/${promptCount} question${pluralize(
      promptCount
    )} correctly to pass.`;

  // Choice input handlers
  const onReviewClicked = () => {
    setIsReview(true);
    setIsModalOpen(true);
    setCurrentPromptIndex(0);
  };

  const resetChoices = () => {
    setSelectedChoiceIds([]);
    setSubmittedChoiceIds([]);
    setInput("");
  };

  const onChangeChoice = (
    e: ChangeEvent<HTMLInputElement>,
    value: string,
    isMultiple?: boolean
  ) => {
    if (isMultiple) {
      if (selectedChoiceIds.includes(value)) {
        setSelectedChoiceIds(
          selectedChoiceIds.filter((item) => item !== value)
        );
      } else {
        setSelectedChoiceIds([value, ...selectedChoiceIds]);
      }
    } else {
      setSelectedChoiceIds([value]);
    }
    _onChangeChoice?.(e);
  };

  const onChangeInput: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setInput(e.target.value.substring(0, 1000));
    setSelectedChoiceIds([]);
    _onChangeInput?.(e);
  };

  // Open form modal

  const onStartForm: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    if (_showConfirmBeforeStart) {
      setShowConfirmBeforeStart(true);
    } else {
      setIsModalOpen(true);
      _onStartForm?.();
    }
  };

  const onConfirmAccept = () => {
    setShowConfirmBeforeStart(false);
    setIsModalOpen(true);

    _onStartForm?.();
  };

  const onRetryForm = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCurrentPromptIndex(0);
    resetChoices();
    setIsModalOpen(true);
    _onRetryForm?.(e);
  };

  // Move to next card from completed form card

  const onNextCard: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    resetChoices();
    _onNextCard?.(e);
  };

  // Proceed within form

  const onSubmitChoice = async () => {
    const choiceIds = hasInputChoice ? inputChoices : selectedChoiceIds;
    const choiceInput = hasInputChoice ? trimmedInput : undefined;
    if (!prompt || !choiceIds.length || (hasInputChoice && !choiceInput)) {
      return;
    }
    await _onSubmitChoice?.(prompt.id, choiceIds, choiceInput);
    setSubmittedChoiceIds(choiceIds);
  };

  const onNextPrompt = () => {
    resetChoices();
    setCurrentPromptIndex((prev) => prev + 1);
    _onNextPrompt?.();
  };

  const onRetryPrompt = () => {
    resetChoices();
    _onRetryPrompt?.();
  };

  const onCloseForm = () => {
    // This is a hack, _isReview only comes from Admin preview
    // So it works.
    if (isReview && !_isReview) {
      setIsReview(false);
    }

    setIsModalOpen(false);

    _onCloseForm?.();
  };

  const onSubmit = async () => {
    if (!isModalCtaEnabled) {
      return;
    }

    const nextOrClose = () => {
      if (promptIndex + 1 === promptCount) {
        onCloseForm();
      } else {
        onNextPrompt();
      }
    };

    if (isReview) {
      return nextOrClose();
    }

    if (isSurvey) {
      await onSubmitChoice();

      return nextOrClose();
    }

    if (!submittedChoiceIds?.length) {
      return onSubmitChoice();
    }

    // At this point the choice was submitted
    if (promptIsCorrect) {
      return nextOrClose();
    }

    if (isGradedQuiz) {
      nextOrClose();
    } else {
      onRetryPrompt();
    }
  };

  let as: CompletionCardType;
  if (isFailed) {
    as = CompletionCardType.FAILED;
  } else if (isSurvey) {
    as = CompletionCardType.SURVEY_SUCCEED;
  } else if (isGradedQuiz) {
    as = CompletionCardType.GRADED_QUIZ_SUCCEED;
  } else {
    as = CompletionCardType.UNGRADED_QUIZ_SUCCEED;
  }

  if (isComplete && !isModalOpen && !isReview) {
    return (
      <CompletionCard
        as={as}
        componentProps={{
          isActive,
          hasAttemptsRemaining,
          attempts,
          maxAttempts,
          correctPromptCount,
          failedStr,
          tryAgainStr,
          attemptStr,
          correctAnswersStr,
          quizCompleteStr,
          surveyCompleteStr,
          continueStr,
          onRetryForm,
          onReviewClicked,
          onNextCard,
          onCardActive,
        }}
      />
    );
  }

  return (
    <FormCardContainer>
      <IntroCard
        showConfirmBeforeStart={showConfirmBeforeStart}
        isActive={isActive}
        isGradedQuiz={isGradedQuiz}
        isSurvey={isSurvey}
        isStarted={prompt?.isCompleted ?? promptIndex > 0}
        title={title}
        continueStr={continueStr}
        goBackStr={goBackStr}
        surveyStr={surveyStr}
        practiceStr={practiceStr}
        gradedQuizReqsStr={gradedQuizReqsStr}
        startStr={startStr}
        resumeStr={resumeStr}
        confirmationStr={_showConfirmBeforeStart}
        onStartForm={isReview ? onReviewClicked : onStartForm}
        onCardActive={onCardActive}
        onConfirmAccept={onConfirmAccept}
        onConfirmClose={() => setShowConfirmBeforeStart(false)}
      />
      {!!prompt && (
        <FormModal
          isReview={isReview}
          isLoading={isLoading}
          isGradedQuiz={isGradedQuiz}
          isSurvey={isSurvey}
          isOpen={isModalOpen}
          hasAttemptsRemaining={hasAttemptsRemaining}
          correctToPass={correctToPass}
          promptCount={promptCount}
          prompt={prompt}
          promptIndex={promptIndex}
          promptIsCorrect={promptIsCorrect}
          areChoicesDisabled={!!submittedChoiceIds.length}
          hasInputChoice={hasInputChoice}
          presetChoices={presetChoices}
          selectedChoiceIds={selectedChoiceIds}
          submittedChoiceIds={submittedChoiceIds}
          input={input}
          trimmedInput={trimmedInput}
          isCtaEnabled={isModalCtaEnabled}
          continueStr={continueStr}
          correctStr={correctStr}
          incorrectStr={incorrectStr}
          tryAgainStr={tryAgainStr}
          checkAnswerStr={checkAnswerStr}
          onChangeChoice={onChangeChoice}
          onChangeInput={onChangeInput}
          onCloseForm={onCloseForm}
          onSubmit={onSubmit}
        />
      )}
    </FormCardContainer>
  );
}
