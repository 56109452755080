import { styled } from "@mui/material/styles";
import { BSPH5 } from "../../../BSPH5";

export const Wrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled(BSPH5)`
  text-align: center;
`;

export const HiddenInput = styled("input")`
  position: absolute;
  visibility: hidden;
  height: 0;
  width: 0;
  border: none;
  padding: 0;
  margin: 0;
`;
