import { isIos, isSafari } from "../../../../../utils/userAgent";
import type { VideoPlayerOptions } from "../../types";

export const DEFAULT_VIDEO_THUMBNAIL_TIME = 0.4;

/**
 * Get media sources
 * @param options
 * @returns videojs.Tech.SourceObject[]
 */
export const getSources = (
  options: VideoPlayerOptions,
  isThumbnail = false
) => {
  const sources = options.sources || [];

  if (options.src) {
    // Issues with Safari (Desktop and Mobile) and Chrome (Only in IOS):
    // https://app.shortcut.com/bigspring/story/25075/mobile-pwa-thumbnails-for-video-reps-are-not-showing-in-the-reps-gallery
    // https://muffinman.io/blog/hack-for-ios-safari-to-display-html-video-thumbnail/
    // https://github.com/videojs/video.js/issues/5606#issuecomment-440142607
    if (isThumbnail && (isIos || isSafari)) {
      sources.push({
        src: options.src + "#t=" + DEFAULT_VIDEO_THUMBNAIL_TIME.toString(),
        type: "video/mp4",
      });
    } else {
      if (typeof options.src === "string") {
        sources.push({ src: options.src });
      } else {
        sources.push(options.src);
      }
    }
  }

  return sources;
};
