import React from "react";
import { PhotoPreview, Props as PhotoProps } from "./components/PhotoPreview";
import { VideoPreview, Props as VideoProps } from "./components/VideoPreview";

export enum PreviewType {
  PHOTO,
  VIDEO,
}

interface PreviewPropsMap {
  [PreviewType.PHOTO]: PhotoProps;
  [PreviewType.VIDEO]: VideoProps;
}

type Props<Type extends PreviewType> = {
  as: Type;
  componentProps: PreviewPropsMap[Type];
};

export function PreviewView<Type extends PreviewType>({
  as,
  componentProps,
}: Props<Type>) {
  switch (as) {
    case PreviewType.PHOTO:
      return <PhotoPreview {...componentProps} />;
    case PreviewType.VIDEO:
      return <VideoPreview {...componentProps} />;
    default:
      throw new Error(`Unsupported preview type: ${as}`);
  }
}
