import { styled } from "@mui/material/styles";
import { DialogContent as BaseDialogContent } from "../dialog";

export const Thumbnail = styled("div")<{
  fullCard?: boolean;
  display?: string;
}>`
  cursor: zoom-in;
  line-height: 0;
  height: ${({ fullCard }) => (fullCard ? "100%" : null)};
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const DialogContent = styled(BaseDialogContent)`
  background: #000;
  border-bottom: 0;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  display: flex;
  align-items: start;
  justify-content: center;
  user-select: none;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  img,
  video {
    user-select: none;
  }
`;
