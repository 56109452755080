import { styled } from "@mui/material/styles";

export const BSPH1 = styled("h1")`
  font-family: "Roboto", var(--bigspring-primary-font);
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 80px;
  letter-spacing: 0.0125em;
  margin: 0;
`;
