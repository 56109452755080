import React, { ReactElement } from "react";
import { Grid } from "./../../../Grid";
import { Select } from "./../../../Select";
import { StyledGrid } from "./styled";

export type PageSize = {
  label: string;
  value: number;
};

export type Props = {
  pageSize?: number;
  pageSizeOptions?: PageSize[];
  onPageSizeChange?: (arg0: any) => void;
  headerLeftComponent?: ReactElement;
  headerRightComponent?: ReactElement;
};

export function Header({
  pageSize,
  onPageSizeChange,
  pageSizeOptions,
  headerLeftComponent,
  headerRightComponent,
}: Props) {
  return (
    <StyledGrid container justifyContent="space-between">
      <Grid item>{headerLeftComponent}</Grid>
      {pageSizeOptions && (
        <Grid item>
          <Select
            options={pageSizeOptions}
            value={pageSize}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={onPageSizeChange}
          />
        </Grid>
      )}
      <Grid item>{headerRightComponent}</Grid>
    </StyledGrid>
  );
}
