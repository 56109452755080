import { styled } from "@mui/material/styles";
import AutocompleteComponent from "@mui/material/Autocomplete";

export const Autocomplete = styled(AutocompleteComponent)`
  .MuiAutocomplete-tag {
    max-width: calc(80% - 6px);
  }

  .MuiOutlinedInput-root {
    padding: 0;
  }

  .MuiAutocomplete-endAdornment {
    right: 10px;
  }
`;
