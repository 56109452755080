import { createContext, useContext, PropsWithChildren, useState } from "react";
import { CC } from "@bigspring/core-components";
import { NextRouter, useRouter } from "next/router";
import { AvailableRoutes, getRoute } from "config/routes";
import { getCompanyId } from "utils/auth";

type NextPushParameters = Parameters<NextRouter["push"]>;
type CustomPushOptions = NextPushParameters[2] & { askBeforeLeaving?: boolean };
type CustomPush = (
  url: AvailableRoutes | { pathname: AvailableRoutes; search?: string },
  params?: Record<string, string>,
  options?: CustomPushOptions,
  as?: NextPushParameters[1]
) => Promise<boolean>;

type NextReplaceParameters = Parameters<NextRouter["replace"]>;
type CustomReplaceOptions = NextReplaceParameters[2] & {
  askBeforeLeaving?: boolean;
};
type CustomReplace = (
  url: AvailableRoutes | { pathname: AvailableRoutes; search?: string },
  params?: Record<string, string>,
  options?: CustomReplaceOptions,
  as?: NextReplaceParameters[1]
) => Promise<boolean>;

type CustomRouterContext = Omit<NextRouter, "push" | "replace" | "forward"> & {
  push: CustomPush;
  replace: CustomReplace;
  setWarnUnsavedChanges: (warn: boolean) => void;
};

const Context = createContext<CustomRouterContext>({
  route: "",
  pathname: "",
  query: {},
  asPath: "",
  basePath: "",
  isLocaleDomain: false,
  push: async () => false,
  replace: async () => false,
  reload: () => undefined,
  back: () => undefined,
  prefetch: async () => undefined,
  beforePopState: () => undefined,
  events: {
    on: () => undefined,
    off: () => undefined,
    emit: () => undefined,
  },
  isFallback: false,
  isReady: false,
  isPreview: false,
  setWarnUnsavedChanges: () => undefined,
});

export function CustomRouterProvider({ children }: PropsWithChildren<unknown>) {
  const {
    push: nextPush,
    replace: nextReplace,
    ...restNextRouterProps
  } = useRouter();
  const { ask } = CC.useNotification();

  const [warnUnsavedChanges, setWarnUnsavedChanges] = useState(false);

  const push: CustomPush = async (url, params, options, as) => {
    // askBeforeLeaving prop takes precedence over internal state. That way we can force the alert to appear or not
    const shouldAsk =
      typeof options?.askBeforeLeaving === "boolean"
        ? options.askBeforeLeaving
        : warnUnsavedChanges;

    if (
      shouldAsk &&
      !(await ask(
        "Are you sure you want to leave this page? You have unsaved changes"
      ))
    ) {
      return false;
    }

    setWarnUnsavedChanges(false);

    // If the companyId is in the URL, re-add it in the new params for the new URL.
    if (
      restNextRouterProps.query.companyId &&
      typeof restNextRouterProps.query.companyId === "string"
    ) {
      params = { companyId: restNextRouterProps.query.companyId, ...params };
    }

    if (typeof url === "object") {
      if (url.search) {
        void nextPush(
          `${getRoute(url.pathname, params)}?${url.search}`,
          as,
          options
        );
      } else {
        void nextPush(getRoute(url.pathname, params), as, options);
      }
    } else {
      void nextPush(getRoute(url, params), as, options);
    }

    return true;
  };

  const replace: CustomReplace = async (url, params, options, as) => {
    // askBeforeLeaving prop takes precedence over internal state. That way we can force the alert to appear or not
    const shouldAsk =
      typeof options?.askBeforeLeaving === "boolean"
        ? options.askBeforeLeaving
        : warnUnsavedChanges;

    if (
      shouldAsk &&
      !(await ask(
        "Are you sure you want to leave this page? You have unsaved changes"
      ))
    ) {
      return false;
    }

    setWarnUnsavedChanges(false);

    const companyId = getCompanyId();
    if (companyId) {
      params = { companyId, ...params };
    }

    if (typeof url === "object") {
      if (url.search) {
        void nextReplace(
          `${getRoute(url.pathname, params)}?${url.search}`,
          as,
          options
        );
      } else {
        void nextReplace(getRoute(url.pathname, params), as, options);
      }
    } else {
      void nextReplace(getRoute(url, params), as, options);
    }

    return true;

    // return nextReplace(getRoute(url, params), as, options);
  };

  return (
    <Context.Provider
      value={{
        setWarnUnsavedChanges,
        push,
        replace,
        ...restNextRouterProps,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useCustomRouter() {
  return useContext(Context);
}
