import { styled } from "@mui/material/styles";
import { Grid } from "./../../../Grid";

export const ActionsColumn = styled(Grid)`
  width: 100%;
  justify-content: flex-end;
`;

ActionsColumn.defaultProps = {
  spacing: 1,
  container: true,
};
