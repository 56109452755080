import { styled } from "@mui/material/styles";
import { DataGrid as DataGridMui } from "@mui/x-data-grid";

export const DataGrid = styled(DataGridMui)`
  &.MuiDataGrid-root {
    border-color: transparent;
  }

  .MuiDataGrid-columnsContainer {
    background-color: ${({ theme }) => theme.kinetic.palette.rgb.gray200};
    border-color: ${({ theme }) => theme.kinetic.palette.rgb.gray100};

    .MuiDataGrid-colCellWrapper {
      .MuiDataGrid-colCell {
        border-color: ${({ theme }) => theme.kinetic.palette.rgb.gray100};
      }
      .MuiDataGrid-cellLeft {
        background-color: inherit;
        border-color: inherit;
      }
    }
  }

  .MuiDataGrid-window {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .MuiDataGrid-columnHeaders {
    .MuiDataGrid-columnHeader {
      background: ${({ theme }) => theme.kinetic.palette.rgb.gray200};
    }
  }

  .MuiDataGrid-row,
  .MuiDataGrid-cell {
    border-color: ${({ theme }) => theme.kinetic.palette.rgb.gray100};
    background-color: ${({ theme }) => theme.kinetic.palette.rgb.white};
  }

  .MuiDataGrid-cell {
    &.dataGrid-error {
      background-color: ${({ theme }) => theme.kinetic.palette.rgb.negative100};
    }
    &.dataGrid-warn {
      background-color: ${({ theme }) => theme.kinetic.palette.rgb.warning100};
    }
    &.dataGrid-info {
      background-color: ${({ theme }) => theme.kinetic.palette.rgb.blue100};
    }
    &.dataGrid-longColumn {
      white-space: normal;
      word-wrap: break-word;
      line-height: normal !important;
      overflow: auto;
      display: flex;
      align-items: center;
    }
  }
  .MuiDataGrid-footer {
    border: none;
    justify-content: flex-start;
  }
`;
