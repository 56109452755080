import React, { RefObject } from "react";
import {
  RecordButton,
  CountDown,
  Wrapper,
  TextArea,
  CameraIcon,
  CameraButton,
  FlipCameraIcon,
  LoadingWrapper,
  ActionsArea,
  CenterMenuWrapper,
  LeftMenuWrapper,
  RightMenuWrapper,
  MenuOption,
  Backdrop,
  Time,
  RedDot,
  RecordWrapper,
  AspectRatioIcon,
  TimeMobile,
  InfinityMirrorWarning,
  InfinityMirrorWarningWrapper,
} from "./styles";
import { CircularProgress } from "../CircularProgress";
import { Tooltip } from "../Tooltip";
import { secondsToTime } from "../../../utils/time";
import { OptionsMenu, OptionType } from "./components/OptionsMenu";
import {
  Effect,
  EffectType,
  VisualEffectsModal,
} from "./components/VisualEffectsModal";
import { SelectedDevices } from "../AudioRecorder/components/SettingsModal";
import { SettingsModal } from "./components/SettingsModal";
import { Button } from "../Button";
import { Grid } from "../Grid";

export enum MenuOptionType {
  PRESENTATION = "presentation",
}

export enum ShootType {
  PHOTO,
  VIDEO,
}

export type Localizations = {
  takePhoto?: string;
  recordVideo?: string;
  audioInput?: string;
  videoInput?: string;
  cameraOn?: string;
  cameraOff?: string;
  micOn?: string;
  micOff?: string;
  backgrounds?: string;
  teleprompterOn?: string;
  teleprompterOff?: string;
  uploadFile?: string;
  settings?: string;
  none?: string;
  ok?: string;
  dismiss?: string;
  infinityMirrorWarning?: string;
};

type Props = {
  canvasRef: RefObject<HTMLCanvasElement>;
  videoDim: { width: number; height: number };
  isSettingsModalOpen: boolean;
  isVisualEffectsModalOpen: boolean;
  isPhotoEnabled: boolean;
  isVideoEnabled: boolean;
  isLoading: boolean;
  hasShootStarted?: ShootType;
  showInfinityMirrorWarning: boolean;
  showCountDown: boolean;
  availableDevices: MediaDeviceInfo[];
  selectedDevices: SelectedDevices;
  countDown: number;
  options: Record<
    | Exclude<
        OptionType,
        OptionType.BACKGROUNDS | OptionType.SETTINGS | OptionType.UPLOAD_FILE
      >
    | MenuOptionType,
    boolean
  >;
  seconds: number;
  localizations?: Localizations;
  onHideInfinityMirrorWarning: VoidFunction;
  onShootClick: (type: ShootType) => void;
  onToggleCameraClick: () => void;
  onOptionClick: (type: OptionType | MenuOptionType) => Promise<boolean>;
  onSettingsChange: (selectedDevices: SelectedDevices) => void;
  onEffectChange: (effect: Effect<EffectType>) => void;
};

export function WebcamView({
  canvasRef,
  videoDim,
  isSettingsModalOpen,
  isVisualEffectsModalOpen,
  isPhotoEnabled,
  isVideoEnabled,
  isLoading,
  hasShootStarted,
  showInfinityMirrorWarning,
  showCountDown,
  availableDevices,
  selectedDevices,
  countDown,
  options,
  seconds,
  localizations,
  onHideInfinityMirrorWarning,
  onShootClick,
  onToggleCameraClick,
  onOptionClick,
  onSettingsChange,
  onEffectChange,
}: Props) {
  if (isLoading) {
    return (
      <LoadingWrapper>
        <CircularProgress color="theme" />
      </LoadingWrapper>
    );
  }

  return (
    <>
      {isSettingsModalOpen && (
        <SettingsModal
          localizations={localizations}
          showAudioInput={isVideoEnabled}
          hasShootStarted={!!hasShootStarted}
          availableDevices={availableDevices}
          selectedDevices={selectedDevices}
          onInputChange={(selectedDevices) => onSettingsChange(selectedDevices)}
          onAcceptClick={() => onOptionClick(OptionType.SETTINGS)}
        />
      )}
      <VisualEffectsModal
        localizations={localizations}
        isOpen={isVisualEffectsModalOpen}
        onEffectChange={onEffectChange}
        onAcceptClick={() => onOptionClick(OptionType.BACKGROUNDS)}
      />
      <Wrapper>
        {showInfinityMirrorWarning && (
          <InfinityMirrorWarningWrapper container spacing={2}>
            <Grid item xs={10}>
              <InfinityMirrorWarning>
                {localizations?.infinityMirrorWarning ||
                  "Helpful Tip: Try sharing only a tab or a different window to avoid an infinity mirror"}
              </InfinityMirrorWarning>
            </Grid>
            <Grid item xs={2}>
              <Button
                type="button"
                color="utility"
                onClick={onHideInfinityMirrorWarning}
                text={localizations?.dismiss || "Dismiss"}
                align="center"
              />
            </Grid>
          </InfinityMirrorWarningWrapper>
        )}
        <canvas
          ref={canvasRef}
          width={videoDim.width}
          height={videoDim.height}
        />
        {isVideoEnabled && (
          <TimeMobile>
            {hasShootStarted && !showCountDown && <RedDot />}
            {secondsToTime(seconds)}
          </TimeMobile>
        )}
        {options.prompter && <TextArea autoFocus />}
        {showCountDown && hasShootStarted === ShootType.VIDEO && <Backdrop />}
        <ActionsArea>
          <LeftMenuWrapper />
          <CenterMenuWrapper>
            {isVideoEnabled && (
              <RecordWrapper>
                <Tooltip
                  title={localizations?.recordVideo ?? "Record video"}
                  placement="top"
                >
                  <RecordButton
                    type="button"
                    disabled={hasShootStarted === ShootType.PHOTO}
                    $showStop={
                      hasShootStarted === ShootType.VIDEO && !showCountDown
                    }
                    $hideBackground={showCountDown}
                    onClick={() => onShootClick(ShootType.VIDEO)}
                  >
                    {showCountDown && <CountDown>{countDown}</CountDown>}
                  </RecordButton>
                </Tooltip>
                <Time>
                  {hasShootStarted && !showCountDown && <RedDot />}
                  {secondsToTime(seconds)}
                </Time>
              </RecordWrapper>
            )}
            {isPhotoEnabled && (
              <RecordWrapper>
                <Tooltip
                  title={localizations?.takePhoto ?? "Take photo"}
                  placement="top"
                >
                  <CameraButton
                    type="button"
                    $hideBackground={showCountDown}
                    disabled={
                      hasShootStarted === ShootType.VIDEO ||
                      hasShootStarted === ShootType.PHOTO
                    }
                    onClick={() => onShootClick(ShootType.PHOTO)}
                  >
                    {showCountDown ? (
                      <CountDown>{countDown}</CountDown>
                    ) : options.presentation ? (
                      <AspectRatioIcon />
                    ) : (
                      <CameraIcon />
                    )}
                  </CameraButton>
                </Tooltip>
              </RecordWrapper>
            )}
          </CenterMenuWrapper>
          <RightMenuWrapper>
            <OptionsMenu
              localizations={localizations}
              showMicIcon={isVideoEnabled}
              showTelePrompterIcon={isVideoEnabled}
              isCameraEnabled={options.camera}
              isMicEnabled={options.mic}
              isPrompterEnabled={options.prompter}
              onOptionClick={onOptionClick}
            />
            <MenuOption
              color="dark"
              icon={<FlipCameraIcon />}
              onClick={onToggleCameraClick}
            />
          </RightMenuWrapper>
        </ActionsArea>
      </Wrapper>
    </>
  );
}
