import {
  Radio as StyledRadio,
  FormControlLabel as StyledFormControlLabel,
} from "./styles";
import React from "react";
import { FormControlLabelProps } from "@mui/material/FormControlLabel";
import { RadioProps as RadioPropsBase } from "@mui/material/Radio";

export type RadioProps = Omit<FormControlLabelProps, "control"> & {
  inputProps?: RadioPropsBase;
};

export function Radio({ inputProps, ...props }: RadioProps) {
  return (
    <StyledFormControlLabel
      {...props}
      control={<StyledRadio {...inputProps} />}
    />
  );
}
