import React, {
  MouseEvent,
  ReactElement,
  useState,
  cloneElement,
  useRef,
  useEffect,
} from "react";
import { MenuProps } from "@mui/material/Menu";
import { HeaderMenuItem, Menu as StyledMenu, MenuItem, Color } from "./styles";
import { CheckIcon } from "../../../../icons";
import { TextField } from "../TextField";

type OptionBase = Record<string, any>;

type FunctionElement = ({
  selectedOption,
  isOpen,
}: {
  selectedOption: string | number;
  isOpen: boolean;
}) => ReactElement;

export type FloatMenuProps<Option extends OptionBase> = Omit<
  MenuProps,
  "onChange" | "open" | "defaultValue"
> & {
  color?: Color;
  defaultValue?: string | number;
  optionsHeader?: ReactElement;
  options: Option[];
  element: ReactElement | FunctionElement;
  anchorOrigin?: MenuProps["anchorOrigin"];
  transformOrigin?: MenuProps["transformOrigin"];
  showFilter?: boolean;
  getOptionLabel: (option: Option) => string;
  getOptionValue: (option: Option) => string | number;
  onChange: (item: any) => void;
};

export function FloatMenu<Option extends OptionBase>({
  color,
  defaultValue,
  optionsHeader,
  options,
  element,
  anchorOrigin,
  transformOrigin,
  showFilter,
  getOptionLabel,
  getOptionValue,
  onChange,
}: FloatMenuProps<Option>) {
  const [filter, setFilter] = useState("");
  const filterInputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState(
    defaultValue ?? getOptionValue(options[0])
  );

  const handleClickListItem = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (option: Option) => {
    onChange?.(option);

    setSelectedOption(getOptionValue(option));

    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilter("");
  };

  useEffect(() => {
    if (filterInputRef) {
      filterInputRef.current?.focus();
    }
  }, [filter]);

  return (
    <div>
      {cloneElement(
        typeof element === "function"
          ? element({ selectedOption, isOpen: !!anchorEl })
          : element,
        {
          onClick: handleClickListItem,
        }
      )}
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handleClose}
      >
        {optionsHeader && (
          <HeaderMenuItem onClick={() => setAnchorEl(null)}>
            {optionsHeader}
          </HeaderMenuItem>
        )}
        {showFilter && (
          <HeaderMenuItem
            selected={false}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <TextField
              type="text"
              value={filter}
              inputRef={filterInputRef}
              placeholder="Search..."
              onChange={(e) => {
                e.stopPropagation();
                setFilter(e.target.value);
              }}
              size="small"
            />
          </HeaderMenuItem>
        )}
        {options
          .filter((option) =>
            option.name.toLowerCase().includes(filter.toLowerCase())
          )
          .map((option) => (
            <MenuItem
              $color={color}
              key={getOptionLabel(option)}
              selected={getOptionValue(option) === selectedOption}
              onClick={() => handleMenuItemClick(option)}
              onKeyDown={(e) => {
                e.stopPropagation();
                filterInputRef.current?.focus();
              }}
            >
              {getOptionLabel(option)}
              {getOptionValue(option) === selectedOption && <CheckIcon />}
            </MenuItem>
          ))}
      </StyledMenu>
    </div>
  );
}
