import { styled } from "@mui/material/styles";
import RadioGroupMui, {
  RadioGroupProps as RadioGroupMuiProps,
} from "@mui/material/RadioGroup";
import { withTransientProps } from "../../../lib/emotion";

export interface StyledProps extends RadioGroupMuiProps {
  $direction?: React.CSSProperties["flexDirection"];
}

export const StyledRadioGroup = styled(
  RadioGroupMui,
  withTransientProps
)<StyledProps>`
  flex-direction: ${({ $direction = "column" }) => $direction};
`;
