import React, { useState, MouseEvent, ReactNode } from "react";
import {
  Input as StyledInput,
  FormHelperText as StyledFormHelperText,
  Wrapper,
} from "./styles";
import {
  WarningIcon,
  InputLabel as StyledInputLabel,
  StartInputAdornment as StyledStartInputAdornment,
  EndInputAdornment as StyledEndInputAdornment,
  ErrorMessageContainer,
} from "../InputAdornment/styles";
import { InputProps as InputPropsBase } from "@mui/material/Input";
import { FormErrorText } from "../form-utils";

export type InputProps = InputPropsBase & {
  id?: string;
  tabIndex?: number;
  label?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  valid?: boolean;
  helperText?: string;
  errors?: string[] | string;
  minHeight?: number;
};

function StartAdornment({ icon }: { icon: ReactNode }) {
  return (
    <StyledStartInputAdornment position="start">
      {icon}
    </StyledStartInputAdornment>
  );
}

function EndAdornment({ icon }: { icon: ReactNode }) {
  return (
    <StyledEndInputAdornment position="end">{icon}</StyledEndInputAdornment>
  );
}

// TODO: Evalutate if we should delete this generic input and create specific ones (e.g: number, checkbox, etc)
// or use just this Input component.
export function Input({
  id,
  name,
  disabled,
  label,
  placeholder,
  startIcon,
  endIcon,
  minHeight,
  onClick: _onClick,
  valid = true,
  errors,
  tabIndex,
  helperText,
  ...props
}: InputProps) {
  const [visited, setVisited] = useState(false);

  const onClick = (e: MouseEvent<HTMLInputElement>) => {
    setVisited(true);
    _onClick?.(e);
  };

  return (
    <Wrapper $minHeight={minHeight}>
      {label && <StyledInputLabel id={id || name}>{label}</StyledInputLabel>}
      <StyledInput
        id={id}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        className={visited ? "Input--visited" : ""}
        tabIndex={disabled ? -1 : tabIndex}
        error={!valid}
        startAdornment={startIcon && <StartAdornment icon={startIcon} />}
        endAdornment={endIcon && <EndAdornment icon={endIcon} />}
        onClick={onClick}
        {...props}
        inputProps={{
          tabIndex: disabled ? -1 : tabIndex,
          ...props.inputProps,
        }}
      />
      {!valid && errors && (
        <ErrorMessageContainer>
          <WarningIcon />{" "}
          <FormErrorText
            id={id && !valid ? `${id}-error` : undefined}
            errors={errors}
          />
        </ErrorMessageContainer>
      )}
      {helperText && (
        <StyledFormHelperText
          $valid={valid}
          id={id ? `${id}-helper` : undefined}
        >
          {helperText}
        </StyledFormHelperText>
      )}
    </Wrapper>
  );
}
