import { SC } from "@bigspring/core-components";

export const Wrapper = SC.styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const LoadingWrapper = SC.styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  height: 100dvh;
`;
