import React from "react";

type Props = {
  children: React.ReactNode;
  justify?:
    | "start"
    | "end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  align?: "start" | "end" | "center" | "stretch" | "baseline";
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
  wrap?: "nowrap" | "wrap" | "wrap-reverse";
  spacing?: number | string;
  styles?: React.CSSProperties;
};

// Material-UI v4 doesn't have flex component
// Developing with just lots of CC.Grid is cumbersome and verbose
// Piloting this component to see if it's useful
export function Flex({
  children,
  align = "start",
  direction = "row",
  justify = "start",
  spacing = 0,
  wrap = "nowrap",
  styles = {},
}: Props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: justify,
        gap: spacing,
        flexWrap: wrap,
        flexDirection: direction,
        alignItems: align,
        ...styles,
      }}
    >
      {children}
    </div>
  );
}
