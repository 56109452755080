import { styled } from "@mui/material/styles";
import { registerComponent, ComponentProps } from "./registerComponent";
import { CircularProgress } from "../../CircularProgress";

const LoadingSpinner = styled(CircularProgress)<ComponentProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

registerComponent(LoadingSpinner, "LoadingSpinner");
