import React from "react";
import { useVideoPlayer } from "./useVideoPlayer";
import { Root, RootFullViewport, Container, Wrapper } from "./styles";
import { Props } from "./types";

/**
 * BigSpring Video Player, wraps Video.js
 * Version 2.1.0
 * @param props
 */
export function VideoPlayer(props: Props) {
  const { id, isVisible, containerRef, videoRef, handleClick } =
    useVideoPlayer(props);

  const {
    isThumbnail = false,
    isFullViewport = false,
    keepAspectRatio = false,
    backgroundColor = "#000",
    className,
    disableFullScreenButton,
  } = props;

  const RootComponent =
    isFullViewport && !keepAspectRatio ? RootFullViewport : Root;

  return (
    <RootComponent
      $keepAspectRatio={keepAspectRatio}
      $isThumbnail={isThumbnail}
      $backgroundColor={backgroundColor}
      className={className}
      onClick={handleClick}
    >
      <Container
        $disableFullScreenButton={disableFullScreenButton}
        $isVisible={isVisible}
        $isThumbnail={isThumbnail}
        $keepAspectRatio={keepAspectRatio}
        $backgroundColor={backgroundColor}
        ref={containerRef}
      >
        <Wrapper data-vjs-player>
          <video
            controlsList={disableFullScreenButton ? "nofullscreen" : undefined}
            id={id}
            ref={videoRef}
            className="video-js"
            crossOrigin="anonymous"
            playsInline
          />
        </Wrapper>
      </Container>
    </RootComponent>
  );
}
