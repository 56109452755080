import { css, styled } from "@mui/material/styles";
import { Button as BaseButton } from "../Button";

export const Root = styled("div")`
  height: 100%;
`;

export const NavBarContainer = styled("div")`
  background-color: white;
  width: 100%;
  border-top: 1px solid
    ${({ theme }) => theme.kinetic.v3.palette.chroma.neutraltint[300].css()};
  display: flex;
  justify-content: center;
`;

export const NavBarContentWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline: 40px;
  min-width: 600px;
  padding-block: 12px;

  @media (max-width: 680px) {
    min-width: fill-available;
  }

  @media (max-width: 576px) {
    margin-inline: 20px;
    padding-block: 12px;
  }
`;

// CARDS
const getScaledWidth = (marginX: number, marginY: number) => {
  const width = (vUnitPrefix = "") => `
    clamp(113px, calc(100${vUnitPrefix}vw - ${
    marginX * 2
  }px), min(calc(66.67${vUnitPrefix}vh - ${marginY * 2}px), 720px))
  `;

  return css`
    width: ${width()};
    width: ${width("d")};
  `;
};

export const Carousel = styled("ol")`
  width: 100%;
  height: calc(100% - 70px);
  display: grid;
  grid-auto-flow: column;
  column-gap: 100vw;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
  position: relative;
  scroll-snap-type: x mandatory;
  scroll-behavior: auto;

  @supports (-webkit-touch-callout: none) {
    overflow-y: hidden;
  }
  @supports not (-webkit-touch-callout: none) {
    overflow-y: auto;
  }
`;

Carousel.defaultProps = {
  "aria-live": "polite",
};

type CardWrapperProps = { $isBefore: boolean; $isAfter: boolean };

export const CardWrapper = styled("li")<CardWrapperProps>`
  width: 100%;
  height: 100%;
  /* overflow-y: auto; */

  &:first-of-type {
    margin-left: 50vw;
    margin-left: 50dvw;
  }

  &:last-of-type {
    margin-right: 50vw;
    margin-right: 50dvw;
  }

  @supports (-webkit-touch-callout: none) {
    overflow-y: auto;
    overflow-x: hidden;
  }

  ${({ theme }) => theme.breakpoint.maxWidth.lg`
    ${getScaledWidth(20, 20)}
  `};
`;

// NAVIGATION
type NavigationButtonProps = {
  $isVisible?: boolean;
};

const NavigationButton = styled(BaseButton)<NavigationButtonProps>`
  width: 44px;
  height: 44px;

  background-color: ${({ theme }) =>
    theme.kinetic.v3.palette.chroma.white.css()};
  border: 1px solid
    ${({ theme }) => theme.kinetic.v3.palette.chroma.neutraltint[300].css()} !important;
  border-radius: 50%;
  box-shadow: none;

  &:hover {
    background-color: ${({ theme }) =>
      theme.kinetic.v3.palette.chroma.white.css()};
    box-shadow: none !important;
  }

  svg {
    fill: ${({ theme }) =>
      theme.kinetic.v3.palette.chroma.neutraltint[900].css()};
  }

  &:disabled {
    background: ${({ theme }) =>
      theme.kinetic.v3.palette.chroma.neutraltint[200].css()} !important;
    box-shadow: none;

    svg {
      fill: ${({ theme }) =>
        theme.kinetic.v3.palette.chroma.neutraltint[500].css()} !important;
    }
  }
`;

export const LeftNavigationButton = styled(NavigationButton)`
  visibility: ${({ $isVisible }) => ($isVisible ? "visible" : "hidden")};
  box-shadow: none;
`;

export const RightNavigationButton = styled(LeftNavigationButton)`
  animation: blink 900ms ease infinite;
  &:disabled {
    animation: none;
  }
  background-color: ${({ theme }) =>
    theme.kinetic.v3.palette.chroma.primarybaseline.css()};
  &:hover {
    background-color: ${({ theme }) =>
      theme.kinetic.v3.palette.chroma.primarybaseline.css()};
  }

  svg {
    fill: ${({ theme }) => theme.kinetic.v3.palette.chroma.white.css()};
  }

  @keyframes blink {
    0% {
      box-shadow: 0 0 0
        ${({ theme }) => theme.kinetic.v3.palette.chroma.primarybaseline.css()};
    }
    50% {
      box-shadow: 0 0 14px
        ${({ theme }) => theme.kinetic.v3.palette.chroma.primarybaseline.css()};
    }
    100% {
      box-shadow: 0 0 0
        ${({ theme }) => theme.kinetic.v3.palette.chroma.primarybaseline.css()};
    }
  }
`;

type ProgressProps = { $progress: number };

export const ProgressTrack = styled("div")`
  border-radius: ${({ theme }) => theme.kinetic.borderRadius.sm};
  border: 1px solid
    ${({ theme }) => theme.kinetic.v3.palette.chroma.neutraltint[300].css()};
  height: 8px;
  width: 100%;
  background: ${({ theme }) =>
    theme.kinetic.v3.palette.chroma.neutraltint[100].css()};
  position: relative;
  margin-inline: 20px;
  box-sizing: content-box;
`;

export const ProgressBar = styled("div")<ProgressProps>`
  border-radius: ${({ theme }) => theme.kinetic.borderRadius.sm};
  height: 8px;
  width: ${({ $progress }) => 100 * $progress}%;
  min-width: 8px;
  max-width: 100%;
  background: ${({ theme }) =>
    theme.kinetic.v3.palette.chroma.positivebaseline.css()};
  transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1);
`;

ProgressBar.defaultProps = {
  role: "progressbar",
  "aria-label": "Progress",
  "aria-valuenow": 0,
};
