import { styled } from "@mui/material/styles";
import {
  MoreVertIcon,
  TextFieldsIcon,
  FormatClearIcon,
  MagicIcon as MagicIconMui,
  MicOnIcon as MicOnIconMui,
  MicOffIcon as MicOffIconMui,
  SettingsIcon2,
  VideoCamIcon as VideoCamIconMui,
  VideoCamOffIcon as VideoCamOffIconMui,
} from "../../../../../../icons";
import { Icon } from "../../styles";

export const Menu = styled("div")<{
  $isOpen?: boolean;
}>`
  position: absolute;
  bottom: 30px;
  left: -3px;
  display: flex;
  visibility: ${({ $isOpen }) => ($isOpen ? "visible" : "hidden")};
  flex-direction: column;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  z-index: 1;
  padding-bottom: 20px;
  transition: opacity 250ms ease, bottom 150ms ease;
  ${({ theme }) => theme.breakpoint.maxWidth.small`
    left: -9px;
  `}
`;

export const Wrapper = styled("div")`
  position: relative;
  z-index: 10;
`;

export const OptionWrapper = styled("div")`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(38 38 38 / 75%);
  transition: background-color 200ms ease;
  display: flex;
  justify-content: center;
  align-items: center;

  & + & {
    margin-top: 12px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiDark};
  }
`;

export const MoreIcon = styled(MoreVertIcon)`
  opacity: 0.7;
  ${Icon};
`;

export const MagicIcon = styled(MagicIconMui)`
  ${Icon};
`;

export const TextOnIcon = styled(TextFieldsIcon)`
  ${Icon};
`;

export const TextOffIcon = styled(FormatClearIcon)`
  ${Icon};
  fill: rgba(252, 89, 89, 1);
`;

export const MicOnIcon = styled(MicOnIconMui)`
  ${Icon};
`;

export const MicOffIcon = styled(MicOffIconMui)`
  ${Icon};
  fill: rgba(252, 89, 89, 1);
`;

export const VideoCamIcon = styled(VideoCamIconMui)`
  ${Icon};
`;

export const VideoCamOffIcon = styled(VideoCamOffIconMui)`
  ${Icon};
  fill: rgba(252, 89, 89, 1);
`;

export const SettingsIcon = styled(SettingsIcon2)`
  ${Icon};
`;
