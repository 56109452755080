import type videojs from "video.js";

const seekTo = (player: videojs.Player, time: number) => {
  player.trigger("seekstart");
  player.currentTime(time);
};

/**
 * Seek to a percentage of the video's duration
 *
 * @param player Video.js player
 * @param percent duration percentage to seek to (0-1)
 * @returns void
 */
export const seekToPercent = (player: videojs.Player, percent: number) => {
  const duration = player.duration();
  const time = duration * percent;
  seekTo(player, time);
};

/**
 * Seek forward or backward by a number of seconds
 *
 * @param player Video.js player
 * @param delta seconds to seek by (positive or negative)
 * @returns void
 */
export const seekToDiff = (player: videojs.Player, delta: number) => {
  const currentTime = player.currentTime();
  const duration = player.duration();
  const time = currentTime + delta;
  const boundedTime = Math.min(Math.max(time, 0), duration);
  seekTo(player, boundedTime);
};
