import "videojs-mux";
import { GLOBALS } from "../../../../../lib/segment";
import type { Props } from "../../types";

/**
 * Get Mux Data tracking metadata
 * @param props
 * @returns Mux tracking metadata object
 * @see https://docs.mux.com/guides/data/make-your-data-actionable-with-metadata
 */
export const getMuxMetadata = ({ metadata }: Props) => ({
  env_key: metadata?.envKey || "q1ov6700lb1qapln134bbinqu", // fallback to local env key
  video_id: metadata?.videoId,
  video_title: metadata?.videoTitle,
  viewer_user_id: metadata?.userId,
  experiment_name: metadata?.experimentName,
  page_type: metadata?.pageType || "watchpage",
  player_init_time: metadata?.playerInitTime || Date.now(),
  player_name: metadata?.playerName || "BigSpring Video",
  player_version:
    metadata?.playerVersion ||
    process.env.NEXT_PUBLIC_VERSION ||
    process.env.VERSION ||
    process.env.GIT_TAG_NAME,
  sub_property_id: metadata?.subPropertyId,
  video_cdn: metadata?.videoCdn,
  video_content_type: metadata?.videoContentType || "movie",
  video_language_code: metadata?.videoLanguageCode?.replace("_", "-"),
  video_producer: metadata?.videoProducer,
  video_series: metadata?.videoSeries,
  video_stream_type: metadata?.videoStreamType || "on-demand",
  video_encoding_variant: metadata?.videoEncodingVariant,
  video_variant_name: metadata?.videoVariantName,
  video_variant_id: metadata?.videoVariantId,
  view_session_id: metadata?.viewSessionId || GLOBALS.sessionId,
});
