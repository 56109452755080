import React, { useState, ReactNode, MouseEventHandler, useId } from "react";
import { Dialog, DialogHeader } from "../dialog";
import { Thumbnail as StyledThumbnail, DialogContent } from "./styles";

type ChildProps = {
  children: ReactNode;
};

type ModalProps = ChildProps & {
  title?: ReactNode;
  onOpen?: MouseEventHandler<HTMLDivElement>;
  onClose?: MouseEventHandler<HTMLDivElement>;
};

type ContentProps = {
  children?: ReactNode;
  thumbnail?: { display?: string } & Partial<ChildProps>;
  modal?: Partial<ModalProps>;
};

export type ExpandableProps = ContentProps & {
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  className?: string;
  fullCard?: boolean;
  isOpen?: boolean;
  display?: string;
};

/**
 * Renders a thumbnail that opens a modal when clicked.
 */
export function Expandable({
  children,
  thumbnail,
  modal,
  fullCard,
  maxWidth = "lg",
  className,
  isOpen,
}: ExpandableProps) {
  const modalTitle = modal?.title;
  const modalId = useId();
  const [isModalOpen, setIsModalOpen] = useState(isOpen ?? false);

  const openModal: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalOpen(true);
    modal?.onOpen?.(e);
  };

  const closeModal: MouseEventHandler<HTMLDivElement> = (e) => {
    setIsModalOpen(false);
    modal?.onClose?.(e);
  };

  return (
    <>
      <StyledThumbnail
        fullCard={fullCard}
        onClick={openModal}
        className={className}
        display={thumbnail?.display}
      >
        {thumbnail?.children ?? children}
      </StyledThumbnail>
      <Dialog
        open={isOpen ?? isModalOpen}
        maxWidth={maxWidth}
        fullScreen
        aria-labelledby={modalTitle ? modalId : undefined}
        onClose={closeModal}
      >
        <DialogHeader id={modalId} onClose={closeModal}>
          {modalTitle}
        </DialogHeader>
        <DialogContent>{modal?.children ?? children}</DialogContent>
      </Dialog>
    </>
  );
}
