import React, { forwardRef } from "react";
import { AspectRatio } from "../../types";
import { Image as StyledImage } from "./styles";

export type Props = {
  imageStyles: {
    aspectRatio?: AspectRatio;
    objectFit?: string;
  };
  isFill?: boolean;
  src: string;
  title?: string;
  onFullscreenChange?: (isFullscreen: boolean) => void;
  onLoad?: () => void;
};

export const Image = forwardRef<HTMLImageElement, Props>(
  ({ src, title }, ref) => (
    <StyledImage ref={ref} src={src} alt={title || "Image "} $isFullscreen />
  )
);

Image.displayName = "CardImage";
