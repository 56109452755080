import { Select as StyledSelect, MenuItem as StyledMenuItem } from "./styles";
import { FormLabel, FormErrorText } from "../form-utils";
import React, { useEffect, useState } from "react";
import { SelectProps as SelectPropsBase } from "@mui/material/Select";

type ValidTypes =
  | string
  | number
  | (Record<string, any> & { disabled?: boolean });

type GetOptionValue<T extends ValidTypes> = (
  option: T
) => T extends object ? T[keyof T] : T;

export type SelectProps<T extends ValidTypes> = Omit<
  SelectPropsBase,
  "onChange" | "value" | "variant"
> & {
  options: T[];
  errors?: string[] | string;
  value?: ReturnType<GetOptionValue<T>>;
  getOptionLabel: (option: T) => T extends object ? T[keyof T] : T;
  getOptionValue?: GetOptionValue<T>;
  onChange?: (value: ReturnType<GetOptionValue<T>>) => void;
  keyFn?: (option: T) => string;
};

function defaultGetter<T extends ValidTypes>(option: T) {
  if (typeof option === "object") {
    if ("value" in option) {
      return option.value;
    }

    throw new Error('Options of type object must have a "value" prop');
  }

  return option;
}

export function Select<T extends ValidTypes>({
  options,
  getOptionLabel = defaultGetter,
  getOptionValue = defaultGetter,
  placeholder,
  label,
  errors,
  inputRef,
  onChange: _onChange,
  value: _value,
  keyFn,
  ...props
}: SelectProps<T>) {
  const [value, setValue] = useState(_value ?? "");

  const onChange: SelectPropsBase["onChange"] = (event) => {
    const newValue = event.target.value as ReturnType<GetOptionValue<T>>;

    setValue(newValue);

    _onChange?.(newValue);
  };

  useEffect(() => {
    setValue(_value ?? "");
  }, [_value]);

  return (
    <>
      {label && <FormLabel label={label} />}
      <StyledSelect
        inputRef={inputRef}
        value={value}
        displayEmpty
        className={
          placeholder && value === ""
            ? `placeholder--selected ${props.className}`
            : props.className
        }
        onChange={onChange}
        {...props}
      >
        {placeholder && (
          <StyledMenuItem key="placeholder" value="" disabled>
            {placeholder}
          </StyledMenuItem>
        )}
        {options.map((option) => (
          <StyledMenuItem
            key={keyFn ? keyFn(option) : getOptionLabel(option)}
            value={getOptionValue(option)}
            disabled={typeof option === "object" ? option.disabled : undefined}
          >
            {getOptionLabel(option)}
          </StyledMenuItem>
        ))}
      </StyledSelect>
      {errors && <FormErrorText errors={errors} />}
    </>
  );
}
