import { css, styled } from "@mui/material/styles";

export const Image = styled("img")<{
  $isFullscreen?: boolean;
  $isFill?: boolean;
  $objectFit?: string;
}>`
  user-select: none;
  user-drag: none;

  ${({ $isFullscreen, $isFill, $objectFit, theme }) =>
    !$isFullscreen &&
    css`
      height ${$isFill ? "100%" : "initial"};
      /* width: 100%; */
      max-width: 600px !important;
      object-fit: ${$objectFit ?? "cover"};
      background-color: ${theme.kinetic.v3.palette.chroma.neutraltint[200].css()};
      border-radius: 16px;
    `}

  @media (max-width: 599px) {
    max-width: 100% !important;
  }
`;
