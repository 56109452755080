import { useState } from "react";

export function useAccordion<T>(openedTabs?: T[]) {
  const [tabs, setOpenedTabs] = useState(openedTabs || []);

  function isOpen(tab: T) {
    return tabs.includes(tab);
  }

  function toggleTab(tab: T) {
    const openedTabs = [...tabs];

    if (openedTabs.includes(tab)) {
      openedTabs.splice(openedTabs.indexOf(tab), 1);
    } else {
      openedTabs.push(tab);
    }

    setOpenedTabs(openedTabs);
  }

  return {
    isOpen,
    toggleTab,
  };
}
