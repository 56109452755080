import React, { RefObject, useRef } from "react";
import { Button } from "../../../Button";
import { BSPBody1 } from "../../../BSPBody1";
import { HiddenInput, Title, Wrapper } from "./styles";

type Props = {
  wrapperRef: RefObject<HTMLDivElement>;
  isVideoEnabled: boolean;
  isPhotoEnabled: boolean;
  message: string;
  onFileUpload: (file: File) => void;
};

export function ErrorView({
  wrapperRef,
  isVideoEnabled,
  isPhotoEnabled,
  message,
  onFileUpload,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const buttonText = ["Upload a"];
  const accept = [];

  if (isPhotoEnabled) {
    buttonText.push("photo");
    accept.push("image/*");
  }

  if (isPhotoEnabled && isVideoEnabled) {
    buttonText.push("or a");
  }

  if (isVideoEnabled) {
    buttonText.push("video");
    accept.push("video/*");
  }

  return (
    <Wrapper ref={wrapperRef}>
      <Title>{message}</Title>
      <br />
      <BSPBody1>You can still upload a file</BSPBody1>
      <br />
      <div>
        <HiddenInput
          ref={inputRef}
          type="file"
          accept={accept.join(",")}
          onChange={(event) => onFileUpload(event.target.files?.[0] as File)}
        />
        <Button type="button" onClick={() => inputRef.current?.click()}>
          {buttonText.join(" ")}
        </Button>
      </div>
    </Wrapper>
  );
}
