import { css, styled } from "@mui/material/styles";
import { BSPSubtext } from "../BSPSubtext";

export const Badge = styled(BSPSubtext)<{
  color?: "negative" | "positive" | "neutral";
  $uppercase?: boolean;
}>`
  padding: ${({ theme }) =>
    `${theme.kinetic.paddings[2]} ${theme.kinetic.paddings[4]}`};
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.white};
  border-radius: ${({ theme }) => theme.kinetic.borderRadius.sm};
  text-transform: ${({ $uppercase }) =>
    $uppercase || typeof $uppercase === "undefined" ? "uppercase" : "none"};
  font-weight: 500;
  cursor: inherit;
  user-select: none;

  ${({ color, theme }) => {
    switch (color) {
      case "neutral":
        return css`
          background-color: ${theme.kinetic.v2.palette.rgb.grayTextLight};
        `;
      case "positive":
        return css`
          background-color: ${theme.kinetic.v2.palette.rgb.positive};
        `;
      default:
        return css`
          background-color: ${theme.kinetic.v2.palette.rgb.negative};
        `;
    }
  }}
`;

export const BadgeV2 = styled(BSPSubtext)<{
  color?: "negative" | "positive" | "neutral";
  $uppercase?: boolean;
}>`
  padding: 3px 8px;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.white};
  border-radius: ${({ theme }) => theme.kinetic.borderRadius.sm};
  text-transform: ${({ $uppercase }) =>
    $uppercase || typeof $uppercase === "undefined" ? "uppercase" : "none"};
  font-weight: 600;
  font-size: "10px";
  line-height: "18px";
  cursor: inherit;
  user-select: none;

  ${({ color, theme }) => {
    switch (color) {
      case "neutral":
        return css`
          background-color: ${theme.kinetic.v3.palette.chroma.neutraltint[300].css()};
        `;
      case "positive":
        return css`
          background-color: ${theme.kinetic.v3.palette.chroma.positivebaseline.css()};
        `;
      default:
        return css`
          background-color: ${theme.kinetic.v2.palette.rgb.negative};
        `;
    }
  }}
`;
