import React from "react";
import {
  AutocompleteProps,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import { TextField, TextFieldProps } from "../TextField";
import { Autocomplete } from "./styles";
import { AutocompleteValue } from "@mui/material/useAutocomplete";

export { createFilterOptions };

export type { AutocompleteValue };
export type ComboBoxProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  "renderInput"
> & {
  textFieldProps?: TextFieldProps;
};

export function ComboBox<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  textFieldProps,
  ...props
}: ComboBoxProps<T, Multiple, DisableClearable, FreeSolo>) {
  return (
    <Autocomplete
      {...(props as any)}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          InputProps={{
            ...params.InputProps,
            ...(textFieldProps ?? {})?.InputProps,
          }}
          inputProps={{
            ...(textFieldProps ?? {})?.inputProps,
            ...params.inputProps,
          }}
        />
      )}
    />
  );
}
