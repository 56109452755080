const VIDEOJS_CSS_URL = "//unpkg.com/@mux/videojs-kit@0.9.1/dist/index.css";
const VIDEOJS_CSS_ID = "videojs-css";

const getStyleTag = () => document.getElementById(VIDEOJS_CSS_ID);

/**
 * Append the external Video.js stylesheet to the document head, returning when loaded
 *
 * @returns Promise<any>
 */
export const appendVideojsStyles = async (): Promise<any> =>
  new Promise((resolve, reject) => {
    const styleTag = getStyleTag();
    if (styleTag) {
      return resolve(styleTag);
    }

    const link = document.createElement("link");
    link.id = VIDEOJS_CSS_ID;
    link.href = VIDEOJS_CSS_URL;
    link.rel = "stylesheet";
    link.onload = resolve;
    link.onerror = reject;
    document.head.append(link);
  });

/**
 * Remove the Video.js stylesheets from the document head
 *
 * @returns void
 */
export const removeVideojsStyles = () => {
  getStyleTag()?.remove();
  document.getElementsByClassName("vjs-styles-defaults")?.[0]?.remove();
};
