import React from "react";
import { BaseCard, CardProps } from "../BaseCard";
import { Description } from "../../components/Description";
import { ImageWthSkeleton } from "../../components/ImageWthSkeleton";
import { Title } from "../../components/Title";
import { AlignedContent } from "../../styles";
import { AspectRatio, Position } from "../../types";
import { FlashCardContainer } from "./styles";

type ExperimentalConfig = {
  imagePosition: "top" | "background" | "topSquare";
  overlayTextVerticalAlignment: Position;
  textJustification: "left" | "center" | "right";
  imageScaleType: "fit" | "crop";
  imageVisible: boolean;
  titleVisible: boolean;
  subtextVisible: boolean;
};

export type FlashCardProps = CardProps & {
  title?: string;
  description?: string;
  imageSrc: string;
  imageAspectRatio?: AspectRatio;
  experimentConfig?: ExperimentalConfig;
  onFullscreenChange?: (isFullscreen: boolean) => void;
};

const DEFAULT_EXPERIMENTAL_CONFIG: ExperimentalConfig = {
  imagePosition: "top",
  overlayTextVerticalAlignment: "center",
  textJustification: "left",
  imageScaleType: "fit",
  imageVisible: true,
  titleVisible: true,
  subtextVisible: true,
};

export function FlashCard({
  isActive,
  title,
  description,
  imageSrc,
  imageAspectRatio = "16 / 9",
  experimentConfig: _experimentConfig,
  onFullscreenChange,
  onCardActive,
}: FlashCardProps) {
  const experimentConfig = {
    ...DEFAULT_EXPERIMENTAL_CONFIG,
    ...(_experimentConfig ?? {}),
  };

  const backgroundImage =
    experimentConfig?.imagePosition === "background" &&
    experimentConfig?.imageVisible
      ? imageSrc
      : undefined;

  let aspectRatio = imageAspectRatio;
  if (experimentConfig?.imagePosition === "topSquare") {
    aspectRatio = "1 / 1";
  }

  let objectFit = "cover";
  if (experimentConfig?.imageScaleType === "fit") {
    objectFit = "contain";
  }

  return (
    <FlashCardContainer $isVerticalCard={backgroundImage}>
      <BaseCard
        backgroundImage={backgroundImage}
        imagePosition={objectFit}
        isActive={isActive}
        onCardActive={onCardActive}
      >
        {experimentConfig?.imageVisible && imageSrc && !backgroundImage && (
          <ImageWthSkeleton
            imageStyles={{
              aspectRatio,
              objectFit,
            }}
            onFullscreenChange={onFullscreenChange}
            src={imageSrc}
            title={title}
          />
        )}
        {((experimentConfig?.titleVisible && title) ||
          (experimentConfig?.subtextVisible && description)) && (
          <AlignedContent
            $align={experimentConfig?.textJustification}
            $verticalAlign="top"
            $hasOverlay={!!backgroundImage}
          >
            {experimentConfig?.titleVisible && title && (
              <Title text={title} isVerticalCard={backgroundImage} />
            )}
            {experimentConfig?.subtextVisible && description && (
              <Description
                text={description}
                isVerticalCard={backgroundImage}
              />
            )}
          </AlignedContent>
        )}
      </BaseCard>
    </FlashCardContainer>
  );
}
