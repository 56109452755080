import React from "react";
import { SkillTag as SkillTagContainer, SkillTagText } from "./styles";

export function SkillTag({ title }: { title: string }) {
  return (
    <SkillTagContainer>
      <SkillTagText>{title}</SkillTagText>
    </SkillTagContainer>
  );
}
