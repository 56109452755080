import React, {
  useEffect,
  ComponentPropsWithoutRef,
  PropsWithChildren,
} from "react";
import { ThemeColor } from "../../types";
import { Container, Content, AlignedContent } from "../../styles";
import { CircularProgress } from "../../../CircularProgress";

type ContainerProps = Omit<
  ComponentPropsWithoutRef<"div">,
  "title" | "role" | "aria-roledescription" | "onClick"
>;

export type CardProps = ContainerProps & {
  isLoading?: boolean;
  isActive?: boolean;
  themeColor?: ThemeColor;
  backgroundImage?: string;
  imagePosition?: string;
  onCardActive?: () => void;
};

export function BaseCard({
  children,
  isLoading,
  isActive,
  themeColor,
  backgroundImage,
  imagePosition,
  onCardActive,
}: PropsWithChildren<CardProps>) {
  // TODO: We need a better way to handle this and we likely don't need this effect,
  // we can just use the isActive property to call onCardActive from the card-stack component
  // avoiding unnecesary re-renders and other unexpected issues
  useEffect(() => {
    if (isActive) {
      onCardActive?.();
    }

    // Don't add onCardActive here, makes this effect to be triggered more than once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  // TODO: Remove this loading when all the requests are resolved before loading the card stack
  if (isLoading) {
    return (
      <Container>
        <Content>
          <AlignedContent $align="center">
            <CircularProgress />
          </AlignedContent>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Content
        $backgroundImageUrl={backgroundImage}
        $imagePosition={imagePosition}
        $themeColor={themeColor}
      >
        {children}
      </Content>
    </Container>
  );
}
