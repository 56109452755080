import { css, styled } from "@mui/material/styles";
import { Skeleton as BaseSkeleton } from "../../../Skeleton";
import { AspectRatio } from "../../types";

export const Skeleton = styled(BaseSkeleton)<{
  $aspectRatio?: AspectRatio;
}>`
  ${({ $aspectRatio }) =>
    css`
      position: relative;
      aspect-ratio: ${$aspectRatio};
      width: 100%;
      height: unset;
    `}
`;
