import { isSafari } from "../../../../../utils/userAgent";
import { getSources } from "./sources";
import { getMuxMetadata } from "./tracking";
import { handleHotkeys } from "./hotkeys";
import { deepMerge } from "../../../../../utils/merge";
import type { Props, VideoJsPlayerOptions } from "../../types";

const DEFAULT_OPTIONS: VideoJsPlayerOptions = {
  autoplay: false, // autoplay: true makes many browsers angry—use workarounds
  controls: true,
  responsive: true,
  fluid: false,
  fill: true,
  preload: "auto",
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  retryOnError: true, // not documented: https://github.com/videojs/video.js/pull/7038
  html5: {
    nativeTextTracks: isSafari,
  },
  controlBar: {
    volumePanel: false,
    currentTimeDisplay: false,
    timeDivider: false,
    pictureInPictureToggle: false,
    fullscreenToggle: true,
  },
  userActions: {
    hotkeys: handleHotkeys,
  },
};

/**
 * Get Video.js player options
 * @param props
 * @returns VideoJsPlayerOptions
 */
export const getOptions = (props: Props): VideoJsPlayerOptions => {
  const baseOptions = deepMerge({}, DEFAULT_OPTIONS, props.options);

  const autoplay = props.isThumbnail ? false : baseOptions.autoplay;

  const controls = props.isThumbnail
    ? false
    : props.options.controls ?? DEFAULT_OPTIONS.controls;

  const sources = getSources(props.options, props.isThumbnail);

  const disableDoubleClick = props.disableFullScreenButton ? false : true;

  const plugins: VideoJsPlayerOptions["plugins"] = { ...props.options.plugins };

  // Mux Data
  // https://docs.mux.com/guides/data/monitor-video-js#2-initialize-mux-data
  if (!props.isThumbnail) {
    plugins.mux = { debug: false, data: getMuxMetadata(props) };
  }

  const options = {
    ...baseOptions,
    autoplay,
    controls,
    fluid: props.keepAspectRatio ?? baseOptions.fluid,
    fill: !props.keepAspectRatio ?? baseOptions.fill,
    src: sources[0].src,
    sources,
    plugins,
    userActions: { doubleClick: disableDoubleClick },
  };

  return options;
};
