import { styled } from "@mui/material/styles";

export const RatingButtonGroup = styled("div")`
  width: 128px;
  height: 64px;
  border-radius: 32px;
  background: ${({ theme }) => theme.kinetic.v2.palette.rgba.grayTextDark(0.5)};
  display: flex;
  align-items: center;
  z-index: 3;
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
`;

export const RatingButton = styled("button")`
  height: 64px;
  width: 64px;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:first-of-type {
    border-top-left-radius: 64px;
    border-bottom-left-radius: 64px;
  }

  &:last-of-type {
    border-top-right-radius: 64px;
    border-bottom-right-radius: 64px;
  }

  svg {
    height: 32px;
    width: 32px;
    fill: ${({ theme }) => theme.kinetic.v2.palette.rgb.white};
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover,
  &:focus-visible {
    svg {
      fill: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiLight};
    }
  }

  &:active {
    svg {
      fill: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiDark};
    }
  }
`;

RatingButton.defaultProps = {
  type: "button",
};
