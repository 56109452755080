import React, { MouseEventHandler } from "react";
import {
  ThumbUpIcon,
  ThumbUpOutlineIcon,
  ThumbDownIcon,
  ThumbDownOutlineIcon,
} from "../../../../../../../../icons";
import { Rating } from "../../../../types";
import { RatingButtonGroup, RatingButton } from "./styles";

type Props = {
  isVisible: boolean;
  rating?: Rating;
  onClick?: (rating: Rating) => void;
};

export function RatingButtons({ isVisible, rating, onClick }: Props) {
  if (!isVisible) {
    return null;
  }

  const onClickLike: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onClick?.(rating === 1 ? null : 1);
  };

  const onClickDislike: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onClick?.(rating === 0 ? null : 0);
  };

  return (
    <RatingButtonGroup>
      <RatingButton
        title={rating === 1 ? "Remove like" : "Like"}
        onClick={onClickLike}
      >
        {rating === 1 ? <ThumbUpIcon /> : <ThumbUpOutlineIcon />}
      </RatingButton>
      <RatingButton
        title={rating === 0 ? "Remove dislike" : "Dislike"}
        onClick={onClickDislike}
      >
        {rating === 0 ? <ThumbDownIcon /> : <ThumbDownOutlineIcon />}
      </RatingButton>
    </RatingButtonGroup>
  );
}
