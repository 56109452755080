import videojs from "video.js";
import { VideoJsPlayer } from "../types";

// TODO: Evaluate if this is even used

// SeekBar patches
// Only patch prototypes when absolutely necessary — prefer to add/remove listeners

type SeekBar = {
  prototype: videojs.SeekBar;
  new (player: VideoJsPlayer, options?: videojs.SliderOptions): SeekBar;
};

const SeekBar = videojs.getComponent("SeekBar") as unknown as SeekBar;

/**
 * Patch the `getPercent` method to get `currentTime` from the cache while seeking.
 * Improves seeking performance, especially with Chrome.
 * @link https://github.com/videojs/video.js/issues/4460#issuecomment-312861657
 */
SeekBar.prototype.getPercent = function getPercent() {
  const player = this.player_ as VideoJsPlayer;

  const currentTime = player.scrubbing()
    ? player.getCache().currentTime
    : player.currentTime();

  const liveTracker = player.liveTracker;
  let percent: number;

  if (liveTracker?.isLive()) {
    if (liveTracker.atLiveEdge()) {
      percent = 1;
    } else {
      percent =
        (currentTime - liveTracker.seekableStart()) / liveTracker.liveWindow();
    }
  } else {
    percent = currentTime / player.duration();
  }

  return percent >= 1 ? 1 : percent;
};

/**
 * Patch the `mousemove` handler to prevent seeking past `maxSeekTime` when it is set.
 */
SeekBar.prototype.handleMouseMove = function handleMouseMove(event) {
  const player = this.player_ as VideoJsPlayer;

  let newTime = this.calculateDistance(event) * player.duration();
  if (newTime === player.duration()) {
    newTime = newTime - 0.1;
  }

  // Seek up until maxSeekTime
  // TODO: find a safer way to do this vs. smashing a property into the player object
  const maxSeekTime = player.maxSeekTime;
  if (!maxSeekTime || newTime <= maxSeekTime) {
    player.currentTime(newTime);
  }

  this.update();
};
