import { styled } from "@mui/material/styles";
import LinearProgressMui from "@mui/material/LinearProgress";

export const LinearProgress = styled(LinearProgressMui)`
  &.MuiLinearProgress-colorPrimary {
    background-color: ${({ theme }) => theme.kinetic.v2.palette.rgb.blueBg};
    .MuiLinearProgress-bar {
      background-color: ${({ theme }) => theme.kinetic.v2.palette.rgb.blue};
    }
  }
`;
