"use client";

import { theme, themeDark, Theme as ThemeBase } from "./app/styles/theme";
import { useSegment } from "./app/segment";

import { debounce } from "./utils/debounce";
import { getTraits } from "./utils/traits";
import {
  uploadToCloudinary,
  getMimeType,
  UploadConfig as UploadConfigBase,
  FileType as FileTypeBase,
} from "./utils/uploadToCloudinary";
import { UploaderS3 } from "./utils/uploadToS3";

// TODO: Remove CC export
import * as CoreComponent from "./app/core";

import { createEmotionCache, withTransientProps } from "./lib/emotion";
import {
  ThemeProvider,
  CacheProvider,
  Global,
  css,
  keyframes,
  EmotionCache as EmotionCacheBase,
} from "@emotion/react";
import createCache from "@emotion/cache";

import styled from "@emotion/styled";

import {
  langmap,
  getLanguageAndRegion,
  getIsoLanguage,
  getLanguageFullName,
} from "./lib/langmap";

import {
  MAX_FILE_SIZE,
  MAX_PHOTO_FILE_SIZE,
  MAX_VIDEO_FILE_SIZE,
  MAX_VTT_FILE_SIZE,
  FILE_ERROR_MESSAGE,
  PHOTO_ERROR_MESSAGE,
  VIDEO_ERROR_MESSAGE,
  VTT_SRT_ERROR_MESSAGE,
} from "./lib/uploads";

import type {
  AdminIdentificationType as AdminIdentificationTypeBase,
  PwaIdentificationType as PwaIdentificationTypeBase,
} from "./lib/segment";
import type {
  SegmentPropFunctions as SegmentPropFunctionsBase,
  SegmentProps as SegmentPropsBase,
} from "./app/segment";
// // TODO: Remove index.ts in /core and import things here directly
import type {
  AutocompleteValue as AutocompleteValueBase,
  AlertProps as AlertPropsBase,
  AlertColor as AlertColorBase,
  ButtonProps as ButtonPropsBase,
  CardProps as CardPropsBase,
  CardFormPrompt as CardFormPromptBase,
  ComboBoxProps as ComboBoxPropsBase,
  DataGridProps as DataGridPropsBase,
  DataGridCellParams as DataGridCellParamsBase,
  DataGridColumnsProps as DataGridColumnsPropsBase,
  DataGridRowModel as DataGridRowModelBase,
  DataGridRowsProps as DataGridRowsPropsBase,
  DialogProps as DialogPropsBase,
  ExpandableProps as ExpandablePropsBase,
  GridProps as GridPropsBase,
  IconProps as IconPropsBase,
  InputProps as InputPropsBase,
  MenuProps as MenuPropsBase,
  MenuItemProps as MenuItemPropsBase,
  LinkProps as LinkPropsBase,
  TabProps as TabPropsBase,
  TabContextProps as TabContextPropsBase,
  TabListProps as TabListPropsBase,
  TabPanelProps as TabPanelPropsBase,
  TabScrollButtonProps as TabScrollButtonPropsBase,
  TabsProps as TabsPropsBase,
  TableProps as TablePropsBase,
  TableBodyProps as TableBodyPropsBase,
  TableContainerProps as TableContainerPropsBase,
  TableFooterProps as TableFooterPropsBase,
  TableHeadProps as TableHeadPropsBase,
  TablePaginationProps as TablePaginationPropsBase,
  TableRowProps as TableRowPropsBase,
  TableSortLabelProps as TableSortLabelPropsBase,
  TableCellProps as TableCellPropsBase,
  TextFieldProps as TextFieldPropsBase,
  VideoCardProps as VideoCardPropsBase,
  Interjection,
} from "./app/core";
import { Props as VideoPlayerPropsBase } from "./app/core/VideoPlayer/types";
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";

export * from "./app/core";

const materialTheme = createTheme({
  ...theme,
  // 6 * 2 except for 20 which is needed to match our designs
  spacing: [0, 6, 12, 20, 24, 48, 96, 192, 384, 768, 1536],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const uploadConfig = {
  MAX_FILE_SIZE,
  MAX_PHOTO_FILE_SIZE,
  MAX_VIDEO_FILE_SIZE,
  MAX_VTT_FILE_SIZE,
  FILE_ERROR_MESSAGE,
  PHOTO_ERROR_MESSAGE,
  VIDEO_ERROR_MESSAGE,
  VTT_SRT_ERROR_MESSAGE,
};

export const CC = CoreComponent;
export const SC = {
  styled,
  withTransientProps,
  css,
  Global,
  keyframes,
  ThemeProvider,
  MaterialThemeProvider,
  materialTheme,
  MATERIAL_THEME_ID,
  theme,
  themeDark,
  createEmotionCache,
  CacheProvider,
  createCache,
};

export const CCHelpers = {
  useSegment,
  debounce,
  getTraits,
  getLanguageAndRegion,
  getIsoLanguage,
  langmap,
  getLanguageFullName,
  getMimeType,
  uploadToCloudinary,
  UploaderS3,
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace CCTypes {
  export type Theme = ThemeBase;
  export type AdminIdentificationType = AdminIdentificationTypeBase;
  export type PwaIdentificationType = PwaIdentificationTypeBase;
  export type AlertProps = AlertPropsBase;
  export type AlertColor = AlertColorBase;
  export type ButtonProps = ButtonPropsBase;
  export type CardProps = CardPropsBase;
  export type CardFormPrompt = CardFormPromptBase;
  export type ComboBoxProps<
    T,
    Multiple extends boolean | undefined
  > = ComboBoxPropsBase<T, Multiple>;
  export type AutocompleteValue<
    T,
    Multiple extends boolean | undefined,
    DisableCrearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
  > = AutocompleteValueBase<T, Multiple, DisableCrearable, FreeSolo>;
  export type DataGridProps = DataGridPropsBase;
  export type DataGridCellParams = DataGridCellParamsBase;
  export type DataGridColumnsProps = DataGridColumnsPropsBase;
  export type DataGridRowModel = DataGridRowModelBase;
  export type DataGridRowsProps = DataGridRowsPropsBase;
  export type DialogProps = DialogPropsBase;
  export type ExpandableProps = ExpandablePropsBase;
  export type GridProps = GridPropsBase;
  export type IconProps = IconPropsBase;
  export type InputProps = InputPropsBase;
  export type MenuProps = MenuPropsBase;
  export type MenuItemProps = MenuItemPropsBase;
  export type SegmentProps = SegmentPropsBase;
  export type SegmentPropFunctions = SegmentPropFunctionsBase;
  export type LinkProps = LinkPropsBase;
  export type TabProps = TabPropsBase;
  export type TabContextProps = TabContextPropsBase;
  export type TabListProps = TabListPropsBase;
  export type TabPanelProps = TabPanelPropsBase;
  export type TabScrollButtonProps = TabScrollButtonPropsBase;
  export type TabsProps = TabsPropsBase;
  export type TableProps = TablePropsBase;
  export type TableBodyProps = TableBodyPropsBase;
  export type TableContainerProps = TableContainerPropsBase;
  export type TableFooterProps = TableFooterPropsBase;
  export type TableHeadProps = TableHeadPropsBase;
  export type TablePaginationProps = TablePaginationPropsBase;
  export type TableRowProps = TableRowPropsBase;
  export type TableSortLabelProps = TableSortLabelPropsBase;
  export type TableCellProps = TableCellPropsBase;
  export type TextFieldProps = TextFieldPropsBase;
  export type VideoCardProps = VideoCardPropsBase;
  export type VideoPlayerProps = VideoPlayerPropsBase;
  export type WebcamInterjection = Interjection;
  export type UploadConfig = UploadConfigBase;
  export type FileType = FileTypeBase;
  export type EmotionCache = EmotionCacheBase;
}
