import { styled } from "@mui/material/styles";

export const Panel = styled("div")`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.border[1]};
  border-radius: ${({ theme }) => theme.kinetic.borderRadius.md};
  background-color: ${({ theme }) => theme.kinetic.v2.palette.rgb.white};
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;
