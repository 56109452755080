import React, { useRef, useState } from "react";
import { BaseCard, CardProps } from "../BaseCard";
import { Container, VideoCardContainer } from "./styles";
import { PlayButton } from "./components/PlayButton";
import { RatingButtons } from "./components/RatingButtons";
import { VideoPlayer } from "../../../VideoPlayer";
import { Expandable } from "../../../Expandable";
import {
  VideoJsPlayer,
  Props as VideoPlayerProps,
} from "../../../VideoPlayer/types";
import { toggleDefaultCaptions } from "../../../VideoPlayer/player/textTracks";
import { AspectRatio, Rating } from "../../types";

type ExperimentalConfig = {
  disableScrubbing?: boolean;
};

export type VideoCardProps = Omit<CardProps, "onTimeUpdate"> &
  Omit<VideoPlayerProps, "onFullscreenChange" | "onTimeUpdate"> & {
    experimentConfig?: ExperimentalConfig;
    rating: Rating;
    onRatingClick?: (rating: Rating) => void;
    onInitialClickPlayButton?: () => void;
    onClickPlayButton?: () => void;
    onTimeUpdate?: (player: VideoJsPlayer) => void;
    onFullscreenChange?: (isFullScreen: boolean) => void;
  };

const DEFAULT_EXPERIMENTAL_CONFIG: ExperimentalConfig = {
  disableScrubbing: false,
};

export function VideoCard({
  isActive,
  options,
  metadata,
  readyEvent,
  rating,
  experimentConfig: _experimentConfig,
  onClickPlayButton: _onClickPlayButton,
  onInitialClickPlayButton,
  disableFullScreenButton,
  onRatingClick,
  onFullscreenChange,
  onPlay,
  onPause,
  onReady: _onReady,
  onTimeUpdate,
  onSeekStart,
  onSeeked,
  onEnded: _onEnded,
  onError,
  onCardActive,
}: VideoCardProps) {
  const playerRef = useRef<VideoJsPlayer>(null);
  const hasPlayedRef = useRef(false);

  const [isFullWindow, setIsFullWindow] = useState(false);
  const [hasHadUserInteraction, setHasHadUserInteraction] = useState(false);

  const experimentConfig = {
    ...DEFAULT_EXPERIMENTAL_CONFIG,
    ...(_experimentConfig ?? {}),
  };

  const onClickPlayButton = () => {
    setIsFullWindow(true);

    if (!playerRef.current) {
      return;
    }

    playerRef.current.currentTime(0);
    hasPlayedRef.current = true;

    _onClickPlayButton?.();

    if (!hasHadUserInteraction) {
      onInitialClickPlayButton?.();
      setHasHadUserInteraction(true);
    }
  };

  const onEnded = (player: VideoJsPlayer) => {
    setIsFullWindow(false);

    _onEnded?.(player);
  };

  const onReady = (player: VideoJsPlayer) => {
    toggleDefaultCaptions(player, false);

    _onReady?.(player);
  };

  return (
    <VideoCardContainer>
      <BaseCard isActive={isActive} onCardActive={onCardActive}>
        <Expandable
          isOpen={isFullWindow}
          modal={{
            onOpen: () => {
              onClickPlayButton();
              onFullscreenChange?.(isFullWindow);
              setIsFullWindow(true);
            },
            onClose: () => setIsFullWindow(false),
            title: metadata?.videoTitle,
          }}
          fullCard
        >
          {/* TODO: Evaluate this cast. Maybe we should move AspectRatio to VideoPlayer component */}
          <Container
            $aspectRatio={options.aspectRatio?.replace(":", "/") as AspectRatio}
            $isFill
          >
            {!isFullWindow && <PlayButton />}
            <RatingButtons
              isVisible={!isFullWindow && hasPlayedRef.current}
              rating={rating}
              onClick={onRatingClick}
            />
            <VideoPlayer
              playerRef={playerRef}
              metadata={metadata}
              options={{
                ...options,
                autoplay: isFullWindow,
                muted: !isFullWindow,
                loop: !isFullWindow,
                controls: isFullWindow,
              }}
              readyEvent={readyEvent}
              disableFullScreenButton={disableFullScreenButton}
              backgroundColor={"#fff"}
              preventSeekAhead={experimentConfig?.disableScrubbing}
              onPlay={onPlay}
              onPause={onPause}
              onReady={onReady}
              onTimeUpdate={onTimeUpdate}
              onSeekStart={onSeekStart}
              onSeeked={onSeeked}
              onEnded={onEnded}
              onError={onError}
            />
          </Container>
        </Expandable>
      </BaseCard>
    </VideoCardContainer>
  );
}
