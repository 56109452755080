import React from "react";
import BaseAlert, {
  AlertProps as BaseAlertProps,
  AlertColor,
} from "@mui/material/Alert";
import { styled } from "@mui/material/styles";

interface AlertProps extends Omit<BaseAlertProps, "color"> {
  color?: AlertColor | "neutral";
}

export const Alert = styled<(props: AlertProps) => JSX.Element>(
  ({ color, ...props }) => (
    <BaseAlert color={color === "neutral" ? undefined : color} {...props} />
  )
)<AlertProps>`
  color: ${({ theme, color }) =>
    color === "neutral"
      ? theme.kinetic.v2.palette.rgb.grayTextLight
      : undefined};
  background-color: ${({ theme, color }) =>
    color === "neutral"
      ? theme.kinetic.v2.palette.rgb.grayUiLighter
      : undefined};
  .MuiSvgIcon-root {
    fill: ${({ theme, color }) =>
      color === "neutral"
        ? theme.kinetic.v2.palette.rgb.grayTextLight
        : undefined};
  }
`;

export type { AlertProps, AlertColor };
