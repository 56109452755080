import { BSPBody1 } from "../../../BSPBody1";
import { AccountCircleOutlineIcon } from "../../../../../../icons";
import { styled } from "@mui/material/styles";

export const ObjectionWrapper = styled("div")`
  position: absolute;
  top: 85px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 6px;
  padding: 5px 0;
  text-align: center;
  backdrop-filter: blur(20px);
  display: inline-flex;
  align-items: center;
`;

export const ObjectionMessage = styled(BSPBody1)`
  font-size: 1.8rem;
  font-weight: 600;
  color: white;
  padding: 5px 0;
  min-width: 600px;
  text-align: top;
  ${({ theme }) => theme.breakpoint.maxWidth.small`
    font-size: 1rem;
  `}
`;

export const PersonIcon = styled(AccountCircleOutlineIcon)`
  height: 80px;
  width: 80px;
  color: white;
`;
export const PersonName = styled(BSPBody1)`
  font-size: 1.1rem;
  font-weight: 500;
  color: white;
  padding: 5px 0;
  text-align: top;
  ${({ theme }) => theme.breakpoint.maxWidth.small`
    font-size: 1rem;
  `}
`;

export const MessageAuthor = styled("div")`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
`;
