import React, { useEffect, useState } from "react";
import {
  ObjectionMessage,
  PersonIcon,
  ObjectionWrapper,
  MessageAuthor,
  PersonName,
} from "./styles";

export type Interjection = {
  start: number;
  end: number;
  message: string;
  author: string;
};

type Props = {
  timerCountDown: number;
  interjections: Interjection[];
};

export function InterjectionView({ timerCountDown, interjections }: Props) {
  const [message, setMessage] = useState("");
  const [author, setAuthor] = useState("");

  useEffect(() => {
    for (const interjection of interjections) {
      if (
        timerCountDown >= interjection.end &&
        timerCountDown <= interjection.start
      ) {
        setMessage(interjection.message);
        setAuthor(interjection.author);
        break; // Stop iterating when the first interjection is found
      } else {
        setMessage("");
        setAuthor("");
      }
    }
  }, [timerCountDown, interjections]);

  return (
    <ObjectionWrapper>
      {message !== "" ? (
        <>
          <MessageAuthor>
            <PersonIcon />
            <PersonName>{author}</PersonName>
          </MessageAuthor>
          <ObjectionMessage>{message}</ObjectionMessage>
        </>
      ) : (
        <></>
      )}
    </ObjectionWrapper>
  );
}
