import { styled } from "@mui/material/styles";
import { Button, ButtonProps } from "../../../Button";

export const LinkoutContainer = styled("div")`
  height: 100%;
  width: 600px;
  max-width: 600px;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled(Button)<ButtonProps>`
  width: fit-content;

  // temporary fix to change fontsize of button
  // that is 6th child of this elem.
  & * {
    font-size: 16px;
  }
`;
