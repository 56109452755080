import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useMemo } from "react";
import cookie from "cookie";
import { fetchNewTokenAndRetry } from "utils/auth";
import routes, { getCompanyIdInRoute } from "./routes";
import fragmentMatcher from "@gql/generated/fragmentMatcher";

export const SKIP_AUTHENTICATION_WHITELIST = ["AuthRefresh"];

let ssrCookies: Record<string, string> = {};
let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

function createApolloClient() {
  const httpLink = new HttpLink({
    uri: `${
      process.env.SSR_BACKEND_URL || process.env.NEXT_PUBLIC_BACKEND_URL
    }/graphql`,
    credentials: "include",
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.debug(
            `[Apollo GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }

      if (networkError) {
        console.debug(`[Apollo Network error]: ${networkError}`);

        // Expired
        if (apolloClient && (networkError as any).statusCode === 401) {
          return fetchNewTokenAndRetry(apolloClient, operation, forward);
        }

        // Maintenance mode
        if ((networkError as any).statusCode === 512) {
          window.location.replace(routes.maintenance);

          return;
        }
      }
    }
  );

  const authLink = setContext(({ operationName }, { headers }) => {
    // Some GraphQL operations must not include auth information
    const mustSkipAuthentication = SKIP_AUTHENTICATION_WHITELIST.includes(
      operationName as string
    );
    if (mustSkipAuthentication) {
      return {};
    }

    const company = getCompanyIdInRoute() || ssrCookies.companyId;

    return {
      headers: {
        ...headers,
        "X-Company-Id": company || "",
      },
    };
  });

  return new ApolloClient({
    cache: new InMemoryCache({
      ...fragmentMatcher,
      typePolicies: {
        Localization: {
          keyFields: ["language", "id", "value"],
        },
      },
    }),
    link: errorLink.concat(authLink).concat(httpLink),
    credentials: "include",
  });
}

export function initializeApollo(initialState: any = {}, ssrCookieHeader = "") {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }

  if (ssrCookieHeader) {
    ssrCookies = cookie.parse(ssrCookieHeader);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: any) {
  return useMemo(() => initializeApollo(initialState), [initialState]);
}
