import { css, styled } from "@mui/material/styles";

type RootProps = {
  $isThumbnail: boolean;
  $keepAspectRatio: boolean;
  $backgroundColor?: string;
};

const root = ({
  $backgroundColor,
  $isThumbnail,
  $keepAspectRatio,
}: RootProps) => css`
  margin: 0;
  max-height: -webkit-fill-available;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: ${$backgroundColor || "#000"};

  ${$isThumbnail &&
  css`
    pointer-events: none;
  `}

  ${!$keepAspectRatio &&
  css`
    height: 100%;
    width: 100%;
  `}
`;

export const RootFullViewport = styled("div")<RootProps>`
  ${root}
  height: 100vh !important;
  height: 100dvh !important;
  width: 100vw !important;
  width: 100dvw !important;
  z-index: 0;
`;

export const Root = styled("div")<RootProps>`
  ${root}
`;

const container = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

type ContainerProps = RootProps & {
  $isVisible: boolean;
  $backgroundColor?: string;
  $disableFullScreenButton?: boolean;
};

export const Container = styled("div")<ContainerProps>`
  ${container}

  // video.js
  [data-vjs-player] {
    ${container}
    position: relative !important;
    font-size: 14px;
    opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
    user-select: none;
    user-drag: none;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, sans-serif;

    ${({ $keepAspectRatio }) =>
      !$keepAspectRatio &&
      css`
        height: 100% !important;
        width: 100% !important;
      `}

    video {
      background: ${({ $backgroundColor }) => $backgroundColor || "#000"};

      > * {
        display: none;
      }
    }

    // Hide cursor when video is playing and user is not interacting with the video
    &.vjs-user-inactive {
      &:not(.vjs-paused) {
        cursor: none;
      }
    }

    // Loading spinner
    .vjs-loading-spinner {
      ${({ $isThumbnail }) =>
        $isThumbnail &&
        css`
          display: none;
        `}

      position: relative;
      top: initial;
      left: initial;
      margin: 0;
      opacity: 1;
      border: 0;
      width: 48px;
      height: 48px;

      &:before,
      &:after {
        display: none;
      }
    }

    // Hide loading spinner while seeking
    &.vjs-scrubbing {
      .vjs-loading-spinner {
        display: none;
      }
    }

    .vjs-fullscreen-control {
      ${({ $disableFullScreenButton }) =>
        $disableFullScreenButton &&
        css`
          display: none;
        `}
    }

    // Custom big play button
    .vjs-big-play-button {
      background-color: rgba(0, 0, 0, 0.5) !important;
      border: 0;
      border-radius: 50%;
      width: 20vh;
      width: 20dvh;
      min-width: 50px;
      max-width: 100px;
      height: 20vh;
      height: 20dvh;
      min-height: 50px;
      max-height: 100px;
      top: 50%;
      left: 50%;
      font-size: min(max(15vh, 33px), 75px);
      font-size: min(max(15dvh, 33px), 75px);
      line-height: min(max(20vh, 50px), 100px);
      line-height: min(max(20dvh, 50px), 100px);
      transform: translate(-50%, -50%);
      transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);

      // Reset focus styles
      &:focus {
        border-color: initial;
        background-color: rgba(0, 0, 0, 0.5) !important;
      }

      &:focus-visible {
        background: rgba(0, 0, 0, 0.4) !important;
        color: ${({ theme }) => theme.kinetic.v2.palette.rgb.blue};
      }
    }

    &:hover {
      .vjs-big-play-button {
        background: rgba(0, 0, 0, 0.4) !important;
      }
    }

    &:focus-visible {
      .vjs-big-play-button {
        color: ${({ theme }) => theme.kinetic.v2.palette.rgb.blue};
      }
    }

    &:active {
      .vjs-big-play-button {
        background: rgba(0, 0, 0, 0.5);
        color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiDark};
      }
    }

    // Reset focus styles
    .vjs-control {
      &:focus {
        text-shadow: initial;
      }

      &:focus-visible {
        color: ${({ theme }) => theme.kinetic.v2.palette.rgb.blue};
      }
    }

    // Custom control bar
    .vjs-control-bar {
      background: rgba(0, 0, 0, 0.5);
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }

    // Custom progress slider
    .vjs-progress-control {
      &:hover {
        .vjs-mouse-display {
          display: none;
        }

        .vjs-progress-holder {
          font-size: inherit;
          height: 8px;
        }

        .vjs-play-progress {
          &::before {
            top: -4px;
            font-size: initial;
          }
        }
      }
    }

    .vjs-progress-holder {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }

    .vjs-slider {
      border-radius: 25px;
      background: ${({ theme }) => theme.kinetic.v2.palette.rgba.white(0.25)};

      // Reset focus styles
      &:focus {
        text-shadow: initial;
        box-shadow: initial;
      }

      &:focus-visible {
        color: ${({ theme }) => theme.kinetic.v2.palette.rgb.blue};
      }
    }

    // Hide loaded segments
    .vjs-load-progress {
      display: none !important;
    }

    .vjs-play-progress {
      border-radius: 25px;
      background: ${({ theme }) => theme.kinetic.v2.palette.rgb.white};

      &::before {
        top: -4px;
      }
    }

    .vjs-time-tooltip {
      color: ${({ theme }) => theme.kinetic.v2.palette.rgb.white};
      background: rgba(0, 0, 0, 0.5);
      top: -3.5em !important;
      font-size: 1em !important;
    }

    // Timeline hover preview
    .vjs-vtt-thumbnail-display {
      transition: none;
      background-color: #000;
    }

    // Text track block
    .vjs-text-track-cue {
      div {
        background: rgba(0, 0, 0, 0.75) !important;
        font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
          Helvetica, Arial, sans-serif !important;
        display: block !important;
        width: fit-content;
        margin: auto;
        padding: ${({ theme }) =>
          `${theme.kinetic.paddings[4]} ${theme.kinetic.paddings[12]}`};
        border-radius: ${({ theme }) => theme.kinetic.borderRadius.sm};
      }
    }

    // Hide captions menu since we only have one captions track per video
    .vjs-subs-caps-button.vjs-control {
      .vjs-menu {
        display: none !important;
      }

      // Make captions button transparent when captions are disabled
      > button {
        &:focus-visible {
          color: ${({ theme }) => theme.kinetic.v2.palette.rgb.blue};
        }

        &[aria-checked="false"] {
          opacity: 0.5;

          &:focus-visible {
            color: ${({ theme }) => theme.kinetic.v2.palette.rgb.blueLight};
          }
        }
      }
    }

    // Hide the "X" in the error modal
    &.vjs-error {
      .vjs-error-display {
        &::before {
          display: none !important;
        }
      }
    }

    .vjs-modal-dialog {
      background: rgba(0, 0, 0, 0.75);
    }

    // Center text in the modal
    .vjs-modal-dialog-content {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .vjs-poster {
      background-color: #fff;
    }
  }
`;

export const Wrapper = styled("div")`
  position: relative !important;
  width: 100% !important;
  height: auto !important;

  [disabled] {
    pointer-events: none;
  }
`;
