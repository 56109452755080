import { CCTypes } from "@bigspring/core-components";
import { typewriterTrack } from "../../../lib/events";
import { TITLE_SUFFIX } from "config/routes";
import { MediaSelected } from "../../../typewriter/segment";
import { LearningCardType } from "@gql/generated/graphql";

export enum ObjectType {
  API_KEYS = "APIKeys",
  COMPANY = "Company",
  PROFILE = "Profile",
  PROFILE_TAG = "ProfileTags",
  GROUP = "Group",
  LEARNING_PLAN_ASSIGNMENT = "LearningPlanAssignment",
  REP_PROMPT = "RepPrompt",
  REP_RESPONSE = "RepResponse",
  SKILL = "Skill",
  TOPIC = "Topic",
  LEARNING_PLAN = "LearningPlans",
  CUSTOM_VOCABULARY_KEYWORDS = "CustomVocabularyKeywords",
}
export enum ActionSourceType {
  CRUD = "CRUD",
  CSV = "CSV",
}

export type GeneralPropsType = {
  companyId: string | null;
  companyProfileId: string | null;
  tab: string;
};

export type GeneralCrudActionType = {
  objectType: ObjectType;
  numObjects: number;
  actionSource?: ActionSourceType;
};

// Why do we need to do this if typewriter creates the methods for us?
// TODO: Evaluate to remove this
export interface IAdminTrack {
  navigationMenuOptionSelected: (props: {
    tab: string;
    tabsVisible: string[];
  }) => void;
  listObjects: (props: GeneralCrudActionType) => void;
  addObject: (props: GeneralCrudActionType) => void;
  updateObject: (props: GeneralCrudActionType) => void;
  deleteObjectRequested: (props: GeneralCrudActionType) => void;
  deleteObject: (props: GeneralCrudActionType) => void;
  sortList: (objectType: ObjectType) => void;
  errorDisplayed: (props: { errorMessage: Array<string> | string }) => void;
  csvExampleDownloaded: () => void;
  csvUploaded: () => void;
  csvSubmitted: () => void;
  csvProcessFailed: () => void;
  csvProcessSuccessWithWarnings: () => void;
  pageLoaded: (name?: string) => void;
  buttonClicked: (buttonText?: string) => void;
  profileCreationStarted: () => void;
  profileEditStarted: () => void;
  profileSaved: (timeSinceStart: number) => void;
  skillCreationStarted: () => void;
  skillEditStarted: () => void;
  skillSaved: (timeSinceStart: number) => void;
  learningPlanCreationStarted: () => void;
  learningPlanEditStarted: () => void;
  learningPlanSaved: (timeSinceStart: number) => void;
  topicCreationStarted: () => void;
  topicEditStarted: () => void;
  topicSaved: (timeSinceStart: number) => void;
  mediaSelected: (props: Omit<MediaSelected, "companyId" | "tab">) => void;
  cardPreviewed: (props: {
    learningCardPosition: number;
    learningCardType: LearningCardType;
    skillExternalId: string;
    skillName: string;
    skillState: "draft" | "new" | "published";
    target: "skill" | "card";
  }) => void;
  tabSelected: (tabName?: string) => void;
  embeddedAnalyticsClicked: (response?: Record<string, unknown>) => void;
}

export default function AdminTracker(
  client: CCTypes.SegmentPropFunctions,
  generalPropsType: GeneralPropsType
): IAdminTrack {
  return {
    navigationMenuOptionSelected: (props) => {
      typewriterTrack("navigationMenuOptionSelected", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
        tab: props.tab,
        tabsVisible: props.tabsVisible,
      });
    },
    listObjects: (props) => {
      typewriterTrack("listObjects", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
        //actionSource: props.actionSource
        numObjects: props.numObjects,
        objectType: props.objectType,
        tab: generalPropsType.tab,
      });
    },
    addObject: (props) => {
      typewriterTrack("addObject", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
        actionSource: props.actionSource,
        numObjects: props.numObjects,
        objectType: props.objectType,
        tab: generalPropsType.tab,
      });
    },
    updateObject: (props) => {
      typewriterTrack("updateObject", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
        actionSource: props.actionSource,
        numObjects: props.numObjects,
        objectType: props.objectType,
        tab: generalPropsType.tab,
      });
    },
    deleteObjectRequested: (props) => {
      void client.track("Delete Object Requested", {
        ...generalPropsType,
        ...props,
      });
    },
    deleteObject: (props) => {
      typewriterTrack("deleteObject", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
        actionSource: props.actionSource,
        numObjects: props.numObjects,
        objectType: props.objectType,
        tab: generalPropsType.tab,
      });
    },
    sortList: (objectType: ObjectType) => {
      typewriterTrack("sortList", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
        objectType: objectType,
        tab: generalPropsType.tab,
      });
    },
    errorDisplayed: (props) => {
      typewriterTrack("errorDisplayed", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
        errorMessage: !props.errorMessage ? ["None"] : [props.errorMessage[0]],
        tab: generalPropsType.tab,
      });
    },
    csvExampleDownloaded: () => {
      void client.track("CSV Example Downloaded", {
        ...generalPropsType,
      });
    },
    csvUploaded: () => {
      typewriterTrack("cSVUploaded", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
        tab: generalPropsType.tab,
      });
    },
    csvSubmitted: () => {
      typewriterTrack("cSVSubmitted", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
        tab: generalPropsType.tab,
      });
    },
    csvProcessFailed: () => {
      typewriterTrack("cSVProcessFailed", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
        tab: generalPropsType.tab,
      });
    },
    csvProcessSuccessWithWarnings: () => {
      void client.track("CSV Process Success With Warnings", {
        ...generalPropsType,
      });
    },
    pageLoaded: (name?: string) => {
      // if no name passed, guess name from page title
      if (name === undefined) {
        name = document.title
          .replace(TITLE_SUFFIX, "")
          .replace(/\s\|\s$/, "")
          .trim();
      }
      if (!name) {
        return;
      }
      void client?.page(name, {
        ...generalPropsType,
      });
    },
    buttonClicked: (buttonText?: string) => {
      typewriterTrack("buttonClicked", {
        buttonText,
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
        location: location.pathname,
        tab: generalPropsType.tab,
      });
    },
    profileCreationStarted: () => {
      typewriterTrack("profileCreationStarted", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
      });
    },
    profileEditStarted: () => {
      typewriterTrack("profileEditStarted", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
      });
    },
    profileSaved: (timeSinceStart: number) => {
      typewriterTrack("profileSaved", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
        duration: timeSinceStart,
      });
    },
    skillCreationStarted: () => {
      typewriterTrack("skillCreationStarted", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
      });
    },
    skillEditStarted: () => {
      typewriterTrack("skillEditStarted", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
      });
    },
    skillSaved: (timeSinceStart) => {
      typewriterTrack("skillSaved", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
        duration: timeSinceStart,
      });
    },
    learningPlanCreationStarted: () => {
      typewriterTrack("learningPlanCreationStarted", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
      });
    },
    learningPlanEditStarted: () => {
      typewriterTrack("learningPlanEditStarted", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
      });
    },
    learningPlanSaved: (timeSinceStart) => {
      typewriterTrack("learningPlanSaved", {
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
        duration: timeSinceStart,
      });
    },
    topicCreationStarted: () => {
      // typewriterTrack('topicCreationStarted', {
      //   companyId: generalPropsType.companyId || undefined,
      //   companyProfileId: generalPropsType.companyProfileId || undefined,
      // })
    },
    topicEditStarted: () => {
      // typewriterTrack('topicEditStarted', {
      //   companyId: generalPropsType.companyId || undefined,
      //   companyProfileId: generalPropsType.companyProfileId || undefined,
      // })
    },
    topicSaved: () => {
      // typewriterTrack('topicSaved', {
      //   companyId: generalPropsType.companyId || undefined,
      //   companyProfileId: generalPropsType.companyProfileId || undefined,
      //   duration: timeSinceStart,
      // })
    },
    mediaSelected: (props) => {
      typewriterTrack("mediaSelected", {
        companyId: generalPropsType.companyId as string,
        tab: generalPropsType.tab,
        ...props,
      });
    },
    cardPreviewed: (props) => {
      void client.track("Card Previewed", {
        learningCardPosition: props.learningCardPosition,
        learningCardType: props.learningCardType,
        skillExternalId: props.skillExternalId,
        skillName: props.skillName,
        skillState: props.skillState,
        target: props.target,
      });
    },
    tabSelected: (tabName?: string) => {
      typewriterTrack("tabSelected", {
        tabName: tabName,
        companyId: generalPropsType.companyId || undefined,
        companyProfileId: generalPropsType.companyProfileId || undefined,
        location: location.pathname,
      });
    },
    embeddedAnalyticsClicked: (response?: Record<string, unknown>) => {
      typewriterTrack("embeddedAnalyticsClicked", {
        companyId: generalPropsType.companyId || undefined,
        profileId: generalPropsType.companyProfileId || undefined,
        response: response,
      });
    },
  };
}
