import { datadogRum } from "@datadog/browser-rum";
import { useCollections } from "@shared/Collections";
import { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { useAuth } from "../Authentication";

type DataDogProviderContext = {
  captureException: (
    error: Error,
    extra?: unknown,
    tags?: Record<string, string>
  ) => void;
};

const Context = createContext<DataDogProviderContext>({
  captureException: () => undefined,
});

export const DATADOG_ENABLED =
  process.env.NEXT_PUBLIC_ENVIRONMENT === "production" ||
  process.env.NEXT_PUBLIC_ENVIRONMENT === "staging";

export function DatadogProvider({ children }: PropsWithChildren<unknown>) {
  const { user, currentTab } = useAuth();
  const { currentCompany } = useCollections();

  useEffect(() => {
    if (!DATADOG_ENABLED) {
      console.info(
        `DataDog disabled on environment "${process.env.NEXT_PUBLIC_ENVIRONMENT}"`
      );

      return;
    }

    if (
      !process.env.NEXT_PUBLIC_DD_APPLICATION_ID ||
      !process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN
    ) {
      console.info(
        `DataDog NEXT_PUBLIC_DD_APPLICATION_ID and NEXT_PUBLIC_DD_CLIENT_TOKEN not setup`
      );

      return;
    }

    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DD_APPLICATION_ID,
      clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "admin",
      env: process.env.NEXT_PUBLIC_ENVIRONMENT,
      version: process.env.NEXT_PUBLIC_VERSION,
      sessionSampleRate: 100,
      trackUserInteractions: true,
      allowedTracingUrls: [
        {
          match: /https:\/\/.*\.bigspring\.io\/admin\/graphql/,
          propagatorTypes: ["tracecontext"],
        },
      ],
    });
  }, []);

  useEffect(() => {
    if (!DATADOG_ENABLED) {
      return;
    }

    // See the comment in Layout -> Navigation for more information about this
    // This is a temporary solution
    let tab =
      typeof window !== "undefined"
        ? window.sessionStorage.getItem("current-tab") ?? ""
        : "";
    if (currentTab) {
      tab = currentTab;
    }

    if (!user || !tab || !currentCompany.id) {
      datadogRum.clearUser();
    } else {
      datadogRum.setUser({
        id: user.id,
        email: user.companyProfiles[0].emailAddress || undefined,
        name: user.companyProfiles[0].externalId || undefined,
        currentTab: tab,
        currentCompanyId: currentCompany.id,
      });
    }
  }, [user, currentCompany.id, currentTab]);

  const captureException = (
    error: Error,
    extra?: unknown,
    tags?: Record<string, string>
  ) => {
    if (DATADOG_ENABLED) {
      try {
        datadogRum.addError(error, { extra, tags });
      } catch (exception) {
        console.error("Error requesting to Datadog: ", exception);
      }
    } else {
      if (error && error.message) {
        console.group("Datadog Disabled - Error:", error.message);
      } else {
        console.group("Datadog Disabled - Error");
      }
      console.groupCollapsed("Details");
      console.info(error);
      console.groupEnd();
      if (extra) {
        console.groupCollapsed("Extra");
        console.info(extra);
        console.groupEnd();
      }
      if (tags) {
        console.groupCollapsed("Custom Tags");
        console.info(tags);
        console.groupEnd();
      }
      console.groupEnd();
    }
  };

  return (
    <Context.Provider value={{ captureException }}>{children}</Context.Provider>
  );
}

export function useDataDog() {
  return useContext(Context);
}
