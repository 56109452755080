
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "LearningCard": [
      "LearningCardFile",
      "LearningCardFlashCard",
      "LearningCardForm",
      "LearningCardLinkout",
      "LearningCardVideo"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "LearningCard": [
      "LearningCardFile",
      "LearningCardFlashCard",
      "LearningCardForm",
      "LearningCardLinkout",
      "LearningCardVideo"
    ]
  }
};
      export default result;
    