import { useRef, useEffect, RefObject } from "react";
import { nanoid } from "nanoid";

type PlayerIdMap = {
  [id: string]: HTMLMediaElement | null;
};

const playerIdMap: PlayerIdMap = {};

/**
 * React hook to enforce only one media element playing at a time
 *
 * @param ref <video> or <audio> ref
 * @param enableOnlyOnePlayer If true, only one player will be playing at a time
 */
export const useOnePlayer = (
  ref: RefObject<HTMLMediaElement | null>,
  enableOnlyOnePlayer = true
) => {
  const id = useRef<string | null>(null);

  // Pause all other players
  const playingEventListener = () =>
    Object.keys(playerIdMap).forEach((key) => {
      if (key !== id.current) {
        playerIdMap[key]?.pause();
      }
    });

  // Set player ID on mount
  useEffect(() => {
    id.current = nanoid();
  }, []);

  useEffect(() => {
    const current = ref.current;

    // Add player to map and attach playing event listener on mount
    if (ref.current && id.current && enableOnlyOnePlayer) {
      playerIdMap[id.current] = ref.current;

      current?.addEventListener("playing", playingEventListener);
    }

    // Remove player from map and remove event listener on unmount
    return () => {
      if (id.current) {
        delete playerIdMap[id.current];
      }

      if (current && enableOnlyOnePlayer) {
        current.removeEventListener("playing", playingEventListener);
      }
    };
  }, [ref, enableOnlyOnePlayer]);
};
