import React from "react";
import { StyledRadioGroup } from "./styles";
import { RadioGroupProps as RadioGroupMuiProps } from "@mui/material/RadioGroup";

export interface RadioGroupProps extends RadioGroupMuiProps {
  direction?: React.CSSProperties["flexDirection"];
}

export function RadioGroup({ direction, ...rest }: RadioGroupProps) {
  return <StyledRadioGroup $direction={direction} {...rest} />;
}
