import React from "react";
import { css, styled } from "@mui/material/styles";
import { theme, Theme } from "../../styles/theme";
import ContainerMui, { ContainerProps } from "@mui/material/Container";
import { withTransientProps } from "../../../lib/emotion";

type StyledContainerProps = ContainerProps & {
  $borderRadius?: Theme["kinetic"]["borderRadius"];
  $white?: boolean;
  $height?: React.CSSProperties["height"];
};

export const Container = styled(
  ContainerMui,
  withTransientProps
)<StyledContainerProps>`
  border-radius: ${({ $borderRadius = theme.kinetic.borderRadius.none }) =>
    $borderRadius};
  background-color: ${({ theme, $white }) =>
    $white ? theme.kinetic.palette.rgb.white : "transparent"};
  height: ${({ $height = "initial" }) => $height};

  ${({ theme, disableGutters }) =>
    !disableGutters &&
    css`
      padding: 20px 40px;
      ${theme.breakpoint.maxWidth.sm`
        padding: ${theme.kinetic.paddings[20]};
      `};
    `}
`;
