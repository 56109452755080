import React from "react";
import { LinearProgressProps } from "@mui/material/LinearProgress";
import { LinearProgress as StyledLinearProgress } from "./styles";
import Box from "@mui/material/Box";
import { BSPBody2 } from "../BSPBody2";

type Props = Omit<LinearProgressProps, "variant">;

export function LinearProgress({ value, ...props }: Props) {
  if (typeof value !== "number") {
    return <StyledLinearProgress variant="indeterminate" {...props} />;
  }

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <StyledLinearProgress variant="determinate" value={value} {...props} />
      </Box>
      <Box minWidth={35}>
        <BSPBody2>{`${Math.round(value)}%`}</BSPBody2>
      </Box>
    </Box>
  );
}

export function LinearProgressWithoutText({ value, ...props }: Props) {
  if (typeof value !== "number") {
    return <StyledLinearProgress variant="indeterminate" {...props} />;
  }

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <StyledLinearProgress variant="determinate" value={value} {...props} />
      </Box>
    </Box>
  );
}
