import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  isValidElement,
  cloneElement,
  forwardRef,
  Children,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
} from "react";
import { nanoid } from "nanoid";
import {
  LeftNavigationButton,
  ProgressBar,
  ProgressTrack,
  RightNavigationButton,
  NavBarContainer,
  NavBarContentWrapper,
  Root,
  Carousel,
  CardWrapper,
} from "./styles";

import Tooltip from "@mui/material/Tooltip";

import { ArrowLeftIcon, ArrowRightIcon } from "../../../../icons";

type Props = {
  currentCardIndex?: number;
  onClickExit?: () => void;
  onPreviousCard?: (index: number) => void;
  onNextCard?: (index: number) => void;
  isNextDisabled: boolean;
  whyNextDisabled?: string;
};

type ChildCardType = {
  isActive: boolean;
  onNextCard: () => void;
};

type ExposedMethods = {
  setCurrentIndex: (index: number) => void;
};

export const CardStack = forwardRef<ExposedMethods, PropsWithChildren<Props>>(
  (
    {
      children,
      currentCardIndex: _currentCardIndex = 0,
      onClickExit,
      onPreviousCard,
      onNextCard,
      isNextDisabled,
      whyNextDisabled = "",
    },
    ref
  ) => {
    const carouselId = useMemo(nanoid, []);
    const carouselRef = useRef<HTMLOListElement | null>(null);
    const cardRefs = useRef<HTMLElement[]>([]);

    const [currentCardIndex, setCurrentCardIndex] = useState(_currentCardIndex);

    // TODO: Maybe it's better just to move the current index state to parents but
    // evaluate it.
    useImperativeHandle(ref, () => ({
      setCurrentIndex(index: number) {
        setCurrentCardIndex(index);
      },
    }));

    // Use progress state and effect so we can animate the initial progress from 0
    const [progress, setProgress] = useState(0);

    const childrenArray = Children.toArray(children);
    const cardCount = childrenArray.length;

    const onControlledScroll = (cardIndex: number) => {
      const cardElement = cardRefs.current?.[cardIndex];
      const carouselElement = carouselRef.current;

      if (!cardElement || !carouselElement) {
        return;
      }

      const absoluteElementLeft =
        cardElement.offsetLeft + cardElement.clientWidth / 2;
      const scrollLeft = absoluteElementLeft - carouselElement.clientWidth / 2;

      setCurrentCardIndex(cardIndex);

      carouselElement.scrollTo({ top: 0, left: scrollLeft, behavior: "auto" });
    };

    const onControlledNavigation = useCallback(
      (cardIndex: number) => {
        const isBefore = cardIndex < currentCardIndex;
        const isAfter = cardIndex > currentCardIndex;

        onControlledScroll(cardIndex);

        if (isBefore) {
          onPreviousCard?.(cardIndex);
        } else if (isAfter) {
          onNextCard?.(cardIndex);
        }
      },
      [currentCardIndex, onNextCard, onPreviousCard]
    );

    const onControlledToPrevCard = useCallback(() => {
      onControlledNavigation(Math.max(currentCardIndex - 1, 0));
    }, [currentCardIndex, onControlledNavigation]);

    const onControlledToNextCard = useCallback(() => {
      if (isNextDisabled) {
        return;
      }

      onControlledNavigation(Math.min(currentCardIndex + 1, cardCount - 1));
    }, [cardCount, currentCardIndex, isNextDisabled, onControlledNavigation]);

    // Remove cards from ref on prop change
    useEffect(() => {
      cardRefs.current = cardRefs.current.slice(0, childrenArray.length);
    }, [childrenArray]);

    // Scroll to current card on prop change
    useEffect(() => {
      setCurrentCardIndex(_currentCardIndex);
      onControlledScroll(_currentCardIndex);
    }, [_currentCardIndex]);

    useEffect(() => {
      const onKeyDown = (event: KeyboardEvent) => {
        switch (event.key) {
          case "ArrowLeft":
            event.preventDefault();
            onControlledToPrevCard();
            break;
          case "ArrowRight":
            event.preventDefault();
            onControlledToNextCard();
            break;
          case "Escape":
            event.preventDefault();
            onClickExit?.();
            break;
          default:
            break;
        }
      };

      document.addEventListener("keydown", onKeyDown);

      return () => document.removeEventListener("keydown", onKeyDown);
    }, [onClickExit, onControlledToNextCard, onControlledToPrevCard]);

    useEffect(
      () => setProgress((currentCardIndex + 1) / cardCount),
      [currentCardIndex, cardCount]
    );

    return (
      <Root>
        <Carousel id={carouselId} ref={carouselRef}>
          {Children.map(children, (child, index) => {
            if (!isValidElement(child)) {
              return child;
            }

            const isActive = index === currentCardIndex;
            const isBefore = index < currentCardIndex;
            const isAfter = index > currentCardIndex;

            const tabIndex = isActive ? 0 : -1;

            return (
              <CardWrapper
                aria-label={
                  cardCount ? `${index + 1} of ${cardCount}` : undefined
                }
                key={child.props.id || index}
                ref={(instance) =>
                  instance && ((cardRefs.current[index] = instance) as any)
                }
                $isBefore={isBefore}
                $isAfter={isAfter}
                tabIndex={tabIndex}
              >
                {isActive &&
                  cloneElement<ChildCardType>(child as any, {
                    isActive,
                    onNextCard: () => {
                      onControlledToNextCard();
                      child.props.onNextCard?.();
                    },
                  })}
              </CardWrapper>
            );
          })}
        </Carousel>
        <NavBarContainer>
          <NavBarContentWrapper>
            <LeftNavigationButton
              icon={<ArrowLeftIcon />}
              disabled={currentCardIndex <= 0}
              title="Previous"
              aria-controls={carouselId}
              onClick={() => onControlledToPrevCard()}
              $isVisible={currentCardIndex > 0}
            />
            <ProgressTrack>
              <ProgressBar $progress={progress} />
            </ProgressTrack>
            <Tooltip
              title={whyNextDisabled}
              disableFocusListener={!isNextDisabled}
              disableHoverListener={!isNextDisabled}
              disableTouchListener={!isNextDisabled}
            >
              <div>
                <RightNavigationButton
                  icon={<ArrowRightIcon />}
                  disabled={isNextDisabled}
                  title="Next"
                  aria-controls={carouselId}
                  onClick={() => onControlledToNextCard()}
                  $isVisible={currentCardIndex + 1 < cardCount}
                />
              </div>
            </Tooltip>
          </NavBarContentWrapper>
        </NavBarContainer>
      </Root>
    );
  }
);

CardStack.displayName = "CardStack";
