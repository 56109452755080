import type videojs from "video.js";

/**
 * Toggle closed captions when one captions track is present
 *
 * @param player Video.js player
 * @param enable whether to enable captions — toggles if unset
 * @returns void
 */
export const toggleDefaultCaptions = (
  player: videojs.Player,
  enable?: boolean
) => {
  let showCaptions: boolean;
  const captionsButton = document.querySelector("button.vjs-subs-caps-button");
  const textTracks = player.textTracks();

  if (!textTracks.length) {
    return;
  }

  for (let i = 0; i < textTracks.length; i++) {
    const { kind, mode } = textTracks[i];
    if (["captions", "subtitles"].includes(kind)) {
      showCaptions = enable === undefined ? mode === "hidden" : enable;

      if (showCaptions) {
        textTracks[i].mode = "showing";
        captionsButton?.setAttribute("aria-checked", "true");
      } else {
        textTracks[i].mode = "hidden";
        captionsButton?.setAttribute("aria-checked", "false");
      }
    }
  }
};
