import { styled } from "@mui/material/styles";

export const TextContainer = styled("div")<{
  lineCount: number;
}>`
  display: -webkit-box;
  -webkit-line-clamp: ${(p: { lineCount: number }) =>
    `${p.lineCount}`}; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
