"use client";

import React, { createRef, useEffect, useMemo, useState } from "react";
import { BaseCard, CardProps } from "../BaseCard";
import { DocumentWrapper, FileCardWrapper } from "./styles";
import { pdfjs, Document, Page } from "react-pdf";

import { CircularProgress } from "../../../CircularProgress";
import { Dialog } from "@mui/material";
import { DialogHeader } from "../../../dialog";

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

export type FileCardProps = CardProps & {
  title?: string;
  url?: string;
};

export function FileCard({ url = "", onCardActive }: FileCardProps) {
  const [numPages, setNumPages] = useState<number>(0);
  const fullScreenRef = createRef<HTMLDivElement>();
  const primaryRef = createRef<HTMLDivElement>();
  const [pageWidth, setPageWidth] = useState<number>(0);
  const [pagesRendered, setPagesRendered] = useState<number>(0);

  const [fullScreenWidth, setFullScreenWidth] = useState<number>(0);
  const [open, setOpen] = useState(false);

  const options = useMemo(() => {
    return {
      cMapUrl: `cmaps/`,
      standardFontDataUrl: `standard_fonts/`,
      httpHeaders: {
        "Accept-Encoding": "identity",
      },
    };
  }, []);

  useEffect(() => {
    const updatePageWidth = () => {
      const container = fullScreenRef.current;

      if (container) {
        setFullScreenWidth(container.clientWidth);
      }
    };
    updatePageWidth();

    window.addEventListener("resize", updatePageWidth);

    return () => window.removeEventListener("resize", updatePageWidth);
  }, [fullScreenRef]);

  useEffect(() => {
    const updatePageWidth = () => {
      const container = primaryRef.current;

      if (container) {
        setPageWidth(container.clientWidth);
      }
    };
    updatePageWidth();

    window.addEventListener("resize", updatePageWidth);

    return () => window.removeEventListener("resize", updatePageWidth);
  }, [primaryRef]);

  const pagesRenderedPlusOne = Math.min(pagesRendered + 1, numPages);

  return (
    <FileCardWrapper ref={primaryRef}>
      <BaseCard isActive={true} onCardActive={onCardActive}>
        <DocumentWrapper>
          <Document
            loading={<CircularProgress />}
            file={url}
            options={options}
            onLoadSuccess={(doc) => {
              setNumPages(doc.numPages);
              setPagesRendered(0);
            }}
          >
            {" "}
            {Array.from(new Array(numPages), (el, index) => {
              const isCurrentlyRendering = pagesRenderedPlusOne === index + 1;
              const isLastPage = numPages === index + 1;
              const needsCallbackToRenderNextPage =
                isCurrentlyRendering && !isLastPage;

              return (
                <Page
                  devicePixelRatio={1}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  onClick={() => setOpen(true)}
                  width={pageWidth}
                  loading={<CircularProgress />}
                  onRenderSuccess={() => {
                    if (needsCallbackToRenderNextPage) {
                      setPagesRendered(index + 1);
                    }
                  }}
                />
              );
            })}
          </Document>
        </DocumentWrapper>
      </BaseCard>
      {/* keep mounted is good for performance here. */}
      <Dialog open={open} fullScreen>
        <DialogHeader onClose={() => setOpen(false)} />
        <DocumentWrapper ref={fullScreenRef}>
          <Document
            loading={<CircularProgress />}
            file={url}
            options={options}
            onLoadSuccess={(doc) => {
              setNumPages(doc.numPages);
              setPagesRendered(0);
            }}
          >
            {" "}
            {Array.from(new Array(numPages), (el, index) => {
              const isCurrentlyRendering = pagesRenderedPlusOne === index + 1;
              const isLastPage = numPages === index + 1;
              const needsCallbackToRenderNextPage =
                isCurrentlyRendering && !isLastPage;

              return (
                <Page
                  devicePixelRatio={1}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  onClick={() => setOpen(true)}
                  width={fullScreenWidth}
                  onRenderSuccess={() => {
                    if (needsCallbackToRenderNextPage) {
                      setPagesRendered(index + 1);
                    }
                  }}
                />
              );
            })}
          </Document>
        </DocumentWrapper>
      </Dialog>
    </FileCardWrapper>
  );
}
