import React from "react";
import { IconProps, PlayIcon, PauseIcon } from "../../../../../../icons";

type Props = Omit<IconProps, "children"> & {
  isPlaying?: boolean;
};

export function ActionIcon({ isPlaying, ...props }: Props) {
  return isPlaying ? <PauseIcon {...props} /> : <PlayIcon {...props} />;
}
