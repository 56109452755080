import { styled } from "@mui/material/styles";

export const FileCardWrapper = styled("div")`
  height: 100%;
  width: 600px;
  max-width: 600px;
  margin-top: 80px;

  @supports (-webkit-touch-callout: none) {
    overflow-y: auto;
    height: calc(100vh - 200px);
  }

  ${({ theme }) => theme.breakpoint.maxWidth.xs`
    width: 100%;
  `};
`;

export const DocumentWrapper = styled("div", {
  label: "DocumentWrapper",
  name: "DocumentWrapper",
})`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: start;
  height: 100%;
  background-color: white;
  width: 100%;

  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .react-pdf__Page {
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .react-pdf_message {
    padding: 20px;
    color: white;
  }
`;
