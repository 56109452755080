import { styled } from "@mui/material/styles";
import { BSPBody2 } from "../BSPBody2";
import { Button } from "../Button";
import { ActionIcon } from "./components/ActionIcon";

export const Container = styled("div")`
  min-width: 200px;
  width: 100%;
  background: ${({ theme }) => theme.kinetic.v2.palette.rgb.white};
  border-radius: ${({ theme }) => theme.kinetic.borderRadius.md};
  border: 1px solid ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiLight};
  padding: ${({ theme }) => theme.kinetic.paddings[16]};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledActionIcon = styled(ActionIcon)`
  width: 30px;
  height: 30px;
`;

export const ActionButton = styled(Button)`
  border-radius: 40px;
  width: 40px;
  height: 40px;
  padding: 0 !important;
`;

ActionButton.defaultProps = {
  color: "primary",
  align: "center",
};

export const Track = styled("div")`
  background: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiLight};
  border-radius: ${({ theme }) => theme.kinetic.borderRadius.sm};
  width: 100%;
  height: 8px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
`;

export const TrackFill = styled("div")<{ $disabled: boolean }>`
  background: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiDarker};
  border-radius: ${({ theme }) => theme.kinetic.borderRadius.sm};
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  ${({ $disabled }) => $disabled && "display: none"};
`;

export const Playhead = styled("button")`
  background: ${({ theme }) => theme.kinetic.v2.palette.rgb.white};
  border: solid 1px ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiDark};
  border-radius: ${({ theme }) => theme.kinetic.borderRadius.md};
  width: 12px;
  height: 12px;
  padding: 0;
  position: absolute;

  &:disabled {
    display: none;
  }

  &:active {
    background: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiLight};
  }

  &:focus-visible {
    outline: solid 2px ${({ theme }) => theme.kinetic.v2.palette.rgb.blue};
    outline-offset: 2px;
  }
`;

export const Duration = styled(BSPBody2)`
  font-weight: 500;
  margin-right: 4px;
  user-select: none;
`;
