import React, { useMemo, useState } from "react";
import { Button } from "../../../../../Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
} from "../../../../../dialog";
import { Grid } from "../../../../../Grid";
import { BSPBody1 } from "../../../../../BSPBody1";
import { BlurIcon, ImageWrapper, MoreBlurIcon } from "./styles";
import deviceDetect from "ismobilejs";

export enum EffectType {
  NONE = "none",
  BLUR = "blur",
  BACKGROUND = "background",
}

interface EffectValueMap {
  [EffectType.NONE]: undefined;
  [EffectType.BLUR]: number;
  [EffectType.BACKGROUND]: string;
}

export type Effect<Type extends EffectType> = {
  type: Type;
  value: EffectValueMap[Type];
};

type Props = {
  isOpen: boolean;
  onEffectChange: (effect: Effect<EffectType>) => void;
  onAcceptClick: () => void;
};

const MEDIUM_BACKGROUND_BLUR_AMOUNT = 5;
const FULL_BACKGROUND_BLUR_AMOUNT = 10;
const BACKGROUND_IMAGES = [
  {
    thumbnail:
      "https://media.bigspring.io/image/upload/q_60,c_thumb,w_200,g_face/v1666991281/Camera%20Backgrounds/beach.jpg",
    full: "https://media.bigspring.io/image/upload/c_scale,q_75,w_1920/v1666991281/Camera%20Backgrounds/beach.jpg",
  },
  {
    thumbnail:
      "https://media.bigspring.io/image/upload/q_60,c_thumb,w_200,g_face/v1666991402/Camera%20Backgrounds/mountain.jpg",
    full: "https://media.bigspring.io/image/upload/c_scale,q_75,w_1920/v1666991281/Camera%20Backgrounds/mountain.jpg",
  },
  {
    thumbnail:
      "https://media.bigspring.io/image/upload/q_60,c_thumb,w_200,g_face/v1666991795/Camera%20Backgrounds/office.jpg",
    full: "https://media.bigspring.io/image/upload/c_scale,q_75,w_1920/v1666991281/Camera%20Backgrounds/office.jpg",
  },
  {
    thumbnail:
      "https://media.bigspring.io/image/upload/q_60,c_thumb,w_200,g_face/v1667224413/Camera%20Backgrounds/house.jpg",
    full: "https://media.bigspring.io/image/upload/c_scale,q_75,w_1920/v1667224413/Camera%20Backgrounds/house.jpg",
  },
  {
    thumbnail:
      "https://media.bigspring.io/image/upload/q_60,c_thumb,w_200,g_face/v1666992487/Camera%20Backgrounds/field.jpg",
    full: "https://media.bigspring.io/image/upload/c_scale,q_75,w_1920/v1666991281/Camera%20Backgrounds/field.jpg",
  },
  {
    thumbnail:
      "https://media.bigspring.io/image/upload/q_60,c_thumb,w_200,g_face/v1666992499/Camera%20Backgrounds/wall.jpg",
    full: "https://media.bigspring.io/image/upload/c_scale,q_75,w_1920/v1666991281/Camera%20Backgrounds/wall.jpg",
  },
];

const MOBILE_BACKGROUND_IMAGES = [
  {
    thumbnail:
      "https://media.bigspring.io/image/upload/q_60,c_thumb,w_200,g_face/v1667249406/Camera%20Backgrounds/beach_mobile.jpg",
    full: "https://media.bigspring.io/image/upload/c_scale,q_70,w_640/v1667249406/Camera%20Backgrounds/beach_mobile.jpg",
  },
  {
    thumbnail:
      "https://media.bigspring.io/image/upload/q_60,c_thumb,w_200,g_face/v1667250336/Camera%20Backgrounds/mountain_mobile.jpg",
    full: "https://media.bigspring.io/image/upload/c_scale,q_70,w_640/v1667250336/Camera%20Backgrounds/mountain_mobile.jpg",
  },
  {
    thumbnail:
      "https://media.bigspring.io/image/upload/q_60,c_thumb,w_200,g_face/v1667250422/Camera%20Backgrounds/office_mobile.jpg",
    full: "https://media.bigspring.io/image/upload/c_scale,q_70,w_640/v1667250422/Camera%20Backgrounds/office_mobile.jpg",
  },
  {
    thumbnail:
      "https://media.bigspring.io/image/upload/q_60,c_thumb,w_200,g_face/v1667250473/Camera%20Backgrounds/house_mobile.jpg",
    full: "https://media.bigspring.io/image/upload/c_scale,q_70,w_640/v1667250473/Camera%20Backgrounds/house_mobile.jpg",
  },
  {
    thumbnail:
      "https://media.bigspring.io/image/upload/q_60,c_thumb,w_200,g_face/v1667250503/Camera%20Backgrounds/field_mobile.jpg",
    full: "https://media.bigspring.io/image/upload/c_scale,q_70,w_640/v1667250503/Camera%20Backgrounds/field_mobile.jpg",
  },
  {
    thumbnail:
      "https://media.bigspring.io/image/upload/q_60,c_thumb,w_200,g_face/v1667250527/Camera%20Backgrounds/wall_mobile.jpg",
    full: "https://media.bigspring.io/image/upload/c_scale,q_70,w_640/v1667250527/Camera%20Backgrounds/wall_mobile.jpg",
  },
];

export function VisualEffectsModal({
  isOpen,
  onEffectChange,
  onAcceptClick,
}: Props) {
  const isMobile = deviceDetect(window.navigator).phone;

  const [selected, setSelected] = useState<Effect<EffectType>>({
    type: EffectType.NONE,
    value: undefined,
  });

  const onEffectClick = (
    type: EffectType,
    value: EffectValueMap[EffectType]
  ) => {
    onEffectChange({ type, value });

    setSelected({ type, value });
  };

  const backgroundImages = useMemo(
    () => (isMobile ? MOBILE_BACKGROUND_IMAGES : BACKGROUND_IMAGES),
    [isMobile]
  );

  return (
    <Dialog
      open={isOpen}
      fullWidth
      fullScreen={isMobile}
      onClose={onAcceptClick}
    >
      <DialogHeader onClose={onAcceptClick}>Background</DialogHeader>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <ImageWrapper
              $isMobile={isMobile}
              $isSelected={selected.value === undefined}
              onClick={() => onEffectClick(EffectType.NONE, undefined)}
            >
              <BSPBody1>None</BSPBody1>
            </ImageWrapper>
          </Grid>
          <Grid item xs={4}>
            <ImageWrapper
              $isMobile={isMobile}
              $isSelected={selected.value === MEDIUM_BACKGROUND_BLUR_AMOUNT}
              onClick={() =>
                onEffectClick(EffectType.BLUR, MEDIUM_BACKGROUND_BLUR_AMOUNT)
              }
            >
              <BlurIcon />
            </ImageWrapper>
          </Grid>
          <Grid item xs={4}>
            <ImageWrapper
              $isMobile={isMobile}
              $isSelected={selected.value === FULL_BACKGROUND_BLUR_AMOUNT}
              onClick={() =>
                onEffectClick(EffectType.BLUR, FULL_BACKGROUND_BLUR_AMOUNT)
              }
            >
              <MoreBlurIcon />
            </ImageWrapper>
          </Grid>
          {backgroundImages.map((image) => (
            <Grid key={image.full} item xs={4}>
              <ImageWrapper
                $isMobile={isMobile}
                $isSelected={selected.value === image.full}
                $backgroundImage={image.thumbnail}
                onClick={() => onEffectClick(EffectType.BACKGROUND, image.full)}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button text="Ok" align="center" onClick={onAcceptClick} />
      </DialogActions>
    </Dialog>
  );
}
