import { CC } from "@bigspring/core-components";
import { Wrapper } from "./styles";

export function LoadingContainer() {
  return (
    <Wrapper>
      <CC.CircularProgress />
    </Wrapper>
  );
}
