import React, { useState } from "react";
import { MenuOption } from "../../styles";
import { Tooltip } from "../../../Tooltip";
import {
  MagicIcon,
  Menu,
  MicOffIcon,
  MicOnIcon,
  MoreIcon,
  OptionWrapper,
  SettingsIcon,
  TextOffIcon,
  TextOnIcon,
  VideoCamIcon,
  VideoCamOffIcon,
  Wrapper,
} from "./styles";
import { SelectedDevices, SettingsModal } from "./components/SettingsModal";
import {
  Effect,
  EffectType,
  VisualEffectsModal,
} from "./components/VisualEffectsModal";

export enum OptionType {
  MIC = "mic",
  CAMERA = "camera",
  PROMPTER = "prompter",
}

type Props = {
  hasShootStarted: boolean;
  isCameraEnabled: boolean;
  isMicEnabled: boolean;
  isPrompterEnabled: boolean;
  availableDevices: MediaDeviceInfo[];
  selectedDevices: SelectedDevices;
  onSettingsChange: (selectedDevices: SelectedDevices) => void;
  onOptionClick: (type: OptionType) => void;
  onEffectChange: (effect: Effect<EffectType>) => void;
};

export function OptionsMenu({
  hasShootStarted,
  isCameraEnabled,
  isMicEnabled,
  isPrompterEnabled,
  availableDevices,
  selectedDevices,
  onSettingsChange,
  onOptionClick,
  onEffectChange,
}: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isVisualEffectsModalOpen, setIsVisualEffectsModalOpen] =
    useState(false);

  return (
    <>
      {isSettingsModalOpen && (
        <SettingsModal
          hasShootStarted={hasShootStarted}
          availableDevices={availableDevices}
          selectedDevices={selectedDevices}
          onInputChange={(selectedDevices) => onSettingsChange(selectedDevices)}
          onAcceptClick={() => setIsSettingsModalOpen(false)}
        />
      )}
      <VisualEffectsModal
        isOpen={isVisualEffectsModalOpen}
        onEffectChange={onEffectChange}
        onAcceptClick={() => setIsVisualEffectsModalOpen(false)}
      />
      <Wrapper>
        <MenuOption onClick={() => setIsMenuOpen((prevState) => !prevState)}>
          <MoreIcon />
        </MenuOption>
        <Menu $isOpen={isMenuOpen}>
          <Tooltip
            title={isPrompterEnabled ? "Turn off prompter" : "Turn on prompter"}
            placement="left"
          >
            <OptionWrapper onClick={() => onOptionClick(OptionType.PROMPTER)}>
              {isPrompterEnabled ? <TextOffIcon /> : <TextOnIcon />}
            </OptionWrapper>
          </Tooltip>
          <Tooltip title="Apply visual effects" placement="left">
            <OptionWrapper onClick={() => setIsVisualEffectsModalOpen(true)}>
              <MagicIcon />
            </OptionWrapper>
          </Tooltip>
          <Tooltip
            title={isCameraEnabled ? "Turn off camera" : "Turn on camera"}
            placement="left"
          >
            <OptionWrapper onClick={() => onOptionClick(OptionType.CAMERA)}>
              {isCameraEnabled ? <VideoCamIcon /> : <VideoCamOffIcon />}
            </OptionWrapper>
          </Tooltip>
          <Tooltip
            title={isMicEnabled ? "Mute microphone" : "Unmute microphone"}
            placement="left"
          >
            <OptionWrapper onClick={() => onOptionClick(OptionType.MIC)}>
              {isMicEnabled ? <MicOnIcon /> : <MicOffIcon />}
            </OptionWrapper>
          </Tooltip>
          <Tooltip title="Settings" placement="left">
            <OptionWrapper onClick={() => setIsSettingsModalOpen(true)}>
              <SettingsIcon />
            </OptionWrapper>
          </Tooltip>
        </Menu>
      </Wrapper>
    </>
  );
}
