import { SC } from "@bigspring/core-components";
import { Button } from "@shared/Button";

export const CreateCompanyButton = SC.styled(Button)`
  &.MuiButtonBase-root {
    display: block;
    margin: 0 auto;
    width: 95%;
  }
  .MuiButton-label span {
    font-size: 16px;
    line-height: 24px;
  }
`;
