import { styled, Theme } from "@mui/material/styles";
import MenuMui from "@mui/material/Menu";
import MenuItemMui from "@mui/material/MenuItem";
import { withTransientProps } from "../../../lib/emotion";

export type Color = "primary" | "default";

export const Menu = styled(MenuMui)`
  .MuiPaper-root {
    border-radius: 8px;
    max-height: 460px;
    max-width: 245px;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
    }
    ul {
      padding: 20px 0;
    }
  }
`;

export const HeaderMenuItem = styled(MenuItemMui)`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  &.MuiMenuItem-root {
    width: 100%;
    background-color: white;
    &:hover {
      background-color: transparent;
    }
  }
`;

function getColor(theme: Theme, color?: Color) {
  switch (color) {
    case "primary":
      return {
        color: theme.radix.colors.blue.blue2,
        hover: theme.radix.colors.blue.blue1,
      };
    default:
      return { color: "#555553", hover: undefined };
  }
}

export const MenuItem = styled(MenuItemMui, withTransientProps)<{
  $color?: Color;
}>`
  height: 45px;
  padding: 6px 22px;
  display: flex;
  justify-content: initial;
  &.Mui-selected {
    color: ${({ theme, $color }) => getColor(theme, $color).color};
    background-color: transparent;
    svg {
      margin-left: 10px;
      path {
        fill: ${({ theme, $color }) =>
          getColor(theme, $color).color} !important;
      }
    }
    &:hover {
      background-color: ${({ theme, $color }) => getColor(theme, $color).hover};
    }
  }
  &:hover {
    background-color: ${({ theme, $color }) => getColor(theme, $color).hover};
  }
`;
