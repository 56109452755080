import { styled } from "@mui/material/styles";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";

export const StyledTabPanel = styled(TabPanel)`
  position: relative;
  background-color: ${({ theme }) =>
    theme.kinetic.v3.palette.chroma.neutraltint[100].css()};
  overflow-x: scroll;
`;

export const StyledTabsList = styled(TabList)`
  overflow: visible;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border[1]};

  & .MuiTabs-scroller {
    overflow: visible !important;
  }

  & .MuiTab-root {
    padding-left: 0;
    padding-right: 0;
    margin-right: 20px;
    color: black;
    text-transform: none;
  }

  & .Mui-selected {
  }

  & .MuiTabs-indicator {
    bottom: -4px;
    height: 7px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.action[1]};
  }
`;
