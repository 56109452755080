import { CC } from "@bigspring/core-components";
import { Button } from "components/shared/Button";
import { Separator } from "components/shared/Separator";
import {
  ErrorContainer,
  ErrorHeader,
  ErrorIconContainer,
  ErrorMessage,
} from "./styles";
import { useCustomRouter } from "components/shared/CustomRouter";
import { useIntl } from "react-intl";

type Props = {
  title?: string;
  friendlyMessage?: string;
  icon?: React.ComponentType;
  err?: Error;
  statusCode?: number;
  onTryAgainClick?: VoidFunction;
};

export function AdminError({
  title,
  friendlyMessage = "Oops! Something went wrong.",
  icon: ErrorIcon = CC.WarningIcon,
  err,
  statusCode,
  onTryAgainClick: _onTryAgainClick,
}: Props) {
  const { formatMessage } = useIntl();
  const { back, reload } = useCustomRouter();

  const errorTitle = title || `Error${statusCode ? ` ${statusCode}` : ""}`;

  const shouldRenderMessage = !!err?.message && statusCode !== 404;

  const onTryAgainClick = () => {
    if (_onTryAgainClick) {
      _onTryAgainClick();
    } else {
      reload();
    }
  };

  return (
    <ErrorContainer>
      <CC.Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <CC.Grid item>
          <ErrorHeader>
            <ErrorIconContainer>
              <ErrorIcon />
            </ErrorIconContainer>
            <CC.Grid item>
              <CC.BSPH4>{errorTitle}</CC.BSPH4>
            </CC.Grid>
          </ErrorHeader>
        </CC.Grid>
        <CC.Grid item>
          <CC.Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <CC.Grid item>
              <CC.Alert severity="error" icon={false}>
                {friendlyMessage}
                {shouldRenderMessage && (
                  <>
                    <Separator />
                    <ErrorMessage>{err.message}</ErrorMessage>
                  </>
                )}
              </CC.Alert>
            </CC.Grid>
            <Separator />
            <CC.Grid container spacing={2}>
              <CC.Grid item xs={6}>
                <Button
                  text="Go Back"
                  align="center"
                  color="utility"
                  onClick={back}
                />
              </CC.Grid>
              <CC.Grid item xs={6}>
                <Button
                  text={formatMessage({
                    id: "common.try_again",
                    defaultMessage: "Try Again",
                  })}
                  align="center"
                  color="utility"
                  onClick={onTryAgainClick}
                />
              </CC.Grid>
            </CC.Grid>
          </CC.Grid>
        </CC.Grid>
      </CC.Grid>
    </ErrorContainer>
  );
}
