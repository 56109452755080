import React, { PropsWithChildren, MouseEventHandler } from "react";
import { Grid, GridProps } from "../../../Grid";
import { RoundButton } from "../../../RoundButton";
import { ArrowLeftIcon, CloseIcon } from "../../../../../../icons";
import { Heading1 } from "../Typography";
import { Section, LeftItem, RightItem } from "./styles";

type Props = PropsWithChildren<
  | {
      onBack?: MouseEventHandler<HTMLButtonElement>;
      onClose?: never;
    }
  | {
      onBack?: never;
      onClose?: MouseEventHandler<HTMLButtonElement>;
    }
>;

export function Header({ children, onBack, onClose }: Props) {
  const hasLeftButton = !!onBack;
  const hasRightButton = !!onClose;

  const innerProps: GridProps = { alignItems: "center" };

  if (hasRightButton) {
    innerProps.justifyContent = "space-between";
  }

  return (
    <Section
      stretchLeft={hasLeftButton}
      stretchRight={hasRightButton}
      innerProps={innerProps}
    >
      {hasLeftButton && (
        <LeftItem item>
          <RoundButton aria-label="Back" onClick={onBack}>
            <ArrowLeftIcon $large />
          </RoundButton>
        </LeftItem>
      )}
      <Grid item>
        <Heading1>{children}</Heading1>
      </Grid>
      {hasRightButton && (
        <RightItem item>
          <RoundButton aria-label="Close" onClick={onClose}>
            <CloseIcon $large />
          </RoundButton>
        </RightItem>
      )}
    </Section>
  );
}
