import { ContainerProps as ContainerPropsBase } from "@mui/material/Container";
import { Container as StyledContainer } from "./styles";
import React from "react";
import { Theme } from "../../styles/theme";

export type ContainerProps = ContainerPropsBase &
  React.PropsWithChildren<{
    borderRadius?: Theme["kinetic"]["borderRadius"];
    white?: boolean;
    height?: React.CSSProperties["height"];
  }>;

export function Container({
  children,
  borderRadius,
  white,
  height,
  ...rest
}: ContainerProps) {
  return (
    <StyledContainer
      $borderRadius={borderRadius}
      $white={white}
      $height={height}
      {...rest}
    >
      {children}
    </StyledContainer>
  );
}
