import { css, styled } from "@mui/material/styles";

export const FlashCardContainer = styled("div")<{ $isVerticalCard?: string }>`
  padding-top: ${(props) => (props.$isVerticalCard ? "0px" : "40px")};
  height: 100%;
  width: 600px;
  max-width: 600px;

  @supports (-webkit-touch-callout: none) {
    overflow-y: auto;
    height: calc(100vh - 200px);
  }

  ${({ theme }) => theme.breakpoint.maxWidth.xs`
    width: 100%;
  `};

  ${({ $isVerticalCard, theme }) => {
    if ($isVerticalCard) {
      return css`
        background-image: url(${$isVerticalCard});
        background-position: top center;
        background-size: contain;
        background-repeat: no-repeat;
        background-attachment: fixed;
        width: 100vw;
        max-width: 600px;

        ${theme.breakpoint.maxWidth.xs`
          width: 100vw;
        `};
      `;
    }
  }}
`;
