import React, { useState } from "react";
import { MenuOption } from "../../styles";
import {
  BackgroundsIcon,
  CollectionsIcon,
  Menu,
  MoreIcon,
  OptionWrapper,
  Wrapper,
} from "./styles";
import {
  TextFieldsIcon,
  FormatClearIcon,
  MicOnIcon,
  MicOffIcon,
  SettingsIcon2,
  VideoCamIcon,
  VideoCamOffIcon,
} from "../../../../../../icons";
import { useIntl } from "react-intl";

export enum OptionType {
  MIC = "mic",
  CAMERA = "camera",
  PROMPTER = "prompter",
  SETTINGS = "settings",
  BACKGROUNDS = "backgrounds",
  UPLOAD_FILE = "upload_file",
}

type Props = {
  localizations?: {
    cameraOn?: string;
    cameraOff?: string;
    micOn?: string;
    micOff?: string;
    backgrounds?: string;
    teleprompterOn?: string;
    teleprompterOff?: string;
    uploadFile?: string;
    settings?: string;
  };
  showMicIcon: boolean;
  showTelePrompterIcon: boolean;
  isCameraEnabled: boolean;
  isMicEnabled: boolean;
  isPrompterEnabled: boolean;
  onOptionClick: (type: OptionType) => void;
};

export function OptionsMenu({
  localizations,
  showMicIcon,
  showTelePrompterIcon,
  isCameraEnabled,
  isMicEnabled,
  isPrompterEnabled,
  onOptionClick,
}: Props) {
  const { formatMessage } = useIntl();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Wrapper>
      <MenuOption
        color="dark"
        onClick={() => setIsMenuOpen((prevState) => !prevState)}
      >
        <MoreIcon />
      </MenuOption>
      <Menu $isOpen={isMenuOpen}>
        <OptionWrapper onClick={() => onOptionClick(OptionType.CAMERA)}>
          {isCameraEnabled ? (
            <>
              <VideoCamIcon />
              <span>
                {localizations?.cameraOn ??
                  formatMessage({
                    id: "rep_creator.settings_menu.camera_on",
                    defaultMessage: "Camera on",
                  })}
              </span>
            </>
          ) : (
            <>
              <VideoCamOffIcon />
              <span>
                {localizations?.cameraOff ??
                  formatMessage({
                    id: "rep_creator.settings_menu.camera_off",
                    defaultMessage: "Camera off",
                  })}
              </span>
            </>
          )}
        </OptionWrapper>
        {showMicIcon && (
          <OptionWrapper onClick={() => onOptionClick(OptionType.MIC)}>
            {isMicEnabled ? (
              <>
                <MicOnIcon />
                {localizations?.micOn ??
                  formatMessage({
                    id: "rep_creator.settings_menu.mic_on",
                    defaultMessage: "Mic on",
                  })}
              </>
            ) : (
              <>
                <MicOffIcon />
                {localizations?.micOff ??
                  formatMessage({
                    id: "rep_creator.settings_menu.mic_off",
                    defaultMessage: "Mic off",
                  })}
              </>
            )}
          </OptionWrapper>
        )}
        <OptionWrapper onClick={() => onOptionClick(OptionType.BACKGROUNDS)}>
          <BackgroundsIcon />
          <span>
            {localizations?.backgrounds ??
              formatMessage({
                id: "rep_creator.settings_menu.backgrounds",
                defaultMessage: "Backgrounds",
              })}
          </span>
        </OptionWrapper>
        {showTelePrompterIcon && (
          <OptionWrapper onClick={() => onOptionClick(OptionType.PROMPTER)}>
            {isPrompterEnabled ? (
              <>
                <TextFieldsIcon />
                <span>
                  {localizations?.teleprompterOn ??
                    formatMessage({
                      id: "rep_creator.settings_menu.teleprompter_on",
                      defaultMessage: "Teleprompter on",
                    })}
                </span>
              </>
            ) : (
              <>
                <FormatClearIcon />
                <span>
                  {localizations?.teleprompterOff ??
                    formatMessage({
                      id: "rep_creator.settings_menu.teleprompter_off",
                      defaultMessage: "Teleprompter off",
                    })}
                </span>
              </>
            )}
          </OptionWrapper>
        )}
        <OptionWrapper onClick={() => onOptionClick(OptionType.UPLOAD_FILE)}>
          <CollectionsIcon />
          <span>
            {localizations?.uploadFile ??
              formatMessage({
                id: "rep_creator.settings_menu.upload_file",
                defaultMessage: "Upload File",
              })}
          </span>
        </OptionWrapper>
        <OptionWrapper onClick={() => onOptionClick(OptionType.SETTINGS)}>
          <SettingsIcon2 />
          <span>
            {localizations?.settings ??
              formatMessage({
                defaultMessage: "Settings",
                id: "common.settings",
              })}
          </span>
        </OptionWrapper>
      </Menu>
    </Wrapper>
  );
}
