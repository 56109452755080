import { styled } from "@mui/material/styles";
import { Button } from "../Button";
import { BSPBody1 } from "../BSPBody1";
import { BSPBody2 } from "../BSPBody2";

export const LoadingWrapper = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.background[3]};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionsArea = styled("div")`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 208px;
  display: grid;
  grid-template-columns: 1fr 0.2fr 1fr;
  column-gap: 10px;
  align-items: center;
  justify-items: center;
  ${({ theme }) => theme.breakpoint.maxWidth.sm`
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 32%, rgba(0, 0, 0, 0.8) 90%);
  `}
  ${({ theme }) => theme.breakpoint.maxWidth.md`
    grid-template-columns: 2fr 140px 2fr;
    height: 192px;
  `}
`;

export const LeftMenuWrapper = styled("div")`
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
  justify-self: center;
  align-self: flex-start;
`;

export const CenterMenuWrapper = styled("div")`
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
  align-items: center;
`;

export const RightMenuWrapper = styled("div")`
  display: grid;
  grid-auto-flow: row;
  row-gap: 20px;
  justify-self: center;
  align-self: center;
`;

export const RecordWrapper = styled("div")`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 11px;
  min-height: 125px;
  min-width: 80px;
  ${({ theme }) => theme.breakpoint.maxWidth.md`
    bottom: 14px;
  `}
`;

export const RecordButton = styled("button")<{
  $hideBackground?: boolean;
  $showStop?: boolean;
}>`
  position: absolute;
  top: ${({ $showStop }) => ($showStop ? 14 : 0)}px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  border-radius: ${({ $showStop }) => ($showStop ? "4px" : "50%")};
  background-color: ${({ $hideBackground }) =>
    $hideBackground ? "transparent" : "#EF3C24"};
  border: 1px solid
    ${({ $hideBackground }) => ($hideBackground ? "transparent" : " #F47766")};
  cursor: pointer;
  margin-bottom: ${({ $showStop }) => ($showStop ? "28px" : 0)};
  width: ${({ $showStop }) => ($showStop ? 36 : 64)}px;
  height: ${({ $showStop }) => ($showStop ? 36 : 64)}px;

  &:disabled {
    opacity: 0.5;
    cursor: initial;
  }

  &::after {
    content: "";
    position: absolute;
    top: ${({ $showStop }) => ($showStop ? -23 : -9)}px;
    left: ${({ $showStop }) => ($showStop ? -23 : -9)}px;
    right: ${({ $showStop }) => ($showStop ? -23 : -9)}px;
    bottom: ${({ $showStop }) => ($showStop ? -23 : -9)}px;
    border: 4px solid white;
    border-radius: 50%;
  }

  ${({ theme, $showStop }) => theme.breakpoint.maxWidth.md`
    top: ${$showStop ? 8 : 0}px;
    width: ${$showStop ? 30 : 46}px;
    height: ${$showStop ? 30 : 46}px;
    &::after {
      top: ${$showStop ? -17 : -9}px;
      left: ${$showStop ? -17 : -9}px;
      right: ${$showStop ? -17 : -9}px;
      bottom: ${$showStop ? -17 : -9}px;
    }
  `}
`;

export const CountDown = styled(BSPBody1)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  font-weight: 700;
  color: white;
  ${({ theme }) => theme.breakpoint.maxWidth.md`
    font-size: 2rem;
  `}
`;

export const Time = styled(BSPBody2)`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #313130;
  border-radius: 24px;
  color: #d7d7d6;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 3px 8px;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.breakpoint.maxWidth.md`
    display: none;
  `}
`;

export const TimeMobile = styled(Time)`
  top: 30px;
  bottom: unset;
  display: none;
  ${({ theme }) => theme.breakpoint.maxWidth.md`
    display: flex;
  `}
`;

export const RedDot = styled("div")`
  border-radius: 50%;
  padding: 5px;
  margin-right: 5px;
  height: fit-content;
  animation: 1s linear 0s infinite alternate background-fade;

  @keyframes background-fade {
    from {
      background-color: #ef3c24;
    }
    to {
      background-color: transparent;
    }
  }
`;

export const MenuOption = styled(Button)`
  width: 44px;
  height: 44px;
  display: none;
  padding: 10px 10px;
  margin-bottom: 25px;
  ${({ theme }) => theme.breakpoint.maxWidth.sm`
    display: flex;
  `}
`;

MenuOption.defaultProps = {
  type: "button",
};
