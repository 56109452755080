import React, { SVGAttributes } from "react";
import { BSPBody1 } from "../BSPBody1";
import { BSPBody2 } from "../BSPBody2";
import { PhotoCameraIcon } from "../../../../icons";
import { css, styled } from "@mui/material/styles";
import { Button } from "../Button";
import { Grid } from "../Grid";

export const Wrapper = styled("div")`
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const LoadingWrapper = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.background[3]};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Backdrop = styled("div")`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.4;
`;

export const CountDown = styled(BSPBody1)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  font-weight: 700;
  color: white;
  ${({ theme }) => theme.breakpoint.maxWidth.md`
    font-size: 2rem;
  `}
`;

export const Time = styled(BSPBody2)`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #313130;
  border-radius: 24px;
  color: #d7d7d6;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 3px 8px;
  align-items: center;
  display: flex;
  ${({ theme }) => theme.breakpoint.maxWidth.sm`
    display: none;
  `}
`;

export const TimeMobile = styled(Time)`
  top: 30px;
  bottom: unset;
  display: none;
  ${({ theme }) => theme.breakpoint.maxWidth.sm`
    display: flex;
  `}
`;

export const RedDot = styled("div")`
  border-radius: 50%;
  padding: 5px;
  margin-right: 5px;
  height: fit-content;
  animation: 1s linear 0s infinite alternate background-fade;

  @keyframes background-fade {
    from {
      background-color: #ef3c24;
    }
    to {
      background-color: transparent;
    }
  }
`;

export const ActionsArea = styled("div")`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.2fr 1fr;
  column-gap: 10px;
  align-items: center;
  justify-items: center;
  padding-bottom: 84px;
  ${({ theme }) => theme.breakpoint.maxWidth.sm`
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 32%, rgba(0, 0, 0, 0.8) 90%);
  `}
  ${({ theme }) => theme.breakpoint.maxWidth.md`
    grid-template-columns: 2fr 140px 2fr;
  `}
`;

export const LeftMenuWrapper = styled("div")`
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
  justify-self: center;
  align-self: flex-start;
`;

export const CenterMenuWrapper = styled("div")`
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
  align-items: center;
`;

export const RightMenuWrapper = styled("div")`
  display: grid;
  grid-auto-flow: row;
  row-gap: 20px;
  justify-self: center;
  align-self: flex-start;
`;

export const RecordWrapper = styled("div")`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 11px;
  min-height: 125px;
  min-width: 80px;
  ${({ theme }) => theme.breakpoint.maxWidth.md`
    bottom: 14px;
  `}
`;

export const RecordButton = styled("button")<{
  $hideBackground?: boolean;
  $showStop?: boolean;
}>`
  position: absolute;
  top: ${({ $showStop }) => ($showStop ? 14 : 0)}px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  border-radius: ${({ $showStop }) => ($showStop ? "4px" : "50%")};
  background-color: ${({ $hideBackground }) =>
    $hideBackground ? "transparent" : "#EF3C24"};
  border: 1px solid
    ${({ $hideBackground }) => ($hideBackground ? "transparent" : " #F47766")};
  cursor: pointer;
  margin-bottom: ${({ $showStop }) => ($showStop ? "28px" : 0)};
  width: ${({ $showStop }) => ($showStop ? 36 : 64)}px;
  height: ${({ $showStop }) => ($showStop ? 36 : 64)}px;

  &:disabled {
    opacity: 0.5;
    cursor: initial;
  }

  &::after {
    content: "";
    position: absolute;
    top: ${({ $showStop }) => ($showStop ? -23 : -9)}px;
    left: ${({ $showStop }) => ($showStop ? -23 : -9)}px;
    right: ${({ $showStop }) => ($showStop ? -23 : -9)}px;
    bottom: ${({ $showStop }) => ($showStop ? -23 : -9)}px;
    border: 4px solid white;
    border-radius: 50%;
  }

  ${({ theme, $showStop }) => theme.breakpoint.maxWidth.md`
    top: ${$showStop ? 8 : 0}px;
    width: ${$showStop ? 32 : 48}px;
    height: ${$showStop ? 32 : 48}px;
    &::after {
      top: ${$showStop ? -17 : -9}px;
      left: ${$showStop ? -17 : -9}px;
      right: ${$showStop ? -17 : -9}px;
      bottom: ${$showStop ? -17 : -9}px;
    }
  `}
`;

export const Icon = css`
  color: white;
  cursor: pointer;
  /* width: 100%; */
  /* height: 100%; */
  transition: opacity 200ms ease;
  /* For Safari to not cause flickering */
  -webkit-transform: translate3d(0, 0, 0);
`;

export const CameraIcon = styled(PhotoCameraIcon)`
  user-select: none;
  ${Icon};
`;

export const CameraButton = styled("button")<{
  $hideBackground: boolean;
}>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  background-color: ${({ $hideBackground }) =>
    $hideBackground ? "transparent" : "#EF3C24"};
  border: 1px solid
    ${({ $hideBackground }) => ($hideBackground ? "transparent" : "#F47766")};
  border-radius: 50%;
  cursor: pointer;
  width: 64px;
  height: 64px;
  padding: 13px;

  &::after {
    content: "";
    position: absolute;
    top: -9px;
    left: -9px;
    right: -9px;
    bottom: -9px;
    border: 4px solid white;
    border-radius: 50%;
  }

  > svg {
    width: 36px;
    height: 36px;
  }

  ${({ theme }) => theme.breakpoint.maxWidth.md`
    width: 48px;
    height: 48px;
    padding: 10px;
    > svg {
      width: 26px;
      height: 26px;
    }
  `}
`;

export const AspectRatioIcon = styled((props: SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 30 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M19.5 19.5H25.5V13.5H23.25V17.25H19.5V19.5ZM4.5 10.5H6.75V6.75H10.5V4.5H4.5V10.5ZM9 24H3C2.175 24 1.469 23.7065 0.882 23.1195C0.294 22.5315 0 21.825 0 21V3C0 2.175 0.294 1.4685 0.882 0.8805C1.469 0.2935 2.175 0 3 0H27C27.825 0 28.5315 0.2935 29.1195 0.8805C29.7065 1.4685 30 2.175 30 3V21C30 21.825 29.7065 22.5315 29.1195 23.1195C28.5315 23.7065 27.825 24 27 24H21H9Z" />
  </svg>
))`
  width: 30px;
  height: 24px;
  fill: white;
`;

export const FlipCameraIcon = styled(() => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.66669 7.33335V5.66669H3.24169C4.39169 3.67502 6.54169 2.33335 9.00002 2.33335C12.1 2.33335 14.7084 4.46669 15.45 7.33335H17.1667C16.3917 3.53335 13.0334 0.666687 9.00002 0.666687C6.27502 0.666687 3.85002 1.98335 2.33335 4.00835V2.33335H0.666687V7.33335H5.66669Z" />
    <path d="M12.3334 10.6667V12.3334H14.7584C13.6084 14.325 11.4584 15.6667 9.00002 15.6667C5.90002 15.6667 3.29169 13.5334 2.55002 10.6667H0.833354C1.60835 14.4667 4.96669 17.3334 9.00002 17.3334C11.725 17.3334 14.15 16.0167 15.6667 13.9917V15.6667H17.3334V10.6667H12.3334Z" />
  </svg>
))`
  ${Icon};
`;

export const MenuOption = styled(Button)`
  width: 44px;
  height: 44px;
  display: none;
  padding: 10px 10px;
  ${({ theme }) => theme.breakpoint.maxWidth.sm`
    display: flex;
  `}
`;

MenuOption.defaultProps = {
  type: "button",
};

export const TextArea = styled("textarea")`
  position: absolute;
  left: 50%;
  top: 20px;
  width: 90%;
  transform: translateX(-50%);
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  resize: none;
  outline: 0;
  color: white;
  font-size: 2rem;
  max-height: calc(100% - 180px);
  text-align: center;
  line-height: 2;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  ${({ theme }) => theme.breakpoint.maxWidth.sm`
    top: 70px;
    width: 100%;
    font-size: 1.5rem;
    max-height: calc(100% - 230px);
  `}
`;

TextArea.defaultProps = {
  rows: 20,
};

export const InfinityMirrorWarningWrapper = styled(Grid)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px 30px;
`;

export const InfinityMirrorWarning = styled(BSPBody2)`
  display: inline-block;
  color: white;
  max-width: 550px;
  font-weight: 600;
  white-space: pre-wrap;
`;
