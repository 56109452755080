import { styled } from "@mui/material/styles";

export const BSPH4 = styled("h4")`
  font-family: "Roboto", var(--bigspring-primary-font);
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.0025em;
  margin: 0;
`;
