import { styled } from "@mui/material/styles";
import { BSPH1 } from "../../../BSPH1";

export const Title = styled(BSPH1)`
  width: 100%;
  font-weight: 700;
  user-select: none;
  font-size: 24px;
  line-height: 36px;

  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
