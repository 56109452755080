import { CCHelpers } from "@bigspring/core-components";
import { Language } from "@gql/generated/graphql";

export const orderByPosition = (a: number, b: number) => {
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  if (a! < b!) {
    return -1;
  }

  if (b! > a!) {
    return 1;
  }

  return 0;
};

export const orderByLanguageName = (
  a: Language,
  b: Language,
  defaultLanguage: Language
) => {
  if (a === defaultLanguage) {
    return -1;
  }
  if (b === defaultLanguage) {
    return 1;
  }

  const languageNameA = CCHelpers.getLanguageFullName(a, false);
  const languageNameB = CCHelpers.getLanguageFullName(b, false);

  return languageNameA.localeCompare(languageNameB);
};
