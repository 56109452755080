import React from "react";
import ToggleButtonGroupBase, {
  ToggleButtonGroupProps as ToggleButtonGroupPropsBase,
} from "@mui/material/ToggleButtonGroup";

import { ToggleButton as Button } from "./styles";

export type ToggleButtonGroupProps = ToggleButtonGroupPropsBase & {
  enforceValueSet?: boolean;
};

function Group({
  enforceValueSet,
  onChange: _onChange,
  ...props
}: ToggleButtonGroupProps) {
  const onChange = (
    event: React.MouseEvent<HTMLElement>,
    newValues: string[] | string | null
  ) => {
    if (Array.isArray(newValues) && newValues.length) {
      _onChange?.(event, newValues);
    } else if (newValues !== null) {
      _onChange?.(event, newValues);
    } else if (!enforceValueSet) {
      _onChange?.(event, newValues);
    }
  };

  return <ToggleButtonGroupBase {...props} onChange={onChange} />;
}

export const ToggleButton = {
  Button,
  Group,
};
