import { styled } from "@mui/material/styles";
import { PlayButtonSvg } from "../../../../../../../../icons";

export const Circle = styled("div")`
  width: 88px;
  height: 88px;
  border-radius: 88px;
  background: ${({ theme }) => theme.kinetic.v2.palette.rgba.grayTextDark(0.5)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonArea = styled("button")`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  z-index: 2;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    path {
      fill: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiLight};
    }
  }

  &:active {
    path {
      fill: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiDark};
    }
  }
`;

ButtonArea.defaultProps = {
  type: "button",
};

export const Icon = styled(PlayButtonSvg)`
  width: 64px;
  height: 64px;

  path {
    fill: ${({ theme }) => theme.kinetic.v2.palette.rgb.white};
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`;
