import { SC, CC, CCTypes } from "@bigspring/core-components";
import { motion } from "framer-motion";

const { css, Global, styled } = SC;

const Nprogress = (theme: CCTypes.Theme) => css`
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${theme.kinetic.v2.palette.rgb.blue};
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
  }

  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px ${theme.kinetic.v2.palette.rgb.blue},
      0 0 5px ${theme.kinetic.v2.palette.rgb.blue};
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }

  #nprogress .spinner {
    display: block;
  }

  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    color: ${theme.kinetic.palette.rgb.white};
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: box-shadow 300ms;
  }
`;

export function GlobalStyles() {
  return (
    <Global
      styles={(theme) =>
        css`
          :root {
            --bigspring-primary-font: "Roboto", sans-serif;
          }

          ${Nprogress(theme as any)};

          body {
            margin: 0;
            font-family: sans-serif;
          }

          a {
            text-decoration: none;
          }

          .SnackbarItem-nowrap > div {
            font-family: var(--bigspring-primary-font);
            flex-wrap: nowrap;
          }
        `
      }
    />
  );
}

export const Wrapper = styled(motion.div, SC.withTransientProps)<{
  $singleColumn?: boolean;
}>`
  display: grid;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: ${({ theme }) => theme.kinetic.palette.rgb.gray100};
  grid-template-columns: ${({ $singleColumn }) =>
    $singleColumn ? "1fr" : "280px 1fr"};
`;

export const Main = styled(motion.main)`
  position: relative;
  padding-bottom: 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const Version = styled(CC.BSPBody2)`
  display: block;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
  padding: 0 1rem;
  font-size: 0.75rem;
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
`;
