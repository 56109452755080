import { css, styled } from "@mui/material/styles";
import MenuMui, { MenuProps as MenuMuiProps } from "@mui/material/Menu";
import MenuItemMui, {
  MenuItemProps as MenuItemMuiProps,
} from "@mui/material/MenuItem";
import { withTransientProps } from "../../../lib/emotion";

export const Menu = styled(MenuMui)<MenuMuiProps>`
  .MuiMenu-paper {
    border-radius: ${({ theme }) => theme.kinetic.borderRadius.md};
    box-shadow: 0px 3px 6px
      ${({ theme }) => theme.kinetic.v2.palette.rgba.grayTextDark(0.24)};
  }
`;

type MenuItemProps = MenuItemMuiProps & {
  $color?: "utility" | "primary" | "negative" | "positive";
};

export const MenuItem = styled(MenuItemMui, withTransientProps)<MenuItemProps>`
  justify-content: center;
  padding: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;

  ${({ theme, $color }) => {
    let color: string;

    switch ($color) {
      default:
      case "utility":
        color = theme.kinetic.v2.palette.rgb.grayTextLighter;
        break;
      case "primary":
        color = theme.kinetic.v2.palette.rgb.blue;
        break;
      case "negative":
        color = theme.kinetic.v2.palette.rgb.negative;
        break;
      case "positive":
        color = theme.kinetic.v2.palette.rgb.positive;
        break;
    }

    return css`
      color: ${color};
    `;
  }}

  &:not(:last-child) {
    border-bottom: 1px solid
      ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiLight};
  }

  &.Mui-selected {
    background-color: ${({ theme }) =>
      theme.kinetic.v2.palette.rgb.grayUiLighter};

    &:hover {
      background-color: ${({ theme }) =>
        theme.kinetic.v2.palette.rgb.grayUiLight};
    }
  }

  &.Mui-focusVisible {
    background-color: initial;
  }
`;
